import React, { createContext, useState, useEffect, useContext } from 'react'
import { auth } from 'FirebaseConfig'
import { MediasContext } from 'providers/Medias'

const MediasOwnContext = createContext()

const MediasOwnProvider = ({ children }) => {
  const [mediasOwn, setMediasOwn] = useState([])

  const { medias } = useContext(MediasContext)

  useEffect(() => {
    if (medias) {
      auth.onAuthStateChanged((user) => {
        if (user) {
          let tmpmedias = medias.filter((row) => {
            return row.userId === user.uid && row.isDeleted !== true
          })
          setMediasOwn(tmpmedias)
        } else {
          console.log('could not find the specified user.')
        }
      })
    }
  }, [medias])

  return <MediasOwnContext.Provider value={{ mediasOwn }}>{children}</MediasOwnContext.Provider>
}

export { MediasOwnContext, MediasOwnProvider }
