import React from 'react'
import { SexPieChart } from 'components/organisms/charts/SexPieChart'
import { AgePieChart } from 'components/organisms/charts/AgePieChart'
import { MediaImage } from 'components/organisms/images/MediaImage'
import './index.css'

const ManageMediaDetail = ({ mediaTarget }) => {
  return (
    <div style={{ padding: '1rem 2rem' }}>
      <div style={{ gridColumn: '1/3', grigRow: '1' }}>
        <MediaImage media={mediaTarget} />
      </div>
      <div style={{ gridColumn: '1/3', grigRow: '2' }}>
        <table className="manageMediaDetail__mediaDetailTable">
          <tr>
            <th>住所</th>
            <td>{mediaTarget.address}</td>
          </tr>
          <tr>
            <th>サイズ[m]</th>
            <td>
              H{mediaTarget.heightSize} × W{mediaTarget.widthSize}
            </td>
          </tr>
          <tr>
            <th>高度[m]</th>
            <td>{mediaTarget.heightPosition}</td>
          </tr>
          <tr>
            <th>向き[°]</th>
            <td>{mediaTarget.heightPosition}</td>
          </tr>
          <tr>
            <th>掲載価格[円/月](DOOHは週額)</th>
            <td>{mediaTarget.cost}</td>
          </tr>
          <tr>
            <th>想定インプレッション[/月]</th>
            <td>{Math.round(mediaTarget.impression)}</td>
          </tr>
          <tr>
            <th>CPM（円）</th>
            <td>{Math.round((mediaTarget.cost / mediaTarget.impression) * 1000)}/1000Imp</td>
          </tr>
        </table>
      </div>

      <div style={{ paddingLeft: '4rem' }}>
        <p>性別</p>
        <SexPieChart maleRatio={mediaTarget.maleRatio} femaleRatio={mediaTarget.femaleRatio} />
      </div>
      <div>
        <p>年齢</p>
        <AgePieChart
          ratio15={mediaTarget.ratio15}
          ratio20={mediaTarget.ratio20}
          ratio30={mediaTarget.ratio30}
          ratio40={mediaTarget.ratio40}
          ratio50={mediaTarget.ratio50}
          ratio60={mediaTarget.ratio60}
          ratio70={mediaTarget.ratio70}
          ratio80={mediaTarget.ratio80}
        />
      </div>
    </div>
  )
}

export default ManageMediaDetail
