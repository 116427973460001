import React, { useContext, useEffect, useState } from 'react'
import FooterNav from 'components/organisms/menubar/FooterNav'
import * as contentful from 'contentful'
import { Link } from 'react-router-dom'
import { auth } from 'FirebaseConfig'
import NavigationBar from 'components/organisms/menubar/NavigationBar'
import NavigationBarOwner from 'components/organisms/menubar/NavigationBarOwner'
import NavigationBarPlane from 'components/organisms/menubar/NavigationBarPlane'
import { isOwner } from 'helpers/isOwner'
import { UsersContext } from 'providers/Users'
import { formatDate } from 'helpers/formats/formatDate'
import './index.css'

const Blogs = () => {
  const { users } = useContext(UsersContext)
  const [items, setItems] = useState([])
  const [owner, setOwner] = useState(false)
  const [customer, setCustomer] = useState(false)
  const [login, setLogin] = useState(false)
  const client = contentful.createClient({
    space: 'va5sd9zt8300',
    accessToken: 'Z1lOxlPSjqZC1kcovMl9G_9mSLrbWHyzTtZdeOuUlQU',
  })
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    if (isLoaded == false) {
      client
        .getEntries({
          content_type: 'blog',
          // order: "-fields.date"
        })
        .then((response) => {
          setItems(response.items)
          setLoaded(true)
        })
    }
  }, [client, isLoaded])

  // TODO: MEATAタグをコンポーネントにする
  useEffect(() => {
    const title = 'フィード｜AdVice（アドバイス）'
    const description =
      '屋外広告に関する記事を表示しています。屋外広告注文プラットフォーム「AdVice（アドバイス）」は、投資対効果ベースで屋外広告を注文できる新しいプラットフォームです。'
    document.title = title
    // title以外のmeta
    const headData = document.head.children

    for (let i = 0; i < headData.length; i++) {
      const nameVal = headData[i].getAttribute('name')
      if (nameVal !== null) {
        if (nameVal.indexOf('description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        // OGP(twitter)の設定
        if (nameVal.indexOf('twitter:title') !== -1) {
          headData[i].setAttribute('content', title)
        }
        if (nameVal.indexOf('twitter:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:title') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:image') !== -1) {
          headData[i].setAttribute('content', process.env.REACT_APP_OGP_URL + '/img_ogp.png')
        }
      }
    }
  })

  // TODO: セッション確認処理を共通化したコンポーネント
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setLogin(true)
        let ref = isOwner(users, user.uid)
        if (ref) {
          setOwner(true)
          setCustomer(false)
        } else {
          setOwner(false)
          setCustomer(true)
        }
      }
    })
  })

  return (
    <>
      <div style={{ display: 'grid' }}>
        <div style={{ gridRow: '1', gridColumn: '1' }} className="blogs__navBarGridRow">
          {owner && <NavigationBarOwner />}
          {customer && <NavigationBar />}
          {login ? <></> : <NavigationBarPlane />}
        </div>
        <div style={{ gridRow: '2', gridColumn: '1' }} className="blogs__appGridContent">
          <section className="latest-posts">
            <div className="title-container">
              <p className="title-container__sub">フィード</p>
            </div>
            <div className="posts">
              {items &&
                items.map((post) => (
                  <Link to={'/blog/' + post.fields.slug} class="post" key={post.fields.slug}>
                    <div className="thumb">
                      <img src={post.fields.image.fields.file.url} />
                    </div>
                    <div v-if="post.fields.type" className="type">
                      {post.fields.type}
                    </div>
                    <div className="post-text">
                      <h2>{post.fields.title}</h2>
                      <p>{formatDate(post.sys.createdAt)}</p>
                    </div>
                  </Link>
                ))}
            </div>
          </section>
          <div style={{ gridRow: '3', gridColumn: '1' }} className="blogs__appGridFooter">
            <FooterNav />
          </div>
        </div>
      </div>
      <style jsx>{`
        .type {
          text-align: left;
          display: inline-block;
          background-color: $primary;
          color: gray;
          padding: 0.5rem 0.75rem;
          font-size: 0.75rem;
          line-height: 0.75rem;
          border-radius: 0.875rem;
          font-weight: 800;
          margin-bottom: 1rem;
        }
        .title-container {
          padding: 2rem 0;
          text-align: center;
        }
        .title-container img {
          margin-bottom: 1rem;
        }
        .title-container__sub {
          text-align: center;
          font-size: 2rem;
          font-weight: 600;
        }
        .post-text {
          padding: 5px 10px 10px;
        }
        .post-text h2 {
          width: fit-content;
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }
        .post-text p {
          font-size: 1rem;
          font-weight: 700;
        }
        .thumb {
          width: 100%;
          padding-bottom: 75%;
          position: relative;
          overflow: hidden;
        }
        .thumb img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 100%;
        }
        a.post {
          width: calc(100% / 2 - 60px);
          text-align: left;
          margin: 30px;
          text-decoration: none;
          color: #111;
        }
        .posts {
          max-width: 1080px;
          margin: 0 auto;
          padding: 10px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
        section.latest-posts {
          padding: 2rem 0 6rem;
          background-color: white;
        }
      `}</style>
    </>
  )
}

export default Blogs
