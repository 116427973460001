import { HUBSPOT_CHAT_URL } from 'FirebaseConfig'
import { useEffect, useState } from 'react'

export const useHubspotChat = () => {
  const [content, setContent] = useState<HTMLElement | null>(document.getElementById('hubspot-messages-iframe-container'))

  const recursiveSearchContent = () => {
    const tmpContent = document.getElementById('hubspot-messages-iframe-container')
    if (tmpContent) {
      setContent(tmpContent)
    } else {
      setTimeout(() => {
        recursiveSearchContent()
      }, 1000)
    }
  }

  useEffect(() => {
    if (document) {
      if (content) {
        content.style.visibility = 'visible'
        return () => {
          content.style.visibility = 'hidden'
        }
      } else {
        if (HUBSPOT_CHAT_URL) {
          const script = document.createElement('script')
          script.src = HUBSPOT_CHAT_URL
          script.async = true
          document.body.appendChild(script)
          recursiveSearchContent()
        }
      }
    }
  }, [content])
}
