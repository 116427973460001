import React from 'react'
import 'App.css'
/*
import 'assets/Session.css'
import 'assets/Owner.css'
import 'assets/NavigationBar.css'
import 'assets/Card.css'
import 'assets/Cart.css'
import 'assets/css/Lp.scss'
import 'assets/Group.css'
import 'assets/Media.css'
*/
import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import paths from 'constants/paths'
import { AuthProvider } from 'providers/Auth'
import { AddMediasProvider } from 'providers/AddMedias'
import Signin from 'components/pages/SignIn/SignInPage'
import MailAction from 'components/pages/Action/ActionPage'
import VerifyEmail from 'components/pages/VerifyEmail/VerifyEmailPage'
import SigninOwner from 'components/pages/Owner/SignIn/SignInOwnerPage'
import Signup from 'components/pages/SignUp/SignUpPage'
import SignupOwner from 'components/pages/Owner/SignUp/SignUpOwnerPage'
import My from 'components/pages/My/MyPage'
import MyOwner from 'components/pages/Owner/My/OwnerMyPage'
import { UsersProvider } from 'providers/Users'
import MenuOwner from 'components/pages/Owner/Menu/MenuPage'
import DashboardOwner from 'components/pages/Owner/Dashboard/DashboardPage'
import AddMedia from 'components/pages/Owner/AddMedia/AddMediaPage'
import RestartMedia from 'components/pages/Owner/RestartMedia/RestartMediaPage'
import StopMedia from 'components/pages/Owner/StopMedia/StopMediaPage'
import { MediasProvider } from 'providers/Medias'
import { MediasOwnProvider } from 'providers/MediasOwn'
import { MediasOwnAllProvider } from 'providers/MediasOwnAll'
import DeleteMedia from 'components/pages/Owner/DeleteMedia/DeleteMediaPage'
import EditMedia from 'components/pages/Owner/EditMedia/EdiatMediaPage'
import NoPageFound from 'components/pages/404/404Page'
import AddMediasList from 'components/pages/Owner/AddMediaList/AddMediaListPage'
import GroupDashboard from 'components/pages/Group/Dashboard/DashboardPage'
import { CartsProvider } from 'providers/Carts'
import EditGroup from 'components/pages/Group/Edit/EditPage'
import AddGroup from 'components/pages/Group/Add/AddPage'
import EditCart from 'components/pages/Cart/Edit/EditPage'
import Blogs from 'components/pages/Blogs/BlogIndexPage'
import Blog from 'components/pages/Blog/BlogPage'
import ConfirmCart from 'components/pages/Cart/Confirm/ConfirmPage'
import ResetPassword from 'components/pages/ResetPassword/ResetPasswordPage'
import ResetPasswordOwner from 'components/pages/ResetPasswordOwner/ResetPasswordOwnerPage'
import Contact from './components/pages/Contact/ContactPage'
import DocumentRequest from './components/pages/Doc-req/DocumentRequestPage'
import { MediasAvailableProvider } from 'providers/MediasAvailable'
import { MediaPublishedAllProvider } from 'providers/MediaPublishedAll'
import Lp from 'components/pages/LandingPage'
import Submit from 'components/pages/Submit/SubmitPage'
import Media from 'components/pages/Media/MediaPage'
import MediaSearch from 'components/pages/MediaSearch/MediaSearchPage'
import MediaTop from 'components/pages/MediaTop/MediaTopPage'
import { CartsAllProvider } from 'providers/CartsAll'
import { MediaBookedAllProvider } from 'providers/MediaBookedAll'
import { UserProvider } from 'providers/User'
import { TagsProvider } from 'providers/Tags'
import AdminTags from 'components/pages/Admin/Tags'
import AdminConsole from 'components/pages/Admin/Console/ConsolePage'
import AdminSignin from 'components/pages/Admin/SignIn/AdminSignInPage'
import AdminAddMedia from 'components/pages/Admin/AddMedia/AddMediaPage'
import AdminEditMediaIndex from 'components/pages/Admin/EditMedia/MediaIndexPage'
import AdminEditMedia from 'components/pages/Admin/EditMedia/EditMediaPage'
import AdminNewMedia from 'components/pages/Admin/EditMedia/NewMediaPage'
import AdminOwners from 'components/pages/Admin/Owners/OwnersPage'
import AdminMediaUnderConsideration from 'components/pages/Admin/MediaUnderConsideration/MediaUnderConsiderationPage'
import Map from 'components/pages/Map'
import AdminPackages from 'components/pages/Admin/Packages/PackagesPage'
import PackagesProvider from 'providers/Packages'
import PackagePage from 'components/pages/Packages/Package/PackagePage'

function App() {
  return (
    <>
      <Router>
        <AuthProvider>
          <UsersProvider>
            <UserProvider>
              <TagsProvider>
                <MediasProvider>
                  <MediasOwnProvider>
                    <MediasOwnAllProvider>
                      <AddMediasProvider>
                        <CartsAllProvider>
                          <CartsProvider>
                            <MediasAvailableProvider>
                              <MediaPublishedAllProvider>
                                <MediaBookedAllProvider>
                                  <PackagesProvider>
                                    <Switch>
                                      <Route path={paths.resetpasswordowner} component={ResetPasswordOwner} key="resetpasswordowner" />
                                      <Route path={paths.resetpassword} component={ResetPassword} key="resetpassword" />
                                      <Route path={paths.confirmcart} component={ConfirmCart} key="confirmcart" />
                                      <Route path={paths.editcart} component={EditCart} key="editcart" />
                                      <Route path={paths.addgroup} component={AddGroup} key="addgroup" />
                                      <Route path={paths.editgroup} component={EditGroup} key="editgroup" />
                                      <Route path={paths.groupdashboard} component={GroupDashboard} key="groupdashboard" />
                                      <Route path={paths.addmedialist} component={AddMediasList} key="addmedialist" />
                                      <Route path={paths.nopagefound} component={NoPageFound} key="nopagefound" />
                                      <Route path={paths.editmedia} component={EditMedia} key="editmedia" />
                                      <Route path={paths.deletemedia} component={DeleteMedia} key="deletemedia" />
                                      <Route path={paths.stopmedia} component={StopMedia} key="stopmedia" />
                                      <Route path={paths.restartmedia} component={RestartMedia} key="restartmedia" />
                                      <Route path={paths.addmedia} component={AddMedia} key="addmedia" />
                                      <Route path={paths.dashboardowner} component={DashboardOwner} key="dashboardowner" />
                                      <Route path={paths.menuowner} component={MenuOwner} key="menuowner" />
                                      <Route path={paths.signup} component={Signup} key="signup" />
                                      <Route path={paths.signupowner} component={SignupOwner} key="signupowner" />
                                      <Route path={paths.signin} component={Signin} key="signin" />
                                      <Route path={paths.action} component={MailAction} key="action" />
                                      <Route path={paths.verifyEmail} component={VerifyEmail} key="verifyEmail" />
                                      <Route path={paths.signinowner} component={SigninOwner} key="signinowner" />
                                      <Route path={paths.my} component={My} key="my" />
                                      <Route path={paths.myowner} component={MyOwner} key="myowner" />
                                      <Route path={paths.contact} component={Contact} key="contact" />
                                      <Route path={paths.documentRequest} component={DocumentRequest} key="documentRequest" />
                                      <Route path={paths.blogs} component={Blogs} key="blogs" />
                                      <Route path={paths.blog} component={Blog} key="blog" />
                                      <Route path={paths.mediatop} component={MediaTop} key="mediaTop" />
                                      <Route path={paths.media} component={Media} key="media" />
                                      <Route path={paths.mediasearch} component={MediaSearch} key="mediasearch" />
                                      <Route path={paths.mapSearch} component={Map} key="mapSearch" />
                                      <Route path={paths.submit} component={Submit} key="submit" />
                                      <Route path={paths.package} component={PackagePage} key="package" />
                                      <Route path={paths.adminConsole} component={AdminConsole} key="adminConsole" />
                                      <Route path={paths.adminSignin} component={AdminSignin} key="adminSignin" />
                                      <Route path={paths.adminAddCSVMedia} component={AdminAddMedia} key="adminAddCSVMedia" />
                                      <Route
                                        path={paths.adminMediaUnderConsideration}
                                        component={AdminMediaUnderConsideration}
                                        key="adminMediaUnderConsideration"
                                      />
                                      <Route path={paths.adminAddOneMedia} component={AdminNewMedia} key="adminNewMedia" />
                                      <Route path={paths.adminEditMediaForm} component={AdminEditMedia} key="adminEditMedia" />
                                      <Route path={paths.adminEditMedia} component={AdminEditMediaIndex} key="adminEditMediaIndex" />
                                      <Route path={paths.adminOwners} component={AdminOwners} key="adminOwners" />
                                      <Route path={paths.adminTags} component={AdminTags} key="adminTags" />
                                      <Route path={paths.adminPackages} component={AdminPackages} key="adminPackages" />
                                      <Route exact path={paths.lp} component={Lp} key="lp" />
                                      <Route component={NoPageFound} key="nopagefound_1" />
                                    </Switch>
                                  </PackagesProvider>
                                </MediaBookedAllProvider>
                              </MediaPublishedAllProvider>
                            </MediasAvailableProvider>
                          </CartsProvider>
                        </CartsAllProvider>
                      </AddMediasProvider>
                    </MediasOwnAllProvider>
                  </MediasOwnProvider>
                </MediasProvider>
              </TagsProvider>
            </UserProvider>
          </UsersProvider>
        </AuthProvider>
      </Router>
      <ToastContainer />
    </>
  )
}

export default App
