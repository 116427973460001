import { Package } from 'components/organisms/admin/PackageTable/helper'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import billbord from 'assets/pictures/mediatop.png'

type props = {
  mediaPackage: Package
}
const PackageCard = ({ mediaPackage }: props) => {
  const history = useHistory()
  const handleClickCard = useCallback(() => {
    history.push(`packages/${mediaPackage.id}`)
  }, [])
  return (
    <div
      className="image-link"
      style={{
        background: `linear-gradient(to bottom, transparent 0%, transparent 65%, black 100%), url(${billbord})`,
        backgroundSize: 'cover',
        width: '95%',
        height: '14rem',
        borderRadius: '.5em',
        margin: '0.2rem',
        display: 'inline-block',
        cursor: 'pointer',
        boxShadow: '0 1px 4px 0 rgba(21,27,38,.08)',
      }}
      onClick={handleClickCard}
    >
      <p
        style={{
          textAlign: 'left',
          color: 'white',
          marginRight: '1rem',
          marginLeft: '1rem',
          marginTop: '0.5rem',
          fontSize: '1.8rem',
          fontWeight: 700,
        }}
      >
        {mediaPackage.name}
      </p>
    </div>
  )
}

export default PackageCard
