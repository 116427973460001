import { generateColumnDataTypes } from 'components/pages/Admin/AddMedia/AddMediaPage/helper'
import { mediaType } from 'helpers/mediaType/mediaDef'
import React from 'react'
import { Table } from 'semantic-ui-react'

type props = {
  csvData: null | string[][]
  mediaType: mediaType
  isJackChild: boolean
}

// TODO: コンポーネントでファイル切り出し、コンポーネントの一般化
export const MediaPreview = ({ csvData, mediaType, isJackChild = false }: props) => {
  if (!csvData) {
    return <div>No Data</div>
  }
  // create header
  const colNames = Object.keys(generateColumnDataTypes(mediaType, isJackChild))
  const headerElem = colNames.map((headerName) => <Table.Cell key={headerName}>{headerName}</Table.Cell>)

  const contentsElem = csvData.map((rowData, index) => {
    const rowElem = colNames.map((colName, idx) => (
      <Table.Cell key={idx}>{JSON.stringify(rowData[colName]) ? JSON.stringify(rowData[colName]) : ''}</Table.Cell>
    ))
    return <Table.Row key={index}>{rowElem}</Table.Row>
  })
  return (
    <div style={{ overflowX: 'scroll', overflowY: 'scroll' }}>
      <Table celled>
        <Table.Header>
          <Table.Row>{headerElem}</Table.Row>
        </Table.Header>

        <Table.Body>{contentsElem}</Table.Body>
      </Table>
    </div>
  )
}
