import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'providers/Auth'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import AppLayout from 'components/organisms/layouts/AppLayout'
import DashboardBreadCrump from 'components/organisms/breadcrumb/DashbordBreadcrump'
import GroupLists from 'components/organisms/group/GroupLists'
import AppLayoutGroupDashboard from 'components/organisms/layouts/AppLayoutGroup'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import { shouldSignin } from 'helpers/shouldSignin'
import './index.css'

const GroupDashboard = () => {
  const { currentUser } = useContext(AuthContext)
  const userId = currentUser && currentUser.uid ? currentUser.uid : ''
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (shouldSignin(currentUser)) {
      history.push(`${paths.signin}`)
    }
  })

  return (
    <>
      <AppLayout>
        <AppLayoutGroupDashboard>
          {loading && (
            <Dimmer active inverted>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
          )}
          <Segment>
            <DashboardBreadCrump />
          </Segment>
          <div className="group__dashboard__groupDashboardScroll">
            <GroupLists setLoading={setLoading} userId={userId} />
          </div>
        </AppLayoutGroupDashboard>
      </AppLayout>
    </>
  )
}

export default GroupDashboard
