import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer, PieLabelRenderProps, ContentRenderer } from 'recharts'

const COLORS = ['#4f8cfe', '#fa5a63', '#fac846', '#02b428', '#37dcfa', '#ff82c8', '#7c2efa', '#a1a1a1']

const RADIAN = Math.PI / 180
const renderCustomizedLabel: ContentRenderer<PieLabelRenderProps> = ({ cx, cy, midAngle, outerRadius, percent, index }) => {
  const fill = COLORS[Number(index) % COLORS.length]
  const sin = Math.sin(-RADIAN * Number(midAngle))
  const cos = Math.cos(-RADIAN * Number(midAngle))
  const sx = Number(cx) + Number(outerRadius) * cos
  const sy = Number(cy) + Number(outerRadius) * sin
  const mx = Number(cx) + (Number(outerRadius) + 20) * cos
  const my = Number(cy) + (Number(outerRadius) + 20) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${(Number(percent) * 100).toFixed(0)}%`}</text>
    </>
  )
}

export const AgePieChart = (props) => {
  const [data, setData] = useState([{}])
  useEffect(() => {
    setData([
      { name: '10代以下', value: Math.round(props.ratio15) },
      { name: '20代', value: Math.round(props.ratio20) },
      { name: '30代', value: Math.round(props.ratio30) },
      { name: '40代', value: Math.round(props.ratio40) },
      { name: '50代', value: Math.round(props.ratio50) },
      { name: '60代', value: Math.round(props.ratio60) },
      { name: '70代', value: Math.round(props.ratio70) },
      { name: '80代以上', value: Math.round(props.ratio80) },
    ])
  }, [props.ratio15, props.ratio20, props.ratio30, props.ratio40, props.ratio50, props.ratio60, props.ratio70, props.ratio80])

  return (
    <ResponsiveContainer width="100%" height={350}>
      <PieChart>
        <Pie
          data={data}
          cx={150}
          cy={120}
          labelLine={false}
          isAnimationActive={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          startAngle={90}
          endAngle={-270}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend verticalAlign="top" align="right" layout="vertical" />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  )
}
