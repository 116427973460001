import React, { createContext, useState, useEffect, useContext } from 'react'
import { db } from 'FirebaseConfig'
// import { MediaData } from './helpers'

type tagsContext = {
  tags: tagDataType
  lines: lineDataType
  highways: highwayDataType
}

export type tagDataType = any //TODO:tagsの型を作成
//  {
//   distance: number
//   docId: string
//   name: string
//   id: string
//   queries: { key: string; value: string }[]
// }
export type lineDataType = any //TODO:linesの型を作成

export type highwayDataType = any //TODO:highwaysの型を作成

const TagsContext = createContext({} as tagsContext)

// provide Tags
const TagsProvider = ({ children }) => {
  const [tags, setTags] = useState<tagDataType>({})
  const [lines, setLines] = useState<lineDataType>({})
  const [highways, setHighways] = useState<highwayDataType>({})
  useEffect(() => {
    //TODO: tagsのwordingを変える(media_tagsとかに)
    db.collectionGroup('tags').onSnapshot((query) => {
      const tempTags = {} as tagDataType
      for (const doc of query.docs) {
        const parentMediaId = doc.ref.parent.parent && doc.ref.parent.parent.id
        if (parentMediaId) {
          const tagData = { ...doc.data(), id: doc.id } as tagDataType
          tempTags[parentMediaId] = Array.isArray(tempTags[parentMediaId]) ? [...tempTags[parentMediaId], tagData] : [tagData]
        }
      }
      setTags(tempTags)
    })
  }, [])

  useEffect(() => {
    db.collectionGroup('lines').onSnapshot((query) => {
      const tempLines = {}
      for (const doc of query.docs) {
        const parentMediaId = doc.ref.parent.parent && doc.ref.parent.parent.id
        if (parentMediaId) {
          const tagData = { ...doc.data(), id: doc.id }
          tempLines[parentMediaId] = Array.isArray(tempLines[parentMediaId]) ? [...tempLines[parentMediaId], tagData] : [tagData]
        }
      }
      setLines(tempLines)
    })
  }, [])

  useEffect(() => {
    db.collectionGroup('highways').onSnapshot((query) => {
      const tempHighways = {}
      for (const doc of query.docs) {
        const parentMediaId = doc.ref.parent.parent && doc.ref.parent.parent.id
        if (parentMediaId) {
          const tagData = { ...doc.data(), id: doc.id }
          tempHighways[parentMediaId] = Array.isArray(tempHighways[parentMediaId]) ? [...tempHighways[parentMediaId], tagData] : [tagData]
        }
      }
      setHighways(tempHighways)
    })
  }, [])

  return (
    <>
      <TagsContext.Provider value={{ tags, lines, highways }}>{children}</TagsContext.Provider>
    </>
  )
}

export { TagsContext, TagsProvider }
export const useTags = () => {
  return useContext(TagsContext)
}
