type argType = {
  status: number
  startDate: { seconds: number }
  endDate: { seconds: number }
}
export const GetGroupStatusLabel = (cart: argType | null) => {
  let statusLabel = ''

  if (cart) {
    if (cart.status === 1) {
      statusLabel = '注文前'
    } else if (cart.status === 2) {
      const todaySeconds = Date.now() / 1000
      statusLabel = '注文中'
      if (cart.startDate && cart.startDate.seconds < todaySeconds) {
        statusLabel = '掲載中'
      }
      if (cart.endDate && cart.endDate.seconds < todaySeconds) {
        statusLabel = '掲載終了'
      }
    }
  }

  return statusLabel
}
