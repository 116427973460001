import React, { useState } from 'react'
import { mergeFilters } from 'components/pages/Admin/EditMedia/MediaIndexPage/helper'
import { Dropdown, Input, Button, GridRow } from 'semantic-ui-react'

// a component which handles filters
export const MediaFilterButtons = (props) => {
  let numberColNames = props.numberColNames
  let setParentFilterOptions = props.setParentFilterOptions
  let [filterOption, setFilterOption] = useState({ lowerBound: -Infinity, upperBound: Infinity, colName: '' })

  if (numberColNames.length === 0) {
    return <div></div>
  }

  // prepare buttons for cols whose values are number
  let filterItems = numberColNames.map((colName) => {
    return { text: colName, value: colName, key: colName }
  })
  const handleBoundChange = (event) => {
    const { name, value } = event.target
    setFilterOption((prevState) => ({
      ...prevState,
      [name]: parseFloat(value), // value is passed as a string
    }))
  }
  const handleColNameChange = (event, data) => {
    setFilterOption((prevState) => ({
      ...prevState,
      colName: data.value,
    }))
  }

  return (
    <GridRow>
      <Button
        onClick={() => {
          setParentFilterOptions((prevOption) => mergeFilters(prevOption, filterOption))
        }}
      >
        フィルタを適用
      </Button>
      <Dropdown
        item
        name="colName"
        text={filterOption.colName}
        options={filterItems}
        onChange={handleColNameChange}
        placeholder="フィルタを適用する行"
        value={filterOption.colName}
      />
      <Input name="lowerBound" type="number" placeholder="以上" value={filterOption.lowerBound} onChange={handleBoundChange} />
      <Input name="upperBound" type="number" placeholder="以下" onChange={handleBoundChange} value={filterOption.upperBound} />
    </GridRow>
  )
}
