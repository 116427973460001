// ### majorResidentsProperty
//     | value | 名前 | 補足説明 |
// | : ----: | : ----: | : ----: |
// | family | 家族 | |
// | single | 単身 | |
// | dinks | DINKs | DINKs(Double Income No Kids): 共働きで子供を意識的に作らない、持たない夫婦 |
// ### mansionType
//     | value | 名前 |
// | : ----: | : ----: |
// | forRent | 賃貸 |
// | forSale | 分譲 |

const MansionOptions = [
  { key: 'forRent', text: '賃貸', value: 'forRent' },
  { key: 'forSale', text: '分譲', value: 'forSale' },
]

export default MansionOptions
