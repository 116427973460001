import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { db } from 'FirebaseConfig'

const TagList = ({ tags, color, textColor = 'black' }) => {
  const history = useHistory()
  const [isTag, setIsTag] = useState([])
  const [lineIds, setLineIds] = useState([])
  useEffect(() => {
    const tagsUnsubscriber = db.collection('tags').onSnapshot((snapshot) => {
      const temp = []
      for (const doc of snapshot.docs) {
        const tag = doc.data()
        temp.push({
          key: doc.id,
          value: tag.name,
        })
      }
      setIsTag(temp)
    })
    const linesUnsubscriber = db.collection('lines').onSnapshot((snapshot) => {
      const temp = snapshot.docs.map((doc) => doc.id)
      setLineIds(temp)
    })
    return () => {
      tagsUnsubscriber()
      linesUnsubscriber()
    }
  }, [])

  const clickLabelHandler = (tag) => {
    for (const doc of isTag) {
      if (tag === doc['key']) {
        return () => history.push(`/mediasearch?tag=${tag}`)
      }
    }
    if (lineIds.includes(tag)) {
      return () => history.push(`/mediasearch?line=${tag}`)
    }
    return () => history.push(`/mediasearch?highway=${tag}`)
  }

  return (
    <>
      {tags &&
        tags.map((tag) => (
          <div
            className="ui label"
            style={{ background: color, color: textColor, cursor: 'pointer', marginTop: 2, marginBottom: 2 }}
            onClick={clickLabelHandler(tag.id)}
            key={tag.id}
          >
            {tag.name}
          </div>
        ))}
    </>
  )
}

export default React.memo(TagList)
