import { cartDataType } from 'providers/CartsAll'
import { AdviceMediaDataType } from 'constants/mediaData'

//カートに入っている媒体をIDを元に抽出
export const mediaListCartGenerator = (medias: Array<AdviceMediaDataType>, cart: cartDataType) => {
  let mediaList: Array<AdviceMediaDataType | undefined> = []
  if (cart.id && cart.id.length && medias !== []) {
    mediaList = cart.id.map((mediaId) => {
      const refMedia: AdviceMediaDataType | undefined = medias.find((m) => m.uid === mediaId)
      if (refMedia) {
        return refMedia
      }
    })
  }
  return mediaList.flatMap((media) => (media === undefined ? [] : [media]))
}
export default mediaListCartGenerator
