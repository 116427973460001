import React from 'react'
import { auth } from 'FirebaseConfig'
import { Button, Table } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'

const MyPassword = (props) => {
  let domain = document.domain
  let port = domain === 'localhost' ? 3000 : ''
  let prot = domain === 'localhost' ? 'http' : 'https'
  const actionCodeSettings = {
    url: `${prot}://${domain}:${port}/${props.path}`,
  }

  const history = useHistory()

  const sendPasswordChangeEmail = () => {
    if (window.confirm('パスワード変更メールを送信しますか？')) {
      auth.onAuthStateChanged(function (user) {
        auth
          .sendPasswordResetEmail(user.email, actionCodeSettings)
          .then(function () {
            // Email sent.
            history.go(0)
            history.push({
              state: {
                text: `${user.email}宛にパスワード変更メールを送信しました`,
                type: 'positive',
              },
            })
          })
          .catch(function (error) {
            // An error happened.
            console.log(error)
            history.go(0)
            history.push({
              state: {
                text: `${user.email}宛にパスワード変更メールを送信できませんでした[${error}]`,
                type: 'negative',
              },
            })
          })
      })
    }
  }

  return (
    <>
      <Table celled>
        <Table.Body>
          <Table.Row>
            <Table.Cell>パスワード</Table.Cell>
            <Table.Cell>
              <>
                <Button color="orange" onClick={() => sendPasswordChangeEmail()}>
                  パスワード変更
                </Button>
              </>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  )
}

export default MyPassword
