import React from 'react'
import { Table } from 'semantic-ui-react'

type Props = {
  targetLocation: string[]
}

const TargetLocationCell = (props: Props) => {
  const l = props.targetLocation.length
  let jsxElms: JSX.Element[] = []
  if (l) {
    jsxElms = props.targetLocation.map((areaName, index) => {
      if (index !== l - 1) {
        if (index % 3 !== 2) {
          return <span>{areaName}、</span>
        } else {
          return (
            <span>
              {areaName}、<br />
            </span>
          )
        }
      } else {
        return <span>{areaName}</span>
      }
    })
  } else {
    jsxElms.push(<span>全て</span>)
  }
  return <Table.Cell>{jsxElms}</Table.Cell>
}

export default TargetLocationCell
