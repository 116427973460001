import React from 'react'
import { Header, Form, Button } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import Spacer from 'components/atoms/Spacer'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import './index.css'

const ResetPasswordForm = (props) => {
  const { handleSubmit, register, errors } = useForm()
  const history = useHistory()

  return (
    <>
      <br />
      <Header as="h2">{props.header}</Header>
      <div className="resetPasswordForm__loginFormNote">{props.note}</div>
      <br />
      <Form onSubmit={handleSubmit(props.clickSendResetPasswordMail)}>
        <p>
          <b>登録済みメールアドレス</b>
        </p>
        <input
          name="email"
          id="email"
          placeholder="name@domain.com"
          onChange={props.handleChange}
          ref={register({
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'invalid email address',
            },
          })}
        />
        <div>{errors.email ? errors.email.message : <>&nbsp;</>}</div>
        <br />
        <Button color="orange" type="submit" fluid>
          送信する
        </Button>
        <br />

        {props.owner ? (
          <>
            <Button fluid onClick={() => history.push(`${paths.signinowner}`)}>
              ログインはこちら
            </Button>
          </>
        ) : (
          <>
            <Button fluid onClick={() => history.push(`${paths.signin}`)}>
              ログインはこちら
            </Button>
          </>
        )}
      </Form>
      <Spacer />
    </>
  )
}

export default ResetPasswordForm
