import React, { createContext, useState, useEffect, useContext } from 'react'
import { AuthContext } from 'providers/Auth'
import { auth } from 'FirebaseConfig'
import { MediasContext } from 'providers/Medias'

const MediasOwnAllContext = createContext()

const MediasOwnAllProvider = ({ children }) => {
  const [mediasOwnAll, setMediasOwnAll] = useState([])
  const { currentUser } = useContext(AuthContext)

  const { medias } = useContext(MediasContext)

  useEffect(() => {
    if (medias) {
      auth.onAuthStateChanged((user) => {
        if (user) {
          let tmpmedias = medias
          tmpmedias = tmpmedias.filter((row) => {
            if (row.userId === user.uid) {
              return row
            }
          })
          setMediasOwnAll(tmpmedias)
        }
      })
    }
  }, [currentUser, medias])

  return <MediasOwnAllContext.Provider value={{ mediasOwnAll }}>{children}</MediasOwnAllContext.Provider>
}

export { MediasOwnAllContext, MediasOwnAllProvider }
