import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import GroupListBillboard from 'components/organisms/group/GroupListBillboard'
import GroupListDigitalSignage from 'components/organisms/group/GroupListDigitalSignage'
import GroupListMansionSignage from 'components/organisms/group/GroupListMansionSignage'
import GroupListLeaflet from 'components/organisms/group/GroupListLeaflet'
import GroupListOthers from 'components/organisms/group/GroupListOthers'
import { CartsContext } from 'providers/Carts'
import firebase, { db } from 'FirebaseConfig'
import { CartFindFilter } from 'helpers/filters/CartFindFilter'
import { Button, Icon, Modal } from 'semantic-ui-react'
import GroupFilters from 'components/organisms/group/GroupFilters'
import { dateSortCallback } from 'components/pages/Group/Dashboard/DashboardPage/helper'
import { statusFilter, mediaTypeFilter, groupSearchFilter } from '../helper'

const GroupLists = (props) => {
  const history = useHistory()
  const location = useLocation()
  const { carts } = useContext(CartsContext)
  const [filteredCarts, setFilteredCarts] = useState([])
  const [cartId, setCartId] = useState('')

  // フィルター初期値
  //マジックナンバー? 1,2,3,4が何を指しているかわからない
  const [status1, setStatus1] = useState(true)
  const [status2, setStatus2] = useState(true)
  const [status3, setStatus3] = useState(true)
  const [status4, setStatus4] = useState(true)

  useEffect(() => {
    let tmpcarts = carts
    //cartsからidが一致するものを取り出す
    tmpcarts = groupSearchFilter(tmpcarts, props.userId)
    //ステータスでフィルターをかける
    tmpcarts = statusFilter(tmpcarts, status1, status2, status3, status4)
    //日付でソートする
    tmpcarts.sort(dateSortCallback)
    //mediaTypeごとに分けてオブジェクトにする
    tmpcarts = mediaTypeFilter(tmpcarts)
    //tmpcartsをセットする
    setFilteredCarts(tmpcarts)
    setCartId('')
  }, [carts, location.search, status1, status2, status3, status4])

  const handleChgange = (cartUid) => {
    if (cartUid === cartId) {
      setCartId('')
    } else {
      setCartId(cartUid)
    }
  }
  //グループのコピー
  const copyCart = () => {
    console.log('copy cart')
    if (window.confirm('グループのコピーを作成しますか？')) {
      console.log('pass confirm to copy')
      let cartRef = CartFindFilter(carts, cartId)
      let docId = db.collection('cart').doc().id
      let data = {}
      data.groupName = 'コピー_' + cartRef.groupName
      data.uid = docId
      data.docId = docId
      data.budget = cartRef.budget ? cartRef.budget : null
      data.startDate = cartRef.startDate ? cartRef.startDate : null
      data.endDate = cartRef.endDate ? cartRef.endDate : null
      data.status = 1
      data.targetAge = cartRef.targetAge ? cartRef.targetAge : null
      data.targetLocation = cartRef.targetLocation ? cartRef.targetLocation : null
      data.targetSex = cartRef.targetSex ? cartRef.targetSex : null
      data.mediaType = cartRef.mediaType ? cartRef.mediaType : null
      data.userId = cartRef.userId ? cartRef.userId : null
      db.collection('cart')
        .doc(docId)
        .set(data)
        .then(() => {
          props.setLoading(false)
          history.push({
            pathname: `/group/dashboard`,
            // search: `?campaignId=${props.campaign.uid}`,
            state: {
              text: '複製しました。',
              type: 'positive',
            },
          })
        })
        .catch((error) => {
          props.setLoading(false)
          console.log(error)
          history.push({
            state: {
              text: error,
              type: 'negative',
            },
          })
        })
    }
  }
  //グループの削除
  const deleteCart = () => {
    if (window.confirm('グループを削除しますか？')) {
      props.setLoading(true)
      console.log('pass confirm to delete')
      let today = new Date(Date.now())
      db.collection('cart')
        .doc(cartId)
        .update({
          isDeleted: true,
          deletedAt: firebase.firestore.Timestamp.fromDate(today),
        })
        .then(() => {
          props.setLoading(false)
          history.push({
            pathname: `/group/dashboard`,
            // search: `?campaignId=${props.campaign.uid}`,
            state: {
              text: '削除しました。',
              type: 'positive',
            },
          })
        })
        .catch((error) => {
          props.setLoading(false)
          console.log(error)
          history.push({
            state: {
              text: error,
              type: 'negative',
            },
          })
        })
    }
  }

  const handleChgangeFilter = (e, { name }) => {
    switch (name) {
      case 'status1':
        setStatus1(!status1)
        break
      case 'status2':
        setStatus2(!status2)
        break
      case 'status3':
        setStatus3(!status3)
        break
      case 'status4':
        setStatus4(!status4)
        break
      default:
        console.log('no key match')
    }
  }

  return (
    <>
      <div className="group__dashboard__groupDashboardButtons">
        <Button color="orange" onClick={() => history.push(`/group/add`)}>
          <Icon name="plus" />
          作成
        </Button>
        &nbsp; &nbsp;
        <Button disabled={!cartId} onClick={() => copyCart(cartId)}>
          複製
        </Button>
        &nbsp; &nbsp;
        <Button disabled={!cartId} onClick={() => history.push(`/group/edit/${cartId}`)}>
          編集
        </Button>
        &nbsp; &nbsp;
        <Button disabled={!cartId} onClick={() => history.push(`/submit/${cartId}`)}>
          クリエイティブ入稿
        </Button>
        &nbsp; &nbsp;
        <Button color="red" disabled={!cartId} onClick={() => deleteCart(cartId)}>
          削除
        </Button>
        &nbsp; &nbsp;
        <Modal
          dimmer="inverted"
          trigger={
            <Button>
              フィルタ
              <Icon name="triangle down" />
            </Button>
          }
        >
          <Modal.Header>フィルタ</Modal.Header>
          <Modal.Content>
            <GroupFilters
              handleChgangeFilter={handleChgangeFilter}
              status1={status1}
              setStatus1={setStatus1}
              status2={status2}
              setStatus2={setStatus2}
              status3={status3}
              setStatus3={setStatus3}
              status4={status4}
              setStatus4={setStatus4}
            />
          </Modal.Content>
        </Modal>
        <br />
        <br />
      </div>
      {/* .length > 0をつけないと0がレンダーされてしまう */}
      {filteredCarts.billboard && filteredCarts.billboard.length > 0 && (
        <GroupListBillboard carts={filteredCarts.billboard} handleChgange={handleChgange} cartId={cartId} />
      )}
      {filteredCarts.digitalSignage && filteredCarts.digitalSignage.length > 0 && (
        <GroupListDigitalSignage carts={filteredCarts.digitalSignage} handleChgange={handleChgange} cartId={cartId} />
      )}
      {filteredCarts.mansionSignage && filteredCarts.mansionSignage.length > 0 && (
        <GroupListMansionSignage carts={filteredCarts.mansionSignage} handleChgange={handleChgange} cartId={cartId} />
      )}
      {filteredCarts.leaflet && filteredCarts.leaflet.length > 0 && (
        <GroupListLeaflet carts={filteredCarts.leaflet} handleChgange={handleChgange} cartId={cartId} />
      )}
      {filteredCarts.others && filteredCarts.others.length > 0 && (
        <GroupListOthers carts={filteredCarts.others} handleChgange={handleChgange} cartId={cartId} />
      )}
    </>
  )
}

export default GroupLists
