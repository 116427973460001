import React, { useContext, useEffect, useState } from 'react'
import { MediasOwnContext } from 'providers/MediasOwn'
import { MediasSearchFilter } from 'helpers/filters/MediasSearchFilter'
import { Card, Form, Segment } from 'semantic-ui-react'
import MediaCard from 'components/organisms/card/MediaCard'
import Spacer from 'components/atoms/Spacer'
import './index.css'

const ManageMediaTab = ({ mediaTarget, setMediaTarget }) => {
  const { mediasOwn } = useContext(MediasOwnContext)
  const [searchWord, setSearchWord] = useState('')
  const [medias, setMedias] = useState('')
  const [tabs, setTabs] = useState(2)
  const handleChange = (event) => {
    setSearchWord(event.target.value)
  }

  useEffect(() => {
    if (mediaTarget) {
      setTabs(3)
    } else {
      setTabs(3)
    }
  }, [mediaTarget])

  useEffect(() => {
    let tmpmedias = []
    if (searchWord) {
      tmpmedias = MediasSearchFilter(mediasOwn, searchWord)
    } else {
      tmpmedias = mediasOwn
    }
    setMedias(tmpmedias)
  }, [mediasOwn, searchWord])

  return (
    <>
      <Form>
        <input type="text" name="searchWord" placeholder="キーワードで候補を絞る" value={searchWord} onChange={handleChange} />
      </Form>

      <Segment>
        <div className="manageMediaTab__scrollColumn">
          <Card.Group itemsPerRow={tabs} textAlign="center">
            {medias &&
              medias.map((media) => (
                <>
                  <MediaCard media={media} setMediaTarget={setMediaTarget} />
                </>
              ))}
          </Card.Group>
        </div>
      </Segment>
      <Spacer />
    </>
  )
}

export default ManageMediaTab
