import React, { useEffect, useState, useContext, useMemo } from 'react'
import { Button, Table } from 'semantic-ui-react'
import MediaCardCart from 'components/organisms/card/MediaCardCart'
import { mediaListGenerator } from 'helpers/filters/mediaListGenerator'
import { MediaBookedAllContext } from 'providers/MediaBookedAll'
import Spacer from 'components/atoms/Spacer'
import './index.css'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import { calcBillboardCost } from 'helpers/mediaType/mediaTypeBillboard'
import { AdviceBillboardDataType } from 'constants/mediaData'
import { daysInMonth } from 'constants/constants'
import { MediasAvailableContext } from 'providers/MediasAvailable'

type KnapsackArray = {
  c: number
  b: number
  n: string
}

const numRecommend = 20 //recommendの数
//ナップサック問題コピペ関数ここからSTATEやPROPSに依存しないのでスコープ外に押し出した
const knapsack = (obj, max_cost: number) => {
  const arr: KnapsackArray[] = []
  for (const key in obj) {
    // {c: コスト合計, b：ターゲットインプレッション合計, n:[c,b]}
    arr.push({ c: obj[key][0], b: obj[key][1], n: key })
  }
  // Sort descending for a 'greedy' initial search order.
  arr.sort(function (a, b) {
    if (a.b === b.b) {
      //if (a.a == b.b) { TODO:元々は左の通りだったので間違ってる可能性あり
      // lower cost breaks tie
      return a.c - b.c
    }
    return b.b - a.b // Consider highest value first.
  })

  const memo = {}
  const ret = knap(arr, max_cost, 0, memo)

  for (let i = 0; i < ret[0].length; i++) {
    // Name the winners.
    ret[0][i] = arr[ret[0][i]].n
  }

  return ret
}

const knap = (items, max_cost, min_ben, memo) => {
  // items={[costSum, impSum,key}, max_cost=budget, min_ben=0, memo=
  let best: number[] = []
  let cost = 0
  let remain_ben = 0
  if (min_ben < 0) min_ben = 0
  const min_ben_inp = min_ben // save the lower bound we started with for caching purposes

  const note = items.length + ' ' + max_cost // attempt to lookup the answer
  if (note in memo) {
    if (memo[note][0] <= min_ben || memo[note][3] >= min_ben) {
      return memo[note].slice(1)
    }
  }

  for (let i = 0; i < items.length; i++) {
    if (items[i].c > max_cost) {
      continue
    } else {
      remain_ben += items[i].b
    } // Can't include.

    if (remain_ben < min_ben) {
      // Early termination check.
      break
    }
    remain_ben -= items[i].b

    const ret = knap(items.slice(i + 1), max_cost - items[i].c, min_ben - items[i].b, memo)
    if (ret[2] + items[i].b > min_ben) {
      // Found a better subproblem solution.
      best = ret[0].map(function (j) {
        return i + j + 1
      })
      best.push(i)
      cost = ret[1] + items[i].c
      min_ben = ret[2] + items[i].b // up the ante
    }
  }

  if (best.length === 0) {
    memo[note] = [min_ben_inp, [], 0, 0]
  } else {
    memo[note] = [min_ben_inp, best, cost, min_ben]
  }

  return memo[note].slice(1)
}
//ナップサック問題コピペ関数ここまで

const CartRecommendTab = (props) => {
  const { mediaBookedAll }: any = useContext(MediaBookedAllContext) //TODO:any
  const [mediaSortedList, setMediaSortedList] = useState<AdviceBillboardDataType[]>([]) //表示媒体のリスト
  const [bundleSummary, setBundleSummary] = useState({}) //表示媒体の束のサマリー
  const { mediasAvailable } = useContext(MediasAvailableContext)

  useEffect(() => {
    // props.setLoading(true);
    // 一回目のときだけ、提案する
    if (props.mediaList && props.cart.status === 1 && props.period) {
      const tmpmedias: AdviceBillboardDataType[] = props.mediaList
      let mediaList: any[] = [] //TODO:any
      if (tmpmedias && mediaBookedAll) {
        mediaList = mediaListGenerator(tmpmedias, props.cart, mediaBookedAll, mediasAvailable)
      }
      // 予算内でターゲットへの広告効果が最大化されるよう媒体が先頭に来るように並び替える
      const tmpObj = {}
      for (let i = 0; i < mediaList.length; i++) {
        //targetImpがNaNの場合があるので、その場合0扱いにする
        const visibility: number = mediaList[i].visibility || 0
        const circulationPerDay: number = mediaList[i].circulationPerDay || 0
        const targetEffect: number = circulationPerDay * visibility * daysInMonth
        const price: number = calcBillboardCost(mediaList[i], props.period.months, true, true, true)
        tmpObj[mediaList[i].uid] = [price, targetEffect * props.period.months]
      }
      const selectedMediaId = knapsack(tmpObj, props.cart.budget)
      let recommendedMediaList = mediaList.filter((medium) => {
        return selectedMediaId[0].indexOf(medium.uid) !== -1
      })
      // 一括で更新
      if (recommendedMediaList.length > numRecommend) {
        recommendedMediaList = recommendedMediaList.slice(0, numRecommend)
      }
      setMediaSortedList(recommendedMediaList) // おすすめする媒体数をnumRecommendに絞る
    }

    props.setLoading(false)
  }, [props.mediaList, props.cart, props.period])

  useMemo(() => {
    //recommend媒体セットの合計価格、インプレッション、ターゲットインプレッションを算出しセットする
    const colInfo = getColInfoFromTypeName(props.cart.mediaType)
    if (mediaSortedList) {
      const newBundleSummary = colInfo.culcBundleSummary(mediaSortedList, props.period, true)
      setBundleSummary(newBundleSummary)
    }
  }, [mediaSortedList])

  useEffect(() => {
    const listedMedias: string[] = []
    mediaSortedList.forEach((media) => {
      listedMedias.push(media.uid)
    })

    props.setRecommendCount(listedMedias.length)
    if (props.activeItem === 'recommend') {
      props.setListedMedias(listedMedias)
    }
  }, [props.activeItem, mediaSortedList])

  const onClickMediaCard = (mediaId, index) => {
    props.setMediaTarget(mediaId)
    props.setCurrentKey(index)
  }

  const onClickAddButton = () => {
    if (window.confirm('おすすめのセットをカートに一括追加します。現状のカートのデータは更新されますがよろしいですか。')) {
      props.changeAllMediaInCart(mediaSortedList)
      props.setActiveItem('cart')
    }
  }

  const colInfo = getColInfoFromTypeName(props.cart.mediaType)
  const jsxElems: JSX.Element[] = []
  if (Object.keys(bundleSummary).length) {
    for (const key of colInfo.cartRecommendSummary) {
      jsxElems.push(
        <Table.Row>
          <Table.Cell>{key.displayedName}</Table.Cell>
          <Table.Cell>{key.displayMethod(bundleSummary)}</Table.Cell>
        </Table.Row>
      )
    }
  }

  return (
    <>
      <div className="cartRecommendTab__scrollSearchTab">
        {mediaSortedList.length < 1 ? (
          <p>入力された広告グループ情報に合う媒体が見つかりません。再度広告グループ情報を編集してください。</p>
        ) : (
          <>
            <Button color="orange" style={{ marginBottom: '2rem' }} onClick={() => onClickAddButton()}>
              セットを一括追加
            </Button>
            <p>セットの合計値</p>
            <Table celled size="small">
              <Table.Body>{jsxElems}</Table.Body>
            </Table>
            <Spacer />
          </>
        )}
        {mediaSortedList &&
          mediaSortedList.map((media, index) => (
            <div
              onClick={() => onClickMediaCard(media.uid, index)}
              onMouseEnter={() => props.setCurrentKeyInfo(index)}
              onMouseLeave={() => props.setCurrentKeyInfo(-1)}
              className={`cartRecommendTab__mediaCardDefault ${index === props.currentKeyInfo ? '' : ''} ${
                index === props.currentKey ? 'cartRecommendTab__mediaCardSelected' : ''
              }`}
              key={media.uid}
            >
              <MediaCardCart media={media} period={props.period} />
            </div>
          ))}
      </div>
    </>
  )
}

export default CartRecommendTab
