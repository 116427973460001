import React from 'react'
import { usePackages } from 'providers/Packages'
import PackageCard from '../PackageCard'
import { Grid } from 'semantic-ui-react'

const PackageList = () => {
  const packages = usePackages()
  return (
    <div>
      <h2>パッケージ</h2>
      <Grid>
        {packages.map((mediaPackage) => {
          return (
            <Grid.Column key={mediaPackage.id} width={4}>
              <PackageCard mediaPackage={mediaPackage} />
            </Grid.Column>
          )
        })}
      </Grid>
    </div>
  )
}

export default PackageList
