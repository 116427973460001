import React from 'react'
import { List, Container } from 'semantic-ui-react'
import AppLayoutAdmin from 'components/organisms/layouts/AppLayoutAdmin'
import paths from 'constants/paths'
import { useHistory } from 'react-router'
const AdminConsole = () => {
  const history = useHistory()
  const handleItemClick = (event, { name }) => {
    switch (name) {
      case 'root':
        history.push(`${paths.adminConsole}`)
        break
      case 'adminLogOut':
        history.push(`${paths.lp}`)
        break
      case 'adminAddCSVMedia':
        history.push(`${paths.adminAddCSVMedia}`)
        break
      case 'adminMediaUnderConsideration':
        history.push(`${paths.adminMediaUnderConsideration}`)
        break
      case 'adminEditMedia':
        history.push(`${paths.adminEditMedia}`)
        break
      case 'adminAddOneMedia':
        history.push(`${paths.adminAddOneMedia}`)
        break
      case 'adminOwners':
        history.push(`${paths.adminOwners}`)
        break
      case 'adminTags':
        history.push(`${paths.adminTags}`)
        break
      case 'adminPackages':
        history.push(paths.adminPackages)
        break
      default:
        console.log('No key match')
    }
  }

  return (
    <>
      <AppLayoutAdmin>
        <Container>
          <div style={{ paddingTop: 20 }}>
            <h2>AdVice管理者ツール</h2>
            <hr />
            <List divided relaxed>
              <List.Item as="a" name="adminTags" onClick={handleItemClick}>
                <List.Icon name="tag" verticalAlign="middle" />
                <List.Content>
                  <List.Header>タグ一覧</List.Header>
                  <List.Description>タグの一覧/作成/更新/削除を行うことができます</List.Description>
                </List.Content>
              </List.Item>
              <List.Item as="a" name="adminMediaUnderConsideration" onClick={handleItemClick}>
                <List.Icon name="list" verticalAlign="middle" />
                <List.Content>
                  <List.Header>被検討媒体一覧</List.Header>
                  <List.Description>各媒体ごとのpdf出力/cart追加の履歴から検討状態を一覧で確認することができます</List.Description>
                </List.Content>
              </List.Item>
              <List.Item as="a" name="adminOwners" onClick={handleItemClick}>
                <List.Icon name="list" verticalAlign="middle" />
                <List.Content>
                  <List.Header>媒体主一覧</List.Header>
                  <List.Description>AdViceに登録した媒体主の一覧を表示します</List.Description>
                </List.Content>
              </List.Item>
              <List.Item as="a" name="adminAddCSVMedia" onClick={handleItemClick}>
                <List.Icon name="add" verticalAlign="middle" />
                <List.Content>
                  <List.Header>CSVから媒体追加</List.Header>
                  <List.Description>掲載媒体の作成/更新をCSVファイルを用いて行うことができます</List.Description>
                </List.Content>
              </List.Item>
              <List.Item as="a" name="adminEditMedia" onClick={handleItemClick}>
                <List.Icon name="edit" verticalAlign="middle" />
                <List.Content>
                  <List.Header>媒体一覧</List.Header>
                  <List.Description>掲載媒体の一覧/更新を行うことができます</List.Description>
                </List.Content>
              </List.Item>
              <List.Item as="a" name="adminAddOneMedia" onClick={handleItemClick}>
                <List.Icon name="add" verticalAlign="middle" />
                <List.Content>
                  <List.Header>媒体追加(adres)</List.Header>
                  <List.Description>媒体作成を行うことができます</List.Description>
                </List.Content>
              </List.Item>
              <List.Item as="a" name="adminPackages" onClick={handleItemClick}>
                <List.Icon name="list" verticalAlign="middle" />
                <List.Content>
                  <List.Header>パッケージ一覧</List.Header>
                  <List.Description>パッケージの一覧/作成/更新/削除を行うことができます</List.Description>
                </List.Content>
              </List.Item>
            </List>
          </div>
        </Container>
      </AppLayoutAdmin>
    </>
  )
}

export default AdminConsole
