import React from 'react'
import { firestore } from 'firebase/app'

type Props = {
  timestamp: firestore.Timestamp
}
// firestoreのtimestamp型を年月日表示するためのコンポーネント
export const GetYearMonthDate = ({ timestamp }: Props) => {
  return (
    <>
      {timestamp.toDate().getFullYear() + '年'}
      {/*  */}
      {timestamp.toDate().getMonth() + 1 + '月'}
      {timestamp.toDate().getDate() + '日'}
    </>
  )
}
