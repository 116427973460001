import React from 'react'
import { Table } from 'semantic-ui-react'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import Loading from 'components/atoms/Loading'

const parseArea = (areaStr) => {
  if (areaStr && areaStr.indexOf('区') !== -1) {
    const city = areaStr.split('区')
    return city[0] + '区'
  } else {
    return ''
  }
}

// 特に支障のない場合、媒体情報の画面表示に使われる関数コンポーネント
const DefaultShorterTable = ({ media }) => {
  const basicInfoFields = getColInfoFromTypeName(media.mediaType).basicInfoFields
  const area = parseArea(media.address) //TODO: areaの関数をdisplayMethodに入れてこの関数コンポーネントが正しい表を返すようにする
  const colInTable = ['area', 'address', 'latitude', 'longitude']
  const fields = colInTable.map((colName) => {
    return colName in media && colName in basicInfoFields ? (
      <Table.Row>
        <Table.Cell>{basicInfoFields[colName].displayedName}</Table.Cell>
        <Table.Cell>{area}</Table.Cell>
      </Table.Row>
    ) : (
      ''
    )
  })
  return <Table>{fields}</Table>
}

// mediaTypeごとに適切な媒体情報表示用コンポーネントを選んで返すファクトリメソッド
const MediaDetailTableShorter = ({ media }) => {
  if (!media) {
    return <Loading />
  }
  switch (media.mediaType) {
    default:
      return <DefaultShorterTable media={media} />
  }
}

export default MediaDetailTableShorter
