import React, { useEffect, useContext, useState } from 'react'
import { Button, Dimmer, Loader, Segment, Message } from 'semantic-ui-react'
import { auth, db } from 'FirebaseConfig'
import { useHistory } from 'react-router-dom'
import Spacer from 'components/atoms/Spacer'
import { AuthContext } from 'providers/Auth'
import { UsersContext } from 'providers/Users'
import paths from 'constants/paths'
import styled from '@emotion/styled'
import { Form, Input } from 'semantic-ui-react-form-validator'
import { ImageSlider } from 'components/organisms/ImageSlider'

import * as firebase from 'firebase/app'
import 'firebase/functions'

const AddMediaForm = () => {
  const MessageContent = styled.p`
     {
      color: #000;
    }
  `
  const fieldStyle = {
    marginBottom: '1rem',
  }

  const { currentUser } = useContext(AuthContext)
  const { users } = useContext(UsersContext)

  const [mediaType, setMediaType] = useState('mediaTypeBillboard')
  const [mediaImages, setMediaImages] = useState([])
  const [mediaName, setMediaName] = useState('')
  const [address, setAddress] = useState('')
  const [widthSize, setWidthSize] = useState(0)
  const [heightSize, setHeightSize] = useState(0)
  const [heightPosition, setHeightPosition] = useState(0)
  const [direction, setDirection] = useState(0)
  const [description, setDescription] = useState('')
  const [summary, setSummary] = useState('')
  const [cost, setCost] = useState(0)
  const [yearlyCost, setYearlyCost] = useState(0)
  const [constructionCost, setConstructionCost] = useState(0)
  const [recoveryCost, setRecoveryCost] = useState(0)
  const [hasIllumination, setHasIllumination] = useState()
  const [fullName, setFullName] = useState('')
  const [tel, setTel] = useState('')
  const [companyName, setCompanyName] = useState('')
  const history = useHistory()
  const [dimmer, setDimmer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(false)

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'mediaName':
        setMediaName(event.target.value)
        break
      case 'address':
        setAddress(event.target.value)
        break
      case 'cost':
        setCost(Number(event.target.value))
        break
      case 'constructionCost':
        setConstructionCost(Number(event.target.value))
        break
      case 'yearlyCost':
        setYearlyCost(Number(event.target.value))
        break
      case 'recoveryCost':
        setRecoveryCost(Number(event.target.value))
        break
      case 'widthSize':
        setWidthSize(Number(event.target.value))
        break
      case 'heightSize':
        setHeightSize(Number(event.target.value))
        break
      case 'heightPosition':
        setHeightPosition(Number(event.target.value))
        break
      case 'direction':
        setDirection(Number(event.target.value))
        break
      case 'hasIllumination':
        setHasIllumination(event.target.value)
        break
      case 'description':
        setDescription(event.target.value)
        break
      case 'summary':
        setSummary(event.target.value)
        break
      case 'mediaType':
        setMediaType(event.target.value)
    }
  }

  const handleChangeFile = (e) => {
    let files = e.target.files
    let imageUrls = [...mediaImages]
    if (files.length > 1) {
      new Promise(() => {
        let counter = 0
        for (let i = 0; i < files.length; i++) {
          const fr = new FileReader()
          // fr.onload((e)=> {
          //   setMediaImages(imageUrls.push({imageUrl: e.result, name: files[i].name, file: files[i]}))
          // })
          fr.readAsDataURL(files[i])
          fr.addEventListener('load', () => {
            let fileType = files[i].name.match(/\.(jpg|png|gif|jpeg)$/)[1]
            let fileName = files[i].name.substring(0, files[i].name.lastIndexOf('.'))
            imageUrls.push({ imageUrl: fr.result, name: fileName + '_' + Number(new Date()) + '.' + fileType, file: files[i] })
            counter += 1 // 本来ならばmutexを使ってアトミックな加算にすべきところだが、jsはシングルスレッドでしか実行されないので、問題ない
            if (counter === files.length) {
              setMediaImages(imageUrls)
            }
          })
        }
      })
    } else if (files.length > 0) {
      const fr = new FileReader()
      let fileType = files[0].name.match(/\.(jpg|png|gif|jpeg)$/)[1]
      let fileName = files[0].name.substring(0, files[0].name.lastIndexOf('.'))
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        imageUrls.push({ imageUrl: fr.result, name: fileName + '_' + Number(new Date()) + '.' + fileType, file: files[0] })
        setMediaImages(imageUrls)
      })
      // imageUrls = createObjectURL(files[0]);
      // setMediaImages([{imageUrl: imageUrls, name: files[0].name}])
    }
  }

  const clickAddMedia = () => {
    setDimmer(true)
    setLoading(true)
    let sendAddMediaMail = firebase.functions().httpsCallable('sendAddMediaMail')
    let docId = db.collection('media').doc().id
    let today = new Date(Date.now())
    let mediaNameArray =
      mediaImages.length > 0
        ? mediaImages.map((image) => {
            return 'images/media/' + image.name
          })
        : []

    let data = {}
    data.uid = docId
    data.name = mediaName
    data.address = address
    data.constructionCost = constructionCost
    data.recoveryCost = recoveryCost
    data.yearlyCost = yearlyCost === 0 ? cost * 12 : yearlyCost
    data.summary = summary
    data.mediaImagePaths = mediaNameArray
    data.mediaType = mediaType
    data.monthlyCost = cost
    data.verticalWidth = heightSize
    data.horizontalWidth = widthSize
    data.height = heightPosition
    data.direction = direction
    data.note = description
    if (hasIllumination === 'hasIllumination') {
      data.hasIllumination = true
    } else {
      data.hasIllumination = false
    }
    data.email = currentUser.email
    data.userId = currentUser.uid
    data.fullName = fullName
    data.tel = tel
    data.companyName = companyName
    data.createdAt = firebase.firestore.Timestamp.fromDate(today)

    const storageRef = firebase.storage().ref()
    db.collection('media')
      .doc(docId)
      .set(data)
      .then(
        sendAddMediaMail(data)
          .then(() => {
            setLoading(false)
            // alert('DB登録成功しました。メール送信しました。')
            // history.go(0);
            setMessage(true)
            mediaImages.length > 0 &&
              mediaImages.forEach((mediaImage) => {
                storageRef
                  .child('images/media/' + mediaImage.name)
                  .put(mediaImage.file)
                  .then((snapshot) => {
                    snapshot.ref.getDownloadURL().then((downloadURL) => {
                      // this.imageUrl = downloadURL;
                      // db.collection("images").add({ downloadURL });
                      console.log('保存できました', downloadURL)
                    })
                  })
              })
          })
          .catch((error) => {
            setLoading(false)
            alert(`DB登録成功しました。メール送信に失敗しました。[${error}]`)
            history.go(0)
          })
      )
      .catch((error) => {
        setLoading(false)
        alert(`DB登録失敗[${error}]`)
      })
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        let ref = users.find((u) => u.docId === user.uid)
        if (ref !== undefined) {
          setFullName(ref.fullName)
          setTel(ref.tel)
          setCompanyName(ref.companyName)
          console.log('user exists')
        } else {
          console.log('user does not exist')
        }
      }
    })
  }, [users])

  const handleDismiss = () => {
    console.log('click handle dismiss')
  }

  return (
    <>
      <Segment>
        <div style={{ padding: '0 12rem' }}>
          {dimmer ? (
            <>
              <Dimmer active inverted>
                {message && (
                  <>
                    <Message positive onDismiss={handleDismiss}>
                      <Message.Header>Successful</Message.Header>
                      <MessageContent>媒体追加が成功しました。媒体管理一覧に戻って下さい。</MessageContent>
                    </Message>
                    <Button color="grey" onClick={() => history.push(`${paths.dashboardowner}`)}>
                      媒体管理一覧へ
                    </Button>
                  </>
                )}
                {loading && <Loader size="massive">Loading</Loader>}
              </Dimmer>
            </>
          ) : (
            <></>
          )}
          <Form onSubmit={clickAddMedia}>
            <p>
              <b>媒体の種類</b>
            </p>
            <p className="addMediaForm__requiredMark">必須</p>
            <br />
            <input
              type="radio"
              label="ビルボード"
              name="mediaType"
              value="mediaTypeBillboard"
              checked={mediaType === 'mediaTypeBillboard'}
              onChange={handleChange}
            />
            屋外看板
            <input type="radio" label="ポスター" name="mediaType" value="mediaTypePoster" checked={mediaType === 'mediaTypePoster'} onChange={handleChange} />
            ポスター
            <input
              type="radio"
              label="デジタルビジョン"
              name="mediaType"
              value="mediaTypeDigitalVision"
              checked={mediaType === 'mediaTypeVision'}
              onChange={handleChange}
            />
            デジタルビジョン
            <p>
              <b>媒体の画像</b>
            </p>
            <label className="addMediaForm__label-for-file">
              ファイルを選択
              <input className="addMediaForm__input-for-file" type="file" multiple accept="image/*" name="mediaImages" onChange={handleChangeFile} />
            </label>
            <p>
              {mediaImages.length > 0
                ? mediaImages.map((image) => (
                    <span style={{ marginRight: '1rem' }} key={image.name}>
                      {image.name}
                    </span>
                  ))
                : '選択されていません'}
            </p>
            <ImageSlider images={mediaImages.map((image) => image.imageUrl).reverse()} />
            {/*配列の中身の順番を逆にすることで、ユーザが直近で追加した画像が最初に表示されるようにする */}
            <p>
              <b>媒体の名前</b>
            </p>
            <p className="addMediaForm__requiredMark">必須</p>
            <Input
              name="mediaName"
              style={fieldStyle}
              type="text"
              value={mediaName}
              placeholder="例：東京パルダッドビル第３看板"
              validators={['required']}
              errorMessages={['媒体名を入力してください']}
              onChange={handleChange}
            />
            <p>
              <b>媒体の住所</b>
            </p>
            <p className="addMediaForm__requiredMark">必須</p>
            <Input
              name="address"
              type="text"
              style={fieldStyle}
              value={address}
              placeholder="例：東京都千代田区1-1"
              validators={['required']}
              errorMessages={['媒体のある住所を入力してください']}
              onChange={handleChange}
            />
            <p>
              <b>媒体の価格 [円（税抜）/月]</b>
            </p>
            {
              //<p className="requiredMark">必須</p>
            }
            <Input
              name="cost"
              type="number"
              style={fieldStyle}
              value={cost}
              onChange={handleChange}
              validators={['required']}
              placeholder="例：12000"
              step="1000"
              errorMessages={['媒体価格を入力してください']}
              width={6}
            />
            <p>
              <b>割引年額 [円/月] ※年単位での掲載の場合はこちらのみにご記入ください。</b>
            </p>
            <Input
              name="yearlyCost"
              type="number"
              style={fieldStyle}
              value={yearlyCost}
              onChange={handleChange}
              placeholder="例：12000"
              step="1000"
              errorMessages={['年額を入力して下さい']}
              width={6}
            />
            <p>
              <b>媒体施工価格 [円（税抜）/月]</b>
            </p>
            <p className="addMediaForm__requiredMark">必須</p>
            <Input
              name="constructionCost"
              type="number"
              style={fieldStyle}
              value={constructionCost}
              onChange={handleChange}
              validators={['required']}
              placeholder="例：12000"
              step="1000"
              errorMessages={['施工価格を入力してください']}
              width={6}
            />
            <p>
              <b>媒体回復価格 [円（税抜）/月]</b>
            </p>
            <p className="addMediaForm__requiredMark">必須</p>
            <Input
              name="recoveryCost"
              type="number"
              style={fieldStyle}
              value={recoveryCost}
              onChange={handleChange}
              validators={['required']}
              placeholder="例：12000"
              step="1000"
              errorMessages={['回復価格を入力してください']}
              width={6}
            />
            <p>
              <b>媒体の縦幅 [m]</b>
            </p>
            <p className="addMediaForm__requiredMark">必須</p>
            <Input
              name="heightSize"
              type="number"
              style={fieldStyle}
              value={heightSize}
              step="0.1"
              onChange={handleChange}
              placeholder="例：10.0"
              validators={['required']}
              errorMessages={['媒体の縦幅を入力してください']}
              width={6}
            />
            <p>
              <b>媒体の横幅 [m]</b>
            </p>
            <p className="addMediaForm__requiredMark">必須</p>
            <Input
              name="widthSize"
              type="number"
              style={fieldStyle}
              value={widthSize}
              step="0.1"
              onChange={handleChange}
              placeholder="例：5.5"
              validators={['required']}
              errorMessages={['媒体の横幅を入力してください']}
              width={6}
            />
            <p>
              <b>媒体の設置高度 [m]</b>
            </p>
            <p className="addMediaForm__requiredMark">必須</p>
            <Input
              name="heightPosition"
              value={heightPosition}
              type="number"
              style={fieldStyle}
              step="0.1"
              onChange={handleChange}
              placeholder="例：15.5"
              validators={['required']}
              errorMessages={['媒体の高さを入力してください']}
              width={6}
            />
            <p>
              <b>広告面の向き [deg]</b>
            </p>
            <p className="addMediaForm__requiredMark">必須</p>
            <Input
              name="direction"
              value={direction}
              type="number"
              style={fieldStyle}
              onChange={handleChange}
              validators={['required']}
              placeholder="例：240"
              errorMessages={['広告面の角度を入力してください（真東が0度で反時計回り）']}
              width={6}
            />
            <p>
              <b>照明の有無</b>
            </p>
            <p className="addMediaForm__requiredMark">必須</p>
            <br />
            <input
              type="radio"
              label="照明あり"
              name="hasIllumination"
              value="hasIllumination"
              //checked={hasIllumination = "hasIllumination"}
              onChange={handleChange}
            />
            あり
            <input
              type="radio"
              label="照明なし"
              name="hasIllumination"
              value="noIllumination"
              //checked=
              onChange={handleChange}
            />
            なし
            <p>
              <b>媒体の紹介文</b>
            </p>
            {/* <input
              name='description'
              type=''
              value={description}
              onChange={handleChange}
            /> */}
            <textarea name="summary" type="" value={summary} onChange={handleChange} />
            <p>
              <b>備考（カバーの種類やその他注意点等）</b>
            </p>
            <textarea name="description" type="" value={description} onChange={handleChange} />
            <Spacer />
            <div>
              <Button color="orange">媒体情報を登録</Button>
            </div>
          </Form>
          <Spacer />
        </div>
      </Segment>
      <Spacer />
    </>
  )
}

export default AddMediaForm
