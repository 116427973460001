import React, { useState, useContext } from 'react'
import { Form, Header, Grid, Button, Container, Dropdown, Card, Message, Loader, Dimmer } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import Spacer from 'components/atoms/Spacer'
import RestartMediaConfirm from 'components/organisms/owner/RestartMediaConfirm'
import firebase, { db } from 'FirebaseConfig'
import { MediasOwnContext } from 'providers/MediasOwn'
import { GetYearMonthDate } from 'components/molecules/GetYearMonthDate'
import './index.css'

const RestartMediaForm = () => {
  const [mediaTarget, setMediaTarget] = useState('')
  const history = useHistory()
  const [restartDate, setRestartDate] = useState('')
  const [restartDateRef, setRestartDateRef] = useState('')
  const [step, setStep] = useState(false)
  const { mediasOwn } = useContext(MediasOwnContext)
  const [visible, setVisible] = useState(false)
  const [message, setMessage] = useState({})
  const [loading, setLoading] = useState(false)

  const handleChange = (event, { name, value }) => {
    switch (name) {
      case 'restartDate':
        setRestartDate(value)
        // month +1 調節
        setRestartDateRef(new Date(value.substring(6, 10), Number(value.substring(3, 5)) - 1, Number(value.substring(0, 2))))
        break
      default:
        console.log('no key match')
    }
  }

  const onChange = (e, data) => {
    let index = 0
    for (let i = 0; i < mediasOwn.length; i++) {
      if (mediasOwn[i].name === data.value) {
        index = i
        break
      }
    }
    setMediaTarget(mediasOwn[index])
  }

  const clickNextToStop = () => {
    if (mediaTarget && restartDate) {
      setStep(true)
      setVisible(false)
    } else {
      console.log('未入力項目があります。')
      setVisible(true)
      setMessage({
        type: 'negative',
        text: '未入力項目があります。',
        title: 'error',
      })
    }
  }

  const clickFix = () => {
    setStep(false)
    setVisible(false)
  }

  const clickToRestart = () => {
    let today = new Date(Date.now())
    db.collection('media')
      .doc(mediaTarget.uid)
      .update({
        restartDate: firebase.firestore.Timestamp.fromDate(restartDateRef),
        updatedAt: firebase.firestore.Timestamp.fromDate(today),
      })
      .then(() => {
        history.go(0)
        history.push({
          state: {
            text: '正常に掲載再開日を登録しました。',
            type: 'positive',
          },
        })
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        history.push({
          state: {
            text: error,
            type: 'negative',
          },
        })
      })
  }

  const handleDismiss = () => {
    setVisible(false)
  }

  return (
    <>
      {loading && (
        <Dimmer active inverted>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      {visible && (
        <div className="restartMediaForm__appLayoutMessage">
          <Message negative onDismiss={handleDismiss} header={message.title} content={message.text} />
        </div>
      )}
      {step ? (
        <>
          <Spacer />
          <Container text>
            <RestartMediaConfirm media={mediaTarget} restartDate={restartDate} restartDateRef={restartDateRef} />
          </Container>
          <Spacer />
          <Grid centered columns={2}>
            <div>
              <Grid.Column>
                <Button color="grey" onClick={clickFix}>
                  修正する
                </Button>
              </Grid.Column>
            </div>
            <div>
              <Grid.Column>
                <Button color="orange" onClick={clickToRestart}>
                  確認
                </Button>
              </Grid.Column>
            </div>
          </Grid>
        </>
      ) : (
        <>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 60px 642px' }}>
            <div style={{ gridColumn: '1' }} className="restartMediaForm__mediaDetailColumn">
              <Header as="h3">掲載を再開する媒体</Header>
              <p>掲載を再開する媒体を選んでください</p>
              <Dropdown
                placeholder="媒体を選択"
                fluid
                search
                selection
                options={
                  mediasOwn &&
                  mediasOwn.map((medium) => {
                    return {
                      key: medium.uid,
                      text: medium.name,
                      value: medium.name,
                    }
                  })
                }
                onChange={onChange}
                defaultValue={mediaTarget && mediaTarget.name}
              />
              <Spacer />
              {mediaTarget && (
                <Card>
                  <Card.Content>
                    <b>掲載停止日</b>
                    <p>{mediaTarget.stopDate && <GetYearMonthDate timestamp={mediaTarget.stopDate} />}</p>
                    <b>掲載再開日</b>
                    <p>{mediaTarget.restartDate && <GetYearMonthDate timestamp={mediaTarget.restartDate} />}</p>
                  </Card.Content>
                </Card>
              )}
            </div>
            <div style={{ gridColumn: '3' }} className="restartMediaForm__mediaDetailColumn">
              <Header as="h3">掲載を再開する日</Header>
              <p>掲載を再開する日を選んでください</p>
              <Form>
                <Form.Group widths="equal">
                  <div className="restartMediaForm__calenderDiv">
                    <p className="restartMediaForm__calenarLetter">
                      {restartDate ? (
                        <>
                          {restartDateRef.getFullYear()}年{/* Month +1 調整 */}
                          {restartDateRef.getMonth() + 1}月{restartDateRef.getDate()}日
                        </>
                      ) : (
                        <>&nbsp;</>
                      )}
                    </p>
                    <DateInput name="restartDate" inline placeholder="掲載を再開する日" value={restartDate} iconPosition="left" onChange={handleChange} />
                  </div>
                </Form.Group>
              </Form>
            </div>
          </div>
          <Spacer />
          <Grid centered columns={2}>
            <div>
              <Grid.Column>
                <Button color="grey" onClick={() => history.push(`${paths.menuowner}`)}>
                  戻る
                </Button>
              </Grid.Column>
            </div>
            <div>
              <Grid.Column>
                <Button color="orange" onClick={clickNextToStop}>
                  次へ
                </Button>
              </Grid.Column>
            </div>
          </Grid>
        </>
      )}
    </>
  )
}

export default RestartMediaForm
