import React, { useEffect, useState } from 'react'
import { Grid, Checkbox, Form, Input, Dropdown } from 'semantic-ui-react'
import Calendar from 'react-calendar'
import Spacer from 'components/atoms/Spacer'
import 'react-calendar/dist/Calendar.css'
import MediaTypeOptions from 'constants/MediaTypeOptions'
const weekPerMilliseconds = 604800000
const monthPerMilliseconds = 2678400000
const dayPerMilliseconds = 86400000

export function calcWeekDiff(dateFrom, dateTo) {
  return Math.ceil((dateTo.getTime() - dateFrom.getTime() + dayPerMilliseconds) / weekPerMilliseconds)
}

export function calcMonthDiff(dateFrom, dateTo) {
  return Math.ceil((dateTo.getTime() - dateFrom.getTime() + dayPerMilliseconds) / monthPerMilliseconds)
}

function parseDate(date) {
  //本当はtypescriptにしてinterface指定したいところ...
  return date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2)
}

// add group と edit group から使いたい
const GroupFormBase = (props) => {
  const today = new Date()
  const [startDate, setStartDate] = useState(new Date(props.startDate))
  const [endDate, setEndDate] = useState(new Date(props.endDate))
  const [budgetComma, setBudgetComma] = useState('')
  const filteredMediaTypeOptions = MediaTypeOptions.filter((x) => x.key !== 'mediaTypeJack' && x.key !== 'mediaTypePoster')

  const handleChange = (e, { name, value }) => {
    switch (name) {
      case 'mediaType':
        props.setMediaType(value)
        break
      case 'groupName':
        props.setGroupName(value)
        break
      case 'budget':
        if (value === '' || /^[,0-9\b]+$/.test(value)) {
          props.setBudget(value.replace(/,/g, ''))
          setBudgetComma(
            value
              .replace(/,/g, '')
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          )
        }
        break
      case 'noBudget':
        props.setNoBudget(!props.noBudget)
        break
      case 'startDate':
        props.setStartDate(value)
        break
      case 'endDateMonth':
        props.setEndDateMonth(value)
        break
      case 'endDateWeek':
        props.setEndDateWeek(value)
        break
      case 'endDate':
        props.setEndDate(value)
        break
      case 'noSpan':
        props.setNoSpan(!props.noSpan)
        break
      default:
        console.log('key not found')
    }
  }

  const onChangeStartDate = (date) => {
    setStartDate(date)
    const defaultEndDate = new Date(date)
    defaultEndDate.setTime(defaultEndDate.getTime() + monthPerMilliseconds - dayPerMilliseconds)
    setEndDate(defaultEndDate)
    const value = parseDate(date)
    const formattedDefaultEndDate = parseDate(defaultEndDate)
    const weekDiff = calcWeekDiff(date, defaultEndDate)
    const monthDiff = calcMonthDiff(date, defaultEndDate)
    handleChange(null, { name: 'startDate', value: value })
    handleChange(null, { name: 'endDate', value: formattedDefaultEndDate })
    handleChange(null, { name: 'endDateWeek', value: weekDiff })
    handleChange(null, { name: 'endDateMonth', value: monthDiff })
  }

  const onChangeEndDate = (date) => {
    setEndDate(date)
    const value = parseDate(date)
    handleChange(null, { name: 'endDate', value: value })
    const weekDiff = calcWeekDiff(startDate, date)
    const monthDiff = calcMonthDiff(startDate, date)
    handleChange(null, { name: 'endDateWeek', value: weekDiff })
    handleChange(null, { name: 'endDateMonth', value: monthDiff })
  }

  const prevDay = today.getTime() - 1000 * 60 * 60 * 24
  const tileDisabledForStart = ({ date, view }) => {
    if (view === 'month' && date.getTime() < prevDay) {
      return true
    } else {
      return false
    }
  }

  const tileDisabledForEnd = ({ date }) => {
    if (date.getTime() < startDate.getTime() + 1000 * 60 * 60 * 24) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    setStartDate(new Date(props.startDate))
    setEndDate(new Date(props.endDate))
    if (props.startDate === props.endDate) {
      onChangeStartDate(startDate)
    }
    if (props.budget !== undefined) {
      setBudgetComma(props.budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
    }
  }, [props])

  return (
    <>
      <Form className="ui form">
        <Form.Field>
          <label>媒体の種類</label>
          <Dropdown name="mediaType" fluid search selection options={filteredMediaTypeOptions} onChange={handleChange} value={props.mediaType} />
        </Form.Field>
        <Spacer />
        <Form.Field>
          <label>グループ名</label>
          <Input value={props.groupName} name="groupName" onChange={handleChange} />
        </Form.Field>
        <Spacer />
        <Form.Field>
          <label>予算</label>
          <Input value={budgetComma} name="budget" placeholder="￥1,000,000" onChange={handleChange} disabled={!props.noBudget == false && true} />
        </Form.Field>
        <Form.Field>
          <Checkbox value={props.noBudget} checked={props.noBudget} name="noBudget" label="予算を指定しない" onChange={handleChange} />
        </Form.Field>
        <Spacer />

        <Grid columns="equal">
          <Grid.Column width={8}>
            <Form.Field>
              <label>開始日</label>
              <Input
                fluid
                type="text"
                pattern="[0-9]{4}/[0/9]{2}/[0-9]{2}"
                name="startDate"
                value={parseDate(startDate)}
                disabled={!props.noSpan == false && true}
                readOnly
              />
            </Form.Field>
            <Calendar locale="ja-JP" calendarType="US" value={[startDate, endDate]} onChange={onChangeStartDate} tileDisabled={tileDisabledForStart} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Field>
              <label>終了日</label>
              <Input
                fluid
                type="text"
                pattern="[0-9]{4}/[0-9]{2}/[0-9]{2}"
                name="endDate"
                value={parseDate(endDate)}
                disabled={!props.noSpan == false && true}
                readOnly
              />
            </Form.Field>
            <Calendar
              tileDisabled={tileDisabledForEnd}
              locale="ja-JP"
              calendarType="US"
              value={[startDate, endDate]}
              onChange={onChangeEndDate}
              defaultActiveStartDate={endDate}
            />
          </Grid.Column>
        </Grid>
        <Spacer />
        <Form.Field>
          <label>配信期間</label>
          <p>
            {props.endDateMonth}ヶ月 または {props.endDateWeek}週間 （＊開始日と終了日より算出）
          </p>
        </Form.Field>
        <Spacer />
      </Form>
      <Spacer />
    </>
  )
}

export default GroupFormBase
