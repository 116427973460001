import React from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'

const AddmediaBreadcrump = () => {
  const history = useHistory()
  return (
    <>
      <Breadcrumb size={'mini'}>
        <Breadcrumb.Section onClick={() => history.push(`${paths.menuowner}`)} link active>
          <i style={{ color: 'gray' }} className="home icon" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active onClick={() => history.push(`${paths.dashboardowner}`)} link>
          <p style={{ color: 'gray' }}>保有媒体一覧</p>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section>媒体情報の登録</Breadcrumb.Section>
      </Breadcrumb>
    </>
  )
}

export default AddmediaBreadcrump
