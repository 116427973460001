const StateOptions = [
  { key: 'adachi', text: '足立区', value: '足立区' },
  { key: 'arakawa', text: '荒川区', value: '荒川区' },
  { key: 'itabashi', text: '板橋区', value: '板橋区' },
  { key: 'edogawa', text: '江戸川区', value: '江戸川区' },
  { key: 'ota', text: '大田区', value: '大田区' },
  { key: 'katsushika', text: '葛飾区', value: '葛飾区' },
  { key: 'kita', text: '北区(東京都)', value: '東京都北区' },
  { key: 'koutou', text: '江東区', value: '江東区' },
  { key: 'sinagawa', text: '品川区', value: '品川区' },
  { key: 'shibuya', text: '渋谷区', value: '渋谷区' },
  { key: 'shinjuku', text: '新宿区', value: '新宿区' },
  { key: 'suginami', text: '杉並区', value: '杉並区' },
  { key: 'sumida', text: '墨田区', value: '墨田区' },
  { key: 'setagaya', text: '世田谷区', value: '世田谷区' },
  { key: 'taitou', text: '台東区', value: '台東区' },
  { key: 'chiyoda', text: '千代田区', value: '千代田区' },
  { key: 'chuo', text: '中央区', value: '中央区' },
  { key: 'toshima', text: '豊島区', value: '豊島区' },
  { key: 'nakano', text: '中野区', value: '中野区' },
  { key: 'nerima', text: '練馬区', value: '練馬区' },
  { key: 'bunkyou', text: '文京区', value: '文京区' },
  { key: 'minato', text: '港区', value: '港区' },
  { key: 'meguro', text: '目黒区', value: '目黒区' },
  { key: 'hachiouji', text: '八王子市', value: '八王子市' },
  { key: 'tachikawa', text: '立川市', value: '立川市' },
  { key: 'musashino', text: '武蔵野市', value: '武蔵野市' },
  { key: 'mitaka', text: '三鷹市', value: '三鷹市' },
  { key: 'oume', text: '青梅市', value: '青梅市' },
  { key: 'fuchu', text: '府中市', value: '府中市' },
  { key: 'akishima', text: '昭島市', value: '昭島市' },
  { key: 'chofu', text: '調布市', value: '調布市' },
  { key: 'machida', text: '町田市', value: '町田市' },
  { key: 'koganei', text: '小金井市', value: '小金井市' },
  { key: 'kodaira', text: '小平市', value: '小平市' },
  { key: 'hino', text: '日野市', value: '日野市' },
  { key: 'higashimurayama', text: '東村山市', value: '東村山市' },
  { key: 'kokubunji', text: '国分寺市', value: '国分寺市' },
  { key: 'kunitachi', text: '国立市', value: '国立市' },
  { key: 'fussa', text: '福生市', value: '福生市' },
  { key: 'komae', text: '狛江市', value: '狛江市' },
  { key: 'higashiyamato', text: '東大和市', value: '東大和市' },
  { key: 'kiyose', text: '清瀬市', value: '清瀬市' },
  { key: 'higashikurume', text: '東久留米市', value: '東久留米市' },
  { key: 'musashimurayama', text: '武蔵村山市', value: '武蔵村山市' },
  { key: 'tama', text: '多摩市', value: '多摩市' },
  { key: 'inagi', text: '稲城市', value: '稲城市' },
  { key: 'hamura', text: '羽村市', value: '羽村市' },
  { key: 'akiruno', text: 'あきる野市', value: 'あきる野市' },
  { key: 'nishitokyo', text: '西東京市', value: '西東京市' },
  { key: 'yokohama', text: '横浜市', value: '横浜市' },
  { key: 'kawasaki', text: '川崎市', value: '川崎市' },
  { key: 'yokosuka', text: '横須賀市', value: '横須賀市' },
  { key: 'kamakura', text: '鎌倉市', value: '鎌倉市' },
  { key: 'zushi', text: '逗子市', value: '逗子市' },
  { key: 'miura', text: '三浦市', value: '三浦市' },
  { key: 'sagamihara', text: '相模原市', value: '相模原市' },
  { key: 'atsugi', text: '厚木市', value: '厚木市' },
  { key: 'yamato', text: '大和市', value: '大和市' },
  { key: 'ebina', text: '海老名市', value: '海老名市' },
  { key: 'zama', text: '座間市', value: '座間市' },
  { key: 'ayase', text: '綾瀬市', value: '綾瀬市' },
  { key: 'hiratsuka', text: '平塚市', value: '平塚市' },
  { key: 'fujisawa', text: '藤沢市', value: '藤沢市' },
  { key: 'chigasaki', text: '茅ヶ崎市', value: '茅ヶ崎市' },
  { key: 'hatano', text: '秦野市', value: '秦野市' },
  { key: 'isehara', text: '伊勢原市', value: '伊勢原市' },
  { key: 'odawara', text: '小田原市', value: '小田原市' },
  { key: 'minamiashigara', text: '南足柄市', value: '南足柄市' },
  { key: 'saitama', text: 'さいたま市', value: 'さいたま市' },
  { key: 'kawagoe', text: '川越市', value: '川越市' },
  { key: 'kumagaya', text: '熊谷市', value: '熊谷市' },
  { key: 'kawaguchi', text: '川口市', value: '川口市' },
  { key: 'gyoda', text: '行田市', value: '行田市' },
  { key: 'chichibu', text: '秩父市', value: '秩父市' },
  { key: 'tokorozawa', text: '所沢市', value: '所沢市' },
  { key: 'hannou', text: '飯能市', value: '飯能市' },
  { key: 'kazo', text: '加須市', value: '加須市' },
  { key: 'honjou', text: '本庄市', value: '本庄市' },
  { key: 'higashimatsuyama', text: '東松山市', value: '東松山市' },
  { key: 'kasukabe', text: '春日部市', value: '春日部市' },
  { key: 'sayama', text: '狭山市', value: '狭山市' },
  { key: 'hanyu', text: '羽生市', value: '羽生市' },
  { key: 'kounosu', text: '鴻巣市', value: '鴻巣市' },
  { key: 'fukaya', text: '深谷市', value: '深谷市' },
  { key: 'ageo', text: '上尾市', value: '上尾市' },
  { key: 'souka', text: '草加市', value: '草加市' },
  { key: 'koshigaya', text: '越谷市', value: '越谷市' },
  { key: 'warabi', text: '蕨市', value: '蕨市' },
  { key: 'toda', text: '戸田市', value: '戸田市' },
  { key: 'iruma', text: '入間市', value: '入間市' },
  { key: 'asaka', text: '朝霞市', value: '朝霞市' },
  { key: 'shiki', text: '志木市', value: '志木市' },
  { key: 'wakou', text: '和光市', value: '和光市' },
  { key: 'niiza', text: '新座市', value: '新座市' },
  { key: 'okegawa', text: '桶川市', value: '桶川市' },
  { key: 'kuki', text: '久喜市', value: '久喜市' },
  { key: 'kitamoto', text: '北本市', value: '北本市' },
  { key: 'yashio', text: '八潮市', value: '八潮市' },
  { key: 'fujimi', text: '富士見市', value: '富士見市' },
  { key: 'misato', text: '三郷市', value: '三郷市' },
  { key: 'hasuda', text: '蓮田市', value: '蓮田市' },
  { key: 'sakado', text: '坂戸市', value: '坂戸市' },
  { key: 'satte', text: '幸手市', value: '幸手市' },
  { key: 'tsurugashima', text: '鶴ヶ島市', value: '鶴ヶ島市' },
  { key: 'hidaka', text: '日高市', value: '日高市' },
  { key: 'yoshikawa', text: '吉川市', value: '吉川市' },
  { key: 'fujimino', text: 'ふじみ野市', value: 'ふじみ野市' },
  { key: 'shiraoka', text: '白岡市', value: '白岡市' },
  { key: 'chiba', text: '千葉市', value: '千葉市' },
  { key: 'choushi', text: '銚子市', value: '銚子市' },
  { key: 'ichikawa', text: '市川市', value: '市川市' },
  { key: 'funabashi', text: '船橋市', value: '船橋市' },
  { key: 'tateyama', text: '館山市', value: '館山市' },
  { key: 'kisarazu', text: '木更津市', value: '木更津市' },
  { key: 'matsudo', text: '松戸市', value: '松戸市' },
  { key: 'noda', text: '野田市', value: '野田市' },
  { key: 'mobara', text: '茂原市', value: '茂原市' },
  { key: 'narita', text: '成田市', value: '成田市' },
  { key: 'sakura', text: '佐倉市', value: '佐倉市' },
  { key: 'tougane', text: '東金市', value: '東金市' },
  { key: 'asahi', text: '旭市', value: '旭市' },
  { key: 'narashino', text: '習志野市', value: '習志野市' },
  { key: 'kashiwa', text: '柏市', value: '柏市' },
  { key: 'katsuura', text: '勝浦市', value: '勝浦市' },
  { key: 'ichihara', text: '市原市', value: '市原市' },
  { key: 'nagareyama', text: '流山市', value: '流山市' },
  { key: 'yachiyo', text: '八千代市', value: '八千代市' },
  { key: 'abiko', text: '我孫子市', value: '我孫子市' },
  { key: 'kamogawa', text: '鴨川市', value: '鴨川市' },
  { key: 'kamagaya', text: '鎌ヶ谷市', value: '鎌ヶ谷市' },
  { key: 'kimitsu', text: '君津市', value: '君津市' },
  { key: 'futtsu', text: '富津市', value: '富津市' },
  { key: 'urayasu', text: '浦安市', value: '浦安市' },
  { key: 'yotsukaidou', text: '四街道市', value: '四街道市' },
  { key: 'sodegaura', text: '袖ヶ浦市', value: '袖ヶ浦市' },
  { key: 'yachimata', text: '八街市', value: '八街市' },
  { key: 'inzai', text: '印西市', value: '印西市' },
  { key: 'shiroi', text: '白井市', value: '白井市' },
  { key: 'tomisato', text: '富里市', value: '富里市' },
  { key: 'minamibousou', text: '南房総氏市', value: '南房総氏市' },
  { key: 'sousa', text: '匝瑳市', value: '匝瑳市' },
  { key: 'katori', text: '香取市', value: '香取市' },
  { key: 'sanmu', text: '山武市', value: '山武市' },
  { key: 'isumi', text: 'いすみ市', value: 'いすみ市' },
  { key: 'ooamishirasato', text: '大網白里市', value: '大網白里市' },
]

export default StateOptions
