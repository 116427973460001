// Library
import React, { useState, useEffect, useContext, useRef } from 'react'
import { Form, TextArea, Button, Message, Segment } from 'semantic-ui-react'
// Components
import { auth, db } from 'FirebaseConfig'
import NavigationBar from 'components/organisms/menubar/NavigationBar'
import NavigationBarOwner from 'components/organisms/menubar/NavigationBarOwner'
import NavigationBarPlane from 'components/organisms/menubar/NavigationBarPlane'
import { UsersContext } from 'providers/Users'
import { isOwner } from 'helpers/isOwner'
import Spacer from 'components/atoms/Spacer'
import FooterNav from 'components/organisms/menubar/FooterNav'
import './index.css'

import * as firebase from 'firebase/app'
import 'firebase/functions'
const sendMail = firebase.functions().httpsCallable('sendMail')

const Contact = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [subject, setSubject] = useState('')
  const [content, setContent] = useState('')
  const [owner, setOwner] = useState(false)
  const [customer, setCustomer] = useState(false)
  const [login, setLogin] = useState(false)
  const [messageVisible, setMessageVisible] = useState(false)
  const { users } = useContext(UsersContext)

  const inputRef = useRef(null)

  const textFieldStyle = {
    display: 'flex',
    width: '100%',
  }

  // onChangeでsetStateする。
  const handleChange = (event) => {
    switch (event.target.name) {
      case 'name':
        setName(event.target.value)
        break
      case 'companyName':
        setCompanyName(event.target.value)
        break
      case 'email':
        setEmail(event.target.value)
        break
      case 'subject':
        setSubject(event.target.value)
        break
      case 'content':
        setContent(event.target.value)
        break
      default:
        console.log('key not found')
    }
  }

  const onSubmitContact = () => {
    let docId = db.collection('contact').doc().id
    let today = new Date(Date.now())
    let data = {}
    data.name = name
    data.companyName = companyName
    data.email = email
    data.subject = subject
    data.content = content
    data.sentAt = firebase.firestore.Timestamp.fromDate(today)
    //TODO: firebase関連?
    db.collection('contact')
      .doc(docId)
      .set(data)
      .then(
        sendMail(data)
          .then(() => {
            setMessageVisible(true)
            setName('')
            setCompanyName('')
            setEmail('')
            setSubject('')
            setContent('')
          })
          .catch((error) => {
            alert(`問い合わせ送信に失敗しました。[${error}]`)
          })
      )
      .catch((error) => {
        alert(`問い合わせ送信に失敗しました。[${error}]`)
      })
  }

  const handleDismiss = () => {
    setMessageVisible(false)
  }

  // TODO: MEATAタグをコンポーネントにする
  useEffect(() => {
    const title = 'お問い合わせ｜AdVice（アドバイス）'
    const description =
      'AdViceについての問い合わせはこちらです。サービス認知を圧倒的に高めるOOHマーケティングを効果的に、カンタンに。屋外広告注文プラットフォーム「AdVice（アドバイス）」は、投資対効果ベースで屋外広告を注文できる新しいプラットフォームです。'
    document.title = title
    // title以外のmeta
    const headData = document.head.children

    for (let i = 0; i < headData.length; i++) {
      const nameVal = headData[i].getAttribute('name')
      if (nameVal !== null) {
        if (nameVal.indexOf('description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        // OGP(twitter)の設定
        if (nameVal.indexOf('twitter:title') !== -1) {
          headData[i].setAttribute('content', title)
        }
        if (nameVal.indexOf('twitter:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:title') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:image') !== -1) {
          headData[i].setAttribute('content', process.env.REACT_APP_OGP_URL + '/img_ogp.png')
        }
      }
    }
  })

  // TODO: セッション確認処理を共通化したコンポーネント
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setLogin(true)
        let ref = isOwner(users, user.uid)
        if (ref) {
          setOwner(true)
          setCustomer(false)
        } else {
          setOwner(false)
          setCustomer(true)
        }
      }
    })
  })

  return (
    <>
      <div style={{ display: 'grid' }}>
        <div style={{ gridRow: '1', gridColumn: '1' }} className="contact__navBarGridRow">
          {owner && <NavigationBarOwner />}
          {customer && <NavigationBar />}
          {login ? <></> : <NavigationBarPlane />}
        </div>
        <div style={{ gridRow: '2', gridColumn: '1' }} className="contact__appGridContent">
          <div className="ui container">
            <Spacer />
            <Segment>
              <h3>お問い合わせ</h3>
              <p>
                下記フォーマットにご記入いただき、「入力内容を確認する」のボタンを押して内容をご確認のうえ、送信してください。自動で受付メールを送信いたします。
              </p>
            </Segment>
            <Segment>
              <Form class="ui form" widths="equal" onSubmit={onSubmitContact} ref={inputRef}>
                <Form.Field>
                  <div className="ui field">
                    <input value={name} name="name" type="text" placeholder="氏名" required style={textFieldStyle} onChange={handleChange} />
                  </div>
                </Form.Field>
                <Form.Field>
                  <div className="ui field">
                    <input value={companyName} name="companyName" type="text" placeholder="会社名" required style={textFieldStyle} onChange={handleChange} />
                  </div>
                </Form.Field>
                <Form.Field>
                  <div className="ui field">
                    <input value={email} name="email" type="email" placeholder="メールアドレス" required style={textFieldStyle} onChange={handleChange} />
                  </div>
                </Form.Field>
                <Form.Field>
                  <div className="ui field">
                    <input value={subject} name="subject" type="text" placeholder="件名" required style={textFieldStyle} onChange={handleChange} />
                  </div>
                </Form.Field>
                <TextArea
                  value={content}
                  required
                  name="content"
                  label="お問い合わせ内容"
                  placeholder="お問い合わせ内容"
                  multiline
                  rows="8"
                  margin="normal"
                  variant="outlined"
                  style={textFieldStyle}
                  onChange={handleChange}
                />
                <Spacer />
                <div className="ui center aligned grid">
                  <Button class="ui button" variant="contained" color="primary" type="submit">
                    送信
                  </Button>
                </div>
                <Spacer />
              </Form>
              {messageVisible ? <Message positive onDismiss={handleDismiss} header={'Successful'} content={'お問い合わせを送信しました'} /> : <></>}
            </Segment>
            <Spacer />
          </div>
          <div style={{ gridRow: '3', gridColumn: '1' }} className="contact__appGridFooter">
            <FooterNav />
          </div>
        </div>
      </div>
    </>
  )
}
export default Contact
