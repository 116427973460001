import { AdviceMediaDataType, isJackChild } from '../constants/mediaData'

export const isSameJackGroup = (media: AdviceMediaDataType, targetMediaId: string, mediasAvailable) => {
  const parentUid = media.mediaType === 'mediaTypeJack' ? media.uid : isJackChild(media) ? media.parentUid : null
  if (!parentUid) {
    return false
  }
  const targetMedia = mediasAvailable.find((e) => e.uid === targetMediaId)
  if (!targetMedia) {
    return false
  }
  const targetParentUid = targetMedia.mediaType === 'mediaTypeJack' ? targetMedia.uid : isJackChild(targetMedia) ? targetMedia.parentUid : null
  return parentUid === targetParentUid
}
