import React, { useEffect, useState } from 'react'
import { Card, Image } from 'semantic-ui-react'
import firebase from 'FirebaseConfig'
import noImage from 'assets/pictures/no_image.jpg'
import { roundAndAddThousandsSeparator } from 'helpers/roundAndAddThousandsSeparator'

const MediaCardSingle = ({ media }) => {
  const [mediaImageUrl, setMediaImageUrl] = useState('')

  useEffect(() => {
    if (media) {
      if (media.mediaImagePaths && media.mediaImagePaths.length > 0) {
        let storageRef = firebase.storage().ref()
        storageRef
          .child(media.mediaImagePaths[0])
          .getDownloadURL()
          .then(function (url) {
            setMediaImageUrl(url)
          })
          .catch(function (error) {
            console.log('Error getting document:', error)
          })
      } else {
        setMediaImageUrl(noImage)
      }
    }
  }, [media])

  return (
    <>
      <Card key={media.uid}>
        <Card.Content>
          <Image floated="left" height="120px" width="200px" objectFit="cover" style={{ marginBottom: '0' }} src={mediaImageUrl} />
          <Card.Header style={{ marginBottom: '1rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <div>{media.name}</div>
          </Card.Header>
          <Card.Meta>
            <table>
              <tr align="justify">
                <td>{media.isVision === true || media.mediaType === 'mediaTypeVision' ? '価格/週' : '価格/月'}</td>
                <td>&nbsp;</td>
                <td align="right">
                  <b>{media.cost && `¥${media.cost && roundAndAddThousandsSeparator(Number(media.cost))}`}</b>
                </td>
              </tr>
              <tr align="justify">
                <td>インプレッション数/月</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td align="right">
                  <b>
                    {media.impressionRe
                      ? media.impressionRe && roundAndAddThousandsSeparator(media.impressionRe, 1)
                      : media.impression && roundAndAddThousandsSeparator(media.impression, 1)}
                  </b>
                </td>
              </tr>
              <tr align="justify">
                <td>CPM</td>
                <td>&nbsp;</td>
                <td align="right">
                  <b>
                    {media.cost && `¥${roundAndAddThousandsSeparator((media.cost / (media.impressionRe ? media.impressionRe : media.impression)) * 1000, 1)}`}
                  </b>
                </td>
              </tr>
            </table>
          </Card.Meta>
        </Card.Content>
      </Card>
    </>
  )
}

export default MediaCardSingle
