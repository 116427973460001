import { checkVisible, calcAngle, getDistance, deg2vector } from './utils' //TODO: import checkCross here and complete makeOutlineByWay
import { SPACE } from './visibility_constants'
import { osm2geojson, getOSM, getWayList, metre2degree } from './utils'

/**
  可視領域の計算関数.
  視認可能点の[緯度、経度]のセットが入った配列と、[lat, lng, isVisible]の配列を返す
  lat,lon,height: 看板位置,高さ
  range: 可視領域の最大半径(m)
**/

// 以下のgetVisibleRegionは修正済み
export const getVisibleRegion = async (
  lat: number,
  lon: number,
  height: number,
  width: number,
  range: number,
  direction: number,
  buildings: number[][][]
): Promise<[number[][], pointInfoType[][]]> => {
  const meshList = makeIdealPointList(lat, lon, range, deg2vector(direction)) // 代表点を取ってくる （緯度, 経度, bool) が入った二次元配列もどきが帰ってくる
  const pointList: [number, number][] = []
  for (let i = 0; i < meshList.length; i++) {
    for (let j = 0; j < meshList[i].length; j++) {
      meshList[i][j][2] = checkVisible(meshList[i][j], buildings, lat, lon, height)
      const [latTmp, lngTmp, isVisible] = meshList[i][j]
      if (isVisible) {
        pointList.push([latTmp, lngTmp])
      }
    }
  }
  return [pointList, meshList]
}

/**
  干渉する建物を取得する関数
**/
export const getBuildingList = (lat: number, lon: number, width: number, range: number, geo_data: GeoJSON.FeatureCollection<any>) => {
  // tslint-disable-next-line:no-explicit-any
  const building_list: any[] = [] // TODO: ここでanyを使えるようにする
  for (let i = 0; i < geo_data.features.length; i++) {
    const regex = /^way/
    if (regex.test(geo_data.features[i].id as string)) {
      if (geo_data.features[i].properties.building === 'yes') {
        //エリア内にある建物の情報
        for (let j = 0; j < geo_data.features[i].geometry.coordinates[0].length; j++) {
          const distance = getDistance(lat, lon, geo_data.features[i].geometry.coordinates[0][j][1], geo_data.features[i].geometry.coordinates[0][j][0])
          if (distance < range) {
            building_list.push(geo_data.features[i])
            break
          }
        }
      }
    }
  }
  return building_list
}

export const geoData22DArray = (features: { geometry: { coordinates: number[][][] } }[]) => {
  const building_list: number[][][] = []
  for (let i = 0; i < features.length; i++) {
    const building_elem: number[][] = []
    for (let j = 0; j < features[i].geometry.coordinates[0].length; j++) {
      building_elem.push([features[i].geometry.coordinates[0][j][1], features[i].geometry.coordinates[0][j][0]])
    }
    building_list.push(building_elem)
  }
  return building_list
}

type pointInfoType = [number, number, boolean]
/**
 * 地点(lat, lon)の周りの代表点を取ってくる関数
 */
const makeIdealPointList = (lat: number, lon: number, range: number, direction: number[]): pointInfoType[][] => {
  const lat_space = SPACE / 110940 // SPACE(m)の緯度のずれ
  const lon_space = SPACE / 91287 // SPCE(m)の経度のずれ
  const mesh: pointInfoType[][] = []
  const x = range / SPACE

  // メッシュ上の全ての点に(緯度、経度, true)を入れておく
  for (let i = -x; i < x + 1; i++) {
    const row: pointInfoType[] = []
    for (let j = -x; j < x + 1; j++) {
      row.push([lat + lat_space * i, lon + lon_space * j, true])
    }
    mesh.push(row)
  }
  // 看板の方向
  for (let i = 0; i < mesh.length; i++) {
    for (let j = 0; j < mesh[i].length; j++) {
      const distance = getDistance(lat, lon, mesh[i][j][0], mesh[i][j][1])
      const angle = calcAngle(
        {
          x: mesh[i][j][1],
          y: mesh[i][j][0],
        },
        {
          x: lon,
          y: lat,
        },
        {
          x: Number(lon) + Number(direction[0]),
          y: Number(lat) + Number(direction[1]),
        }
      )
      // TODO: 視認できる角度を60度に設定しているのでちゃんと検証して指定をしたい
      if (distance > range || angle > 60) {
        // 遠すぎたり、看板が見えない角度だったら、３番目の要素にfalseを入れておく
        mesh[i][j][2] = false
      }
    }
  }
  return mesh
}

/*
const isInsideBounds = (val, minVal, maxVal) => {
  return minVal <= val && val < maxVal
}

const addSurroundingPoints = (points) => {
  const extendedPoints = JSON.parse(JSON.stringify(points))
  const dirs = [
    [-1, 0],
    [1, 0],
    [0, 1],
    [0, -1],
  ]
  const numRow = points.length
  for (let row = 0; row < numRow; row++) {
    let numCol = points[row].length
    for (let col = 0; col < numCol; col++) {
      if (points[row][col] !== 0) {
        for (const [dRow, dCol] of dirs) {
          // 各方向に対して視認範囲を広げる
          let [tmpRow, tmpCol] = [row + dRow, col + dCol]
          if (isInsideBounds(tmpRow, 0, numRow) && isInsideBounds(tmpCol, 0, numCol)) {
            extendedPoints[tmpRow][tmpCol][2] = true
          }
        }
      }
    }
  }
  return extendedPoints
}

export const makeOutLine = (points) => {
  points = addSurroundingPoints(points);
  let isVisited = {}; // 既に訪れた点か見るフラグ
  let startPoint = null;
  points.forEach((p) => {
    const [lat, lng, isVisible] = p;
    if (isVisible){
      isVisited[JSON.stringify({lat: lat, lng: lng})] = false;
      startPoint = {lat: lat, lng: lng};
    }
  });
  if (startPoint === null){
    return []; // No visible points found
  }
  const queue = [startPoint];
  const dirs = [[-1, 0], [1, 0], [0, 1], [0, -1]];
  //TODO: 複数視認エリアに対応させる
  while (queue.length > 0){
    queue.shift
    for (const [dx, dy] of dirs){

    }
  }
}
*/
/*
export function makeOutline(points) {
  // pointsはキモい形式
  // 半時計回りに自分の周りの点を見ていって、輪郭を作る
  //FIXME: 複数の視認エリアに対応する
  points = addSurroundingPoints(points)
  let outline = []
  let startPoint = [0, 0]
  let standardPoint = [0, 0]
  for (let i = 0; i < points.length; i++) {
    let findFlag = false
    for (let j = 0; j < points[i].length; j++) {
      if (points[i][j][2]) {
        startPoint[0] = i
        standardPoint[0] = i
        startPoint[1] = j
        standardPoint[1] = j
        findFlag = true
        break
      }
    }
    if (findFlag) {
      break
    }
  }
  let direction = 0
  let previousDirection = -1

  while (true) {
    let around = makeAroundPoints(startPoint[0], startPoint[1], points) // ここでキモい形式の配列使う
    while (true) {
      if (direction === around.length) {
        direction = 0
      }
      if (around[direction] && around[direction].point_info) {
        let diff = previousDirection - direction > 0 ? previousDirection - direction : direction - previousDirection
        if (previousDirection === -1 || diff > 0) {
          outline.push([points[startPoint[0]][startPoint[1]][0], points[startPoint[0]][startPoint[1]][1]])
          previousDirection = direction
        }
        startPoint[0] = around[direction].i
        startPoint[1] = around[direction].j
        direction = direction - 3 < 0 ? direction + 5 : direction - 3
        break
      }
      direction++
    }
    //最初の点に戻ったら終了する処理
    if (startPoint[0] === standardPoint[0] && startPoint[1] === standardPoint[1]) {
      outline.push([points[standardPoint[0]][standardPoint[1]][0], points[standardPoint[0]][standardPoint[1]][1]])
      break
    }
  }

  return outline
}

function makeAroundPoints(x, y, points) {
  const maxX = points.length
  if (maxX === 0) {
    return []
  }
  const maxY = points[0].length
  const dirs = [
    [1, 1],
    [1, 0],
    [1, -1],
    [0, -1],
    [-1, -1],
    [-1, 0],
    [-1, 1],
    [0, 1],
  ]

  let surrPoints = []
  for (const [dx, dy] of dirs) {
    let tmpX = x + dx
    let tmpY = y + dy
    if (isInsideBounds(tmpX, 0, maxX) && isInsideBounds(tmpY, 0, maxY)) {
      surrPoints.push({ i: tmpX, j: tmpY, point_info: points[tmpX][tmpY] })
    }
  }
  return surrPoints
}

export function makeOutlineByWay(outline, way_list) {
  let gateList = []
  let result = []
  for (let i = 0; i < outline.length - 1; i++) {
    let crossFlag = false
    for (let j = 0; j < way_list.length; j++) {
      for (let k = 0; k < way_list[j].length - 1; k++) {
        let isCross = checkCross(
          {
            x: outline[i][0],
            y: outline[i][1],
          },
          {
            x: outline[i + 1][0],
            y: outline[i + 1][1],
          },
          {
            x: way_list[j][k][0],
            y: way_list[j][k][1],
          },
          {
            x: way_list[j][k + 1][0],
            y: way_list[j][k + 1][1],
          }
        )
        if (isCross) {
          gateList.push(outline[i])
          gateList.push(outline[i + 1])
          crossFlag = true
          break
        }
      }
      if (crossFlag) {
        break
      }
    }
  }
  for (let i = 0; i < gateList.length - 1; i++) {
    if (gateList[i][0] !== gateList[i + 1][0] || gateList[i][1] !== gateList[i + 1][1]) {
      result.push(gateList[i])
    }
  }
  result.push(gateList[gateList.length - 1])
  return result
}
*/

export async function getWayPoint(lat, lon, range) {
  const range_degree = metre2degree(range * 2)
  const min_lat = lat - range_degree[0]
  const min_lon = lon - range_degree[1]
  const max_lat = lat + range_degree[0]
  const max_lon = lon + range_degree[1]
  const osm_data = await getOSM(min_lat, min_lon, max_lat, max_lon)
  const geo_data = osm2geojson(osm_data)
  const way_list = getWayList(geo_data)
  return way_list // 道の部分に対して数字（道の種類に対応したコード）
}
