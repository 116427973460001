import React, { useState, useEffect } from 'react'
import { Message } from 'semantic-ui-react'
import { useLocation, useHistory } from 'react-router-dom'
import './index.css'

type LocationState = {
  text: string
  type: string
}

const PageMessage = () => {
  const location = useLocation<LocationState>()
  const history = useHistory<LocationState>()
  const [visible, setVisible] = useState<boolean>(true)
  const [text, setText] = useState<string>('')
  const [type, setType] = useState<string>('')

  const handleDismiss = () => {
    setVisible(false)
    history.push({
      state: { text: '', type: '' },
    })
  }

  useEffect(() => {
    if (location.state) {
      history.push({
        state: {
          text: location.state.text,
          type: location.state.type,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (location.state) {
      setText(location.state.text)
      setType(location.state.type)
    }
  }, [location.state])

  return (
    <>
      {visible && text && type === 'positive' && (
        <div className="pageMessage__appLayoutMessage">
          <Message positive onDismiss={handleDismiss}>
            <Message.Header>Successed</Message.Header>
            <p>{text}</p>
          </Message>
        </div>
      )}
      {visible && text && type === 'negative' && (
        <div className="pageMessage__appLayoutMessage">
          <Message negative onDismiss={handleDismiss}>
            <Message.Header>Failed</Message.Header>
            <p>{text}</p>
          </Message>
        </div>
      )}
    </>
  )
}

export default PageMessage
