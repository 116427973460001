import React from 'react'
import { Header, Grid } from 'semantic-ui-react'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'

const ConfirmCartSummary = (props) => {
  const colInfo = getColInfoFromTypeName(props.cart.mediaType)
  const jsxElemsSummary = []
  for (const elem of colInfo.cartConfirmSummary) {
    jsxElemsSummary.push(
      <Grid.Row>
        <Grid.Column width={6}>
          <b>{elem.displayedName}</b>
        </Grid.Column>
        <Grid.Column width={10}>{elem.displayMethod(props.mediaSortedList, props.cart, props.bundleSummary)}</Grid.Column>
      </Grid.Row>
    )
  }
  return (
    <>
      <Header as="h4">注文内容</Header>
      <Grid columns={2}>{jsxElemsSummary}</Grid>
    </>
  )
}

export default ConfirmCartSummary
