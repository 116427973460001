import React, { useContext, useEffect, useState } from 'react'
import { MediasOwnContext } from 'providers/MediasOwn'
import { MediasSearchFilter } from 'helpers/filters/MediasSearchFilter'
import { Card, Form, Segment } from 'semantic-ui-react'
import MediaCardOwnerCart from 'components/organisms/card/MediaCardOwnerCart'
import Spacer from 'components/atoms/Spacer'
import { MediaIsCartGenerator } from 'helpers/filters/MediaIsCartGenerator'
import { CartsContext } from 'providers/Carts'
import { UsersContext } from 'providers/Users'
import './index.css'

const CartMediaTab = ({ setMediaTarget }) => {
  const { users } = useContext(UsersContext)
  const { carts } = useContext(CartsContext)
  const { mediasOwn } = useContext(MediasOwnContext)
  const [searchWord, setSearchWord] = useState('')
  const [medias, setMedias] = useState('')
  const handleChange = (event) => {
    setSearchWord(event.target.value)
  }

  useEffect(() => {
    let tmpmedias = []
    let mediaRef = []

    if (searchWord) {
      tmpmedias = MediasSearchFilter(mediasOwn, searchWord)
    } else {
      tmpmedias = mediasOwn
    }
    // mediasOwnから(mediaBookedAll)にあるデータを抽出して、予約期間のコラムを追加して返す
    mediaRef = MediaIsCartGenerator(tmpmedias, carts)
    setMedias(mediaRef)
  }, [carts, mediasOwn, searchWord, users])

  return (
    <>
      <Form>
        <input type="text" name="searchWord" placeholder="キーワードで候補を絞る" value={searchWord} onChange={handleChange} />
      </Form>

      <Segment>
        <div className="cartMediaTab__scrollColumn">
          <Card.Group itemsPerRow="1" textAlign="center">
            {medias &&
              medias.map((media) => (
                <>
                  <MediaCardOwnerCart media={media} setMediaTarget={setMediaTarget} />
                </>
              ))}
          </Card.Group>
        </div>
      </Segment>
      <Spacer />
    </>
  )
}

export default CartMediaTab
