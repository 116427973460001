import React, { useEffect, useState, useContext } from 'react'
import { auth } from 'FirebaseConfig'
import { UsersContext } from 'providers/Users'
import { isOwner } from 'helpers/isOwner'

const AppLayout = (props) => {
  const { users } = useContext(UsersContext)
  const [owner, setOwner] = useState(false)

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user && users) {
        let ref = isOwner(users, user.uid)
        setOwner(ref)
      }
    })
  }, [users])

  return (
    <>
      {/* TODO: ここにヘッダーを持ってくる */}
      {owner ? <>{props.children}</> : <>{props.children}</>}
    </>
  )
}

export default AppLayout
