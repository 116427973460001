import React, { useEffect, useState } from 'react'
import { db } from 'FirebaseConfig'
import { Button, Grid, GridRow, Radio } from 'semantic-ui-react'
import AppLayoutAdmin from 'components/organisms/layouts/AppLayoutAdmin'
import { applyFilter, getNumberColNames } from 'components/pages/Admin/EditMedia/MediaIndexPage/helper'
import { MediaFilterButtons } from 'components/organisms/admin/MediaFilterButtons'
import { MediaPreviewFromDicts } from 'components/organisms/admin/MediaPreviewFormDicts'
import { mediaTypesEnum } from 'constants/mediaTypes'
import Spacer from 'components/atoms/Spacer'
import Loading from 'components/atoms/Loading'

const AdminEditMedia = () => {
  const [media, setMedia] = useState([]) //フィルタ機能を書ける前のmedia一覧
  const [filteredMedia, setFilteredMedia] = useState([]) // フィルタ機能をかけた後のmedia
  const [shouldLoadMedia, setShouldLoad] = useState(true)
  const [filterOptions, setFilterOptions] = useState([])
  const [numberColNames, setNumberColNames] = useState([]) // フィルタ対象の列の名前
  const [selectedMediaType, setSelectedMediaType] = useState(mediaTypesEnum.BILLBOARD)
  const [loadingFlag, setLoadingFlag] = useState(false)

  useEffect(() => {
    if (shouldLoadMedia) {
      setLoadingFlag(true)
      db.collection('media')
        .where('mediaType', '==', selectedMediaType)
        .onSnapshot((query) => {
          const data = []
          query.forEach((d) => data.push({ ...d.data() }))
          setMedia(data)
          setFilteredMedia(data) //TODO: この行どうするか考えておく
          setNumberColNames(getNumberColNames(data))
          setLoadingFlag(false)
        })

      setShouldLoad(false)
      // update numberCols
    }
    return () => setShouldLoad(false) // clean up function
  }, [media, shouldLoadMedia, selectedMediaType]) // this effect takes place when this component is mounted

  useEffect(() => {
    setFilteredMedia(applyFilter(filterOptions, media))
  }, [filterOptions, media])
  return (
    <>
      <AppLayoutAdmin>
        <Grid>
          <MediaFilterButtons numberColNames={numberColNames} setParentFilterOptions={setFilterOptions} />
          <MediaFilterButtons numberColNames={numberColNames} setParentFilterOptions={setFilterOptions} />
          <MediaFilterButtons numberColNames={numberColNames} setParentFilterOptions={setFilterOptions} />
          <GridRow>
            <Button
              onClick={() => {
                setFilteredMedia(media)
                setFilterOptions([])
              }}
            >
              全てのフィルタをリセット
            </Button>
          </GridRow>
        </Grid>
        <Spacer />
        <div>入力する媒体の種類を選択</div>
        <div>
          {Object.keys(mediaTypesEnum).map((mediaType) => {
            return (
              <Radio
                key={mediaType}
                label={mediaType}
                name="radioGroup"
                value={mediaTypesEnum[mediaType]}
                checked={selectedMediaType === mediaTypesEnum[mediaType]}
                onChange={(e, { value }) => {
                  setSelectedMediaType(value)
                  setShouldLoad(true)
                }}
              />
            )
          })}
        </div>
        <Spacer />
        {loadingFlag ? <Loading /> : <MediaPreviewFromDicts media={filteredMedia} mediaType={selectedMediaType} />}
      </AppLayoutAdmin>
    </>
  )
}

export default AdminEditMedia
