import { MediaListElem } from 'components/organisms/admin/PackageTable/helper'
import { AdviceMediaDataType } from 'constants/mediaData'
import { mediaType } from 'helpers/mediaType/mediaDef'

export type AdviceMediaDataTypeWithPeriod = AdviceMediaDataType & { weeks?: number; months?: number }

type splitMediaListObjType = {
  [type in mediaType]?: AdviceMediaDataTypeWithPeriod[]
}
export const splitMediaListByMediaType = (mediaPackageList: MediaListElem[], mediaList: AdviceMediaDataType[]) => {
  const splitMediaListObj: splitMediaListObjType = {}
  mediaPackageList.forEach((_media) => {
    const mediaObj = mediaList.find((medium) => medium.uid === _media.id)
    if (mediaObj) {
      const prev = splitMediaListObj[mediaObj.mediaType] || []

      splitMediaListObj[mediaObj.mediaType] = [...prev, { ...mediaObj, weeks: _media.weeks, months: _media.months }]
    }
  })
  return splitMediaListObj
}
