import { types } from 'constants/types'
import { generateColumnDataTypes } from '../../AddMedia/AddMediaPage/helper'
// merge a newly added filter option to the old ones
export const mergeFilters = (filterOptions, newOption) => {
  const colName = newOption.colName
  let newFilterOptions = []
  let merged = false
  for (const option of filterOptions) {
    let mergedOption
    if (option.colName === colName) {
      mergedOption = {
        colName: colName,
        upperBound: Math.min(option.upperBound, newOption.upperBound),
        lowerBound: Math.max(option.lowerBound, newOption.lowerBound),
      }
      merged = true
    } else {
      mergedOption = option
    }
    newFilterOptions.push(mergedOption)
  }
  if (!merged) {
    newFilterOptions.push(newOption)
  }
  return newFilterOptions
}

// 長過ぎる文字列の先頭部分だけ返す関数
export const truncateLongStr = (str) => {
  let maxLen = 15
  if (str.length > 15) {
    return str.slice(0, maxLen - 3) + '...' // 末尾に付ける三点リーダの分もあわせてmaxLenの長さになるようにする
  }
  return str
}

export const isinsideBoundary = (lowerBound, upperBound, value) => {
  return lowerBound <= value && value <= upperBound
}

export const applyFilter = (filterOptions, media) => {
  let filteredMedia = media.filter((rowDict) => {
    // フィルタ条件を満たす列だけを残す
    for (const option of filterOptions) {
      if (option.colName !== '') {
        let currentVal = rowDict[option.colName]
        if (!isinsideBoundary(option.lowerBound, option.upperBound, currentVal)) {
          return false
        }
      }
    }
    return true
  })
  return filteredMedia
}

// データ型が数字であるカラム名の入ったリストを返す
export const getNumberColNames = (mediaType) => {
  let colNames = []
  for (const [name, type] of Object.entries(generateColumnDataTypes(mediaType))) {
    if (type === types.number) colNames.push(name)
  }
  return colNames
}
