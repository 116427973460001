import React, { useContext, useEffect } from 'react'
import { AuthContext } from 'providers/Auth'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import { Container, Header, Segment } from 'semantic-ui-react'
import Spacer from 'components/atoms/Spacer'
import AppLayoutOwner from 'components/organisms/layouts/AppLayoutOwner'
import StopMediaForm from 'components/organisms/owner/StopMediaForm'
import Breadcrump from 'components/organisms/breadcrumb/StopMediaBreadcrump'
import { shouldSignin } from 'helpers/shouldSignin'

const StopMedia = () => {
  const { currentUser } = useContext(AuthContext)
  const history = useHistory()

  // TODO: currentUserのチェックをlayoutに移動
  useEffect(() => {
    if (shouldSignin(currentUser)) {
      history.push(`${paths.signinowner}`)
    }
  })

  return (
    <>
      <AppLayoutOwner>
        <Spacer />
        <Container>
          <Breadcrump></Breadcrump>
          <Segment>
            <Header as="h2">掲載一時停止</Header>
          </Segment>
          <StopMediaForm />
        </Container>
      </AppLayoutOwner>
    </>
  )
}

export default StopMedia
