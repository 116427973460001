// Library
import React, { useState, useEffect, useContext } from 'react'
// npm install @emotion/core and npm install @emotion/styled
import { Container, Segment, Dimmer, Loader, Message } from 'semantic-ui-react'
// import theme from 'assets/pictures/theme'
import Spacer from 'components/atoms/Spacer'
import paths from 'constants/paths'
import { auth } from 'FirebaseConfig'
import { AuthContext } from 'providers/Auth'
import NavigationBarPlane from 'components/organisms/menubar/NavigationBarPlane'
import { useHistory } from 'react-router-dom'
import ResetPasswordForm from 'components/organisms/sessions/ResetPasswordForm'
import './index.css'

const ResetPasswordOwner = () => {
  let domain = document.domain
  let port = domain === 'localhost' ? 3000 : ''
  let prot = domain === 'localhost' ? 'http' : 'https'
  const actionCodeSettings = {
    url: `${prot}://${domain}:${port}/owner/my`,
  }
  // currentUser is provided
  const [email, setEmail] = useState('')

  const history = useHistory()
  const { currentUser } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({})
  const [visible, setVisible] = useState(false)

  // styledでcssかいたらhandleChangeのたびに再レンダーされるのでinputには向いてない。解決方法あるか。
  const handleChange = (event) => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value)
        break
      default:
        console.log('key not found')
    }
  }

  useEffect(() => {
    if (currentUser) {
      history.push(`${paths.my}`)
    }
  }, [currentUser, history])

  const clickSendResetPasswordMail = () => {
    setLoading(true)
    console.log('click send reset password mail')
    auth
      .sendPasswordResetEmail(email, actionCodeSettings)
      .then(function () {
        // Email sent.
        setVisible(true)
        setMessage({
          type: 'positive',
          title: 'Successful',
          text: `${email}宛にパスワード変更メールを送信しました`,
        })
        setLoading(false)
      })
      .catch(function (error) {
        // An error happened.
        setVisible(true)
        setMessage({
          type: 'negative',
          title: 'Error',
          text: `${email}宛にパスワード変更メールを送信できませんでした[${error}]`,
        })
        setLoading(false)
      })
  }

  const handleDismiss = () => {
    setVisible(false)
    history.push({
      state: { text: '', type: '' },
    })
  }

  return (
    <>
      <NavigationBarPlane />
      <Spacer />
      {loading && (
        <Dimmer active inverted>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <Container text>
        <Segment>
          <div className="resetPasswordOwner__loginFormContainer">
            <ResetPasswordForm
              header="パスワードをお忘れの方へ"
              note="登録済みのメールアドレスにパスワード再設定リンクを送信いたします。"
              clickSendResetPasswordMail={clickSendResetPasswordMail}
              handleChange={handleChange}
              email={email}
              setEmail={setEmail}
              owner={true}
            />
          </div>
        </Segment>
      </Container>
      {visible && message && message.type === 'positive' && (
        <div className="resetPasswordOwner__appLayoutMessage">
          <Message positive onDismiss={handleDismiss} header={message.title} content={message.text} />
        </div>
      )}
      {visible && message && message.type === 'negative' && (
        <div className="resetPasswordOwner__appLayoutMessage">
          <Message negative onDismiss={handleDismiss} header={message.title} content={message.text} />
        </div>
      )}
    </>
  )
}

export default ResetPasswordOwner
