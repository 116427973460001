import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend, ContentRenderer, PieLabelRenderProps } from 'recharts'

const COLORS = ['#1854d2', '#eb4e91']

const RADIAN = Math.PI / 180
const renderCustomizedLabel: ContentRenderer<PieLabelRenderProps> = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = Number(innerRadius) + (Number(outerRadius) - Number(innerRadius)) * 0.5
  const x = Number(cx) + radius * Math.cos(-Number(midAngle) * RADIAN)
  const y = Number(cy) + radius * Math.sin(-Number(midAngle) * RADIAN)

  return (
    <text x={x} y={y} fill="white" textAnchor={x > Number(cx) ? 'start' : 'end'} dominantBaseline="central">
      {`${(Number(percent) * 100).toFixed(0)}%`}
    </text>
  )
}

export const SexPieChart = ({ maleRatio, femaleRatio }) => {
  const [data, setData] = useState([{}])
  useEffect(() => {
    setData([
      { name: '男性', value: maleRatio },
      { name: '女性', value: femaleRatio },
    ])
  }, [maleRatio, femaleRatio])

  return (
    <ResponsiveContainer width="100%" height={250}>
      <PieChart>
        <Pie
          data={data}
          cx={150}
          cy={100}
          labelLine={false}
          isAnimationActive={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          startAngle={90}
          endAngle={-270}
        >
          <Tooltip />
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend verticalAlign="top" align="right" layout="vertical" />
      </PieChart>
    </ResponsiveContainer>
  )
}
