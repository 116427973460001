// Library
import React, { useState, useEffect, useRef } from 'react'
import { Form, TextArea, Button, Message, Segment } from 'semantic-ui-react'
// Components
import { db } from 'FirebaseConfig'
//TODO:ヘッダーとフッターが別々になっている
import NavigationBarPlane from 'components/organisms/menubar/NavigationBarPlane'
import Spacer from 'components/atoms/Spacer'
import FooterNav from 'components/organisms/menubar/FooterNav'
import './index.css'

import * as firebase from 'firebase/app'
import 'firebase/functions'

const DocumentRequest = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [subject, setSubject] = useState('')
  const [content, setContent] = useState('')
  const [messageVisible, setMessageVisible] = useState(false)

  const inputRef = useRef(null)

  const textFieldStyle = {
    display: 'flex',
    width: '100%',
  }

  // TODO: METAタグの共通化
  useEffect(() => {
    const title = '資料請求｜AdVice（アドバイス）'
    const description =
      'AdViceについての問い合わせはこちらです。屋外広告注文プラットフォーム「AdVice（アドバイス）」は、投資対効果ベースで屋外広告を注文できる新しいプラットフォームです。'
    document.title = title
    // title以外のmeta
    const headData = document.head.children

    for (let i = 0; i < headData.length; i++) {
      const nameVal = headData[i].getAttribute('name')
      if (nameVal !== null) {
        if (nameVal.indexOf('description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        // OGP(twitter)の設定
        if (nameVal.indexOf('twitter:title') !== -1) {
          headData[i].setAttribute('content', title)
        }
        if (nameVal.indexOf('twitter:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:title') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:image') !== -1) {
          headData[i].setAttribute('content', process.env.REACT_APP_OGP_URL + '/img_ogp.png')
        }
      }
    }
  })

  // onChangeでsetStateする。
  const handleChange = (event) => {
    switch (event.target.name) {
      case 'name':
        setName(event.target.value)
        break
      case 'companyName':
        setCompanyName(event.target.value)
        break
      case 'email':
        setEmail(event.target.value)
        break
      case 'tel':
        setSubject(event.target.value)
        break
      case 'content':
        setContent(event.target.value)
        break
      default:
        console.log('key not found')
    }
  }

  const onSubmitContact = () => {
    let today = new Date(Date.now())
    let data = {}
    data.name = name
    data.companyName = companyName
    data.email = email
    data.tel = subject
    data.content = content
    data.sentAt = firebase.firestore.Timestamp.fromDate(today)

    // let sendMail = firebase.functions().httpsCallable('sendMail');

    db.collection('documentRequest')
      .doc()
      .set(data)
      .then(() => {
        // sendMail(data).then(() => {
        setMessageVisible(true)
        setName('')
        setCompanyName('')
        setEmail('')
        setSubject('')
        setContent('')
        // }).catch((error)=>{
        //     alert(`問い合わせ送信に失敗しました。[${error}]`);
        //     setMessageVisible(false);
        // })
      })
      .catch((error) => {
        alert(`問い合わせ送信に失敗しました。[${error}]`)
        setMessageVisible(false)
      })
  }

  const handleDismiss = () => {
    setMessageVisible(false)
  }

  return (
    <>
      <div style={{ display: 'grid' }}>
        <div style={{ gridRow: '1', gridColumn: '1' }} className="doc-req__navBarGridRow">
          <NavigationBarPlane />
        </div>
        <div style={{ gridRow: '2', gridColumn: '1' }} className="doc-req__appGridContent">
          <div className="ui container">
            <Spacer />
            <Segment>
              <h2>資料請求・お問い合わせ</h2>
              <p>
                下記フォーマットにご記入いただき、「入力内容を確認する」のボタンを押して内容をご確認のうえ、送信してください。自動で受付メールを送信いたします。
              </p>
            </Segment>
            <Spacer />
            <Segment>
              {messageVisible ? (
                <Message positive onDismiss={handleDismiss} header={'Successful'} content={'お問い合わせを送信しました'} />
              ) : (
                <Form size="huge" class="ui form" widths="equal" onSubmit={onSubmitContact} ref={inputRef}>
                  <Form.Field required>
                    <label>会社名（屋号）</label>
                    <input
                      value={companyName}
                      name="companyName"
                      type="text"
                      placeholder="株式会社パルダッド"
                      required
                      style={textFieldStyle}
                      onChange={handleChange}
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label>ご担当者名</label>
                    <input value={name} name="name" type="text" placeholder="山田 太郎" required style={textFieldStyle} onChange={handleChange} />
                  </Form.Field>
                  <Form.Field required>
                    <label>メールアドレス</label>
                    <input value={email} name="email" type="email" placeholder="info@palledad.com" style={textFieldStyle} onChange={handleChange} />
                  </Form.Field>
                  <Form.Field>
                    <label>電話番号</label>
                    <input value={subject} name="tel" type="number" placeholder="00000000000" style={textFieldStyle} onChange={handleChange} />
                  </Form.Field>
                  <Form.Field>
                    <label>ご意見・ご要望等</label>
                    <TextArea
                      value={content}
                      name="content"
                      label="お問い合わせ内容"
                      multiline
                      rows="8"
                      margin="normal"
                      variant="outlined"
                      style={textFieldStyle}
                      onChange={handleChange}
                    />
                  </Form.Field>
                  <Spacer />
                  <Button color="orange" size="huge" type="submit" class="" variant="contained">
                    送信
                  </Button>
                  <Spacer />
                </Form>
              )}
            </Segment>
            <Spacer />
          </div>
          <div style={{ gridRow: '3', gridColumn: '1' }} className="doc-req__appGridFooter">
            <FooterNav />
          </div>
        </div>
      </div>
    </>
  )
}
export default DocumentRequest
