import React, { useState, useEffect } from 'react'

const GetGroupStatus = (props) => {
  const [stasusLabel, setStatusLabel] = useState('')

  useEffect(() => {
    if (props.cart) {
      if (props.cart.status === 1) {
        setStatusLabel('注文前')
      } else if (props.cart.status === 2) {
        let todaySeconds = Date.now() / 1000
        setStatusLabel('注文中')
        if (props.cart.startDate && props.cart.startDate.seconds < todaySeconds) {
          setStatusLabel('掲載中')
        }
        if (props.cart.endDate && props.cart.endDate.seconds < todaySeconds) {
          setStatusLabel('掲載終了')
        }
      }
    }
  }, [props.cart])

  return <>{stasusLabel}</>
}

export default GetGroupStatus
