import { getPeriod } from './helpers'
import { daysInMonth, taxRate as defaultTaxRate } from '../../constants/constants'
import { calcBillboardCost, bundleSummaryDTypeBillboard, calcCPM } from './mediaTypeBillboard'
import { roundAndAddThousandsSeparator } from '../roundAndAddThousandsSeparator'
import { AdviceMediaDataType } from '../../constants/mediaData'
import { types } from 'constants/types'

export const mediaTypeJack = {
  name: 'ジャック',
  basicInfoFields: [
    {
      displayedName: '月額掲載費',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: (media) =>
        media.isPrivate ? '価格非公開' : `¥${roundAndAddThousandsSeparator(media.monthlyCost * media.marginRate * defaultTaxRate, 1)}`,
    },
    {
      displayedName: '年額掲載費',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: (media) => (media.isPrivate ? '価格非公開' : `¥${roundAndAddThousandsSeparator(media.yearlyCost * media.marginRate * defaultTaxRate, 1)}`),
    },
    {
      displayedName: '施工費',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: (media) =>
        media.isPrivate ? '価格非公開' : `¥${roundAndAddThousandsSeparator(media.constructionCost * media.marginRate * defaultTaxRate, 1)}`,
    },
    {
      displayedName: '回復費',
      displayedSuffix: '',
      displayedPrefix: '',
      valueType: 'number',
      displayMethod: (media) =>
        media.isPrivate ? '価格非公開' : `¥${roundAndAddThousandsSeparator(media.recoveryCost * media.marginRate * defaultTaxRate, 1)}`,
    },
  ],
  //TODO: 子媒体から効果指標を計算して表示させる
  effectIndicatorFields: [],
  //カート編集画面の効果指標テーブルのコンテンツ
  //TODO: 子媒体から効果指標を計算して表示させる
  cartEditMediaDetailEffectTable: [],
  // 媒体がたくさん表示される画面で表示される小さなカードに載せる値
  //TODO: 子媒体から効果指標を計算して表示させる
  cardContent: {
    mediaSearch: [
      {
        displayedName: '価格/月',
        displayMethod: (media) => `¥${roundAndAddThousandsSeparator(calcBillboardCost(media, 12, true, false, false), 1)}`,
        displayedPrefix: '',
        displayedSuffix: '',
      },
      {
        displayedName: '媒体数',
        displayMethod: (media) => media.mediaType === 'mediaTypeJack' && media.childMedia.length,
        displayedPrefix: '',
        displayedSuffix: '',
      },
    ],
    cartEdit: [
      {
        displayedName: '価格/月',
        // eslint-disable-next-line
        displayMethod: (media, period) => `¥${roundAndAddThousandsSeparator(calcBillboardCost(media, period.months, true, true, false), 1)}`,
        displayedPrefix: '',
        displayedSuffix: '',
      },
      {
        displayedName: '媒体数',
        displayMethod: (media) => media.mediaType === 'mediaTypeJack' && media.childMedia.length,
        displayedPrefix: '',
        displayedSuffix: '',
      },
    ],
  },
  cartFooterContent: [
    {
      displayedName: '合計月額',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) => `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForMonth, 1)}`,
    },
  ],
  cartRecommendSummary: [
    {
      displayedName: '単月費用',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) => `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForMonth, 1)}`,
    },
  ],
  //TODO: 子媒体から効果指標を計算して表示させる
  propertyFields: [],
  cartConfirmCard: {
    header: ['媒体', '掲載月数', '単月掲載費', '施工費', '回復費', '小計'],
    contents: [
      {
        displayedName: '掲載月数',
        displayMethod: (media, period) => period.months + 'ヶ月',
      },
      {
        displayedName: '単月掲載費',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayMethod: (media, period) => `¥${roundAndAddThousandsSeparator(media.monthlyCost * media.marginRate * defaultTaxRate, 1)}`,
      },
      {
        displayedName: '施工費',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayMethod: (media, period) => `¥${roundAndAddThousandsSeparator(media.constructionCost * media.marginRate * defaultTaxRate, 1)}`,
      },
      {
        displayedName: '回復費',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayMethod: (media, period) => `¥${roundAndAddThousandsSeparator(media.recoveryCost * media.marginRate * defaultTaxRate, 1)}`,
      },
      {
        displayedName: '小計',
        displayMethod: (media, period) => `¥${roundAndAddThousandsSeparator(calcBillboardCost(media, period.months, true, true, true), 1)}`,
      },
    ],
  },
  cartConfirmSummary: [
    {
      displayedName: '媒体数',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeBillboard) => mediaSortedList.length,
    },
    {
      displayedName: '掲載期間',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeBillboard) => getPeriod(cart),
    },
    {
      displayedName: '合計掲載費',
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeBillboard) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalPublicationCostForEntirePeriod, 1)}`,
    },
    {
      displayedName: '合計施工回復費',
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeBillboard) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalConstructionCost + bundleSummary.totalRecoveryCost, 1)}`,
    },
    {
      displayedName: '合計金額',
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeBillboard) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForEntirePeriod, 1)}`,
    },
  ],
  cartConfirmFooter: [
    {
      displayedName: '合計インプレッション',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) => roundAndAddThousandsSeparator(bundleSummary.totalImpPerMonth, 1),
    },
    {
      displayedName: '媒体掲載費合計(税込)',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForEntirePeriod * defaultTaxRate, 1)}`,
    },
    {
      displayedName: '施工回復費合計(税込)',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) =>
        `¥${roundAndAddThousandsSeparator((bundleSummary.totalConstructionCost + bundleSummary.totalRecoveryCost) * defaultTaxRate, 1)}`,
    },
    {
      displayedName: 'CPM',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) =>
        !isNaN(bundleSummary.cpm) ? `¥${roundAndAddThousandsSeparator(bundleSummary.cpm, 1)}` : 'N/A',
    },
  ],
  culcBundleSummary: (mediaList, period, isTaxIncluded): bundleSummaryDTypeBillboard => {
    const taxRate: number = isTaxIncluded ? defaultTaxRate : 1
    const bundleSummary: bundleSummaryDTypeBillboard = {
      totalOverallCostForEntirePeriod: 0,
      totalOverallCostForMonth: 0,
      totalPublicationCostForEntirePeriod: 0,
      totalPublicationCostForMonth: 0,
      totalConstructionCost: 0,
      totalRecoveryCost: 0,
      totalImpPerMonth: 0,
      totalTargetImpPerMonth: 0,
      cpm: 0,
      tCpm: 0,
      mediaType: 'mediaTypeBillboard',
    }
    mediaList.forEach((medium) => {
      bundleSummary.totalPublicationCostForMonth += medium.monthlyCost * medium.marginRate * taxRate
      bundleSummary.totalConstructionCost += medium.constructionCost * taxRate
      bundleSummary.totalRecoveryCost += medium.recoveryCost * taxRate
      bundleSummary.totalImpPerMonth += medium.impressionPerDay * daysInMonth
      bundleSummary.totalTargetImpPerMonth += medium.targetCirculationPerDay * medium.visibility * daysInMonth
    })
    bundleSummary.totalPublicationCostForEntirePeriod = bundleSummary.totalPublicationCostForMonth * period.months
    bundleSummary.totalOverallCostForEntirePeriod =
      bundleSummary.totalPublicationCostForEntirePeriod + bundleSummary.totalRecoveryCost + bundleSummary.totalConstructionCost
    bundleSummary.totalOverallCostForMonth = bundleSummary.totalOverallCostForEntirePeriod / period.months
    bundleSummary.cpm =
      !isNaN(bundleSummary.totalOverallCostForMonth) && !isNaN(bundleSummary.totalImpPerMonth)
        ? calcCPM(bundleSummary.totalOverallCostForMonth, bundleSummary.totalImpPerMonth)
        : NaN
    bundleSummary.tCpm =
      !isNaN(bundleSummary.totalOverallCostForMonth) && !isNaN(bundleSummary.totalTargetImpPerMonth)
        ? calcCPM(bundleSummary.totalOverallCostForMonth, bundleSummary.totalTargetImpPerMonth)
        : NaN
    return bundleSummary
  },
  purchasePeriodUnit: 'month',
}

export const jackColumnDtypes = {
  address: types.string,
  ageRatio: types.string,
  circulation: types.array,
  circulationStatus: types.string,
  constructionCost: types.number,
  direction: types.number,
  femaleAgeRatio: types.map,
  hasIllumination: types.bool,
  height: types.number,
  horizontalWidth: types.number,
  impression: types.array,
  latitude: types.number,
  longitude: types.number,
  maleAgeRatio: types.map,
  //TODO: arrayにして、複数の画像のパスを入力できるようにする
  mediaImagePaths: types.stringArrayElem,
  mediaType: types.enum,
  monthlyCost: types.number,
  name: types.string,
  note: types.string,
  recoveryCost: types.number,
  summary: types.string,
  uid: types.string,
  verticalWidth: types.number,
  visibility: types.number,
  visibilityStatus: types.string,
  yearlyCost: types.number,
  isPrivate: types.bool,
  tempVisibility: types.nullableNumber,
  tempCirculation: types.nullableNumber,
}
