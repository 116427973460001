import React from 'react'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import './index.css'

const CartTabFooter = (props) => {
  const colInfo = getColInfoFromTypeName(props.mediaType)
  const jsxElems = []
  for (const key of colInfo.cartFooterContent) {
    jsxElems.push(
      <div className="cartTabFooter__cartTabFooterItem" key={key.displayedName}>
        <p className="cartTabFooter__cartTabFooterSmall">{key.displayedName}</p>
        <p className="cartTabFooter__cartTabFooterNumber">{key.displayMethod(props.bundleSummary)}</p>
      </div>
    )
  }

  return <div className="cartTabFooter__cartTabFooter">{jsxElems}</div>
}

export default CartTabFooter
