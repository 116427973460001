import React, { useMemo } from 'react'
import DefaultMediaForm from 'components/organisms/admin/DefaultMediaForm'
import MansionSignageEditMediaForm from 'components/organisms/admin/MansionSignageEditMediaForm'
import LeafletEditMediaForm from 'components/organisms/admin/LeafletEditMediaForm'
import { mediaTypesEnum } from 'constants/mediaTypes'
import VisionMediaForm from '../VisionMediaForm'

const AdminEditForm = ({ mediaInfo, mediaID }) => {
  const selectedMediaForm = useMemo(() => {
    switch (mediaInfo.mediaType) {
      case mediaTypesEnum.LEAFLET:
        return <LeafletEditMediaForm mediaInfo={mediaInfo} mediaID={mediaID} />
      case mediaTypesEnum.MANSION_SIGNAGE:
        return <MansionSignageEditMediaForm mediaInfo={mediaInfo} mediaID={mediaID} />
      case mediaTypesEnum.DIGITAL_VISION:
        return <VisionMediaForm mediaInfo={mediaInfo} mediaID={mediaID} />
      default:
        return <DefaultMediaForm mediaInfo={mediaInfo} mediaID={mediaID} />
    }
  }, [mediaInfo.mediaType])
  return <>{selectedMediaForm}</>
}

export default AdminEditForm
