import React from 'react'
import { Button, Table } from 'semantic-ui-react'
import { truncateLongStr } from 'components/pages/Admin/EditMedia/MediaIndexPage/helper'
import paths from 'constants/paths'
import firebase from 'FirebaseConfig'
import { generateColumnDataTypes } from 'components/pages/Admin/AddMedia/AddMediaPage/helper'
const db = firebase.firestore()

// {colName: value}の形式で表された媒体情報の配列を受け取ってプレビューを表示するコンポーネント
export const MediaPreviewFromDicts = (props) => {
  let media = props.media
  if (media.length === 0) {
    return <div>条件を満たすデータはありません</div>
  }

  const deleteMediaFromAll = async (mediaId) => {
    if (window.confirm('このメディアを消去してもよろしいですか？')) {
      try {
        const batch = db.batch()
        // メディアをデータベースから消去
        const deleteMediaRef = db.collection('media').doc(mediaId)
        batch.delete(deleteMediaRef)

        // 消去されたメディアをカートから消去
        const deleteMediaSnapshot = await db.collection('cart').where('id', 'array-contains', mediaId).get()
        for (let doc of deleteMediaSnapshot.docs) {
          const deleteRef = db.collection('cart').doc(doc.data().uid)
          batch.update(deleteRef, { id: firebase.firestore.FieldValue.arrayRemove(mediaId) })
        }
        if (window) {
          alert('データが消去されました')
        }
        await batch.commit()
      } catch (error) {
        if (window) {
          console.error(error)
          alert('データの消去に失敗')
        }
      }
    }
  }

  let colNames = Object.keys(generateColumnDataTypes(props.mediaType))
  colNames = colNames.filter((colName) => colName !== 'uid')
  colNames.sort() // sort keys in the Alphabetical order
  colNames.unshift('uid') // 表示したときにドキュメントIDが左端に来るように、ドキュメントIDを先頭に持ってくる

  const headerElem = colNames.map((headerName) => (
    <Table.HeaderCell key={headerName} style={{ minWidth: '100px' }}>
      {headerName}
    </Table.HeaderCell>
  ))
  const contentsElem = media.slice().map((rowData) => {
    const rowElem = colNames.map((colName) => {
      let valueStr = colName in rowData ? truncateLongStr(JSON.stringify(rowData[colName])) : ''
      return (
        <Table.Cell
          key={rowData.uid + colName}
          style={{ minWidth: '100px' }}
          onClick={() => {
            if (window) {
              const win = window.open(paths.adminEditMedia + '/' + rowData['uid'], '_blank')
              win.focus()
            }
          }}
        >
          {valueStr}
        </Table.Cell>
      )
    })
    return (
      <Table.Row key={rowData.uid}>
        {rowElem}
        <Table.Cell>
          <Button style={{ color: 'orange', margin: 'auto' }} onClick={() => deleteMediaFromAll(rowData['uid'])}>
            delete this media
          </Button>
        </Table.Cell>
      </Table.Row>
    )
  })
  return (
    <div style={{ overflowX: 'scroll' }}>
      <Table celled>
        <Table.Header>
          <Table.Row>{headerElem}</Table.Row>
        </Table.Header>

        <Table.Body>{contentsElem}</Table.Body>
      </Table>
    </div>
  )
}
