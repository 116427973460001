// ### majorResidentsProperty
//     | value | 名前 | 補足説明 |
// | : ----: | : ----: | : ----: |
// | family | 家族 | |
// | single | 単身 | |
// | dinks | DINKs | DINKs(Double Income No Kids): 共働きで子供を意識的に作らない、持たない夫婦 |
// ### mansionType
//     | value | 名前 |
// | : ----: | : ----: |
// | forRent | 賃貸 |
// | forSale | 分譲 |

const ResidentPropertyOptions = [
  { key: 'family', text: '家族', value: 'family' },
  { key: 'single', text: '単身', value: 'single' },
  { key: 'dinks', text: 'DINKs', value: 'dinks' },
]

export default ResidentPropertyOptions
