import React, { createContext, useState, useEffect, useContext, useCallback } from 'react'
import { MediasContext } from 'providers/Medias'
import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'
import { calculateCentroid } from 'helpers/calculates/calculateCentroid'

type mediaAvailableDataType = {
  mediasAvailable: AdviceMediaDataType[]
  getChildMedia: (parentUid: string) => AdviceMediaDataType[]
  isSameJackGroup: (media: AdviceMediaDataType, targetMediaId: string) => boolean
}

const MediasAvailableContext = createContext<mediaAvailableDataType>({
  mediasAvailable: [],
  getChildMedia: () => {
    return []
  },
  isSameJackGroup: () => {
    return false
  },
})

const MediasAvailableProvider = ({ children }) => {
  const [mediasAvailable, setMediasAvailable] = useState<AdviceMediaDataType[]>([])

  const { medias } = useContext(MediasContext)

  const getChildMedia = useCallback(
    (parentUid: string) => {
      return mediasAvailable.filter((element) => isJackChild(element) && element.parentUid === parentUid)
    },
    [mediasAvailable]
  )

  const isSameJackGroup = useCallback(
    (media: AdviceMediaDataType, targetMediaId: string) => {
      const parentUid = media.mediaType === 'mediaTypeJack' ? media.uid : isJackChild(media) ? media.parentUid : null
      if (!parentUid) {
        return false
      }
      const targetMedia = mediasAvailable.find((e) => e.uid === targetMediaId)
      if (!targetMedia) {
        return false
      }
      const targetParentUid = targetMedia.mediaType === 'mediaTypeJack' ? targetMedia.uid : isJackChild(targetMedia) ? targetMedia.parentUid : null
      return parentUid === targetParentUid
    },
    [mediasAvailable]
  )

  useEffect(() => {
    if (medias) {
      let tmpmedias = medias
      tmpmedias = tmpmedias.filter((row) => {
        if (row.isDeleted || row.isPrivate) {
          return false
        } else {
          return row
        }
      })
      // ジャック媒体については子媒体をInject
      tmpmedias.forEach((media) => {
        if (media.mediaType === 'mediaTypeJack') {
          media.childMedia = getChildMedia(media.uid)
          const centroid = calculateCentroid(media.childMedia)
          media.latitude = centroid.latitude
          media.longitude = centroid.longitude
        }
      })
      setMediasAvailable(tmpmedias)
    }
  }, [medias])

  return <MediasAvailableContext.Provider value={{ mediasAvailable, getChildMedia, isSameJackGroup }}>{children}</MediasAvailableContext.Provider>
}

export { MediasAvailableContext, MediasAvailableProvider }
export const useMediaAvailable = () => {
  return useContext(MediasAvailableContext)
}
