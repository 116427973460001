import React, { createContext, useState, useEffect, useContext } from 'react'
// import { AuthContext } from 'components/Auth'
import { db } from 'FirebaseConfig'
import firebase from 'firebase'
// import { useCollectionData } from "react-firebase-hooks/firestore";

const MediaBookedAllContext = createContext({})
// const uid = auth.currentUser.uid

// provide carts.all
export type mediaBookedDataType = {
  campaignId?: string // このフィールドはdeprecatedだが、DBの更新が終わるまでは消さずに残す
  cartId: string
  date: firebase.firestore.Timestamp
  docId: string
  isBooked: boolean
  mediaId: string
  uid: string
  userId: string
}

const MediaBookedAllProvider = ({ children }) => {
  const [mediaBookedAll, setMediaBookedAll] = useState<mediaBookedDataType[]>([])

  useEffect(() => {
    db.collection('mediaBooked').onSnapshot((query) => {
      const data: mediaBookedDataType[] = []
      query.forEach((d) => data.push({ ...(d.data() as mediaBookedDataType) }))
      setMediaBookedAll(data)
    })
  }, [])

  return <MediaBookedAllContext.Provider value={{ mediaBookedAll }}>{children}</MediaBookedAllContext.Provider>
}

export { MediaBookedAllContext, MediaBookedAllProvider }

export const useMediaBookedAll = () => {
  const mediaBookedAll = useContext(MediaBookedAllContext)
  return mediaBookedAll
}
