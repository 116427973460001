import { useMemo, useState } from 'react'
import { SemanticCOLORS } from 'semantic-ui-react'

type buttonStatus = 'CREATING' | 'STANBY' | 'READY'

export const useDownloadePdfStatus = () => {
  const [buttonStatus, setButtonStatus] = useState<buttonStatus>('STANBY')
  const downloadBtnText = useMemo(() => {
    switch (buttonStatus) {
      case 'CREATING':
        return 'PDFを作成中です'
      case 'READY':
        return 'PDFをダウンロードする'
      case 'STANBY':
        return 'PDFを作成する'
      default:
        return 'PDFを作成中です'
    }
  }, [buttonStatus])

  const buttonColor = useMemo<SemanticCOLORS>(() => {
    return buttonStatus === 'READY' ? 'red' : 'grey'
  }, [buttonStatus])

  return { downloadBtnText, buttonStatus, setButtonStatus, buttonColor }
}
