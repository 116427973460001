import { bundleSummaryDType } from './../../../helpers/mediaType/helpers'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import { mediaListCartGenerator } from 'helpers/filters/mediaListCartGenerator'
import { GetGroupStatusLabel } from 'helpers/GetGroupStatusLabel'
import { cartDataType } from 'providers/CartsAll'
import { AdviceMediaDataType } from 'constants/mediaData'

export const statusFilter = (carts, stuts1, stuts2, stuts3, stuts4) => {
  let tmpCarts = carts
  tmpCarts = tmpCarts.filter((row) => {
    let flag = false

    const statusLabel = GetGroupStatusLabel(row)
    if (stuts1 && statusLabel === '注文前') {
      flag = true
    }
    if (stuts2 && statusLabel === '注文中') {
      flag = true
    }
    if (stuts3 && statusLabel === '掲載中') {
      flag = true
    }
    if (stuts4 && statusLabel === '掲載終了') {
      flag = true
    }
    if (flag) {
      return row
    }
  })
  return tmpCarts
}

type SortedCarts = {
  billboard: cartDataType[]
  digitalSignage: cartDataType[]
  mansionSignage: cartDataType[]
  leaflet: cartDataType[]
  poster: cartDataType[]
  others: cartDataType[]
}

export const mediaTypeFilter = (carts: cartDataType[]) => {
  const sortedCarts: SortedCarts = {
    billboard: [],
    digitalSignage: [],
    mansionSignage: [],
    leaflet: [],
    poster: [],
    others: [],
  }
  //既存のグループの登録ではmediaTypeを配列にしていたため、switch文で捉えられないので、includes()を用いている
  if (carts.length) {
    for (let i = 0; i < carts.length; i++) {
      switch (carts[i].mediaType) {
        case 'mediaTypeBillboard':
          sortedCarts.billboard.push(carts[i])
          break
        case 'mediaTypeVision':
          sortedCarts.digitalSignage.push(carts[i])
          break
        case 'mediaTypeMansionSignage':
          sortedCarts.mansionSignage.push(carts[i])
          break
        case 'mediaTypeLeaflet':
          sortedCarts.leaflet.push(carts[i])
          break
        case 'mediaTypePoster':
          sortedCarts.poster.push(carts[i])
          break
        default:
          sortedCarts.others.push(carts[i])
      }
    }
  }

  return sortedCarts
}

export const campaignSearchFilter = (campaigns, campaignId) => {
  let tmpCampaign = campaigns
  tmpCampaign = tmpCampaign.filter((row) => {
    if (row.campaignId === campaignId) {
      return row
    } else {
      return false
    }
  })
  return tmpCampaign
}
//グループの配列から、ユーザーIDが一致するものを抽出する
export const groupSearchFilter = (groups, userId) => {
  let tmpGroups = groups
  tmpGroups = tmpGroups.filter((row) => {
    if (row.userId === userId) {
      return row
    } else {
      return false
    }
  })
  return tmpGroups
}

export const getSummary = (cart: cartDataType, mediasAvailable: AdviceMediaDataType[]): bundleSummaryDType | undefined => {
  const mediaList: (AdviceMediaDataType | undefined)[] = mediasAvailable ? mediaListCartGenerator(mediasAvailable, cart) : []
  if (mediaList) {
    const colInfo = getColInfoFromTypeName(cart.mediaType)
    const period = {
      months: cart.endDateMonth,
      weeks: cart.endDateWeek,
    }
    const summary: bundleSummaryDType = colInfo.culcBundleSummary(mediaList, period, true)
    return summary
  } else return undefined
}
