import { db } from 'FirebaseConfig'
import { mediaType } from 'helpers/mediaType/mediaDef'

export const PACKAGE_FIELDS = {
  ID: 'id',
  NAME: 'name',
  MEDIA_LIST: 'mediaList',
} as const

export type MediaListElem = {
  id: string
  mediaType: mediaType
  months?: number
  weeks?: number
}

//TODO: どこかにtypeをまとめたフォルダを作りたい
export type Package = {
  [PACKAGE_FIELDS.ID]: string
  [PACKAGE_FIELDS.NAME]: string
  [PACKAGE_FIELDS.MEDIA_LIST]: MediaListElem[]
}

export const initialPackageValue: Package = {
  id: '',
  name: '',
  mediaList: [],
}

export const createPackage = async (docId: string, packageObj: Package) => {
  try {
    await db.collection('packages').doc(docId).set(packageObj)
  } catch (err) {
    console.log(err)
  }
}

export const updatePackage = async (docId: string, packageObj: Package) => {
  try {
    await db.collection('packages').doc(docId).update(packageObj)
  } catch (err) {
    console.log(err)
  }
}

export const deletePackage = async (docId: string) => {
  try {
    await db.collection('packages').doc(docId).delete()
  } catch (err) {
    console.log(err)
  }
}

export const isPackage = (arg: unknown): arg is Package => {
  return (
    typeof arg === 'object' &&
    arg !== null &&
    Object.values(PACKAGE_FIELDS).every((key) => {
      return arg[key] !== undefined
    })
  )
}
