// Library
import React, { useState, useEffect, useContext } from 'react'
// npm install @emotion/core and npm install @emotion/styled
import { Segment, Container, Dimmer, Loader, Message } from 'semantic-ui-react'
// import theme from 'assets/pictures/theme'
import Spacer from 'components/atoms/Spacer'
import paths from 'constants/paths'
import { auth, db } from 'FirebaseConfig'
import { AuthContext } from 'providers/Auth'
import { useHistory } from 'react-router-dom'
import NavigationBarPlane from 'components/organisms/menubar/NavigationBarPlane'
import SignUpForm from 'components/organisms/sessions/SignupForm'
import FooterNav from 'components/organisms/menubar/FooterNav'
import './index.css'

const SignupOwner = () => {
  // currentUser is provided
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const history = useHistory()
  const { currentUser } = useContext(AuthContext)
  const [companyName, setCompanyName] = useState('')
  const [address, setAddress] = useState('')
  const [fullName, setFullName] = useState('')
  const [tel, setTel] = useState('')
  const [policy, setPolicy] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({})
  const [visible, setVisible] = useState(false)

  // styledでcssかいたらhandleChangeのたびに再レンダーされるのでinputには向いてない。解決方法あるか。
  const handleChange = (event) => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value)
        break
      case 'password':
        setPassword(event.target.value)
        break
      case 'passwordConfirm':
        setPasswordConfirm(event.target.value)
        break
      case 'companyName':
        setCompanyName(event.target.value)
        break
      case 'address':
        setAddress(event.target.value)
        break
      case 'fullName':
        setFullName(event.target.value)
        break
      case 'tel':
        setTel(event.target.value)
        break
      case 'policy':
        setPolicy(!policy)
        break
      default:
        console.log('key not found')
    }
  }

  const clickSignupAsOwner = () => {
    setLoading(true)
    if (policy) {
      if (password === passwordConfirm) {
        auth
          .createUserWithEmailAndPassword(email, password)
          .then(() => {
            auth.onAuthStateChanged(function (user) {
              if (user) {
                // 媒体主はdisplayName: adminを登録する。
                db.collection('user')
                  .doc(user.uid)
                  .set({
                    uid: user.uid,
                    docId: user.uid,
                    companyName: companyName,
                    address: address,
                    fullName: fullName,
                    tel: tel,
                    isOwner: true,
                  })
                  .then(() => {
                    let domain = document.domain
                    let port = domain === 'localhost' ? 3000 : ''
                    let prot = domain === 'localhost' ? 'http' : 'https'
                    const actionCodeSettings = {
                      url: `${prot}://${domain}:${port}/${paths.myowner}`,
                    }
                    user
                      .sendEmailVerification(actionCodeSettings)
                      .then(() => {
                        history.push({
                          pathname: `${paths.myowner}`,
                          state: {
                            text: `${user.email}宛に確認メールを送信しました。メールを確認して認証を完了させてください。`,
                            type: 'positive',
                          },
                        })
                      })
                      .catch((error) => {
                        history.push({
                          pathname: `${paths.myowner}`,
                          state: {
                            text: `${user.email}宛に確認メールを送信できませんでした[${error}]`,
                            type: 'negative',
                          },
                        })
                      })
                  })
                  .catch(() => {
                    setMessage({
                      type: 'negative',
                      title: '登録失敗',
                      text: 'ユーザーのデータ作成に失敗しました。',
                    })
                    setVisible(true)
                    setLoading(false)
                  })
              }
            })
          })
          // メールリンク認証
          // リダイレクト先を指定するとコンティニューボタンが現れる。
          .then(() => {
            history.push({
              pathname: `${paths.myowner}`,
              state: {
                text: 'ログインしました。',
                type: 'positive',
              },
            })
          })
      } else {
        setVisible(true)
        setMessage({
          type: 'negative',
          text: 'パスワードが一致しません。',
          title: 'error',
        })
        setLoading(false)
      }
    } else {
      setVisible(true)
      setMessage({
        type: 'negative',
        text: '同意して下さい。',
        title: 'error',
      })
      setLoading(false)
    }
    // mail for e-mail address verification can be sent here by using sendSignInLinkToEmail()
  }

  useEffect(() => {
    if (currentUser) {
      history.push({
        pathname: `${paths.myowner}`,
        state: {
          text: 'ログインしました。',
          type: 'positive',
        },
      })
    }
  }, [currentUser, history])

  const handleDismiss = () => {
    setVisible(false)
    history.push({
      state: { text: '', type: '' },
    })
  }

  return (
    <>
      <NavigationBarPlane />
      <Spacer />
      {loading && (
        <Dimmer active inverted>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <Container text>
        <Segment>
          <div className="signup__loginFormContainer">
            <SignUpForm
              header="パルダッドに新しく登録する"
              note="パルダッドに登録して媒体管理を行いましょう。"
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              companyName={companyName}
              setCompanyName={setCompanyName}
              address={address}
              setAddress={setAddress}
              fullName={fullName}
              setFullName={setFullName}
              tel={tel}
              setTel={setTel}
              clickSignup={clickSignupAsOwner}
              handleChange={handleChange}
              owner={true}
            />
          </div>
        </Segment>
      </Container>
      <Spacer />
      {visible && (
        <div className="signup__appLayoutMessage">
          <Message negative onDismiss={handleDismiss} header={message.title} content={message.text} />
        </div>
      )}
      <FooterNav />
    </>
  )
}

export default SignupOwner
