// Library
import React, { useState, useEffect, useContext } from 'react'
// npm install @emotion/core and npm install @emotion/styled
import { Segment, Container, Loader, Dimmer, Message } from 'semantic-ui-react'
// import theme from 'assets/pictures/theme'
import Spacer from 'components/atoms/Spacer'
import paths from 'constants/paths'
import { auth } from 'FirebaseConfig'
import { AuthContext } from 'providers/Auth'
import NavigationBarPlane from 'components/organisms/menubar/NavigationBarPlane'
import { useHistory } from 'react-router-dom'
import SignInForm from 'components/organisms/sessions/SignInForm'
import FooterNav from 'components/organisms/menubar/FooterNav'
import './index.css'
import * as firebase from 'firebase/app'

const Signin = () => {
  // currentUser is provided
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()
  const { currentUser } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({})
  const [visible, setVisible] = useState(false)
  const [page] = useState(false)

  // TODO: styledでcssかいたらhandleChangeのたびに再レンダーされるのでinputには向いてない。解決方法あるか。
  const handleChange = (event) => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value)
        break
      case 'password':
        setPassword(event.target.value)
        break
      default:
        console.log('key not found')
    }
  }

  const clickLoginAsUser = () => {
    setLoading(true)
    auth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        firebase.analytics().logEvent('login')
        console.log('emailverified:', res.user.emailVerified)
        if (res.user.emailVerified) {
          if (page !== false) {
            history.push({
              pathname: `${page}`,
              state: {
                text: 'ログインしました。',
                type: 'positive',
              },
            })
          } else {
            history.push({
              pathname: `${paths.mediatop}`,
              state: {
                text: 'ログインしました',
                type: 'positive',
              },
            })
          }
          setLoading(false)
        } else {
          let domain = document.domain
          let port = domain === 'localhost' ? ':3000' : ''
          let prot = domain === 'localhost' ? 'http' : 'https'
          const actionCodeSettings = {
            url: `${prot}://${domain}${port}${paths.signin}?uid=${res.user && res.user.uid}`,
          }
          res.user
            .sendEmailVerification(actionCodeSettings)
            .then(() => {
              setMessage({
                type: 'negative',
                title: 'ログインエラー',
                text: 'メール認証が終わっていません。認証用メールを再度送りましたのでご確認下さい。',
              })
              setVisible(true)
            })
            .catch(() => {
              setMessage({
                type: 'negative',
                title: 'ログインエラー',
                text: 'メール認証が終わっていません。以前お送りした認証用メールをご確認下さい。',
              })
              setVisible(true)
            })
          setLoading(false)
          return
        }
      })
      .catch((error) => {
        setLoading(false)
        setVisible(true)
        console.log(error)
        setMessage({
          type: 'negative',
          title: 'Error',
          text: error.code == 'auth/user-disabled' ? '現在審査中のため該当ユーザーの利用は現在許可されていません' : error.message,
        })
      })
  }

  // TODO: metaタグのコンポーネント
  useEffect(() => {
    const title = 'ログイン画面｜AdVice（アドバイス）'
    const description =
      'AdViceにログインできます。サービス認知を圧倒的に高めるOOHマーケティングを効果的に、カンタンに。屋外広告注文プラットフォーム「AdVice（アドバイス）」は、投資対効果ベースで屋外広告を注文できる新しいプラットフォームです。'
    document.title = title
    // title以外のmeta
    const headData = document.head.children

    for (let i = 0; i < headData.length; i++) {
      const nameVal = headData[i].getAttribute('name')
      if (nameVal !== null) {
        if (nameVal.indexOf('description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        // OGP(twitter)の設定
        if (nameVal.indexOf('twitter:title') !== -1) {
          headData[i].setAttribute('content', title)
        }
        if (nameVal.indexOf('twitter:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:title') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:image') !== -1) {
          headData[i].setAttribute('content', process.env.REACT_APP_OGP_URL + '/img_ogp.png')
        }
      }
    }
  })
  useEffect(() => {
    if (currentUser) {
      if (currentUser.emailVerified) {
        history.push({
          pathname: `${paths.mediatop}`,
          state: {
            text: 'ログインしました。',
            type: 'positive',
          },
        })
      }
    } else {
      console.log('メールアドレスはありません')
    }
  }, [currentUser, history])

  const handleDismiss = () => {
    setVisible(false)
    history.push({
      state: { text: '', type: '' },
    })
  }

  return (
    <>
      <NavigationBarPlane />
      <Spacer />
      {loading && (
        <Dimmer active inverted>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <Container text>
        <Segment>
          <div className="signin__loginFormContainer">
            <SignInForm
              header="AdVice（アドバイス）にログインする"
              note="AdVice（アドバイス）にログインして屋外広告の注文を行いましょう。"
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              clickLogin={clickLoginAsUser}
              handleChange={handleChange}
              owner={false}
            />
          </div>
        </Segment>
      </Container>
      <Spacer />
      {visible && (
        <div className="signin__appLayoutMessage">
          <Message negative onDismiss={handleDismiss} header={message.title} content={message.text} />
        </div>
      )}
      <FooterNav />
    </>
  )
}

export default Signin
