import { Button, Dimmer, Grid, Header, Segment } from 'semantic-ui-react'
import React, { useState } from 'react'
import Spacer from 'components/atoms/Spacer'
import ImpressionFigure from '../../tables/ImpressionFigure'
import MediaDetailTable from '../../tables/MediaDetailTable'
import MediaDetailTableShorter from '../../tables/MediaDetailTableShorter'
import MediaImpressionTable from '../../tables/MediaImpressionTable/MediaImpressionTable'
import TagList from '../../tag/TagList'
import { MediaImage } from '../../images/MediaImage'
import firebase from 'FirebaseConfig'
import { convertValueToText } from '../../../../helpers/mediaType/helpers'
import { isJackChild } from 'constants/mediaData'

// TODO: mediaTypeのハードコーディングをまとめる
const mediaTypesAllowdPDFOutput = ['mediaTypeVision', 'mediaTypeBillboard', 'mediaTypePoster', 'mediaTypeLeaflet', 'mediaTypeMansionSignage']
const getMediaPDF = firebase.functions().httpsCallable('getMediaPDF')
const recordDownloadPDF = firebase.functions().httpsCallable('recordDownloadPDF')

const MediaSegments = ({ media, login, paths, user }) => {
  const [downloadBtnText, setDownloadBtnText] = useState('媒体情報をPDF形式でダウンロードする')

  const downloadPDF = async () => {
    setDownloadBtnText('pdfを作成中です')
    const res = await getMediaPDF({
      mediaID: media.uid,
    })
    if (res && res.data.status === 200 && window) {
      window.open(res.data.fileDownloadURL)
      await recordDownloadPDF({
        mediaId: media.uid,
        userId: user.uid,
      })
    } else {
      if (window) {
        window.alert('pdfが正常に作成されませんでした。')
      }
      console.error(res.data.message)
    }
    setDownloadBtnText('媒体情報をPDF形式でダウンロードする')
  }

  return (
    <>
      <Spacer />
      <div className="media__mediaSegment">
        <Segment>
          <TagList tags={media.tags} color="#f39800" textColor="white" />
          <TagList tags={media.lines} color="#5cBBcf" textColor="white" />
          <TagList tags={media.highways} color="#5cBBcf" textColor="white" />
          <h1>{media.name}</h1>
          <div>
            <Segment basic>
              {isJackChild(media) && <Button compact>エリアジャック専用</Button>}
              <Button active compact color="red">
                {convertValueToText(media.mediaType)}
              </Button>
            </Segment>
          </div>
          <Spacer />
          <div>
            {/* <MediaCarousel media={media} width={'300px'}/> */}
            <MediaImage floated="left" width="150px" style={{ borderRadius: '.5rem 0 0 .5rem' }} media={media} />
          </div>
        </Segment>
      </div>
      <Spacer />
      <div className="media__mediaSegment">
        <Segment>
          <b className="mediaDetailTitle">概要</b>
          <Segment basic style={{ whiteSpace: 'normal', lineHeight: '1.5rem' }}>
            {media.summary}
          </Segment>
        </Segment>
      </div>
      <Spacer />
      <div className="media__mediaSegment">
        <Segment>
          <b className="mediaDetailTitle">基本情報</b>
          {login ? <MediaDetailTable media={media} /> : <MediaDetailTableShorter media={media} />}
        </Segment>
      </div>
      <Spacer />
      {media.mediaType !== 'mediaTypeJack' && !media.isLocalMedia && (
        <>
          <div className="media__mediaSegment">
            <Segment>
              <Dimmer.Dimmable blurring dimmed={!login}>
                <Dimmer active={!login} inverted>
                  <Header as="p" style={{ color: 'black' }}>
                    ※続きをご覧頂くには会員登録が必要です。
                  </Header>
                  <Button
                    color="orange"
                    onClick={() => {
                      history.push(`${paths.signin}`, { page: '/media/' + location.pathname.match(/\w+/g)[location.pathname.match(/\w+/g).length - 1] })
                    }}
                  >
                    会員登録/ログイン
                  </Button>
                </Dimmer>
                <b className="mediaDetailTitle">効果</b>
                <MediaImpressionTable media={media} />
              </Dimmer.Dimmable>
            </Segment>
          </div>
          <Spacer />
        </>
      )}
      <div className="media__mediaSegment">
        {media.mediaType !== 'mediaTypeJack' && !media.isLocalMedia && (
          <>
            <Segment>
              <Dimmer.Dimmable blurring dimmed={!login}>
                <Dimmer active={!login} inverted>
                  <Header as="p" style={{ color: 'black' }}>
                    ※続きをご覧頂くには会員登録が必要です。
                  </Header>
                  <Button
                    color="orange"
                    onClick={() => {
                      history.push(`${paths.signin}`, { page: '/media/' + location.pathname.match(/\w+/g)[location.pathname.match(/\w+/g).length - 1] })
                    }}
                  >
                    会員登録/ログイン
                  </Button>
                </Dimmer>
                <div>
                  <b className="mediaDetailTitle">属性</b>
                </div>
                <Spacer />
                <div>
                  <ImpressionFigure media={media} />
                </div>
                {mediaTypesAllowdPDFOutput.includes(media.mediaType) && (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        onClick={async () => {
                          await downloadPDF()
                        }}
                        disabled={downloadBtnText !== '媒体情報をPDF形式でダウンロードする'}
                      >
                        {downloadBtnText}
                      </Button>
                    </Grid.Column>
                  </Grid>
                )}
              </Dimmer.Dimmable>
            </Segment>
            <Spacer />
          </>
        )}
      </div>
      <div className="media__mediaSegment">
        <Segment>
          <b className="mediaDetailTitle">備考</b>
          <Segment basic>
            <p style={{ whiteSpace: 'normal' }}>{media.description}</p>
          </Segment>
        </Segment>
        {media.mediaType === 'mediaTypeJack' && (
          <Segment>
            <b className="mediaDetailTitle">エリアジャックに含まれる広告媒体一覧</b>
            {media.childMedia.map((element) => {
              return <MediaSegments key={element.uid} media={element} login={login} paths={paths} user={user} />
            })}
          </Segment>
        )}
      </div>
      <Spacer />
    </>
  )
}

export default MediaSegments
