import { types } from 'constants/types'

export const columnDtypes = {
  fullName: types.string,
  companyName: types.string,
  tel: types.string,
  docId: types.string,
  uid: types.string,
  address: types.string,
  isOwner: types.bool,
  marginRate: types.number,
}

export const validationCallBacks = {
  marginRate: (d) => 0 <= d,
}
