import React, { useCallback, useContext, useEffect, useState } from 'react'
import firebase from 'firebase'
import { MediasAvailableContext } from 'providers/MediasAvailable'
import { getTargetCirculation } from 'helpers/calculates/getTargetCirculation'
import ImpressionFigure from 'components/organisms/tables/ImpressionFigure'
import { MediaCarousel } from 'components/molecules/MediaCarousel'
import TagList from 'components/organisms/tag/TagList'
import { Segment, Button, Icon } from 'semantic-ui-react'
import MediaDetailTable from 'components/organisms/tables/MediaDetailTable'
import MediaDetailEffectTable from 'components/organisms/tables/MediaDetailEffectTable'
import Spacer from 'components/atoms/Spacer'
import Loading from 'components/atoms/Loading'
import { convertValueToText } from 'helpers/mediaType/helpers'
import './index.css'
import { UserContext } from 'providers/User'
import { isJackChild } from 'constants/mediaData'

const getMediaPDF = firebase.functions().httpsCallable('getMediaPDF')
const recordDownloadPDF = firebase.functions().httpsCallable('recordDownloadPDF')

const CartMediaDetail = (props) => {
  const { mediasAvailable } = useContext(MediasAvailableContext)
  const [media, setMedia] = useState(null)
  const { user } = useContext(UserContext)
  const [isDownloading, setIsDownloading] = useState(false)
  const [isAtCart, setIsAtCart] = useState(false)

  //TODO:media.isAtCartを使えないか
  useEffect(() => {
    if (media && props.cart.id) {
      if (props.cart.id.includes(media.uid)) {
        setIsAtCart(true)
      } else {
        setIsAtCart(false)
      }
    }
  }, [media, props.cart.id])

  useEffect(() => {
    if (props.mediaTarget) {
      let ref = mediasAvailable.find((media) => media.uid === props.mediaTarget)
      if (ref) {
        setMedia(ref)
      } else {
        // detail表示中にisDeleted=trueにされたときにdetailを閉じる
        props.setMediaTarget('')
      }
    }
  }, [mediasAvailable, props.mediaTarget])

  useEffect(() => {
    if (media && props.cart) {
      let Ref = getTargetCirculation(media, props.cart.targetAge, props.cart.targetSex)
      media.targetCirculationPerDay = Ref
    }
  }, [media, props.cart, props.groupTarget])

  const downloadPDFHandler = useCallback(async () => {
    setIsDownloading(true)
    const res = await getMediaPDF({
      mediaID: media.uid,
    })
    if (res && res.data.status === 200 && window) {
      window.open(res.data.fileDownloadURL)
      user &&
        (await recordDownloadPDF({
          mediaId: media.uid,
          userId: user.uid,
        }))
    } else {
      if (window) {
        window.alert('pdfが正常に作成されませんでした。')
      }
      console.error(res.data.message)
    }
    setIsDownloading(false)
  }, [media])

  return (
    <>
      {!media ? (
        <Loading />
      ) : (
        <div>
          <Segment>
            <b>{media.name}</b>
          </Segment>
          <div style={{ display: 'grid' }} className="cartMediaDetail__scrollMediaDetail">
            <div style={{ gridColumn: '1/3', gridRow: '1' }}>
              <Segment basic>
                {isJackChild(media) && <Button compact>エリアジャック専用</Button>}
                {media ? (
                  <Button active compact color="red">
                    {convertValueToText(media.mediaType)}
                  </Button>
                ) : (
                  <></>
                )}
                <div style={{ margin: '1rem 0' }}>
                  <TagList tags={media.tags} color="#f39800" textColor="white" />
                  <TagList tags={media.lines} color="#5cBBcf" textColor="white" />
                  <TagList tags={media.highways} color="#5cBBcf" textColor="white" />
                </div>
              </Segment>
            </div>
            <div style={{ gridColumn: '1/3', gridRow: '2' }}>
              <MediaCarousel imageURLArray={media.mediaImagePaths} width={'300px'} />
            </div>
            <div style={{ gridColumn: '1/3', gridRow: '3' }}>
              <br />
              <b className="cartMediaDetail__mediaDetailTitle">概要</b>
              <Segment basic style={{ whiteSpace: 'normal', lineHeight: '1.5rem' }}>
                {media.summary}
              </Segment>
              <b className="cartMediaDetail__mediaDetailTitle">基本情報</b>
              <MediaDetailTable media={media} />
              {!media.isLocalMedia && (
                <>
                  <b className="cartMediaDetail__mediaDetailTitle">効果</b>
                  {/* TODO: targetImpを計算するロジックを切り出して、mediaTypeの定義ファイルに入れられるようにする */}
                  <MediaDetailEffectTable period={props.period} media={media} mediaType={media.mediaType} />
                  <Spacer />
                  <div style={{ gridColumn: '1/3', gridRow: '4' }}>
                    <ImpressionFigure media={media} isMinimized={true} />
                  </div>
                </>
              )}
            </div>
            <div style={{ gridColumn: '1/3', gridRow: '5' }}>
              <b className="cartMediaDetail__mediaDetailTitle">備考</b>
              <Segment basic>
                <p style={{ whiteSpace: 'normal' }}>{media.description}</p>
              </Segment>
            </div>
          </div>
          <div style={{ height: '54px' }} className="cartMediaDetail__cartDetailButtonWrapper">
            {props.cart.status === 1 && (
              <>
                {isAtCart ? (
                  <>
                    <Button color="grey" onClick={() => props.clickRemoveMediaFromCart(media)}>
                      <Icon name="cart" />
                      カートから削除する
                    </Button>
                  </>
                ) : (
                  <>
                    <Button color="teal" onClick={() => props.clickAddMediaToCart(media)}>
                      <Icon name="cart" />
                      カートに追加する
                    </Button>
                  </>
                )}
                <Button onClick={downloadPDFHandler} disabled={isDownloading} color="teal">
                  PDFでダウンロード
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default CartMediaDetail
