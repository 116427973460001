import React, { useEffect, useContext, useState } from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import { useLocation } from 'react-router-dom'
import { CartsContext } from 'providers/Carts'
import { CartFindFilter } from 'helpers/filters/CartFindFilter'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'

const CartConfirmBreadcrump = () => {
  const location = useLocation()
  const { carts } = useContext(CartsContext)
  const [cart, setCart] = useState('')
  const history = useHistory()

  useEffect(() => {
    if (location.pathname) {
      const cartRef = CartFindFilter(carts, location.pathname.substr(-20))
      if (cartRef) {
        setCart(cartRef)
      }
    } else {
      console.log('no iud')
    }
  }, [carts, location.pathname])

  return (
    <>
      <Breadcrumb size="mini">
        <Breadcrumb.Section onClick={() => history.push(`${paths.mediatop}`)} link active>
          <i style={{ color: 'gray' }} className="home icon"></i>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section onClick={() => history.push('/group/dashboard')} link active style={{ color: 'gray' }}>
          ダッシュボード
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section onClick={() => history.push(`/cart/edit/${cart.uid}`)} link active style={{ color: 'gray' }}>
          {cart.groupName}
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active>注文確認</Breadcrumb.Section>
      </Breadcrumb>
    </>
  )
}

export default CartConfirmBreadcrump
