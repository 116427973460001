import React from 'react'
import styled from '@emotion/styled'
import InViewMonitor from 'react-inview-monitor'

type Props = {
  children: React.ReactNode
  delay: number
  margin: string
}
export const Up = ({ children, delay, margin = '-20%' }: Props) => (
  <InViewMonitor childPropsInView={{ isActive: true }} intoViewMargin={margin}>
    <FadeInUp isActive={false} delay={delay}>
      {children}
    </FadeInUp>
  </InViewMonitor>
)

export const Down = ({ children, delay, margin = '-20%' }: Props) => (
  <InViewMonitor childPropsInView={{ isActive: true }} intoViewMargin={margin}>
    <FadeInDown isActive={false} delay={delay}>
      {children}
    </FadeInDown>
  </InViewMonitor>
)

export const Left = ({ children, delay, margin = '-20%' }: Props) => (
  <InViewMonitor childPropsInView={{ isActive: true }} intoViewMargin={margin}>
    <FadeInLeft isActive={false} delay={delay}>
      {children}
    </FadeInLeft>
  </InViewMonitor>
)

export const Right = ({ children, delay, margin = '-20%' }: Props) => (
  <InViewMonitor childPropsInView={{ isActive: true }} intoViewMargin={margin}>
    <FadeInRight isActive={false} delay={delay}>
      {children}
    </FadeInRight>
  </InViewMonitor>
)

const FadeIn = styled.div<{ isActive: boolean; delay: number }>`
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease;
  ${({ isActive }) => isActive && `opacity: 1;`}
  ${({ delay }) => delay && `transition-delay: ${delay}ms;`}
`

const FadeInUp = styled(FadeIn)<{ isActive: boolean }>`
  transform: translateY(30px);
  ${({ isActive }) => isActive && `transform: translateY(0px);`}
`

const FadeInDown = styled(FadeIn)<{ isActive: boolean }>`
  transform: translateY(-30px);
  ${({ isActive }) => isActive && `transform: translateY(0px);`}
`

const FadeInLeft = styled(FadeIn)<{ isActive: boolean }>`
  transform: translateX(-30px);
  ${({ isActive }) => isActive && `transform: translateX(0px);`}
`

const FadeInRight = styled(FadeIn)<{ isActive: boolean }>`
  transform: translateX(30px);
  ${({ isActive }) => isActive && `transform: translateX(0px);`}
`
