import React from 'react'
import { Table, Radio, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import GetGroupStatus from 'components/organisms/group/GetGroupStatus'
import TargetSexCell from 'components/molecules/group/TargetSexCell'
import TargetAgeCell from 'components/molecules/group/TargetAgeCell'
import TargetLocationCell from 'components/molecules/group/TargetLocationCell'
import { getPeriod } from 'helpers/mediaType/helpers'
import 'components/organisms/group/GroupLists/index.css'

const GroupListOthers = (props) => {
  return (
    <div className="groupList__TableContainer">
      <Segment className="groupList__segment" style={{ margin: '1rem 0rem' }}>
        <p className="groupList__Label">その他</p>
      </Segment>
      <Table style={{ marginTop: '0rem', marginBottom: '3rem' }}>
        <Table.Header>
          <Table.HeaderCell rowSpan="2">選択</Table.HeaderCell>
          <Table.HeaderCell rowSpan="2">グループ名</Table.HeaderCell>
          <Table.HeaderCell rowSpan="2">配信状況</Table.HeaderCell>
          <Table.HeaderCell rowSpan="2">期間</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {props.carts.map((cart) => (
            <Table.Row key={cart.uid}>
              <Table.Cell>
                <Radio value={cart.uid} checked={props.cartId === cart.uid} onClick={() => props.handleChgange(cart.uid)} />
              </Table.Cell>
              <Table.Cell>
                <Link to={`/cart/edit/${cart.uid}`}>{cart.groupName}</Link>
              </Table.Cell>
              <Table.Cell>
                <GetGroupStatus cart={cart} />
              </Table.Cell>
              <Table.Cell>{cart.startDate && cart.endDate && getPeriod(cart)}</Table.Cell>
              <TargetLocationCell targetLocation={cart.targetLocation} />
              <TargetAgeCell targetAge={cart.targetAge} />
              <TargetSexCell targetSex={cart.targetSex} />
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

export default GroupListOthers
