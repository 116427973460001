import React, { useContext, useEffect, useState } from 'react'
import firebase, { auth } from 'FirebaseConfig'
import { AuthContext } from 'providers/Auth'
import { Button, Form, Table, Dimmer, Loader } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { UsersContext } from 'providers/Users'
import { isOwner } from 'helpers/isOwner'
import { AccountImage } from 'components/organisms/images/AccountImage'
import './index.css'

const MySettings = (props) => {
  const [email, setEmail] = useState('')
  const [accountImage, setAccountImage] = useState('')
  const [emailVarified, setEmailVarified] = useState(true)
  const [owner, setOwner] = useState(false)
  const history = useHistory()
  const { currentUser } = useContext(AuthContext)
  const { users } = useContext(UsersContext)
  const [loading, setLoading] = useState(false)

  let domain = document.domain
  let port = domain === 'localhost' ? 3000 : ''
  let prot = domain === 'localhost' ? 'http' : 'https'
  const actionCodeSettings = {
    url: `${prot}://${domain}:${port}/${props.path}?uid=${currentUser && currentUser.uid}`,
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setEmail(user.email)
        if (user.emailVerified) {
          setEmailVarified(true)
        } else {
          setEmailVarified(false)
        }
        let ref = isOwner(users, user.uid)
        setOwner(ref)
      }
    })
  }, [users])

  const sendValidEmail = () => {
    if (window.confirm('認証メールを送信しますか？')) {
      // try {
      setLoading(true)
      auth.onAuthStateChanged(function (user) {
        if (user) {
          user
            .sendEmailVerification(actionCodeSettings)
            .then(() => {
              setLoading(false)
              history.go(0)
              history.push({
                state: {
                  text: `${user.email}宛に確認メールを送信しました`,
                  type: 'positive',
                },
              })
            })
            .catch((error) => {
              setLoading(false)
              history.go(0)
              history.push({
                state: {
                  text: `${user.email}宛に確認メールを送信できませんでした[${error}]`,
                  type: 'negative',
                },
              })
            })
        }
      })
    }
  }

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value)
        break
      case 'accountImage':
        setAccountImage(event.target.files[0])
        break
      default:
        console.log('key not found')
    }
  }

  const clickUpdateEmail = () => {
    setLoading(true)
    auth.onAuthStateChanged((user) => {
      user
        .updateEmail(email)
        .then(function () {
          user
            .sendEmailVerification(actionCodeSettings)
            .then(() => {
              setLoading(false)
              history.go(0)
              history.push({
                state: {
                  text: `${user.email}宛に確認メールを送信しました`,
                  type: 'positive',
                },
              })
            })
            .catch((error) => {
              setLoading(false)
              history.go(0)
              history.push({
                state: {
                  text: `${user.email}宛に確認メールを送信できませんでした[${error}]`,
                  type: 'negative',
                },
              })
            })
        })
        .catch(function (error) {
          // An error happened.
          alert(`メールアドレスの更新に失敗しました$[${error}]`)
          history.push(props.path)
        })
    })
  }

  const onSubmitFile = () => {
    setLoading(true)
    let timestamp = Date.now()
    let ref = firebase
      .storage()
      .ref()
      .child(currentUser.uid + '_' + timestamp + '.jpg')
    let fileName = currentUser.uid + '_' + timestamp + '.jpg'
    ref
      .put(accountImage)
      .then(() => {
        currentUser.updateProfile({
          photoURL: fileName,
        })
      })
      .then(() => {
        setLoading(false)
        history.go(0)
        history.push({
          state: {
            text: '画像を更新しました。',
            type: 'positive',
          },
        })
      })
      .catch((error) => {
        setLoading(false)
        history.go(0)
        history.push({
          state: {
            text: `画像更新できませんでした[${error}]`,
            type: 'negative',
          },
        })
      })
    // })
  }

  return (
    <>
      {loading && (
        <Dimmer active inverted>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <Table celled>
        <Table.Body>
          <Table.Row>
            <Table.Cell>メール認証</Table.Cell>
            <Table.Cell>{emailVarified ? '完了' : '未完了'}</Table.Cell>
            <Table.Cell>
              {emailVarified ? (
                <>
                  <Button color="orange" disabled>
                    メール認証する
                  </Button>
                </>
              ) : (
                <>
                  <Button color="orange" onClick={() => sendValidEmail()}>
                    メール認証する
                  </Button>
                </>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>登録メールアドレス</Table.Cell>
            <Table.Cell>
              <Form>
                <Form.Input name="email" value={email} onChange={handleChange} />
              </Form>
            </Table.Cell>
            <Table.Cell>
              <>
                <Button color="orange" onClick={() => clickUpdateEmail()}>
                  更新する
                </Button>
              </>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>プロフィール画像</Table.Cell>
            <Table.Cell>
              <></>
              <div className="mySettings__accountImageCircle">
                <AccountImage width="50px" height="50px" />
              </div>
              <></>
              <input type="file" name="accountImage" onChange={handleChange} />
            </Table.Cell>
            <Table.Cell>
              <>
                <Button color="orange" onClick={onSubmitFile}>
                  更新する
                </Button>
              </>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>媒体掲載資格</Table.Cell>
            <Table.Cell>{owner ? <>あり</> : <>なし</>}</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  )
}

export default MySettings
