import React, { createContext, useState, useEffect, useContext } from 'react'
import { db } from 'FirebaseConfig'
import { convertMedia } from 'constants/mediaData'
import { TagsContext } from 'providers/Tags'
import { AdviceMediaDataType } from 'constants/mediaData'

type mediasProviderDataType = {
  medias: AdviceMediaDataType[]
  mediaLoading: boolean
}

const MediasContext = createContext<mediasProviderDataType>({ medias: [], mediaLoading: true })

// provide medias
const MediasProvider = ({ children }) => {
  const { tags, lines, highways }: any = useContext(TagsContext) //TODO:tagsとlinesの型を追加
  const [medias, setMedias] = useState<AdviceMediaDataType[]>([])
  const [mediaLoading, setMediaLoading] = useState<boolean>(false)
  useEffect(() => {
    setMediaLoading(true)
    db.collection('media')
      .where('name', '!=', '')
      .onSnapshot(
        async (query) => {
          const data = [] as AdviceMediaDataType[]
          const childMedia = await db.collectionGroup('childMedia').get()
          const mediaArray = [...query.docs, ...childMedia.docs]
          for (const doc of mediaArray) {
            //todo サブコレクションにownerの情報を入れたい <- ユーザーごとにマージンを変更して使う仕様にしたので以下の余分な処理を行う必要あり、パフォーマンス低下の可能性あり
            try {
              const mediaTmp = convertMedia(doc.data())
              if (doc.data().userId) {
                const ownerRef = await db.collection('user').doc(doc.data().userId).get()
                const ownerData = ownerRef.data()
                if (ownerData && ownerData.marginRate) {
                  mediaTmp.marginRate = ownerData.marginRate
                }
              }
              mediaTmp.tags = tags && tags[doc.id] ? tags[doc.id] : []
              mediaTmp.lines = lines && lines[doc.id] ? lines[doc.id] : []
              mediaTmp.highways = (highways && highways[doc.id]) || []
              data.push(mediaTmp)
            } catch (error) {
              console.error(`error at ${doc.id}, error: ${error}`)
            }
          }
          setMedias(data)
          setMediaLoading(false)
        },
        function () {
          setMedias([])
        }
      )
  }, [tags, lines, highways])

  return (
    <>
      <MediasContext.Provider value={{ medias, mediaLoading }}>{children}</MediasContext.Provider>
    </>
  )
}

export { MediasContext, MediasProvider }

export const useMedias = () => {
  const { medias } = useContext(MediasContext)
  return medias
}
