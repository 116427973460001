import { SexPieChartDispMethod, AgePieChartDispMethod, SexRatioByAgeChartDispMethod, bool2Has, getCol, parseArea, sizeDisplayMethod } from './helpers'
import ResidentPropertyOptions from 'constants/ResidentsPropertyOptions'
import MansionOptions from 'constants/MansionOptions'
import { taxRate as defaultTaxRate } from 'constants/constants'
import { getPeriod } from 'helpers/mediaType/helpers'
import { types } from 'constants/types'
import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'
import { roundAndAddThousandsSeparator } from 'helpers/roundAndAddThousandsSeparator'

// TODO: 以下のmediaTypeMansionSignage内のbasicInfoFieldsのfieldNameを追加すべし
export const mediaTypeMansionSignage = {
  name: 'マンションサイネージ',
  basicInfoFields: [
    {
      displayedName: '住所',
      displayedSuffix: '',
      displayedPrefix: '',
      valueType: 'string',
      displayMethod: getCol('address'),
    },
    {
      displayedName: '緯度',
      displayedPrefix: 'N',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: getCol('latitude'),
    },
    {
      displayedName: '経度',
      displayedPrefix: 'E',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: getCol('longitude'),
    },
    {
      displayedName: 'サイズ',
      displayedSuffix: '',
      displayedPrefix: '',
      valueType: 'number',
      displayMethod: sizeDisplayMethod,
    },
    {
      displayedName: '週額掲載費',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: (media) =>
        media.isPrivate
          ? '価格非公開'
          : isJackChild(media)
          ? '-'
          : `¥${roundAndAddThousandsSeparator(media.weeklyCost * media.marginRate * defaultTaxRate, 1)}`,
    },
    {
      displayedName: '音声',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'bool',
      displayMethod: (media) => bool2Has(media['hasAudio']),
    },
    {
      displayedName: 'エリア',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'string',
      displayMethod: (media) => parseArea(media['address']),
    },
    {
      displayedName: '住民属性',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'array',
      displayMethod: (media) => {
        //valueからtextに変換する
        if (media.mansionType) {
          const tmp = MansionOptions.find((elem) => elem.value === media.mansionType)
          if (tmp) {
            return tmp.text
          } else {
            throw Error('Unkonwn Option found: ' + JSON.stringify(media.mansionType))
          }
        }
        return '全て'
      },
    },
    {
      displayedName: '分譲・賃貸',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'array',
      displayMethod: (media) => {
        let list = []
        //valueからtextに変換する
        if (media.residentsProperty && media.residentsProperty.length && media.residentsProperty.length !== ResidentPropertyOptions.length) {
          list = media.residentsProperty.map((key) => {
            const result = ResidentPropertyOptions.find((elem) => elem.value === key)
            if (result) {
              return result.text
            } else {
              throw Error('Unknown Option found: ' + JSON.stringify(key))
            }
          })
          return list.join('、')
        } else {
          return '全て'
        }
      },
    },
  ],
  effectIndicatorFields: [
    {
      fieldName: 'numOfHouses',
      displayedName: '戸数',
      summary: null,
      displayMethod: {
        mediaSearch: (media) => `${roundAndAddThousandsSeparator(media.numOfHouses, 1)}世帯`,
        cartEdit: (media) => `${roundAndAddThousandsSeparator(media.numOfHouses, 1)}世帯`,
      },
    },
    {
      fieldName: '未定',
      displayedName: '価格 / 戸数',
      displayedPrefix: '¥',
      displayedSuffix: '',
      summary: '※１：1ヶ月掲載時の1戸・1週間あたりの価格（施工回復費・消費税を含む）',
      displayMethod: {
        mediaSearch: (media) =>
          media.isPrivate
            ? '価格非公開'
            : isJackChild(media)
            ? '-'
            : `¥${roundAndAddThousandsSeparator(calcMansionSignageCostEffectiveness(media, 4, true), 1)}`,
        cartEdit: null,
      },
    },
  ],

  // 媒体がたくさん表示される画面で表示される小さなカードに載せる値
  cardContent: {
    mediaSearch: [
      {
        displayedName: '価格/週',
        displayMethod: (media) => (isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(media.weeklyCost * media.marginRate * defaultTaxRate, 1)}`),
        prefix: '¥',
        suffix: '',
      },
      {
        displayedName: '戸数',
        displayMethod: (media) => `${roundAndAddThousandsSeparator(media.numOfHouses, 1)}世帯`,
        prefix: '',
        suffix: '',
      },
      {
        displayedName: '価格/戸',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayMethod: (media) => (isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(calcMansionSignageCostEffectiveness(media, 4, true), 1)}`),
        prefix: '¥',
        suffix: '',
      },
    ],
    cartEdit: [
      {
        displayedName: '価格/週',
        displayMethod: (media, period) =>
          isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(calcMansionSignageCost(media, period.weeks, true, false), 1)}`,
        prefix: '¥',
        suffix: '',
      },
      {
        displayedName: '戸数',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayMethod: (media, period) => `${roundAndAddThousandsSeparator(media.numOfHouses, 1)}世帯`,
        prefix: '',
        suffix: '',
      },
      {
        displayedName: '価格/戸',
        displayMethod: (media, period) =>
          isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(calcMansionSignageCostEffectiveness(media, period.weeks, true), 1)}`,
        prefix: '¥',
        suffix: '',
      },
    ],
  },
  cartEditMediaDetailEffectTable: [
    {
      displayedName: '戸数',
      displayMethod: (args) => `${roundAndAddThousandsSeparator(args.media.numOfHouses, 1)}世帯`,
    },
    {
      displayedName: '価格／戸',
      displayMethod: (args) =>
        isJackChild(args.media) ? '-' : `¥${roundAndAddThousandsSeparator(calcMansionSignageCostEffectiveness(args.media, args.period.weeks, true), 1)}`,
    },
    {
      displayedName: '住民属性',
      displayMethod: (args) => {
        let result = '全て'
        //valueからtextに変換する
        if (args.media.mansionType) {
          const tmp = MansionOptions.find((elem) => elem.value === args.media.mansionType)
          if (tmp) {
            result = tmp.text
          }
        }
        return result
      },
      // (args.media.majorResidentsProperty ? args.media.majorResidentsProperty.join(',') : '未登録'),
    },
    {
      displayedName: '分譲・賃貸',
      displayMethod: (args) => {
        const list: string[] = []
        //valueからtextに変換する
        if (args.media.residentsProperty && args.media.residentsProperty.length && args.media.residentsProperty.length !== ResidentPropertyOptions.length) {
          args.media.residentsProperty.map((key) => {
            const result = ResidentPropertyOptions.find((elem) => elem.value === key)
            if (result) {
              list.push(result.text)
            }
          })
        } else {
          list.push('全て')
        }
        return list.join('、')
      },
    },
  ],
  cartFooterContent: [
    {
      displayedName: '総世帯数',
      displayMethod: (bundleSummary: bundleSummaryDTypeMansionSignage) => roundAndAddThousandsSeparator(bundleSummary.totalNumOfHouses, 1) + '世帯',
    },
    {
      displayedName: '総週額費用',
      displayMethod: (bundleSummary: bundleSummaryDTypeMansionSignage) => `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForWeek, 1)}`,
    },
  ],
  cartRecommendSummary: [
    {
      displayedName: '週額費用',
      displayMethod: (bundleSummary: bundleSummaryDTypeMansionSignage) => `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForWeek, 1)}`,
    },
    {
      displayedName: '総戸数',
      displayMethod: (bundleSummary: bundleSummaryDTypeMansionSignage) =>
        bundleSummary.totalNumOfHouses ? roundAndAddThousandsSeparator(bundleSummary.totalNumOfHouses, 1) : '未登録',
    },
    {
      displayedName: '価格／戸',
      displayMethod: (bundleSummary: bundleSummaryDTypeMansionSignage) =>
        bundleSummary.costEffectiveness ? `¥${bundleSummary.costEffectiveness.toLocaleString()}` : '未登録',
    },
  ],
  propertyFields: [
    {
      fieldName: 'totalSexRatio',
      displayedName: '性別',
      graphType: 'pie',
      displayMethod: SexPieChartDispMethod,
    },
    {
      fieldName: 'ageRatio',
      displayedName: '年齢',
      graphType: 'pie',
      displayMethod: AgePieChartDispMethod,
    },
    {
      fieldName: 'sexRatioByAge',
      displayedName: '年代別性別比',
      graphType: 'stackedBar',
      displayMethod: SexRatioByAgeChartDispMethod,
    },
  ],

  cartConfirmCard: {
    header: ['媒体', '掲載週数', '単週掲載費', '小計'],
    contents: [
      {
        displayedName: '掲載週数',
        displayMethod: (media, period) => period.weeks + '週間',
      },
      {
        displayedName: '単週掲載費',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayMethod: (media, period) => {
          return isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(media.weeklyCost * media.marginRate * defaultTaxRate, 1)}`
        },
      },
      {
        displayedName: '小計',
        displayMethod: (media, period) => {
          return isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(calcMansionSignageCost(media, period.weeks, true, true), 1)}`
        },
      },
    ],
  },
  cartConfirmSummary: [
    {
      displayedName: '媒体数',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeMansionSignage) => mediaSortedList.length,
    },
    {
      displayedName: '掲載期間',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeMansionSignage) => getPeriod(cart),
    },
    {
      displayedName: '掲載費合計(税抜)',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeMansionSignage) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalPublicationCostForEntirePeriod, 1)}`,
    },
    {
      displayedName: '消費税',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeMansionSignage) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForEntirePeriod * (defaultTaxRate - 1), 1)}`,
    },
    {
      displayedName: '合計金額(税込)',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeMansionSignage) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForEntirePeriod * defaultTaxRate, 1)}`,
    },
  ],
  cartConfirmFooter: [
    {
      displayedName: '総世帯数',
      displayMethod: (bundleSummary: bundleSummaryDTypeMansionSignage) => roundAndAddThousandsSeparator(bundleSummary.totalNumOfHouses, 1) + '世帯',
    },
    {
      displayedName: '掲載費合計(税込)',
      displayMethod: (bundleSummary: bundleSummaryDTypeMansionSignage) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalPublicationCostForEntirePeriod * defaultTaxRate, 1)}`,
    },
    {
      displayedName: '施工回復費合計(税込)',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (_bundleSummary: bundleSummaryDTypeMansionSignage) => `¥${roundAndAddThousandsSeparator(0 * defaultTaxRate, 1)}`,
    },
  ],
  culcBundleSummary: (mediaList, period, isTaxIncluded) => {
    const taxRate: number = isTaxIncluded ? defaultTaxRate : 1
    const bundleSummary: bundleSummaryDTypeMansionSignage = {
      totalOverallCostForEntirePeriod: 0,
      totalOverallCostForWeek: 0,
      totalPublicationCostForEntirePeriod: 0,
      totalPublicationCostForWeek: 0,
      totalNumOfHouses: 0,
      totalTargetCirculationPerWeek: 0,
      costEffectiveness: 0,
      mediaType: 'mediaTypeMansionSignage',
    }
    //TODO: MediaDataのTS型を作り終えたら以下のanyを外す
    mediaList
      .filter((medium) => !isJackChild(medium))
      .forEach((medium: any) => {
        bundleSummary.totalPublicationCostForWeek += medium.weeklyCost * medium.marginRate * taxRate
        bundleSummary.totalNumOfHouses += medium.numOfHouses
      })
    bundleSummary.totalPublicationCostForEntirePeriod += bundleSummary.totalPublicationCostForWeek * period.weeks
    bundleSummary.totalOverallCostForEntirePeriod = bundleSummary.totalPublicationCostForEntirePeriod
    bundleSummary.totalOverallCostForWeek = bundleSummary.totalOverallCostForEntirePeriod / period.weeks
    bundleSummary.costEffectiveness =
      !isNaN(bundleSummary.totalOverallCostForWeek) && !isNaN(bundleSummary.totalNumOfHouses)
        ? bundleSummary.totalOverallCostForWeek / bundleSummary.totalNumOfHouses
        : NaN
    return bundleSummary
  },
  purchasePeriodUnit: 'week',
} as const

export const mansionSignageColumnDtypes = {
  address: types.string,
  latitude: types.number,
  longitude: types.number,
  horizontalWidth: types.number,
  verticalWidth: types.number,
  weeklyCost: types.number,
  mediaType: types.enum,
  //TODO: arrayにして、複数の画像のパスを入力できるようにする
  mediaImagePaths: types.stringArrayElem,
  name: types.string,
  note: types.string,
  summary: types.string,
  uid: types.string,
  isPrivate: types.bool,
  ageRatio: types.string,
  femaleAgeRatio: types.map,
  maleAgeRatio: types.map,
  hasAudio: types.bool,
  majorResidentsProperty: types.stringArrayElem,
  mansionType: types.enum,
  numOfHouses: types.number,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { weeklyCost, ...others } = mansionSignageColumnDtypes
export const jackChildMansionSignageColumnDtypes = { ...others, parentUid: types.string }

export const mansionTypeOptions = [
  {
    name: '賃貸',
    value: 'forRent',
  },
  {
    name: '分譲',
    value: 'forSale',
  },
]

export const majorResidentsOptions = [
  {
    name: 'ファミリー',
    value: 'family',
  },
  {
    name: '単身',
    value: 'single',
  },
  {
    name: 'DINKs',
    value: 'dinks',
  },
]

export const calcMansionSignageCost = (media /*:adviceMediaDataType*/, weeks: number, isTaxIncluded: boolean, isForEntirePeriod: boolean) => {
  const taxRate: number = isTaxIncluded ? defaultTaxRate : 1
  const costForEntirePeriod: number = media.weeklyCost * media.marginRate * weeks * taxRate
  const result = isForEntirePeriod ? costForEntirePeriod : costForEntirePeriod / weeks
  return result
}

//mediaTypeVisionの費用対効果計算関数
export const calcMansionSignageCostEffectiveness = (media, weeks, isTaxIncluded: boolean) => {
  const cost = calcMansionSignageCost(media, weeks, isTaxIncluded, false)
  const costEffectiveness = cost / media.numOfHouses
  return costEffectiveness
}

// total: 全媒体について合計する
// overall: 掲載費用だけでなく、施工回復費用なので全ての種類の費用を計上する
// forEntirePeriod: 出稿期間全体について費用を算出する
export type bundleSummaryDTypeMansionSignage = {
  totalOverallCostForEntirePeriod: number //全ての種類の費用、全ての期間の合計費用
  totalOverallCostForWeek: number //全ての種類の費用、ひと月あたりの費用
  totalPublicationCostForEntirePeriod: number
  totalPublicationCostForWeek: number
  totalNumOfHouses: number
  totalTargetCirculationPerWeek: number
  costEffectiveness: number
  mediaType: 'mediaTypeMansionSignage'
}
