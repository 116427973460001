import React, { useState, useEffect, useMemo } from 'react'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import './index.css'
import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'
import { useMediaAvailable } from 'providers/MediasAvailable'

type props = {
  lat: number
  lng: number
  id: string
  mediaId: string
  showBalloon: boolean
  showInfo: boolean
  media: AdviceMediaDataType
  period: any
  isAtCart: boolean
  isSameJackGroup: boolean
  isPrivate: boolean
  setMediaTarget: any
  setCurrentKey: any
}

export const Marker = ({ id, mediaId, showBalloon, showInfo, period, media, isAtCart, isPrivate, ...props }: props) => {
  const isJackParent = useMemo(() => {
    return media.mediaType === 'mediaTypeJack'
  }, [])
  const [frag, setFrag] = useState(false)

  const { mediasAvailable } = useMediaAvailable()
  const price = useMemo(() => {
    const _media = media
    if (isJackChild(_media)) {
      const m = mediasAvailable.find((m) => m.uid === _media.parentUid)
      if (!m) {
        return '-'
      }
      const colInfo = getColInfoFromTypeName(m.mediaType)
      //cartEdit[0]は単位期間あたりの総費用を求めるためのオブジェクト
      return colInfo.cardContent.cartEdit[0].displayMethod(m, period)
    }

    const colInfo = getColInfoFromTypeName(_media.mediaType)
    //cartEdit[0]は単位期間あたりの総費用を求めるためのオブジェクト
    return colInfo.cardContent.cartEdit[0].displayMethod(_media, period)
  }, [media, period])

  function markerClassName() {
    if (isAtCart && frag) {
      return 'bubbleAtCartFocused'
    } else if (isAtCart && !frag) {
      return 'bubbleAtCart'
    } else if (frag) {
      return isJackParent ? 'bubble09' : 'bubble07'
    } else if (props.isSameJackGroup) {
      return isJackParent ? 'bubble10' : 'bubble11'
    } else {
      return isJackParent ? 'bubble08' : 'bubble06'
    }
  }

  const markerClicked = () => {
    props.setMediaTarget(mediaId)
    props.setCurrentKey(id)
  }

  useEffect(() => {
    if (showBalloon || showInfo) {
      setFrag(true)
    } else setFrag(false)
  }, [showBalloon, showInfo])

  return (
    <div>
      {showInfo && (
        <div className="mapInfo">
          <>{media.name}</>
        </div>
      )}
      <div className={markerClassName()} onClick={() => markerClicked()} style={{ cursor: 'pointer' }} title={media.name}>
        <>{isPrivate ? '非公開' : price}</>
      </div>
      {/* MEMO: マーカーのエフェクトの動作が重たいので無効にしている   */}
      {/* {!frag ? <div className="pulse" /> : <></>} */}
    </div>
  )
}
