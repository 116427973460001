// mediaType関連の便利メソッドが書かれたファイル
import React from 'react'
import { SexPieChart } from 'components/organisms/charts/SexPieChart'
import { AgePieChart } from 'components/organisms/charts/AgePieChart'
import { SexRatioByAgeChart } from 'components/organisms/charts/SexRatioByAgeChart'
import MediaTypeOptions from 'constants/MediaTypeOptions'
import { priceSetting } from 'FirebaseConfig'
import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'
import { bundleSummaryDTypeBillboard } from './mediaTypeBillboard'
import { bundleSummaryDTypeVision } from './mediaTypeVision'
import { bundleSummaryDTypeLeaflet } from './mediaTypeLeaflet'
import { bundleSummaryDTypeMansionSignage } from './mediaTypeMansionSignage'
import { cartDataType } from 'providers/CartsAll'

// ------------------------------基本情報欄のディスプレイメソッド-----------------------
// hasIlluminationなどのdisplayMethodで使う関数
export const bool2Has = (boolVal) => {
  return boolVal ? '有' : '無'
}

// media内のカラムの値を返す関数を戻す  主にdisplayMethodで使う
type getColOptionType = {
  isTaxIncluded?: boolean
  isMarginIncluded?: boolean
  isCurrency?: boolean
  shouldFilterJackChild?: boolean
}
export const getCol = (
  colName: string,
  { isTaxIncluded, isMarginIncluded, isCurrency, shouldFilterJackChild }: getColOptionType = {
    isTaxIncluded: false,
    isMarginIncluded: false,
    isCurrency: false,
    shouldFilterJackChild: false,
  }
) => {
  const result = (media) => {
    if (shouldFilterJackChild && isJackChild(media)) {
      return '-'
    }
    let value = media[colName]
    if (!value) {
      value = 'N/A'
    }
    if (isMarginIncluded) {
      value *= media.marginRate || priceSetting.marginRate || 1
    }
    if (typeof value === 'number') {
      if (isTaxIncluded) {
        value = (value * priceSetting.taxRate).toLocaleString()
      } else {
        value = value.toLocaleString()
      }
      if (isCurrency) {
        value = `¥${value}`
      }
    } //数字の場合はカンマを打つ
    return value
  }
  return result
}

// 住所から地域を切り出す関数
export const parseArea = (address) => {
  if (address && address.indexOf('区') !== -1) {
    const city = address.split('区')
    return city[0] + '区'
  } else {
    return ''
  }
}

const eightDirection = ['東', '北東', '北', '北西', '西', '南西', '南', '南東']
const deg2EightDirection = (deg) => {
  deg = (deg + 22.5) % 360
  return eightDirection[Math.floor(deg / 45)]
}

// 方角を示す角度を八方位へ直して返すdisplayMethod
export const directionDisplayMethod = (media) => deg2EightDirection(media['direction'])

// 媒体のサイズを返すディスプレイメソッド
export const sizeDisplayMethod = (media) => {
  return 'W' + String(media.horizontalWidth) + '[m] H' + String(media.verticalWidth) + '[m]'
}

// ------------------------------図を描画するディスプレイメソッド-----------------------
// mediaを受け取ってSexPieChartを返すメソッド. 図のdisplayMethodとして指定する
export const SexPieChartDispMethod = (media) => {
  //TODO: SexPieChartをtypeScript化したのちに、ここをtsにする
  return <SexPieChart maleRatio={media.maleRatio} femaleRatio={media.femaleRatio} />
}

// mediaを受け取ってAgePieChartを返すメソッド. 図のdisplayMethodとして指定する
export const AgePieChartDispMethod = (media) => {
  return (
    <AgePieChart
      style={{ margin: 'auto', paddingLeft: '4rem' }}
      ratio15={media.ratio15}
      ratio20={media.ratio20}
      ratio30={media.ratio30}
      ratio40={media.ratio40}
      ratio50={media.ratio50}
      ratio60={media.ratio60}
      ratio70={media.ratio70}
    />
  )
}

// mediaを受け取ってSexRatioByAgeChartを返すメソッド. 図のdisplayMethodとして指定する
export const SexRatioByAgeChartDispMethod = (media: AdviceMediaDataType, isMinimized = false) => {
  return <SexRatioByAgeChart media={media} isMinimized={isMinimized} />
}

export const getPeriod = (cart: cartDataType) => {
  return `
    ${cart.startDate.toDate().getFullYear()}/${cart.startDate.toDate().getMonth() + 1}/${cart.startDate.toDate().getDate()} ~ ${cart.endDate
    .toDate()
    .getFullYear()}/${cart.endDate.toDate().getMonth() + 1}/${cart.endDate.toDate().getDate()}
    `
}
//mediaTypeのvalueを受け取り、textを返す関数
export const convertValueToText = (mediaType: string) => {
  const result = MediaTypeOptions.find((type) => type.key === mediaType)
  if (result) return result.text
}

export type bundleSummaryDType = bundleSummaryDTypeBillboard | bundleSummaryDTypeVision | bundleSummaryDTypeLeaflet | bundleSummaryDTypeMansionSignage
