// Library
import React, { useState, useEffect, useRef } from 'react'
import { Grid, Divider, Button, Segment } from 'semantic-ui-react'
// Components
import AppLayoutNotAuthRequired from 'components/organisms/layouts/AppLayoutNotAuthRequired'
import Spacer from 'components/atoms/Spacer'
import { useHistory } from 'react-router-dom'
import MediaSearchForm from 'components/organisms/mediasearch/MediaSearchForm'
import topImage from 'assets/pictures/mediatop-firstview.png'
import shibuya from 'assets/pictures/shibuya.png'
import shinjuku from 'assets/pictures/shinjuku.png'
import minato from 'assets/pictures/minato.png'
import map from 'assets/pictures/map.png'
import step1 from 'assets/pictures/step1.png'
import step2 from 'assets/pictures/step2.png'
import step3 from 'assets/pictures/step3.png'
import * as contentful from 'contentful'
import { Link } from 'react-router-dom'
import areaArray from 'constants/AreaList'
import { useHubspotChat } from 'helpers/useHubspotChat'
import PackageList from 'components/organisms/mediaTop/PackageList'
import './index.css'
import paths from 'constants/paths'

require('firebase/functions')

const client = contentful.createClient({
  space: 'va5sd9zt8300',
  accessToken: 'Z1lOxlPSjqZC1kcovMl9G_9mSLrbWHyzTtZdeOuUlQU',
})

const urlAreaQuery = (area) => '/mediasearch?area=' + area

const MediaTop = () => {
  const history = useHistory()
  const [isLoaded, setLoaded] = useState(false)

  const isBrowzerBack = useRef(false)
  const [items, setItems] = useState([])

  useHubspotChat()

  // ブラウザ履歴を移動したときのみフラグをセット
  window.onpopstate = () => {
    isBrowzerBack.current = true
  }
  useEffect(() => {
    if (isLoaded == false) {
      client
        .getEntries({
          content_type: 'blog',
        })
        .then((response) => {
          setItems(response.items)
          setLoaded(true)
        })
    }
  }, [])

  // TODO: METAタグの共通化
  useEffect(() => {
    const title = 'トップ｜AdVice（アドバイス）'
    const description =
      'サービス認知を圧倒的に高めるOOHマーケティングを効果的に、カンタンに。屋外広告注文プラットフォーム「AdVice（アドバイス）」は、投資対効果ベースで屋外広告を注文できる新しいプラットフォームです。'
    document.title = title
    // title以外のmeta
    const headData = document.head.children

    for (let i = 0; i < headData.length; i++) {
      const nameVal = headData[i].getAttribute('name')
      if (nameVal !== null) {
        if (nameVal.indexOf('description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        // OGP(twitter)の設定
        if (nameVal.indexOf('twitter:title') !== -1) {
          headData[i].setAttribute('content', title)
        }
        if (nameVal.indexOf('twitter:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:title') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:image') !== -1) {
          headData[i].setAttribute('content', process.env.REACT_APP_OGP_URL + '/img_ogp.png')
        }
      }
    }
  })

  return (
    <>
      <Grid as="Segment" style={{ background: 'white' }}>
        <AppLayoutNotAuthRequired>
          <Grid.Row
            style={{
              padding: '0px',
              background: `url(${topImage})`,
              backgroundSize: 'cover',
              minHeight: '40rem',
            }}
          >
            <Grid.Column style={{ padding: '0px' }}>
              <Grid>
                <Grid.Row>
                  <div
                    style={{
                      paddingTop: '10rem',
                      fontSize: '2.25rem',
                      letterSpacing: '.125rem',
                      width: '100%',
                      textAlign: 'center',
                      color: 'white',
                      fontWeight: '600',
                    }}
                  >
                    最適な屋外広告媒体を探す
                  </div>
                </Grid.Row>
                <Grid.Row
                  style={{
                    padding: '2rem 10rem',
                  }}
                >
                  <Grid.Column
                    width={10}
                    style={{
                      padding: '1.5rem',
                    }}
                  >
                    <div
                      style={{
                        padding: '3rem',
                        center: 'calc(80%)',
                        margin: 'auto',
                        boxShadow: '0 1px 4px 0 rgba(21,27,38,.08)',
                        borderRadius: '3em',
                        background: 'white',
                      }}
                    >
                      <p style={{ fontSize: '1.2rem', fontWeight: 600 }}>検索条件指定</p>
                      <MediaSearchForm mediaType={[]} searchWord={''} area={[]} tag={[]} line={[]} highway={[]} buttonText="リストから検索" />
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    width={6}
                    style={{
                      padding: '1.5rem',
                    }}
                  >
                    <Grid
                      style={{
                        padding: '1.5rem',
                        center: 'calc(80%)',
                        margin: 'auto',
                        boxShadow: '0 0 4px 0 rgba(21,27,38,.08)',
                        borderRadius: '3em',
                        background: `url(${map})`,
                        backgroundSize: 'auto',
                        height: '16rem',
                        flexDirection: 'column-reverse',
                      }}
                    >
                      <Grid.Column textAlign="center">
                        <Button
                          onClick={() => {
                            history.push(paths.mapSearch)
                          }}
                          style={{ width: '100%', padding: '1rem' }}
                          color="orange"
                        >
                          地図から探す
                        </Button>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <div className="ui container">
              <Grid.Row>
                <Grid.Column>
                  <Spacer />
                  <h2>人気のエリア</h2>
                  <Spacer />
                  <Grid>
                    <Grid.Row columns={3}>
                      <Grid.Column>
                        <div
                          floated
                          className="image-link"
                          style={{
                            background: `linear-gradient(to bottom, transparent 0%, transparent 65%, black 100%), url(${shibuya})`,
                            backgroundSize: 'cover',
                            width: '95%',
                            height: '14rem',
                            borderRadius: '.5em',
                            margin: '0.2rem',
                            display: 'inline-block',
                            cursor: 'pointer',
                            boxShadow: '0 1px 4px 0 rgba(21,27,38,.08)',
                          }}
                          onClick={() => {
                            history.push(urlAreaQuery('渋谷区'))
                          }}
                        >
                          <p
                            style={{
                              textAlign: 'left',
                              color: 'white',
                              marginLeft: '2rem',
                              marginTop: '10.5rem',
                              fontSize: '2rem',
                              fontWeight: '700',
                            }}
                          >
                            渋谷区
                          </p>
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <div
                          floated
                          className="image-link"
                          style={{
                            background: `linear-gradient(to bottom, transparent 0%, transparent 65%, black 100%), url(${shinjuku})`,
                            backgroundSize: 'cover',
                            width: '95%',
                            height: '14rem',
                            borderRadius: '.5em',
                            margin: '0.2rem',
                            display: 'inline-block',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            history.push(urlAreaQuery('新宿区'))
                          }}
                        >
                          <p
                            style={{
                              textAlign: 'left',
                              color: 'white',
                              marginLeft: '2rem',
                              marginTop: '10.5rem',
                              fontSize: '2rem',
                              fontWeight: '700',
                            }}
                          >
                            新宿区
                          </p>
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <div
                          floated
                          className="image-link"
                          style={{
                            background: `linear-gradient(to bottom, transparent 0%, transparent 65%, black 100%), url(${minato})`,
                            backgroundSize: 'cover',
                            width: '95%',
                            height: '14rem',
                            borderRadius: '.5em',
                            margin: '0.2rem',
                            display: 'inline-block',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            history.push(urlAreaQuery('新宿区'))
                          }}
                        >
                          <p
                            style={{
                              textAlign: 'left',
                              color: 'white',
                              marginLeft: '2rem',
                              marginTop: '10.5rem',
                              fontSize: '2rem',
                              fontWeight: '700',
                            }}
                          >
                            港区
                          </p>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Spacer />
                  <PackageList />
                  <h2>地域</h2>
                  <Segment style={{ textAlign: 'center' }}>
                    {areaArray.slice(0, 23).map((value) => (
                      <Button
                        class="area-link"
                        basic
                        color="black"
                        style={{ paddingLeft: '1rem', paddingRight: '1rem', margin: '0.25rem', fontSize: '1rem', width: '9rem' }}
                        onClick={() => {
                          history.push(urlAreaQuery(value))
                        }}
                        key={value}
                      >
                        {value}
                      </Button>
                    ))}
                  </Segment>
                  <Spacer />
                </Grid.Column>
              </Grid.Row>
            </div>
          </Grid.Row>
          <div className="ui container">
            <Divider />
            <Spacer />
            <Grid.Row>
              <div className="ui container">
                <Divider />
                <Spacer />
                <Grid.Row>
                  <Grid.Column>
                    <h3
                      style={{
                        textAlign: 'center',
                        fontWeight: '700',
                        fontSize: '3rem',
                      }}
                    >
                      AdViceではOOH広告を
                      <br />
                      迅速かつ戦略的に注文できます
                    </h3>
                    <Spacer />
                    <Segment
                      style={{
                        textAlign: 'center',
                        margin: '2  rem 0rem',
                      }}
                    >
                      <Grid>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <img
                              src={step1}
                              style={{
                                backgroundSize: 'cover',
                                width: '75%',
                              }}
                            ></img>
                          </Grid.Column>
                          <Grid.Column>
                            <h4 style={{ margin: '4rem 1rem', lineHeight: '2rem', fontSize: '2rem', color: '#E68423' }}>
                              予算とターゲットを指定して<br></br>キャンペーンを作成
                            </h4>
                            <p style={{ margin: '3rem 1rem', fontSize: '1.3rem' }}>広告キャンペーンの条件を入力すれば、最適な媒体が提案されます。</p>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                    <Segment
                      style={{
                        textAlign: 'center',
                        margin: '2rem 0rem',
                      }}
                    >
                      <Grid>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <h4 style={{ margin: '4rem 1rem', lineHeight: '2rem', fontSize: '2rem', color: '#E68423' }}>広告媒体をカートに入れて注文</h4>
                            <p style={{ margin: '3rem 1rem', fontSize: '1.3rem' }}>
                              条件に沿った媒体の中からご希望の選択してカートに入れて発注できます。また、提案された媒体をそのままカートに入れることもできます。
                            </p>
                          </Grid.Column>
                          <Grid.Column>
                            <img
                              src={step2}
                              style={{
                                backgroundSize: 'cover',
                                width: '75%',
                              }}
                            ></img>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                    <Segment
                      style={{
                        textAlign: 'center',
                        margin: '2rem 0rem',
                      }}
                    >
                      <Grid>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <img
                              src={step3}
                              style={{
                                backgroundSize: 'cover',
                                width: '75%',
                              }}
                            ></img>
                          </Grid.Column>
                          <Grid.Column>
                            <h4 style={{ margin: '4rem 1rem', lineHeight: '2rem', fontSize: '2rem', color: '#E68423' }}>キャンペーンの効果を確認・評価</h4>
                            <p style={{ margin: '3rem 1rem', fontSize: '1.3rem' }}>
                              ダッシュボードではキャンペーン全体をまとめて管理でき、広告キャンペーンの効果も一目で見ることができます。
                            </p>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                    <Spacer />
                  </Grid.Column>
                </Grid.Row>
              </div>
            </Grid.Row>
            <Grid.Row>
              <div className="ui container">
                <Grid.Row>
                  <Grid.Column>
                    <Divider />
                    <Spacer />
                    <h2>フィード</h2>
                    <div className="posts">
                      {items &&
                        items.map((post) => (
                          <Link to={'/blog/' + post.fields.slug} class="post" key={post.fields.slug}>
                            <Segment>
                              <div className="thumb">
                                <img src={post.fields.image.fields.file.url} />
                              </div>
                              <div v-if="post.fields.type" className="type">
                                {post.fields.type}
                              </div>
                              <div className="post-text">
                                <h2>{post.fields.title}</h2>
                                <p></p>
                              </div>
                            </Segment>
                          </Link>
                        ))}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Spacer />
              </div>
            </Grid.Row>
          </div>
        </AppLayoutNotAuthRequired>
      </Grid>
      <style jsx>{`
        .area-link {
          cursor: pointer;
          transition: all 0.2s;
        }
        .area-link:hover {
          text-decoration: underline;
        }
        .image-link {
          transition: all 0.2s;
        }
        .image-link:hover {
          opacity: 0.7;
        }
        .type {
          text-align: left;
          display: inline-block;
          background-color: #f2711c;
          color: white;
          padding: 0.5rem 0.75rem;
          font-size: 0.75rem;
          line-height: 0.75rem;
          border-radius: 0.875rem;
          font-weight: 800;
          margin-bottom: 0.25rem;
        }
        .post-text {
          padding: 5px 10px 10px;
        }
        .post-text h2 {
          width: fit-content;
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }
        .post-text p {
          font-size: 1rem;
          font-weight: 700;
        }
        .thumb {
          width: 100%;
          padding-bottom: 75%;
          position: relative;
          overflow: hidden;
        }
        .thumb img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 100%;
        }
        a.post {
          width: calc(100% / 3 - 60px);
          text-align: left;
          margin: 30px;
          text-decoration: none;
          color: #111;
        }
        .posts {
          max-width: 1080px;
          margin: 0 auto;
          padding: 10px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
        section.latest-posts {
          padding: 2rem 0 6rem;
        }
      `}</style>
    </>
  )
}
export default MediaTop
