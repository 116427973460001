import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownItemProps, Icon } from 'semantic-ui-react'
import { db } from 'FirebaseConfig'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type props = {
  // TODO: any型を排除する
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tags: string[]
  lines: string[]
  highways: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeTagCallback: any
  placeholder?: string
  style?: CSSProperties
}

const TagDropdown = ({ tags, lines, highways, changeTagCallback, placeholder, style }: props) => {
  const [tagOptions, setTagOptions] = useState<DropdownItemProps[]>([])
  const [lineTagOptions, setLineTagOptions] = useState<DropdownItemProps[]>([])
  const [highwayTagOptions, setHighwayTagOptions] = useState<DropdownItemProps[]>([])
  // 付与されるtagの情報を監視するリスナーに関するuseEffect
  // tagの情報は一般的な形式ではなく、ドロップダウンのオプションに提供するデータの形式を取っていることに注意
  useEffect(() => {
    const getTags = async () => {
      const resTag = await db.collection('tags').get()
      const tagOptions: DropdownItemProps[] = []
      for (const doc of resTag.docs) {
        const tag = doc.data()
        tagOptions.push({
          key: doc.id,
          value: doc.id,
          text: tag.name,
          name: 'tag',
          onClick: changeTagCallback,
          label: (
            <div className="ui label" style={{ background: '#f39800', color: 'white' }}>
              施設
            </div>
          ),
        })
      }
      setTagOptions(tagOptions)

      const resLine = await db.collection('lines').get()
      const lineOptions: DropdownItemProps[] = []
      for (const doc of resLine.docs) {
        const line = doc.data()
        lineOptions.push({
          key: doc.id,
          value: doc.id,
          text: line.name,
          name: 'line',
          onClick: changeTagCallback,
          label: (
            <div className="ui label" style={{ background: '#5cBBcf', color: 'white' }}>
              路線
            </div>
          ),
        })
      }
      setLineTagOptions(lineOptions)

      const resHighway = await db.collection('highways').get()
      const highwayOptions: DropdownItemProps[] = []
      for (const doc of resHighway.docs) {
        const highway = doc.data()
        highwayOptions.push({
          key: doc.id,
          value: doc.id,
          text: highway.name,
          name: 'highway',
          onClick: changeTagCallback,
          label: (
            <div className="ui label" style={{ background: '#5cBBcf', color: 'white' }}>
              道路
            </div>
          ),
        })
      }
      setHighwayTagOptions(highwayOptions)
    }
    getTags()
  }, [])

  return (
    <Dropdown
      style={style}
      compact
      fluid
      multiple
      value={[...tags, ...lines, ...highways]}
      selection
      scrolling
      options={[...tagOptions, ...lineTagOptions, ...highwayTagOptions]}
      renderLabel={(item) => {
        if (item.name === 'tag') {
          return (
            <div className="ui label" style={{ background: '#f39800', color: 'white' }}>
              {item.text}
              <Icon as="i" name="delete" onClick={(event) => changeTagCallback(event, { name: 'deleteTag', value: item.value })} />
            </div>
          )
        } else if (item.name === 'line') {
          return (
            <div className="ui label" style={{ background: '#5cBBcf', color: 'white' }}>
              {item.text}
              <Icon as="i" name="delete" onClick={(event) => changeTagCallback(event, { name: 'deleteLineTag', value: item.value })} />
            </div>
          )
        } else if (item.name === 'highway') {
          return (
            <div className="ui label" style={{ background: '#5cBBcf', color: 'white' }}>
              {item.text}
              <Icon as="i" name="delete" onClick={(event) => changeTagCallback(event, { name: 'deleteHighwayTag', value: item.value })} />
            </div>
          )
        }
      }}
      placeholder={placeholder}
    />
  )
}

export default React.memo(TagDropdown)
