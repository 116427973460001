import React, { useContext } from 'react'
import { Table, Radio } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import TargetSexCell from 'components/molecules/group/TargetSexCell'
import TargetAgeCell from 'components/molecules/group/TargetAgeCell'
import TargetLocationCell from 'components/molecules/group/TargetLocationCell'
import GetGroupStatus from '../GetGroupStatus'
import { getSummary } from '../helper'
import 'components/organisms/group/GroupLists/index.css'
import { getPeriod } from 'helpers/mediaType/helpers'
import { bundleSummaryDType } from 'helpers/mediaType/helpers'
import { MediasAvailableContext } from 'providers/MediasAvailable'
import { roundAndAddThousandsSeparator } from 'helpers/roundAndAddThousandsSeparator'

const GroupListBillboard = (props) => {
  const { mediasAvailable } = useContext(MediasAvailableContext)
  let jsxElems = []
  jsxElems = props.carts.map((cart) => {
    const summary: bundleSummaryDType | undefined = getSummary(cart, mediasAvailable)
    return (
      <Table.Row key={cart.uid}>
        <Table.Cell>
          <Radio value={cart.uid} checked={props.cartId === cart.uid} onClick={() => props.handleChgange(cart.uid)} />
        </Table.Cell>
        <Table.Cell>
          <Link to={`/cart/edit/${cart.uid}`}>{cart.groupName}</Link>
        </Table.Cell>
        <Table.Cell>
          <GetGroupStatus cart={cart} />
        </Table.Cell>
        <Table.Cell>{cart.startDate && cart.endDate && getPeriod(cart)}</Table.Cell>
        <TargetLocationCell targetLocation={cart.targetLocation} />
        <TargetAgeCell targetAge={cart.targetAge} />
        <TargetSexCell targetSex={cart.targetSex} />
        {summary && summary.mediaType === 'mediaTypeBillboard' ? (
          <>
            <Table.Cell>{!isNaN(summary.totalImpPerMonth) ? roundAndAddThousandsSeparator(summary.totalImpPerMonth, 1) : '-'}</Table.Cell>
            <Table.Cell>{!isNaN(summary.cpm) ? `¥${roundAndAddThousandsSeparator(summary.cpm, 1)}` : '-'}</Table.Cell>
            <Table.Cell>
              {!isNaN(summary.totalOverallCostForEntirePeriod) ? `¥${roundAndAddThousandsSeparator(summary.totalOverallCostForEntirePeriod, 1)}` : '-'}
            </Table.Cell>
          </>
        ) : (
          <>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
          </>
        )}
      </Table.Row>
    )
  })

  return (
    <div className="groupList__TableContainer">
      <Table basic style={{ backgroundColor: 'white', marginTop: '3rem', borderRadius: '10px', overflow: 'hidden' }}>
        <Table.Header style={{ backgroundColor: '#E0E1E2' }}>
          <Table.HeaderCell colSpan="10">ビルボード</Table.HeaderCell>
        </Table.Header>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan="2">選択</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">グループ名</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">配信状況</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">期間</Table.HeaderCell>
            <Table.HeaderCell colSpan="3">ターゲット</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">インプレッション</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">CPM</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">費用</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>エリア</Table.HeaderCell>
            <Table.HeaderCell>年代</Table.HeaderCell>
            <Table.HeaderCell>性別</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{jsxElems}</Table.Body>
      </Table>
    </div>
  )
}

export default GroupListBillboard
