//TODO: 価格表記に問題あり owner画面で使われているので未着手
import React, { useEffect, useState } from 'react'
import { Card, Image, Button, Modal } from 'semantic-ui-react'
import firebase from 'FirebaseConfig'
import noImage from 'assets/pictures/no_image.jpg'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import ManageMediaDetail from 'components/pages/Owner/Dashboard/DashboardPage/ManageMediaDetail'
import { roundAndAddThousandsSeparator } from 'helpers/roundAndAddThousandsSeparator'

const MediaCard = ({ media, setMediaTarget }) => {
  const CardContentWrapper = styled(Card.Content)`
     {
      cursor: pointer;
    }
  `

  const history = useHistory()
  const [mediaImageUrl, setMediaImageUrl] = useState('')

  useEffect(() => {
    if (media) {
      if (media.mediaImagePaths && media.mediaImagePaths.length > 0) {
        const storageRef = firebase.storage().ref()
        storageRef
          .child(media.mediaImagePaths[0])
          .getDownloadURL()
          .then(function (url) {
            setMediaImageUrl(url)
          })
          .catch(function (error) {
            console.log('Error getting document:', error)
          })
      } else {
        setMediaImageUrl(noImage)
      }
    }
  }, [media])

  const clickCardContent = () => {
    if (setMediaTarget && media) {
      setMediaTarget(media)
    }
  }

  return (
    <>
      <Card key={media.uid}>
        <Modal
          trigger={
            <CardContentWrapper style={{ padding: 0 }} onClick={clickCardContent}>
              <div></div>
              <Image
                // floated='left'
                // width='200px'
                style={{ height: '200px', width: '100%', objectFit: 'fit' }}
                src={mediaImageUrl}
              />
              <Card.Header>
                <div style={{ margin: '1rem' }}>{media.name}</div>
              </Card.Header>
              <Card.Description style={{ margin: '0 1rem .5rem' }}>
                <table>
                  <tr align="justify">
                    <td>{media.isVision === true || media.mediaType === 'mediaTypeVision' ? '価格/週' : '価格/月'}</td>
                    <td>&nbsp;</td>
                    <td align="right">
                      <b>{`¥${media.cost && roundAndAddThousandsSeparator(Number(media.cost))}`}</b>
                    </td>
                  </tr>
                  <tr align="justify">
                    <td>インプレッション数/月</td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    <td align="right">
                      <b>
                        {media.impressionRe
                          ? media.impressionRe && roundAndAddThousandsSeparator(media.impressionRe, 1)
                          : media.impression && roundAndAddThousandsSeparator(media.impression, 1)}
                        {media.impression && roundAndAddThousandsSeparator(media.impression, 1)}
                      </b>
                    </td>
                  </tr>
                  <tr align="justify">
                    <td>CPM</td>
                    <td>&nbsp;</td>
                    <td align="right">
                      <b>{media.cost && `¥${roundAndAddThousandsSeparator((media.cost / media.impression) * 1000, 1)}`}</b>
                    </td>
                  </tr>
                </table>
              </Card.Description>
            </CardContentWrapper>
          }
          size="tiny"
        >
          <Modal.Header>{media.name}</Modal.Header>
          <ManageMediaDetail mediaTarget={media}></ManageMediaDetail>
        </Modal>
        <Card.Content>
          <div className="ui two buttons">
            <Button color="orange" onClick={() => history.push(`/owner/editmedia/${media.uid}`)}>
              編集
            </Button>
            <Button color="grey" onClick={() => history.push(`/owner/deletemedia/${media.uid}`)}>
              削除
            </Button>
          </div>
        </Card.Content>
      </Card>
    </>
  )
}

export default MediaCard
