import React, { useMemo } from 'react'
import MediaCardConfirm from 'components/organisms/card/MediaCardConfirm'
import { Table } from 'semantic-ui-react'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import './index.css'
import { cartDataType } from 'providers/CartsAll'
import { AdviceMediaDataType } from 'constants/mediaData'

type propsType = {
  cart: cartDataType
  mediaSortedList: AdviceMediaDataType[]
  clickRemoveMediafromCart: (media: AdviceMediaDataType) => void
  period: any
  bundleSummary: any
}

const ConfirmCartContent = (props: propsType) => {
  const colInfo = getColInfoFromTypeName(props.cart.mediaType)
  const jsxElemsHeader = [] as JSX.Element[]
  colInfo.cartConfirmCard.header.forEach((elem, index) => {
    index === 0
      ? jsxElemsHeader.push(<Table.HeaderCell colSpan="3">{elem}</Table.HeaderCell>)
      : jsxElemsHeader.push(<Table.HeaderCell>{elem}</Table.HeaderCell>)
  })

  const jsxElemsContents = [] as JSX.Element[]
  if (props.mediaSortedList) {
    props.mediaSortedList.map((media) => {
      jsxElemsContents.push(<Table.Row></Table.Row>)
      jsxElemsContents.splice(
        -1,
        0,
        <Table.Cell colSpan="3">
          <MediaCardConfirm media={media} clickRemoveMediafromCart={props.clickRemoveMediafromCart} />
        </Table.Cell>
      )
      for (const elem of colInfo.cartConfirmCard.contents) {
        jsxElemsContents.splice(-1, 0, <Table.Cell>{elem.displayMethod(media, props.period)}</Table.Cell>)
      }
    })
  }

  const jsxElemsFooter = useMemo(() => {
    return colInfo.cartConfirmFooter.map((elem) => {
      return (
        <Table.HeaderCell key={elem.displayedName}>
          <p className="confirmCartContent__cartSummaryText">{elem.displayedName}</p>
          <b>{elem.displayMethod(props.bundleSummary)}</b>
        </Table.HeaderCell>
      )
    })
  }, [colInfo, props.bundleSummary])

  return (
    <>
      <div className="confirmCartContent__campaignListTableContainer">
        <Table className="confirmCartContent__campaignListTable">
          <Table.Header>
            <Table.Row>{jsxElemsHeader}</Table.Row>
          </Table.Header>
          <Table.Body>{jsxElemsContents}</Table.Body>
          <Table.Row>{jsxElemsFooter}</Table.Row>
        </Table>
      </div>
    </>
  )
}

export default ConfirmCartContent
