import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'
import { mediaTypesEnum } from 'constants/mediaTypes'

export const MapMediasSearchFilter = (
  mediaList: AdviceMediaDataType[],
  targetTags: string[],
  targetLines: string[],
  targetHighways: string[],
  areaList: string[],
  mediaTypeList: string[],
  searchWord: string
) => {
  const searchWordLower = searchWord.toLocaleLowerCase()

  return mediaList.filter((media) => {
    if (media.name.indexOf(searchWordLower) === -1) {
      return false
    }
    if (mediaTypeList.length) {
      if (isJackChild(media)) {
        // ジャック子媒体の場合はmediaTypeJackの指定でも検索に引っかかるようにする
        if (!mediaTypeList.some((targetType) => [media.mediaType, mediaTypesEnum.JACK].includes(targetType))) {
          return false
        }
      } else if (media.mediaType === 'mediaTypeJack') {
        // ジャック媒体の場合は子媒体のmediaTypeでも検索に引っかかるようにする
        const isMediaTypeListIncludesJack = mediaTypeList.includes(media.mediaType)
        const isMediaTypeListIncludesChildMediaType = media.childMedia.some((childMedia) => mediaTypeList.includes(childMedia.mediaType))
        if (!isMediaTypeListIncludesJack && !isMediaTypeListIncludesChildMediaType) {
          return false
        }
      } else {
        // ジャック子媒体ではない媒体は指定されたmediaTypeListに当てはまらなければ除外する
        if (!mediaTypeList.includes(media.mediaType)) {
          return false
        }
      }
    }
    if (areaList.length) {
      const isIncludeArea = areaList.some((area) => {
        return media.address.includes(area)
      })
      if (!isIncludeArea) {
        return false
      }
    }
    if (targetTags.length > 0) {
      let res = false
      if (media.tags && media.tags.length > 0) {
        res = targetTags.every((targetTag) => {
          return media.tags.some((tag) => tag.id === targetTag)
        })
      }
      if (!res) {
        return false
      }
    }
    if (targetLines.length > 0) {
      let res = false
      if (media.lines && media.lines.length > 0) {
        res = targetLines.every((targetLine) => {
          return media.lines.some((line) => line.id === targetLine)
        })
      }
      if (!res) {
        return false
      }
    }
    if (targetHighways.length > 0) {
      let res = false
      if (media.highways && media.highways.length > 0) {
        res = targetHighways.every((targetHighway) => {
          return media.highways.some((highway) => highway.id === targetHighway)
        })
      }
      if (!res) {
        return false
      }
    }
    return true
  })
}
