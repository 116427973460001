import React, { useEffect, useState, useContext } from 'react'
import { auth } from 'FirebaseConfig'
import NavigationBarOwner from 'components/organisms/menubar/NavigationBarOwner'
import { Container, Button } from 'semantic-ui-react'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import Spacer from 'components/atoms/Spacer'
import PageMessage from 'components/atoms/PageMessage'
import { UsersContext } from 'providers/Users'
import { isOwner } from 'helpers/isOwner'
import FooterNav from 'components/organisms/menubar/FooterNav'
import './index.css'

const AppLayoutOwner = (props) => {
  const { users } = useContext(UsersContext)
  const [owner, setOwner] = useState(true)
  const history = useHistory()

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user && users) {
        let ref = isOwner(users, user.uid)
        setOwner(ref)
      } else {
        history.push(`${paths.signinowner}`)
      }
    })
  })

  return (
    <>
      <div
        style={{ display: 'grid' }}
        // className='appGridLayout'
      >
        <div style={{ gridRow: '1', gridColumn: '1' }} className="appLayoutOwner__navBarGridRow">
          <NavigationBarOwner />
        </div>
        <div style={{ gridRow: '2', gridColumn: '1' }} className="appLayoutOwner__appGridContent">
          <PageMessage />
          {owner ? (
            <>{props.children}</>
          ) : (
            <>
              <Spacer />
              <Container>
                <Button onClick={() => history.push(`${paths.my}`)}>広告主ページに移動</Button>
              </Container>
            </>
          )}
        </div>
        <div style={{ gridRow: '3', gridColumn: '1' }} className="appLayoutOwner__appGridFooter">
          <FooterNav />
        </div>
      </div>
    </>
  )
}

export default AppLayoutOwner
