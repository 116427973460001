import React, { useState, useEffect } from 'react'
import { Table, Input, Button } from 'semantic-ui-react'
import AppLayoutAdmin from 'components/organisms/layouts/AppLayoutAdmin'
import { db } from 'FirebaseConfig'
import { columnDtypes, lineColumnDTypes, highwayColumnDTypes, validationCallBacks, serializeValue } from './tagsDef'
import { parseData, checkDataContent } from 'components/pages/Admin/AddMedia/AddMediaPage/helper.js'

export default function AdminTags() {
  const [newTag, setNewTag] = useState({})
  const [tags, setTags] = useState([])
  const [header, setHeader] = useState([])
  const [newLineTag, setNewLineTag] = useState({})
  const [lineTags, setLineTags] = useState([])
  const [lineHeader, setLineHeader] = useState([])
  const [newHighwayTag, setNewHighwayTag] = useState({})
  const [highwayTags, setHighwayTags] = useState([])
  const [highwayHeader, setHighwayHeader] = useState([])

  useEffect(() => {
    const unsubscriber = db.collection('tags').onSnapshot((query) => {
      const temp = []
      for (const doc of query.docs) {
        const data = doc.data()
        temp.push({ ...data, docId: doc.ref.id })
        setHeader((prevState) => {
          if (prevState.length === 0) {
            return Object.keys(data).sort()
          } else {
            return prevState
          }
        })
      }
      setTags(temp)
    })
    return () => {
      unsubscriber()
    }
  }, [])

  const onChangeHandler = (idx, key, e) => {
    const [parsedValue, error] = parseData(e.target.value, columnDtypes[key])
    if (error || (typeof parsedValue === 'number' && isNaN(parsedValue)) || !checkDataContent(parsedValue, key, validationCallBacks)) {
      if (window) {
        window.alert('カラム: ' + key + ' に不正な値: ' + e.target.value + 'が入っています ')
      }
      e.preventDefault()
    } else {
      setTags((prevState) => {
        return [...prevState.slice(0, idx), { ...prevState[idx], [key]: parsedValue }, ...prevState.slice(idx + 1)]
      })
    }
  }

  const onChangeNewTagHandler = (key, e) => {
    const [parsedValue, error] = parseData(e.target.value, columnDtypes[key])
    if (error || (typeof parsedValue === 'number' && isNaN(parsedValue)) || !checkDataContent(parsedValue, key, validationCallBacks)) {
      if (window) {
        window.alert('カラム: ' + key + ' に不正な値: ' + e.target.value + 'が入っています ')
      }
      e.preventDefault()
    } else {
      setNewTag((prevState) => {
        return { ...prevState, [key]: parsedValue }
      })
    }
  }

  const onClickUpdateHandler = async (idx) => {
    try {
      const batch = db.batch()
      batch.update(db.collection('tags').doc(tags[idx].docId), tags[idx])
      const tagsSnapshot = await db.collectionGroup('tags').where('id', '==', tags[idx].docId).get()
      tagsSnapshot.docs.forEach((tagDoc) => batch.update(tagDoc.ref, tags[idx]))
      await batch.commit()
      if (window) {
        alert('データが更新されました')
      }
    } catch (error) {
      if (window) {
        console.error(error)
        alert('データの書き換えに失敗')
      }
    }
  }
  // Todo: queriesの値をしっかりバリデーションできるようにする
  // Todo: 項目の不足をバリデーションできるようにする
  const onClickAdd = async () => {
    try {
      delete tags.docId
      await db.collection('tags').doc(newTag.docId).set(newTag)
      if (window) {
        alert('データが更新されました')
      }
    } catch (error) {
      if (window) {
        console.error(error)
        alert('データの書き換えに失敗')
      }
    }
  }

  useEffect(() => {
    const linesUnsubscriber = db.collection('lines').onSnapshot((query) => {
      const temp = []
      for (const doc of query.docs) {
        const data = doc.data()
        temp.push({ ...data, docId: doc.ref.id })
        setLineHeader((prevState) => {
          if (prevState.length === 0) {
            return Object.keys(data).sort()
          } else {
            return prevState
          }
        })
      }
      setLineTags(temp)
    })
    return () => {
      linesUnsubscriber()
    }
  }, [])

  const onChangeLineHandler = (idx, key, e) => {
    const [parsedValue, error] = parseData(e.target.value, lineColumnDTypes[key])
    if (error || (typeof parsedValue === 'number' && isNaN(parsedValue)) || !checkDataContent(parsedValue, key, validationCallBacks)) {
      if (window) {
        window.alert('カラム: ' + key + ' に不正な値: ' + e.target.value + 'が入っています ')
      }
      e.preventDefault()
    } else {
      setLineTags((prevState) => {
        return [...prevState.slice(0, idx), { ...prevState[idx], [key]: parsedValue }, ...prevState.slice(idx + 1)]
      })
    }
  }

  const onChangeNewLineTagHandler = (key, e) => {
    const [parsedValue, error] = parseData(e.target.value, lineColumnDTypes[key])
    if (error || (typeof parsedValue === 'number' && isNaN(parsedValue)) || !checkDataContent(parsedValue, key, validationCallBacks)) {
      if (window) {
        window.alert('カラム: ' + key + ' に不正な値: ' + e.target.value + 'が入っています ')
      }
      e.preventDefault()
    } else {
      setNewLineTag((prevState) => {
        return { ...prevState, [key]: parsedValue }
      })
    }
  }

  const onClickUpdateLineHandler = async (idx) => {
    try {
      const batch = db.batch()
      batch.update(db.collection('lines').doc(lineTags[idx].docId), lineTags[idx])
      // サブコレクションの名前どうしよう
      const lineTagsSnapshot = await db.collectionGroup('lines').where('id', '==', lineTags[idx].docId).get()
      lineTagsSnapshot.docs.forEach((lineDoc) => batch.update(lineDoc.ref, lineTags[idx]))
      await batch.commit()
      if (window) {
        alert('データが更新されました')
      }
    } catch (error) {
      if (window) {
        console.error(error)
        alert('データの書き換えに失敗')
      }
    }
  }

  const onClickAddLine = async () => {
    try {
      delete tags.docId
      await db.collection('lines').doc(newLineTag.docId).set(newLineTag)
      if (window) {
        alert('データが更新されました')
      }
    } catch (error) {
      if (window) {
        console.error(error)
        alert('データの書き換えに失敗')
      }
    }
  }

  useEffect(() => {
    const highwaysUnsubscriber = db.collection('highways').onSnapshot((query) => {
      const temp = []
      for (const doc of query.docs) {
        const data = doc.data()
        temp.push({ ...data, docId: doc.ref.id })
        setHighwayHeader((prevState) => {
          if (prevState.length === 0) {
            return Object.keys(data).sort()
          } else {
            return prevState
          }
        })
      }
      setHighwayTags(temp)
    })
    return () => {
      highwaysUnsubscriber()
    }
  }, [])

  const onChangeHighwayHandler = (idx, key, e) => {
    const [parsedValue, error] = parseData(e.target.value, highwayColumnDTypes[key])
    if (error || (typeof parsedValue === 'number' && isNaN(parsedValue)) || !checkDataContent(parsedValue, key, validationCallBacks)) {
      if (window) {
        window.alert('カラム: ' + key + ' に不正な値: ' + e.target.value + 'が入っています ')
      }
      e.preventDefault()
    } else {
      setHighwayTags((prevState) => {
        return [...prevState.slice(0, idx), { ...prevState[idx], [key]: parsedValue }, ...prevState.slice(idx + 1)]
      })
    }
  }

  const onChangeNewHighwayTagHandler = (key, e) => {
    const [parsedValue, error] = parseData(e.target.value, highwayColumnDTypes[key])
    if (error || (typeof parsedValue === 'number' && isNaN(parsedValue)) || !checkDataContent(parsedValue, key, validationCallBacks)) {
      if (window) {
        window.alert('カラム: ' + key + ' に不正な値: ' + e.target.value + 'が入っています ')
      }
      e.preventDefault()
    } else {
      setNewHighwayTag((prevState) => {
        return { ...prevState, [key]: parsedValue }
      })
    }
  }

  const onClickUpdateHighwayHandler = async (idx) => {
    try {
      const batch = db.batch()
      batch.update(db.collection('highways').doc(highwayTags[idx].docId), highwayTags[idx])
      // サブコレクションの名前どうしよう
      const highwayTagsSnapshot = await db.collectionGroup('highways').where('id', '==', highwayTags[idx].docId).get()
      highwayTagsSnapshot.docs.forEach((highwayDoc) => batch.update(highwayDoc.ref, highwayTags[idx]))
      await batch.commit()
      if (window) {
        alert('データが更新されました')
      }
    } catch (error) {
      if (window) {
        console.error(error)
        alert('データの書き換えに失敗')
      }
    }
  }

  const onClickAddHighway = async () => {
    try {
      delete tags.docId
      await db.collection('highways').doc(newHighwayTag.docId).set(newHighwayTag)
      if (window) {
        alert('データが更新されました')
      }
    } catch (error) {
      if (window) {
        console.error(error)
        alert('データの書き換えに失敗')
      }
    }
  }

  return (
    <AppLayoutAdmin>
      <h2>施設タグ追加</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            {header.map((key) => (
              <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
            ))}
            <Table.HeaderCell>追加ボタン</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            {header.map((key) => (
              <Table.Cell key={key}>
                <Input onChange={(e) => onChangeNewTagHandler(key, e)} value={serializeValue(newTag[key], columnDtypes[key])} />
              </Table.Cell>
            ))}
            <Table.Cell>
              <Button
                onClick={() => {
                  onClickAdd()
                }}
              >
                追加
              </Button>
            </Table.Cell>
          </Table.Row>
        </Table.Body>

        <Table.Header>
          <Table.Row>
            {header.map((key) => (
              <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
            ))}
            <Table.HeaderCell>変更ボタン</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tags.map((tag, rowIdx) => (
            <Table.Row key={tag.docId}>
              {header.map((key) => (
                <Table.Cell key={key}>
                  <Input onChange={(e) => onChangeHandler(rowIdx, key, e)} value={serializeValue(tag[key], columnDtypes[key])} />
                </Table.Cell>
              ))}
              <Table.Cell>
                <Button
                  onClick={() => {
                    onClickUpdateHandler(rowIdx)
                  }}
                >
                  変更
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <hr />
      <h2>路線タグ追加</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            {lineHeader.map((key) => (
              <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
            ))}
            <Table.HeaderCell>追加ボタン</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            {lineHeader.map((key) => (
              <Table.Cell key={key}>
                <Input onChange={(e) => onChangeNewLineTagHandler(key, e)} value={serializeValue(newLineTag[key], lineColumnDTypes[key])} />
              </Table.Cell>
            ))}
            <Table.Cell>
              <Button
                onClick={() => {
                  onClickAddLine()
                }}
              >
                追加
              </Button>
            </Table.Cell>
          </Table.Row>
        </Table.Body>

        <Table.Header>
          <Table.Row>
            {lineHeader.map((key) => (
              <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
            ))}
            <Table.HeaderCell>変更ボタン</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {lineTags.map((line, rowIdx) => (
            <Table.Row key={line.docId}>
              {lineHeader.map((key) => (
                <Table.Cell key={key}>
                  <Input onChange={(e) => onChangeLineHandler(rowIdx, key, e)} value={serializeValue(line[key], lineColumnDTypes[key])} />
                </Table.Cell>
              ))}
              <Table.Cell>
                <Button
                  onClick={() => {
                    onClickUpdateLineHandler(rowIdx)
                  }}
                >
                  変更
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <hr />
      <h2>道路タグ追加</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            {highwayHeader.map((key) => (
              <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
            ))}
            <Table.HeaderCell>追加ボタン</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            {highwayHeader.map((key) => (
              <Table.Cell key={key}>
                <Input onChange={(e) => onChangeNewHighwayTagHandler(key, e)} value={serializeValue(newHighwayTag[key], highwayColumnDTypes[key])} />
              </Table.Cell>
            ))}
            <Table.Cell>
              <Button
                onClick={() => {
                  onClickAddHighway()
                }}
              >
                追加
              </Button>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
        <Table.Header>
          <Table.Row>
            {highwayHeader.map((key) => (
              <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
            ))}
            <Table.HeaderCell>変更ボタン</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {highwayTags.map((highway, rowIdx) => (
            <Table.Row key={highway.docId}>
              {highwayHeader.map((key) => (
                <Table.Cell key={key}>
                  <Input onChange={(e) => onChangeHighwayHandler(rowIdx, key, e)} value={serializeValue(highway[key], highwayColumnDTypes[key])} />
                </Table.Cell>
              ))}
              <Table.Cell>
                <Button
                  onClick={() => {
                    onClickUpdateHighwayHandler(rowIdx)
                  }}
                >
                  変更
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </AppLayoutAdmin>
  )
}
