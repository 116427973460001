import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AuthContext } from 'providers/Auth'
import MySettings from 'components/organisms/usersetting/MySettings'
import MyFields from 'components/organisms/usersetting/MyFields'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import { Container, Header, Message } from 'semantic-ui-react'
import Spacer from 'components/atoms/Spacer'
import AppLayout from 'components/organisms/layouts/AppLayout'
import AppLayoutPlane from 'components/organisms/layouts/AppLayoutPlane'
import MyPassword from 'components/organisms/usersetting/MyPassword'
import { Menu } from 'semantic-ui-react'
import { shouldSignin } from 'helpers/shouldSignin'

const My = (props) => {
  const { currentUser } = useContext(AuthContext)
  const history = useHistory()
  const [activeItem, setActiveItem] = useState('profile')
  const [visible, setVisible] = useState(true)

  // TODO: currentUserの有無のチェックをlayoutに移動
  useEffect(() => {
    if (shouldSignin(currentUser)) {
      history.push(`${paths.signin}`)
    }
  })

  const handleItemClick = (e, { id }) => {
    setActiveItem(id)
  }

  const handleDismiss = () => {
    setVisible(false)
    history.push({
      state: { text: '', type: '' },
    })
  }

  const StatusMessage = useMemo(() => {
    if (props.location.state && props.location.state.text === 'positive') {
      return <Message positive onDismiss={handleDismiss} header={'Successful'} content={props.location.state.text} />
    } else if (props.location.state && props.location.state.text === 'negative') {
      return <Message negative onDismiss={handleDismiss} header={'Error'} content={props.location.state.text} />
    } else {
      return <></>
    }
  }, [props])

  return (
    <>
      <AppLayout>
        <AppLayoutPlane>
          <Spacer />
          <Container style={{ minHeight: '50rem' }}>
            <Header as="h2">マイページ</Header>
            <Menu secondary size="large" pointing>
              <Menu.Item name="プロフィール" id="profile" color="orange" active={activeItem === 'profile'} onClick={handleItemClick} />
              <Menu.Item name="パスワード" id="pw" color="orange" active={activeItem === 'pw'} onClick={handleItemClick} />
            </Menu>
            {activeItem === 'profile' && (
              <>
                <MySettings path={paths.my} />
                <MyFields path={paths.my} />
              </>
            )}
            {activeItem === 'pw' && (
              <>
                <MyPassword path={paths.my} />
              </>
            )}
            {visible && StatusMessage}
          </Container>
          <Spacer />
        </AppLayoutPlane>
      </AppLayout>
    </>
  )
}

export default My
