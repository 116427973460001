import React from 'react'
import { Breadcrumb, Icon } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import paths from 'constants/paths'

const MapSearchBreadcrump = () => {
  const history = useHistory()

  return (
    <>
      <Breadcrumb size={'mini'}>
        <Breadcrumb.Section style={{ color: 'gray' }} onClick={() => history.push(paths.mediatop)} link active>
          <Icon name="home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active>マップで媒体検索</Breadcrumb.Section>
      </Breadcrumb>
    </>
  )
}

export default MapSearchBreadcrump
