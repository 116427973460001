import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as contentful from 'contentful'
import FooterNav from 'components/organisms/menubar/FooterNav'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { Breadcrumb } from 'semantic-ui-react'
import { auth } from 'FirebaseConfig'
import NavigationBar from 'components/organisms/menubar/NavigationBar'
import NavigationBarOwner from 'components/organisms/menubar/NavigationBarOwner'
import NavigationBarPlane from 'components/organisms/menubar/NavigationBarPlane'
import { isOwner } from 'helpers/isOwner'
import { UsersContext } from 'providers/Users'
import { formatDate } from 'helpers/formats/formatDate'
import './index.css'

const client = contentful.createClient({
  space: 'va5sd9zt8300',
  accessToken: 'Z1lOxlPSjqZC1kcovMl9G_9mSLrbWHyzTtZdeOuUlQU',
})

const Blog = () => {
  const [post, setPost] = useState({})
  const { users } = useContext(UsersContext)
  const [owner, setOwner] = useState(false)
  const [customer, setCustomer] = useState(false)
  const [login, setLogin] = useState(false)

  const { uid: slug } = useParams()
  const options = {
    renderText: (text) => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => `<img src=${node.data.target.fields.file.url} key=${node.data.target.fields.file.url} />`, // eslint-disable-line react/display-name
    },
  }
  useEffect(() => {
    client
      .getEntries({
        content_type: 'blog',
        limit: 1,
        // order: "-fields.date"
        'fields.slug[in]': slug,
      })
      .then((response) => {
        const rawRichTextField = response.items[0].fields.body
        setPost(response.items[0])
        return documentToHtmlString(rawRichTextField, options)
      })
      .then((renderedHtml) => {
        document.getElementById('rich-text-body').innerHTML = renderedHtml
      })
      .catch((error) => console.log(error))
  }, [])

  // TODO: MEATAタグをコンポーネントにする
  useEffect(() => {
    if (post.fields) {
      const title = post.fields.title + '｜AdVice（アドバイス）'
      const description =
        post.fields.title +
        'AdViceにログインできます。サービス認知を圧倒的に高めるOOHマーケティングを効果的に、カンタンに。屋外広告注文プラットフォーム「AdVice（アドバイス）」は、投資対効果ベースで屋外広告を注文できる新しいプラットフォームです。'
      document.title = title
      // title以外のmeta
      const headData = document.head.children

      for (let i = 0; i < headData.length; i++) {
        const nameVal = headData[i].getAttribute('name')
        if (nameVal !== null) {
          if (nameVal.indexOf('description') !== -1) {
            headData[i].setAttribute('content', description)
          }
          // OGP(twitter)の設定
          if (nameVal.indexOf('twitter:title') !== -1) {
            headData[i].setAttribute('content', title)
          }
          if (nameVal.indexOf('twitter:description') !== -1) {
            headData[i].setAttribute('content', description)
          }
          if (nameVal.indexOf('og:description') !== -1) {
            headData[i].setAttribute('content', description)
          }
          if (nameVal.indexOf('og:title') !== -1) {
            headData[i].setAttribute('content', description)
          }
          if (nameVal.indexOf('og:image') !== -1) {
            headData[i].setAttribute('content', process.env.REACT_APP_OGP_URL + '/img_ogp.png')
          }
        }
      }
    }
  }, [post])

  // TODO: セッション確認処理を共通化したコンポーネント
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setLogin(true)
        let ref = isOwner(users, user.uid)
        if (ref) {
          setOwner(true)
          setCustomer(false)
        } else {
          setOwner(false)
          setCustomer(true)
        }
      }
    })
  })
  //TODO: メニューバーが3つに分かれるパターン
  return (
    <>
      <div style={{ display: 'grid' }}>
        <div style={{ gridRow: '1', gridColumn: '1' }} className="blog__navBarGridRow">
          {owner && <NavigationBarOwner />}
          {customer && <NavigationBar />}
          {login ? <></> : <NavigationBarPlane />}
        </div>
        <div style={{ gridRow: '2', gridColumn: '1' }} className="blog__appGridContent">
          <article className="article">
            {post.fields && (
              <div className="single">
                <Breadcrumb>
                  <Breadcrumb.Section link>
                    <a href="/blogs">フィード一覧</a>
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section>{post.fields.title}</Breadcrumb.Section>
                </Breadcrumb>
                <h1 className="post-title">{post.fields.title}</h1>
                <p className="post-created-at">{formatDate(post.sys.createdAt)}</p>
                <img src={post.fields.image.fields.file.url} />
                <div className="post-content" id="rich-text-body"></div>
              </div>
            )}
          </article>
          <div style={{ gridRow: '3', gridColumn: '1' }} className="blog__appGridFooter">
            <FooterNav />
          </div>
        </div>
      </div>
      <style jsx>{`
        .ui.breadcrumb a {
          color: #e45d04;
          transition: all 0.3s;
        }
        .ui.breadcrumb a:hover {
          color: orange;
        }
        article.article {
          padding: 4rem 6rem;
          background-color: white;
        }
        .single {
          max-width: 900px;
          margin: 0 auto;
          padding: 10px;
          color: #222;
        }
        .single img {
          width: 100%;
        }
        .single h1,
        h2,
        h3 {
          margin: 16px 0;
        }
        .single h1.post-title {
          font-size: 32px;
          text-align: left;
          margin-bottom: 2rem;
        }
        .post-content h1 {
          font-size: 30px;
          line-height: 46px;
          margin-top: 30px;
          font-weight: 700;
        }
        .post-content h2 {
          font-size: 24px;
          background: #ccc;
          font-size: 26px;
          line-height: 42px;
          margin: 100px 0 60px;
        }
        .post-content a {
          color: #e45d04;
          text-decoration: underline;
        }
        .post-content ul {
          list-style-type: disc;
          margin-bottom: 1.5em;
          margin-top: 1em;
          padding: 1em 1em 1em 1.5em;
          border: 2px solid #ffc86b;
          border-radius: 3px;
        }
        .post-content li {
          margin-left: 1rem;
        }
        .post-content p {
          margin: 16px 0;
          margin-top: 30px;
          color: #666;
          font-size: 18px;
          line-height: 34px;
        }
        .post-content img {
          max-width: 100%;
          border: 1px solid #000;
        }
        .post-created-at {
          display: table-caption;
          margin-bottom: 2rem;
          background-color: #e8e8e8;
          color: #757763;
          padding: 0.5rem 0.75rem;
          border-radius: 0.875rem;
          font-size: 0.75rem;
          line-height: 0.75rem;
        }
      `}</style>
    </>
  )
}
export default Blog
