import React, { useState, useEffect, useContext } from 'react'
import { Segment, Breadcrumb } from 'semantic-ui-react'
import Spacer from 'components/atoms/Spacer'
import { useHistory, useLocation } from 'react-router-dom'
import paths from 'constants/paths'
import { auth, db } from 'FirebaseConfig'
import NavigationBar from 'components/organisms/menubar/NavigationBar'
import NavigationBarOwner from 'components/organisms/menubar/NavigationBarOwner'
import NavigationBarPlane from 'components/organisms/menubar/NavigationBarPlane'
import FooterNav from 'components/organisms/menubar/FooterNav'
import { isOwner } from 'helpers/isOwner'
import { UsersContext } from 'providers/Users'
import { UserContext } from 'providers/User'
import { convertMedia } from 'constants/mediaData'
import './index.css'
import Loading from 'components/atoms/Loading'
import MediaSegments from '../../../organisms/media/MediaSegments'
import { useTags } from 'providers/Tags'
import { MediasAvailableContext } from 'providers/MediasAvailable'
import { calculateCentroid } from 'helpers/calculates/calculateCentroid'

require('firebase/functions')

const Media = () => {
  const [owner, setOwner] = useState(false)
  const [customer, setCustomer] = useState(false)
  const [login, setLogin] = useState(false)
  const [media, setMedia] = useState(null)
  const { users } = useContext(UsersContext)
  const { user } = useContext(UserContext)
  const history = useHistory()
  const location = useLocation()
  const { tags, lines } = useTags()
  const { getChildMedia } = useContext(MediasAvailableContext)

  // TODO: セッション確認処理を共通化したコンポーネント
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        setLogin(true)
        let ref = isOwner(users, user.uid)
        if (ref) {
          setOwner(true)
          setCustomer(false)
        } else {
          setOwner(false)
          setCustomer(true)
        }
      }
    })
  })

  // TODO: METAタグの共通化
  useEffect(() => {
    const title = (media ? media.name : '') + '｜AdVice（アドバイス）'
    const description =
      (media ? media.content + '。' : '') +
      'サービス認知を圧倒的に高めるOOHマーケティングを効果的に、カンタンに。屋外広告注文プラットフォーム「AdVice（アドバイス）」は、投資対効果ベースで屋外広告を注文できる新しいプラットフォームです。'
    document.title = title
    // title以外のmeta
    const headData = document.head.children

    for (let i = 0; i < headData.length; i++) {
      const nameVal = headData[i].getAttribute('name')
      if (nameVal !== null) {
        if (nameVal.indexOf('description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        // OGP(twitter)の設定
        if (nameVal.indexOf('twitter:title') !== -1) {
          headData[i].setAttribute('content', title)
        }
        if (nameVal.indexOf('twitter:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:title') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:image') !== -1) {
          headData[i].setAttribute('content', process.env.REACT_APP_OGP_URL + '/img_ogp.png')
        }
      }
    }
  })

  useEffect(() => {
    let unmounted = false
    async function createMediaState(mediaId) {
      let data
      try {
        const mediaDoc = await db.collection('media').doc(mediaId).get()
        if (mediaDoc.exists) {
          data = convertMedia(mediaDoc.data())
          if (data.mediaType === 'mediaTypeJack') {
            data.childMedia = getChildMedia(data.uid)
            const centroid = calculateCentroid(data.childMedia)
            data.latitude = centroid.latitude
            data.longitude = centroid.longitude
          }
          data.tags = tags[mediaDoc.id] || []
          data.lines = lines[mediaDoc.id] || []
        } else {
          console.log('Doc does not exist')
        }
      } catch (error) {
        console.log('Error getting document or marginRate:', error)
      }
      if (!unmounted) {
        setMedia(data)
      }
    }

    if (location.pathname) {
      const urlMediaId = location.pathname.match(/\w+/g)[location.pathname.match(/\w+/g).length - 1]
      const mediaId = urlMediaId.replace('mediaid', 'mediaId')
      createMediaState(mediaId)
    }

    return function () {
      unmounted = true
    }
  }, [location.pathname, tags, getChildMedia])

  return (
    <>
      {!media ? (
        <Loading />
      ) : (
        <div style={{ display: 'grid' }}>
          {/* TODO: mediaページ用のlayoutを作成してそれを使う */}
          <div style={{ gridRow: '1', gridColumn: '1' }} className="media__navBarGridRow">
            {owner && <NavigationBarOwner />}
            {customer && <NavigationBar />}
            {login ? <></> : <NavigationBarPlane />}
          </div>
          <div style={{ gridRow: '2', gridColumn: '1' }} className="media__appGridContent">
            <div className="ui container" style={{ maxWidth: '80%' }}>
              <Spacer />
              <div className="media__mediaSegment">
                <Segment>
                  <Breadcrumb>
                    <Breadcrumb.Section
                      link
                      style={{ color: 'orange' }}
                      onClick={() => {
                        history.push(`/mediasearch`)
                      }}
                    >
                      媒体一覧
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section>{media && media.name}</Breadcrumb.Section>
                  </Breadcrumb>
                </Segment>
              </div>
              <MediaSegments key={media.uid} media={media} login={login} paths={paths} user={user} />
            </div>
            <div style={{ gridRow: '3', gridColumn: '1' }} className="media__appGridFooter">
              <FooterNav />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default Media
