import { MediaImage } from 'components/organisms/images/MediaImage'
import { AdviceMediaDataType } from 'constants/mediaData'
import React, { useCallback } from 'react'
import { Grid, Header } from 'semantic-ui-react'

type props = {
  media: AdviceMediaDataType
}
const MediaCard = ({ media }: props) => {
  const clickExternalLinkHandler = useCallback(() => {
    const win = window.open(`/media/${media.uid}`, '_blank')
    win && win.focus()
  }, [media])

  return (
    <Grid>
      <Grid.Column width={3}>
        <MediaImage media={media} />
      </Grid.Column>
      <Grid.Column width={13}>
        <Header as="h4">{media.name}</Header>
        <p>{media.address}</p>
        <a style={{ cursor: 'pointer' }} onClick={clickExternalLinkHandler}>
          詳細
        </a>
      </Grid.Column>
    </Grid>
  )
}

export default MediaCard
