import { db } from 'FirebaseConfig'
import { pos2mesh } from './utils'

type calcRatioArg = {
  latitude: number
  longitude: number
}
type ratioObjType = Record<string, number>

/**
 *
 * @param {object} dataObj
 * @returns {maleAgeRatio: maleAgeRatio, femaleAgeRatio: femaleAgeRatio, ageRatio: ageRatio}
 */
const calcRatio = async (dataObj: calcRatioArg): Promise<{ maleAgeRatio: ratioObjType; femaleAgeRatio: ratioObjType; ageRatio: ratioObjType }> => {
  const meshCD = pos2mesh(dataObj.latitude, dataObj.longitude)
  const ratioDoc = await db.collection('mesh_ratio').doc(String(meshCD)).get()
  if (!ratioDoc.exists) {
    throw new Error('mesh info not found')
  }
  const ratio = ratioDoc.data()
  let sumRatio = 0
  let sumMale = 0
  for (const key in ratio) {
    const sumTmp = ratio[key]['1'] + ratio[key]['2']
    sumMale += ratio[key]['1']
    sumRatio += sumTmp
  }
  const maleAgeRatio = {}
  const femaleAgeRatio = {}
  const ageRatio = {}
  for (const key in ratio) {
    const sumTmp = ratio[key]['1'] + ratio[key]['2']
    ageRatio[key] = Math.round((sumTmp / sumRatio) * 10000) / 100 // 年代別
    maleAgeRatio[key] = Math.round((ratio[key]['1'] / sumTmp) * 10000) / 100 // 年代別男性
    femaleAgeRatio[key] = Math.round((ratio[key]['2'] / sumTmp) * 10000) / 100 // 年代別女性
  }
  maleAgeRatio['total'] = Math.round((sumMale / sumRatio) * 1000) / 10
  femaleAgeRatio['total'] = Math.round(((sumRatio - sumMale) / sumRatio) * 1000) / 10
  return { maleAgeRatio: maleAgeRatio, femaleAgeRatio: femaleAgeRatio, ageRatio: ageRatio }
}

export default calcRatio
