import { AdviceMediaDataType } from '../../constants/mediaData'

export const mediaList2Bounds = (mediaList: AdviceMediaDataType[]) => {
  const latsLngs = mediaList.reduce(
    (accum, curr) => {
      return {
        latitude: [...accum.latitude, curr.latitude],
        longitude: [...accum.longitude, curr.longitude],
      }
    },
    { latitude: [] as number[], longitude: [] as number[] }
  )
  const minLat = Math.min(...latsLngs.latitude)
  const minLng = Math.min(...latsLngs.longitude)
  const maxLat = Math.max(...latsLngs.latitude)
  const maxLng = Math.max(...latsLngs.longitude)
  return new window.google.maps.LatLngBounds(
    {
      lat: minLat,
      lng: minLng,
    },
    {
      lat: maxLat,
      lng: maxLng,
    }
  )
}

export const calculateAverageLatLng = (mediaList: AdviceMediaDataType[], defaultMapCenterLat: number, defaultMapCenterLng: number) => {
  let aveLat = defaultMapCenterLat
  let aveLng = defaultMapCenterLng
  let sumLat = 0
  let sumLng = 0
  let countRef = 0
  mediaList.forEach((media) => {
    sumLat += Number(media.latitude)
    sumLng += Number(media.longitude)
    countRef += 1
  })
  if (countRef) {
    aveLat = Number(sumLat) / Number(countRef)
    aveLng = Number(sumLng) / Number(countRef)
  }
  return { aveLat, aveLng }
}
