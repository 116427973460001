import React, { useEffect, useState, useContext } from 'react'
import MediaCardSubmit from 'components/organisms/card/MediaCardSubmit'
import { Table, Checkbox, Button, Grid, Dimmer, Loader } from 'semantic-ui-react'
import Spacer from 'components/atoms/Spacer'
import Slider from 'react-slick'
import firebase, { db } from 'FirebaseConfig'
import { AuthContext } from 'providers/Auth'
import './index.css'

const ConfirmCartContent = (props) => {
  const [mediaAspectRatioList, setMediaAspectRatioList] = useState([])
  const [checkedMediaIdList, setCheckedMediaIdList] = useState([])
  const [submittedImagesList, setSubmittedImageList] = useState([])
  const [submitAspect, setSubmitAspect] = useState([])

  const { currentUser } = useContext(AuthContext)

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slideToShow: 1,
    sliderToScroll: 1,
    swipe: true,
  }

  const handleChangeFile = (e) => {
    let files = e.target.files
    let imageUrls = []

    if (files.length > 1) {
      new Promise(() => {
        let counter = 0
        for (let i = 0; i < files.length; i++) {
          const fr = new FileReader()
          // fr.onload((e)=> {
          //   setMediaImages(imageUrls.push({imageUrl: e.result, name: files[i].name, file: files[i]}))
          // })
          fr.readAsDataURL(files[i])
          fr.addEventListener('load', () => {
            imageUrls = submittedImagesList
            let fileType = files[i].name.match(/\.(jpg|png|gif|jpeg)$/)[1]
            let fileName = files[i].name.substring(0, files[i].name.lastIndexOf('.'))
            imageUrls.push({ imageUrl: fr.result, name: fileName + '_' + Number(new Date()) + '.' + fileType, file: files[i] })
            counter += 1
            if (counter === files.length) {
              setSubmittedImageList(imageUrls)
            }
          })

          // imageUrls.push({imageUrl: createObjectURL(files[i]), name: files[i]})
          // imageUrls.push({imageUrl: createObjectURL(files[i]), name: files[i]})
        }
      })
    } else if (files.length > 0) {
      const fr = new FileReader()
      let fileType = files[0].name.match(/\.(jpg|png|gif|jpeg)$/)[1]
      let fileName = files[0].name.substring(0, files[0].name.lastIndexOf('.'))
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        imageUrls.push({ imageUrl: fr.result, name: fileName + '_' + Number(new Date()) + '.' + fileType, file: files[0] })
        setSubmittedImageList(imageUrls)
      })
    }
  }

  const clickAddMedia = () => {
    console.log('click add media')
    props.setLoading(true)
    let sendSubmitMail = firebase.functions().httpsCallable('sendSubmitMail')
    const cartUid = props.cart.uid
    let docId = cartUid
    let mediaNameArray =
      submittedImagesList.length > 0
        ? submittedImagesList.map((image) => {
            return 'images/media/' + image.name
          })
        : []
    console.log('ファイルはこちら', submittedImagesList)
    console.log('保存された画像', mediaNameArray)
    console.log(docId)

    const storageRef = firebase.storage().ref()

    let data = {}
    data.email = currentUser.email
    data.userId = currentUser.uid
    data.mediaList = checkedMediaIdList
    data.submittedImagesList = submittedImagesList

    sendSubmitMail(data)
      .then(() => {
        submittedImagesList.length > 0 &&
          submittedImagesList.forEach((submitedImage) => {
            storageRef
              .child(`images/submittedImage/${submitedImage.name}`)
              .put(submitedImage.file)
              .then((snapshot) => {
                props.setLoading(false)
                snapshot.ref.getDownloadURL().then((downloadURL) => {
                  console.log('保存できました', downloadURL)
                  alert('DB登録成功しました。Palledadにメール送信しました。')
                })
              })
            for (let media of checkedMediaIdList) {
              db.collection('cart')
                .doc(docId)
                .update({ [media]: `images/submittedImage/${submitedImage.name}` })
            }
          })
      })
      .catch((error) => {
        props.setLoading(false)
        alert(`DB登録失敗[${error}]`)
      })
  }

  const handleChange = (e, { name, value }) => {
    switch (name) {
      case 'media':
        if (checkedMediaIdList.includes(value) === false) {
          setCheckedMediaIdList(checkedMediaIdList.concat([value]))
        } else {
          setCheckedMediaIdList(checkedMediaIdList.filter((n) => n !== value))
        }
        break
    }
  }

  useEffect(() => {
    let tmpAspectRatioList = []
    props.mediaSortedList.map((media) => tmpAspectRatioList.push(media.widthSize + ':' + media.heightSize))

    tmpAspectRatioList = Array.from(new Set(tmpAspectRatioList))

    let tmpMediaAspectRatioList = []
    for (let i of tmpAspectRatioList) {
      let tmpMediaList = []
      if (!Number.isNaN(i)) {
        for (let j of props.mediaSortedList) {
          if (j.widthSize !== undefined) {
            if (i === j.widthSize + ':' + j.heightSize) {
              tmpMediaList.push(j)
            }
          } else {
            tmpMediaList.push({ widthSize: '?', heightize: '?' })
          }
        }
        tmpMediaAspectRatioList.push([i, tmpMediaList])
      }
    }
    setMediaAspectRatioList(tmpMediaAspectRatioList)
    // setMediaAspectRatioList(tmpMediaAspectRatioList.filter((x, i, self) => self.indexOf(x.toString()) === i.toString()))
  }, [])

  const undefCheck = (data) => {
    if (data !== undefined) {
      // const tmparr = Object.keys(data)
      return true
    } else {
      return false
    }
  }

  return (
    <>
      {props.loading && (
        <Dimmer active inverted>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <div className="submitContent__campaignListTableContainer">
        <Table className="submitContent__campaignListTable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="3">縦横比</Table.HeaderCell>
              <Table.HeaderCell>媒体</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {mediaAspectRatioList &&
              mediaAspectRatioList.map((lis, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <h3>{undefCheck(lis[1][0]) ? <div>{lis[1][0].widthSize + 'm :' + lis[1][0].heightSize + 'm'}</div> : <></>}</h3>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <label
                            color="gray"
                            style={{ padding: '10px 40px', color: '#ffffff', backgroundColor: 'gray', cursor: 'pointer', borderRadius: '5px', margin: 'auto' }}
                          >
                            ファイルを選択
                            <input
                              className="submitContent__input-for-file"
                              type="file"
                              multiple
                              accept="image/*"
                              name="mediaImages"
                              onChange={handleChangeFile}
                              onClick={() => {
                                setSubmitAspect(lis[1][0].widthSize / lis[1][0].heightSize)
                              }}
                            />
                          </label>
                          <br></br>
                          <br></br>
                          <p>
                            {submittedImagesList.length > 0 && submitAspect === lis[1][0].widthSize / lis[1][0].heightSize
                              ? submittedImagesList.map((image) => (
                                  <span style={{ marginRight: '1rem' }} key={image.name}>
                                    {image.name}
                                  </span>
                                ))
                              : '選択されていません'}
                          </p>
                          {submittedImagesList.length > 0 && submitAspect === lis[1][0].widthSize / lis[1][0].heightSize ? (
                            <Slider {...settings}>
                              {submittedImagesList.map((madiaImage) => (
                                <img
                                  src={madiaImage.imageUrl}
                                  style={{ maxHeight: 'rem10', maxWidth: 'rem10', objectFit: 'cover' }}
                                  key={madiaImage.imageUrl}
                                />
                              ))}
                            </Slider>
                          ) : (
                            <></>
                          )}
                          <br />
                          <br />
                          {undefCheck(lis[1][0]) ? (
                            <Button color="orange" onClick={() => clickAddMedia()} disabled={submitAspect !== lis[1][0].widthSize / lis[1][0].heightSize}>
                              選択した媒体について入稿する
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Table.Cell>

                  <Table.Cell>
                    <Grid>
                      {lis[1] &&
                        lis[1].map((media) => (
                          <Grid.Row key={media.uid}>
                            <Grid.Column>
                              <Spacer />
                              <Checkbox
                                value={media.uid}
                                onChange={handleChange}
                                name={'media'}
                                style={{ position: 'absolute', margin: 'auto' }}
                                disabled={submitAspect !== lis[1][0].widthSize / lis[1][0].heightSize}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <MediaCardSubmit media={media} clickRemoveMediafromCart={props.clickRemoveMediafromCart} />
                            </Grid.Column>
                          </Grid.Row>
                        ))}
                    </Grid>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </div>
    </>
  )
}

export default ConfirmCartContent
