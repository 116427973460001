import React from 'react'
import { Table } from 'semantic-ui-react'

const TargetSexCell = (props) => {
  let targetSexForDisplay = ''
  switch (props.targetSex) {
    case 'male':
      targetSexForDisplay = '男性'
      break
    case 'female':
      targetSexForDisplay = '女性'
      break
    case 'unisex':
      targetSexForDisplay = '全て'
      break
    default:
      targetSexForDisplay = '全て'
  }
  return <Table.Cell>{targetSexForDisplay}</Table.Cell>
}

export default TargetSexCell
