// Library
import React, { useState, useEffect, useContext, useRef } from 'react'
import { Grid, Button, Card, Divider, Pagination, Header } from 'semantic-ui-react'
// Components
import AppLayoutNotAuthRequired from 'components/organisms/layouts/AppLayoutNotAuthRequired'
import Spacer from 'components/atoms/Spacer'
import { MediaPublishedAllContext } from 'providers/MediaPublishedAll'
import { MediaBookedAllContext } from 'providers/MediaBookedAll'
import { MediasContext } from 'providers/Medias'
import { AuthContext } from 'providers/Auth'
import { SearchPageFilter } from 'helpers/filters/SearchPageFilter'
import MediaCardSearch from 'components/organisms/card/MediaCardSearch'
import paths from 'constants/paths'
import { useHistory, useLocation } from 'react-router-dom'
import MediaSearchForm from 'components/organisms/mediasearch/MediaSearchForm'
import Loading from 'components/atoms/Loading'
import { nameSortCallback, costSortCallback, costEffectivenessSortCallback } from 'helpers/sortCallbacks'
import './index.css'

require('firebase/functions')

const MediaSearch = () => {
  const [searchWord, setSearchWord] = useState('')
  const [mediaType, setMediaType] = useState([])
  const [area, setArea] = useState([])
  const [tag, setTag] = useState([])
  const [line, setLine] = useState([])
  const [highway, setHighway] = useState([])
  const [sortKey, setSortKey] = useState('costEffectiveness')
  const [page, setPage] = useState(1)
  const [startDate] = useState(new Date())
  const [endDate] = useState(new Date())
  const [minPrice] = useState('')
  const [maxPrice] = useState('')
  const [minCpm] = useState('')
  const [mediaSortedList, setMediaSortedList] = useState([])
  const [currentKeyInfo] = useState(-1)
  const [currentKey, setCurrentKey] = useState(-1)
  const [login, setLogin] = useState(false)
  const [pagenationStart, setPagenationStart] = useState(0)
  const [pagenationEnd, setPagenationEnd] = useState(10)
  const [loading, setLoading] = useState(true)
  const { mediaPublished } = useContext(MediaPublishedAllContext)
  const { mediaBookedAll } = useContext(MediaBookedAllContext)
  const { mediaLoading } = useContext(MediasContext)
  const { currentUser } = useContext(AuthContext)
  const history = useHistory()
  const location = useLocation()

  const isBrowzerBack = useRef(false)

  // ブラウザ履歴を移動したときのみフラグをセット
  window.onpopstate = () => {
    isBrowzerBack.current = true
  }

  useEffect(() => {
    // TODO: 認証をlayoutに移動する
    if (currentUser) {
      setLogin(true)
    }
  }, [currentUser])

  useEffect(() => {
    const searchConditions = new URLSearchParams(location.search)
    const mediaTypeParamsValue = searchConditions.get('media_type')
    if (mediaTypeParamsValue) {
      const madiaTypeParamsList = mediaTypeParamsValue.split(',')
      setMediaType(madiaTypeParamsList)
    } else {
      setMediaType()
    }
    const areaParamsValue = searchConditions.get('area')
    if (areaParamsValue) {
      const areaParamsList = areaParamsValue.split(',')
      setArea(areaParamsList)
    } else {
      setArea() //TODO:setArea([])にするとaddressがない媒体しか表示されなくなる原因を特定する
    }
    const searchWordParamsValue = searchConditions.get('searchword')
    if (searchWordParamsValue) {
      setSearchWord(searchWordParamsValue)
    } else {
      setSearchWord('')
    }
    const tagParamsValue = searchConditions.get('tag')
    if (tagParamsValue) {
      setTag(tagParamsValue.split(','))
    } else {
      setTag('')
    }
    const lineParamsValue = searchConditions.get('line')
    if (lineParamsValue) {
      setLine(lineParamsValue.split(','))
    } else {
      setLine('')
    }
    const highwayParamsValue = searchConditions.get('highway')
    if (highwayParamsValue) {
      setHighway(highwayParamsValue.split(','))
    } else {
      setHighway('')
    }
    const sortParamsValue = searchConditions.get('sort')
    if (sortParamsValue) {
      setSortKey(sortParamsValue)
    } else {
      setSortKey('costEffectiveness')
    }
    const pageParamsValue = searchConditions.get('page')
    if (pageParamsValue) {
      setPage(pageParamsValue)
    } else {
      setPage(1)
    }
  }, [location])

  useEffect(() => {
    setPagenationStart(10 * (page - 1))
    setPagenationEnd(10 * (page - 1) + 10)
  }, [page])

  // TODO: MEATタグの共通化
  useEffect(() => {
    const title = '媒体検索画面｜AdVice（アドバイス）'
    const description =
      '屋外広告媒体を媒体を検索することができます。サービス認知を圧倒的に高めるOOHマーケティングを効果的に、カンタンに。屋外広告注文プラットフォーム「AdVice（アドバイス）」は、投資対効果ベースで屋外広告を注文できる新しいプラットフォームです。'
    document.title = title
    // title以外のmeta
    const headData = document.head.children

    for (let i = 0; i < headData.length; i++) {
      const nameVal = headData[i].getAttribute('name')
      if (nameVal !== null) {
        if (nameVal.indexOf('description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        // OGP(twitter)の設定
        if (nameVal.indexOf('twitter:title') !== -1) {
          headData[i].setAttribute('content', title)
        }
        if (nameVal.indexOf('twitter:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:title') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:image') !== -1) {
          headData[i].setAttribute('content', process.env.REACT_APP_OGP_URL + '/img_ogp.png')
        }
      }
    }
  })
  useEffect(() => {
    let tmpmedias = []
    tmpmedias = SearchPageFilter(
      mediaPublished,
      searchWord,
      area,
      tag,
      line,
      highway,
      minPrice,
      maxPrice,
      minCpm,
      startDate,
      endDate,
      mediaBookedAll,
      mediaType
    )
    let mediaList = []
    if (tmpmedias && mediaBookedAll) {
      mediaList = tmpmedias
      //ソート
      switch (sortKey) {
        case 'name':
          mediaList.sort(nameSortCallback)
          break
        case 'price':
          mediaList.sort(costSortCallback)
          break
        case 'costEffectiveness':
          mediaList.sort(costEffectivenessSortCallback)
          break
        default:
          mediaList.sort(nameSortCallback)
      }
    }
    setMediaSortedList(mediaList)
    setLoading(false)
  }, [mediaPublished, mediaLoading, searchWord, area, tag, line, highway, sortKey, minPrice, maxPrice, minCpm, isBrowzerBack.current, mediaType, page])

  const onClickPageChange = (_event, data) => {
    setPage(data.activePage)
    const searchConditions = new URLSearchParams(location.search)
    searchConditions.set('page', data.activePage)
    history.push(`/mediasearch?${searchConditions.toString()}`)
  }

  const onClickMediaCard = (mediaId, index) => {
    history.push(`/media/${mediaId}`)
    setCurrentKey(index)
  }

  return (
    <>
      <Grid as="Segment" style={{ background: 'white' }}>
        <AppLayoutNotAuthRequired>
          <Grid.Row textAlign="center">
            {loading ? (
              <Loading header={'Successful'} content={'キャンペーン作成が成功しました。グループ作成に移りましょう。'} />
            ) : (
              <Grid.Column>
                <div className="mediaSearch__appGridContent" style={{ maxWidth: '50rem', margin: 'auto' }}>
                  <div className="ui container">
                    <Spacer />
                    <MediaSearchForm searchWord={searchWord} area={area} tag={tag} line={line} highway={highway} mediaType={mediaType} buttonText="検索" />
                    <Divider />

                    <Header as="p" style={{ color: 'black', textAlign: 'left' }}>
                      <div>{mediaSortedList.length}の媒体</div>
                    </Header>
                    <div className="mediaSearch__scrollSearchTab">
                      {mediaSortedList.length < 1 && <p>入力された広告グループ情報に合う媒体が見つかりません。再度広告グループ情報を編集してください。</p>}
                      {mediaSortedList.length > 0 &&
                        mediaSortedList.slice(pagenationStart, pagenationEnd).map((media, index) => (
                          <div
                            onClick={() => onClickMediaCard(media.uid, index)}
                            className={`mediaSearch__mediaCardDefault ${index === currentKeyInfo ? 'mediaSearch__mediaCardHovered' : ''} ${
                              index === currentKey ? 'mediaSearch__mediaCardSelected' : ''
                            }`}
                            key={media.uid}
                          >
                            <MediaCardSearch login={login} media={media} />
                          </div>
                        ))}
                    </div>
                    {login ? (
                      <></>
                    ) : (
                      <>
                        <div style={{ display: 'block', margin: 'auto', textAlign: 'center' }}>
                          <Header as="p" style={{ color: 'black', marginBottom: '1rem' }}>
                            ※価格、効果をご覧頂くには会員登録が必要です。
                          </Header>
                        </div>
                        <div style={{ display: 'grid' }}>
                          <div style={{ gridRow: '1/1', gridColumn: '2/3' }}>
                            <Card>
                              <Button
                                color="orange"
                                onClick={() => {
                                  history.push(`${paths.signin}`, { page: '/mediasearch' })
                                }}
                              >
                                会員登録/ログイン
                              </Button>
                            </Card>
                          </div>
                        </div>
                        <Spacer />
                      </>
                    )}

                    <center>
                      <Pagination activePage={page} totalPages={Math.floor(mediaSortedList.length / 10 + 1)} onPageChange={onClickPageChange} />
                    </center>

                    <Spacer />

                    <div style={{ gridRow: '3', gridColumn: '1' }} className="mediaSearch__appGridFooter"></div>
                  </div>
                </div>
              </Grid.Column>
            )}
          </Grid.Row>
        </AppLayoutNotAuthRequired>
      </Grid>
    </>
  )
}
export default MediaSearch
