import React, { useCallback, useState } from 'react'
import { Button } from 'semantic-ui-react'
import firebase from 'FirebaseConfig'
import { toast } from 'react-toastify'
import { useDownloadePdfStatus } from '../helper'

const getPackagePdf = firebase.functions().httpsCallable('getPackagePDF')

type props = {
  packageId: string
}

const PackagePdfDownloader = ({ packageId }: props) => {
  const [pdfURL, setPdfURL] = useState('')
  const { buttonStatus, setButtonStatus, downloadBtnText, buttonColor } = useDownloadePdfStatus()

  //pdfのダウンロード処理
  const fetchPDF = useCallback(async () => {
    setButtonStatus('CREATING')
    const res = await getPackagePdf({
      packageId: packageId,
    })
    if (res && res.data.status === 200 && window) {
      toast.success(
        <div>
          PDFが作成されました。
          <br />
          PDFをダウンロードできます。
        </div>
      )
      setButtonStatus('READY')
      setPdfURL(res.data.fileDownloadURL)
    } else {
      if (window) {
        window.alert('PDFが正常に作成されませんでした。')
      }
      console.error(res.data.message)
      setButtonStatus('STANBY')
    }
  }, [packageId])

  const downloadBtnHandler = useCallback(async () => {
    if (pdfURL === '') {
      await fetchPDF()
    } else {
      window.open(pdfURL)
    }
  }, [pdfURL])

  return (
    <Button color={buttonColor} onClick={downloadBtnHandler} disabled={buttonStatus === 'CREATING'}>
      {downloadBtnText}
    </Button>
  )
}

export default PackagePdfDownloader
