import { Package } from 'components/organisms/admin/PackageTable/helper'
import { AdviceMediaDataType } from 'constants/mediaData'
import { db, priceSetting } from 'FirebaseConfig'
import { bundleSummaryDType } from 'helpers/mediaType/helpers'
import { mediaType } from 'helpers/mediaType/mediaDef'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import { cartDataType } from 'providers/CartsAll'
import { AdviceMediaDataTypeWithPeriod, splitMediaListByMediaType } from '../PackageSubtotalList/helper'

type displayMethod = (a: Package, b?: AdviceMediaDataType[]) => string | number
type displayMethodObj = {
  name: string
  method: displayMethod
}

const addPriceNotation = (value: number) => {
  return `¥${value.toLocaleString()}`
}
const displayMethodFactory: (callback: callback) => displayMethod = (callback) => {
  return (mediaPackage, mediaList) => {
    const packageCost = calculatePackageCostFactory(callback)(mediaPackage, mediaList)
    if (typeof packageCost === 'number') {
      return addPriceNotation(packageCost)
    }
    return packageCost
  }
}

type callback = (bundle: bundleSummaryDType) => number
const calculatePackageCostFactory: (callback: callback) => displayMethod = (callback) => {
  return (mediaPackage, mediaList) => {
    const splitMediaListObj = mediaList && splitMediaListByMediaType(mediaPackage.mediaList, mediaList)
    if (!splitMediaListObj) {
      return 'N/A'
    }
    const totalCost = (Object.keys(splitMediaListObj) as mediaType[]).reduce((sum, mediaType) => {
      const splitMediaList = splitMediaListObj[mediaType]
      const colInfo = getColInfoFromTypeName(mediaType)
      const subTotal = splitMediaList
        ? splitMediaList.reduce((prevValue, currentValue) => {
            const period = {
              weeks: currentValue.weeks,
              months: currentValue.months,
            }
            const bundleSummary = colInfo.culcBundleSummary([currentValue], period, false)
            const cost = callback(bundleSummary)
            return prevValue + cost
          }, 0)
        : 0
      return sum + subTotal
    }, 0)
    return totalCost
  }
}

export const calcTotalCost = calculatePackageCostFactory((bundle) => {
  return Math.round(bundle.totalOverallCostForEntirePeriod * priceSetting.taxRate)
})

export const displayMethods: displayMethodObj[] = [
  {
    name: '媒体数',
    method: (mediaPackage) => {
      return mediaPackage.mediaList.length
    },
  },
  { name: '掲載費計(税抜)', method: displayMethodFactory((bundle) => Math.round(bundle.totalPublicationCostForEntirePeriod)) },
  {
    name: '施工回復費計(税抜)',
    method: displayMethodFactory((bundle) => {
      return Math.round(bundle.mediaType === 'mediaTypeBillboard' ? bundle.totalConstructionCost + bundle.totalRecoveryCost : 0)
    }),
  },
  {
    name: '配布費計(税抜)',
    method: displayMethodFactory((bundle) => {
      return Math.round(bundle.mediaType === 'mediaTypeLeaflet' ? bundle.totalDistributionCost : 0)
    }),
  },
  {
    name: '消費税',
    method: displayMethodFactory((bundle) => {
      return Math.round(bundle.totalOverallCostForEntirePeriod * (priceSetting.taxRate - 1))
    }),
  },
  {
    name: '合計金額',
    method: displayMethodFactory((bundle) => {
      return Math.round(bundle.totalOverallCostForEntirePeriod * priceSetting.taxRate)
    }),
  },
]

export const createCart = async (data: Omit<cartDataType, 'uid'>) => {
  const docId = db.collection('cart').doc().id
  await db
    .collection('cart')
    .doc(docId)
    .set({ ...data, uid: docId, docId })
}

type splitMediaListObj = {
  [period: number]: AdviceMediaDataTypeWithPeriod[]
}
export const splitMediaListByPeriod = (mediaList: AdviceMediaDataTypeWithPeriod[]) => {
  const splitMediaListObj = {} as splitMediaListObj
  mediaList.forEach((media) => {
    const period = media.weeks || media.months
    if (period) {
      if (Array.isArray(splitMediaListObj[period])) {
        splitMediaListObj[period] = [...splitMediaListObj[period], media]
      } else {
        splitMediaListObj[period] = [media]
      }
    }
  })
  return Object.values(splitMediaListObj)
}
