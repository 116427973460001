// Library
import React, { useContext } from 'react'
// import Sidebar from 'semantic-ui-react/dist/commonjs/modules/Sidebar';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import { Dropdown, Icon } from 'semantic-ui-react'
import paths from 'constants/paths'
import logo from 'assets/pictures/advice-beta-logo-white.png'
import styled from '@emotion/styled'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import { auth } from 'FirebaseConfig'
import { useHistory } from 'react-router-dom'
import { AuthContext } from 'providers/Auth'
import './index.css'

const NavigationBarOwner = () => {
  const NavBar = styled.div`
     {
      background-color: #444;
      padding: 0 2rem;
      z-index: 999;
      height: 56px;
    }
  `

  const RedDiv = styled.div`
    color: #de3c2d;
  `

  const ImageWrapper = styled(Image)`
    height: 24px !important;
    width: auto !important;
  `
  const history = useHistory()
  const { currentUser } = useContext(AuthContext)

  const clickLogoutOwner = () => {
    // すぐにサインアウトしないため/loginに遷移させることができない。
    if (window.confirm('ログアウトしますか？')) {
      auth.signOut()
    }
  }

  const handleItemClick = (event, { name }) => {
    switch (name) {
      case 'root':
        history.push(`${paths.lp}`)
        break
      case 'signin':
        history.push(`${paths.signinowner}`)
        break
      case 'signup':
        history.push(`${paths.signupowner}`)
        break
      case 'my':
        history.push(`${paths.myowner}`)
        break
      case 'menu':
        history.push(`${paths.menuowner}`)
        break
      case 'contact':
        history.push(`${paths.contact}`)
        break
      case 'customer':
        history.push(paths.mediatop)
        break
      default:
        console.log('No key match')
    }
  }

  return (
    <NavBar>
      <Menu secondary size="massive" inverted>
        <Menu.Item
          fitted
          name="root"
          // onClick={handleItemClick}
        >
          &nbsp;&nbsp;
          <ImageWrapper src={logo} size="mini" />
          &nbsp;&nbsp;
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item name="customer" onClick={handleItemClick} className="navigationBarOwner__headerText">
            広告主サイトへ
          </Menu.Item>
          <Menu.Item name="menu" onClick={handleItemClick} className="navigationBarOwner__headerText">
            媒体管理メニュー
          </Menu.Item>
          {currentUser ? (
            <>
              <Dropdown item trigger={<Icon name="bars" size="large" />} icon={null}>
                <Dropdown.Menu>
                  <Dropdown.Item name="my" onClick={handleItemClick}>
                    ユーザー設定
                  </Dropdown.Item>
                  <Dropdown.Item name="contact" onClick={handleItemClick}>
                    お問い合わせ
                  </Dropdown.Item>
                  <Dropdown.Item onClick={clickLogoutOwner}>
                    <RedDiv>ログアウト</RedDiv>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <>
              <Dropdown item>
                <Dropdown.Menu>
                  <Dropdown.Item name="signup" onClick={handleItemClick}>
                    新規登録
                  </Dropdown.Item>
                  <Dropdown.Item name="signin" onClick={handleItemClick}>
                    ログイン
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </Menu.Menu>
      </Menu>
    </NavBar>
  )
}

export default NavigationBarOwner
