import medium1 from 'assets/pictures/media245_20190712.png'
import medium2 from 'assets/pictures/mediaId242_0001.png'
import medium3 from 'assets/pictures/mediaId208_0001.png'
import medium4 from 'assets/pictures/mediaId244_0001.png'
import medium5 from 'assets/pictures/mediaid350_20200723.jpg'

export const mediaExample = [
  {
    id: 0,
    name: '渋谷区　Aビル屋上',
    size: 'H 3.5[m] × W 7.0[m]',
    cpm: '￥556',
    cost: '6ヶ月 210万円',
    image: medium1,
  },
  {
    id: 1,
    name: '渋谷区　Bビル屋上',
    size: 'H 6.0[m] × W 9.0[m]',
    cpm: '￥409',
    cost: '6ヶ月 100万円',
    image: medium2,
  },
  {
    id: 2,
    name: '千代田区　Cビル壁面',
    size: 'H 15.0[m] × W 4.8[m]',
    cpm: '￥460',
    cost: '6ヶ月 200万円',
    image: medium3,
  },
  {
    id: 3,
    name: '港区　Dビル屋上',
    size: 'H 3.0[m] × W 4.0[m]',
    cpm: '￥442',
    cost: '6ヶ月 85万円',
    image: medium4,
  },
  {
    id: 4,
    name: '渋谷区　Eビル屋上',
    size: 'H 3.0[m] × W 2.4[m]',
    cpm: '￥277',
    cost: '6ヶ月 78万円',
    image: medium5,
  },
]
