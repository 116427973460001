import React from 'react'
import { Menu, Modal, Header } from 'semantic-ui-react'
import styled from '@emotion/styled'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import TermsOfService from 'components/organisms/policy/TermsOfService'
import PrivacyPolicy from 'components/organisms/policy/PrivacyPolicy'
import Spacer from 'components/atoms/Spacer'
import letterLogo from 'assets/pictures/advice-beta-logo-colored.png'
import './index.css'

//background-color: #eee;をstyled.footerから消した
const FooterNav = () => {
  const FooterNavBar = styled.footer`
    padding: 0 2rem;
    z-index: 999;
    left: 0;
    bottom: 0;
    border-top: solid 1px #ddd;
    text-align: center;
    width: 100%;
  `

  const ImageWrapper = styled(Image)`
    height: 36px !important;
    width: auto !important;
    margin: 4rem auto 2rem;
  `

  return (
    <>
      <FooterNavBar>
        <ImageWrapper src={letterLogo} />
        <Menu secondary size="massive" widths={8} style={{ margin: 'auto' }}>
          <Menu.Item name="signinowner" href="/owner/signup" className="footerNav__footerItem">
            媒体主様はこちら
          </Menu.Item>
          <Menu.Item name="blogs" href="/blogs" className="footerNav__footerItem">
            フィード
          </Menu.Item>
          <Modal trigger={<Menu.Item className="footerNav__footerItem">プライバシーポリシー</Menu.Item>} dimmer={'inverted'}>
            <Modal.Content scrolling>
              <Modal.Description>
                <Header>プライバシーポリシー</Header>
                <PrivacyPolicy />
              </Modal.Description>
            </Modal.Content>
          </Modal>

          <Modal trigger={<Menu.Item className="footerNav__footerItem">利用規約</Menu.Item>} dimmer={'inverted'}>
            <Modal.Content scrolling>
              <Modal.Description>
                <Header>利用規約</Header>
                <TermsOfService />
              </Modal.Description>
            </Modal.Content>
          </Modal>

          <Menu.Item href="https://www.palledad.com" className="footerNav__footerItem" target="_blank">
            運営会社
          </Menu.Item>
          {/* <Menu.Item
          className='footerItem'
          >
          </Menu.Item> */}
        </Menu>
        <p className="footerNav__footerItem">&copy; 2022 PalledAd,Inc</p>
        <Spacer />
      </FooterNavBar>
    </>
  )
}

export default FooterNav
