import React from 'react'
import { Header, Form, Button } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import './index.css'

// AddCampaign と EditCampaign の両方で使えるフォームにする
const SignInForm = (props) => {
  const history = useHistory()
  const { handleSubmit, register, errors } = useForm()

  return (
    <>
      <Header as="h2">{props.header}</Header>
      <div className="signInForm__loginFormNote">{props.note}</div>
      <br />
      <Form onSubmit={handleSubmit(props.clickLogin)}>
        <p>
          <b>メールアドレス</b>
        </p>
        <input
          name="email"
          id="email"
          placeholder="name@domain.com"
          onChange={props.handleChange}
          ref={register({
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'invalid email address',
            },
          })}
        />
        <div>{errors.email ? errors.email.message : <>&nbsp;</>}</div>
        <p>
          <b>パスワード</b>
        </p>
        <input
          type="password"
          label="パスワード"
          name="password"
          id="password"
          placeholder="password"
          onChange={props.handleChange}
          ref={register({
            required: 'Required',
            minLength: {
              value: 6,
              message: 'password needs min 6 caractors',
            },
          })}
        />
        <div>{errors.password ? errors.password.message : <>&nbsp;</>}</div>
        <br />
        <Button fluid color="orange" type="submit">
          ログイン
        </Button>
        {props.owner ? (
          <>
            <p className="signInForm__passwordResetLink" onClick={() => history.push(`${paths.resetpasswordowner}`)}>
              パスワードをお忘れの方
            </p>
          </>
        ) : (
          <>
            <p className="signInForm__passwordResetLink" onClick={() => history.push(`${paths.resetpassword}`)}>
              パスワードをお忘れの方
            </p>
          </>
        )}

        {props.owner ? (
          <>
            <Button fluid onClick={() => history.push(`${paths.signupowner}`)}>
              新規登録はこちら
            </Button>
          </>
        ) : (
          <>
            <Button fluid onClick={() => history.push(`${paths.signup}`)}>
              新規登録はこちら
            </Button>
          </>
        )}
      </Form>
    </>
  )
}

export default SignInForm
