// Library
import React from 'react'
// import Sidebar from 'semantic-ui-react/dist/commonjs/modules/Sidebar';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import paths from 'constants/paths'
import logo from 'assets/pictures/advice-beta-logo-white.png'
import styled from '@emotion/styled'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'
import { auth } from 'FirebaseConfig'
import './index.css'

const handleAdminLogOut = () => {
  // logout button
  if (window.confirm('ログアウトしますか？')) {
    auth.signOut()
  }
}

const NavigationBarAdmin = () => {
  const NavBar = styled.div`
     {
      background-color: #444;
      padding: 0 2rem;
      z-index: 999;
      height: 56px;
    }
  `

  const ImageWrapper = styled(Image)`
    height: 24px !important;
    width: auto !important;
  `
  const history = useHistory()

  const handleItemClick = (event, { name }) => {
    switch (name) {
      case 'root':
        history.push(`${paths.adminConsole}`)
        break
      case 'adminLogOut':
        history.push(`${paths.lp}`)
        break
      case 'adminAddCSVMedia':
        history.push(`${paths.adminAddCSVMedia}`)
        break
      case 'adminMediaUnderConsideration':
        history.push(`${paths.adminMediaUnderConsideration}`)
        break
      case 'adminEditMedia':
        history.push(`${paths.adminEditMedia}`)
        break
      case 'adminAddOneMedia':
        history.push(`${paths.adminAddOneMedia}`)
        break
      case 'adminOwners':
        history.push(`${paths.adminOwners}`)
        break
      case 'adminTags':
        history.push(`${paths.adminTags}`)
        break
      default:
        console.log('No key match')
    }
  }

  return (
    <NavBar>
      <Menu secondary size="massive" inverted>
        <Menu.Item fitted name="root" onClick={handleItemClick}>
          &nbsp;&nbsp;
          <ImageWrapper src={logo} size="mini" />
          &nbsp;&nbsp;
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Menu position="right">
            <Menu.Item name="adminTags" onClick={handleItemClick} className="navigationBarAdmin__headerText">
              タグ一覧
            </Menu.Item>
            <Menu.Item name="adminMediaUnderConsideration" onClick={handleItemClick} className="navigationBarAdmin__headerText">
              被検討媒体一覧
            </Menu.Item>
            <Menu.Item name="adminOwners" onClick={handleItemClick} className="navigationBarAdmin__headerText">
              媒体主一覧
            </Menu.Item>
            <Menu.Item name="adminAddCSVMedia" onClick={handleItemClick} className="navigationBarAdmin__headerText">
              CSVから媒体追加
            </Menu.Item>
            <Menu.Item name="adminEditMedia" onClick={handleItemClick} className="navigationBarAdmin__headerText">
              媒体編集
            </Menu.Item>

            <Menu.Item name="adminAddOneMedia" onClick={handleItemClick} className="navigationBarAdmin__headerText">
              媒体追加(adres)
            </Menu.Item>
            <Menu.Item name="adminLogOut" className="navigationBarAdmin__headerText" onClick={handleAdminLogOut}>
              ログアウト
            </Menu.Item>

            {/* <Menu.Item
                name='signinowner'
                onClick={handleItemClick}
                className="headerText"
              >
                媒体主としてログイン
              </Menu.Item> */}
          </Menu.Menu>
        </Menu.Menu>
      </Menu>
    </NavBar>
  )
}

export default withRouter(NavigationBarAdmin)
