import React from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'

const ManageMediaBreadcrump = () => {
  const history = useHistory()
  return (
    <>
      <Breadcrumb size={'mini'}>
        <Breadcrumb.Section onClick={() => history.push(`${paths.menuowner}`)} link active>
          <i style={{ color: 'gray' }} className="home icon" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section style={{ color: 'gray' }}>保有媒体一覧</Breadcrumb.Section>
        {/* <Breadcrumb.Divider icon='right angle' /> */}
        {/* <Breadcrumb.Section >
          <a style={{color: 'black'}} href='/campaign/add'>ビジネス情報の登録</a>
        </Breadcrumb.Section> */}
      </Breadcrumb>
    </>
  )
}

export default ManageMediaBreadcrump
