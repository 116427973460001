import React, { useMemo } from 'react'
import NavigationBar from 'components/organisms/menubar/NavigationBar'
import VerticalBarCart from 'components/organisms/menubar/VerticalBarCart'
import { Icon } from 'semantic-ui-react'
import './index.css'

const AppLayoutGroupMenuBar = ({ flagAngle, setFlagAngle, hasPadding = true, children }) => {
  //アイコンをクリックするとflagAngleを切り替える
  //サイドメニューがせり出す機能
  const clickIcon = () => {
    setFlagAngle(!flagAngle)
  }

  const paddingStyle = useMemo(() => {
    return {
      padding: hasPadding ? 20 : 0,
    }
  }, [hasPadding])

  return (
    <>
      <div style={{ display: 'grid', gridTemplateColumns: '5vw 5vw 1fr', hight: '100vm' }}>
        <div style={{ gridRow: '1', gridColumn: '1/4' }} className="appLayoutGroupEdit__navBarGridRow">
          <NavigationBar />
        </div>
        <div style={flagAngle ? { gridColumn: '1/3' } : { gridColumn: '1/2' }} className="appLayoutGroupEdit__verticalBarColumn">
          <VerticalBarCart flagAngle={flagAngle} />
          <div onClick={clickIcon}>
            {flagAngle ? (
              <>
                <Icon className="appLayoutGroupEdit__navbarAngleOpen" circular color="grey" name="angle left" size="large" inverted />
              </>
            ) : (
              <>
                <Icon className="appLayoutGroupEdit__navbarAngleClosed" circular color="grey" name="angle right" size="large" inverted />
              </>
            )}
          </div>
        </div>
        <div style={{ ...(flagAngle ? { gridColumn: '3/4' } : { gridColumn: '2/4' }), ...paddingStyle }} className="appLayoutGroupEdit__groupLayoutContent">
          {children}
        </div>
      </div>
    </>
  )
}

export default AppLayoutGroupMenuBar
