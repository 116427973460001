import React, { useEffect, useState } from 'react'
import { Button, Radio, Dimmer, Loader } from 'semantic-ui-react'
import { MediaImage } from 'components/organisms/images/MediaImage'
import firebase, { db } from 'FirebaseConfig'
import { useHistory } from 'react-router-dom'
import Spacer from 'components/atoms/Spacer'
import { Form, Input } from 'semantic-ui-react-form-validator'

const EditMediaForm = (props) => {
  const [mediaName, setMediaName] = useState('')
  const [address, setAddress] = useState('')
  const [widthSize, setWidthSize] = useState('')
  const [heightSize, setHeightSize] = useState('')
  const [heightPosition, setHeightPosition] = useState('')
  const [direction, setDirection] = useState('')
  const [description, setDescription] = useState('')
  const [cost, setCost] = useState('')
  const [mediaImage, setMediaImage] = useState('')
  const [constructionCost, setConstructionCost] = useState('')
  const [mediaType, setMediaType] = useState('')
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.media) {
      setMediaName(props.media.name)
      setAddress(props.media.address)
      setWidthSize(props.media.widthSize)
      setHeightSize(props.media.heightSize)
      setHeightPosition(props.media.heightPosition)
      setDirection(props.media.direction)
      setDescription(props.media.description)
      setCost(props.media.cost)
      setConstructionCost(props.media.constructionCost)
      setMediaType(props.media.mediaType)
    }
  }, [props.media])

  const handleChangeRadio = (e, { value }) => {
    setMediaType(value)
  }

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'mediaName':
        setMediaName(event.target.value)
        break
      case 'address':
        setAddress(event.target.value)
        break
      case 'cost':
        setCost(event.target.value)
        break
      case 'constructionCost':
        setConstructionCost(event.target.value)
        break
      case 'widthSize':
        setWidthSize(event.target.value)
        break
      case 'heightSize':
        setHeightSize(event.target.value)
        break
      case 'heightPosition':
        setHeightPosition(event.target.value)
        break
      case 'direction':
        setDirection(event.target.value)
        break
      case 'description':
        setDescription(event.target.value)
        break
      case 'mediaImage':
        setMediaImage(event.target.files[0])
        break
      default:
        console.log('no key match')
    }
  }

  const clickUpdateImage = () => {
    let today = new Date(Date.now())
    setLoading(true)
    let timestamp = Date.now()
    let fileName = props.media.uid + '_' + timestamp + '.jpg'
    let ref = firebase.storage().ref().child(fileName)
    ref.put(mediaImage)
    db.collection('media')
      .doc(props.media.uid)
      .update({
        mediaImagePaths: firebase.firestore.FieldValue.arrayUnion(fileName),
        updatedAt: firebase.firestore.Timestamp.fromDate(today),
      })
      .then(() => {
        setLoading(false)
        setMediaImage('')
        history.go(0)
        history.push({
          state: {
            text: '媒体の画像を更新しました。',
            type: 'positive',
          },
        })
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        history.push({
          state: {
            text: error,
            type: 'negative',
          },
        })
      })
  }

  const clickUpdateMedia = () => {
    let today = new Date(Date.now())
    setLoading(true)
    db.collection('media')
      .doc(props.media.uid)
      .update({
        name: mediaName,
        address: address,
        monthlyCost: cost,
        constructionCost: constructionCost,
        verticalWidth: heightSize,
        horizontalWidth: widthSize,
        height: heightPosition,
        direction: direction,
        note: description,
        mediaType: mediaType,
        updatedAt: firebase.firestore.Timestamp.fromDate(today),
      })
      .then(() => {
        setLoading(false)
        history.go(0)
        history.push({
          state: {
            text: '媒体の情報を更新しました。',
            type: 'positive',
          },
        })
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        history.push({
          state: {
            text: error,
            type: 'negative',
          },
        })
      })
  }

  return (
    <>
      {loading && (
        <Dimmer active inverted>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      {props.media && (
        <>
          <Form>
            <p>
              <b>媒体の写真</b>
            </p>
            <MediaImage media={props.media} width={'300px'} />
            <input type="file" name="mediaImage" onChange={handleChange} />
            <br />
            <br />
            <div>
              <Button onClick={clickUpdateImage} color="orange" disabled={!mediaImage}>
                画像を更新
              </Button>
            </div>
            <Spacer />
          </Form>

          <Form onSubmit={clickUpdateMedia}>
            <p>
              <b>媒体の名前</b>
            </p>
            <p className="editMediaForm__requiredMark">必須</p>
            <Input
              name="mediaName"
              id="mediaName"
              placeholder="媒体の名前"
              value={mediaName}
              onChange={handleChange}
              validators={['required']}
              errorMessages={['媒体名を入力してください']}
            />
            <p>
              <b>媒体の住所</b>
            </p>
            <p className="editMediaForm__requiredMark">必須</p>
            <Input
              name="address"
              id="address"
              placeholder="例：東京パルダッドビル第３看板"
              value={address}
              validators={['required']}
              errorMessages={['媒体のある住所を入力してください']}
              onChange={handleChange}
            />
            <p>
              <b>媒体の形式</b>
            </p>
            <p className="editMediaForm__requiredMark">必須</p>
            <br />
            <Radio name="mediaTypeRadioGroup" value={'mediaTypeDigitalVision'} checked={mediaType === 'mediaTypeDigitalVision'} onChange={handleChangeRadio} />
            ビジョン
            <Radio name="mediaTypeRadioGroup" value={'mediaTypeBillboard'} checked={mediaType === 'mediaTypeBillboard'} onChange={handleChangeRadio} />
            屋外広告
            <Radio name="mediaTypeRadioGroup" value={'mediaTypePoster'} checked={mediaType === 'mediaTypePoster'} onChange={handleChangeRadio} />
            ポスター
            <p>
              <b>媒体の価格（円/月）(DOOHは週額を記入してください)</b>
            </p>
            <p className="editMediaForm__requiredMark">必須</p>
            <Input
              name="cost"
              id="cost"
              type="number"
              step="1000"
              value={cost}
              placeholder="例：1000000"
              validators={['required']}
              errorMessages={['媒体の価格を入力してください']}
              onChange={handleChange}
            />
            <p>
              <b>施工費（円）</b>
            </p>
            <p className="editMediaForm__requiredMark">必須</p>
            <Input
              name="constructionCost"
              id="constructionCost"
              type="number"
              step="1000"
              value={constructionCost}
              placeholder="例：1000000"
              validators={['required']}
              errorMessages="媒体の施工費を入力してください"
              onChange={handleChange}
            />
            <p>
              <b>媒体の縦幅 [m]</b>
            </p>
            <p className="editMediaForm__requiredMark">必須</p>
            <Input
              name="heightSize"
              id="heightSize"
              type="number"
              step="0.1"
              value={heightSize}
              placeholder="例：2"
              validators={['required']}
              errorMessages="媒体の縦幅を入力してください"
              onChange={handleChange}
            />
            <p>
              <b>媒体の横幅 [m]</b>
            </p>
            <p className="editMediaForm__requiredMark">必須</p>
            <Input
              name="widthSize"
              id="widthSize"
              type="number"
              step="0.1"
              value={widthSize}
              placeholder="例：3"
              validators={['required']}
              errorMessages="媒体の横幅を入力してください"
              onChange={handleChange}
            />
            <p>
              <b>媒体の設置高度 [m]</b>
            </p>
            <p className="editMediaForm__requiredMark">必須</p>
            <Input
              name="heightPosition"
              id="heightPosition"
              type="number"
              step="0.1"
              value={heightPosition}
              placeholder="例：3"
              validators={['required']}
              errorMessages="媒体の設置高度を入力してください"
              onChange={handleChange}
            />
            <p>
              <b>媒体の向き [deg]</b>
            </p>
            <p className="editMediaForm__requiredMark">必須</p>
            <Input
              name="direction"
              id="direction"
              type="number"
              step="1"
              value={direction}
              placeholder="例：30"
              validators={['required']}
              errorMessages="媒体の向きを入力してください"
              onChange={handleChange}
            />
            <p>
              <b>媒体の紹介文</b>
            </p>
            <textarea name="description" type="" value={description} onChange={handleChange} />
            <Spacer />
            <div>
              <Button color="orange" type="submit">
                媒体情報を更新
              </Button>
            </div>
          </Form>
          <Spacer />
        </>
      )}
    </>
  )
}

export default EditMediaForm
