import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Table } from 'semantic-ui-react'
import { AuthContext } from 'providers/Auth'
import { auth, db } from 'FirebaseConfig'
import { UsersContext } from 'providers/Users'
import { useHistory } from 'react-router-dom'

const MyFields = () => {
  const { currentUser } = useContext(AuthContext)
  const { users } = useContext(UsersContext)

  const [fullName, setFullName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [address, setAddress] = useState('')
  const [tel, setTel] = useState('')
  const history = useHistory()

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'fullName':
        setFullName(event.target.value)
        break
      case 'companyName':
        setCompanyName(event.target.value)
        break
      case 'address':
        setAddress(event.target.value)
        break
      case 'tel':
        setTel(event.target.value)
        break
      default:
        console.log('key not found')
    }
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        let ref = users.find((u) => u.docId === user.uid)
        if (ref !== undefined) {
          setFullName(ref.fullName)
          setTel(ref.tel)
          setCompanyName(ref.companyName)
          setAddress(ref.address)
          console.log('user exists')
        } else {
          console.log('user not exists')
        }
      }
    })
  }, [users])

  const clickUpdate = () => {
    if (currentUser) {
      db.collection('user')
        .doc(currentUser.uid)
        .update({
          docId: currentUser.uid,
          uid: currentUser.uid,
          fullName: fullName,
          companyName: companyName,
          address: address,
          tel: tel,
        })
        .then(() => {
          history.go(0)
          history.push({
            state: {
              text: 'プロフィールを更新しました。',
              type: 'negative',
            },
          })
        })
        .catch((error) => {
          alert(error.message)
          history.go(0)
          history.push({
            state: {
              text: `プロフィールの更新に失敗しました。[${error}]`,
              type: 'negative',
            },
          })
        })
    }
  }

  return (
    <>
      <Table celled>
        <Table.Body>
          <Table.Row>
            <Table.Cell>会社名</Table.Cell>
            <Table.Cell>
              <Form>
                <Form.Input name="companyName" value={companyName} onChange={handleChange} />
              </Form>
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>住所</Table.Cell>
            <Table.Cell>
              <Form>
                <Form.Input name="address" value={address} onChange={handleChange} />
              </Form>
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>担当者名</Table.Cell>
            <Table.Cell>
              <Form>
                <Form.Input name="fullName" value={fullName} onChange={handleChange} />
              </Form>
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>電話番号</Table.Cell>
            <Table.Cell>
              <Form>
                <Form.Input name="tel" value={tel} onChange={handleChange} />
              </Form>
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>

          <Table.Row />
        </Table.Body>
      </Table>

      <Button color="orange" onClick={clickUpdate}>
        更新する
      </Button>
    </>
  )
}

export default MyFields
