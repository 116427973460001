import React, { useEffect, useState } from 'react'
import firebase from 'FirebaseConfig'
import noImage from 'assets/pictures/no_image.jpg'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

type Props = {
  imageURLArray: string[]
}

const MediaCarousel = ({ imageURLArray }: Props) => {
  const [mediaImagePaths, setmediaImagePaths] = useState<string[]>([])
  const [flag, setFlag] = useState<boolean>(false)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slideToShow: 1,
    sliderToScroll: 1,
    swipe: true,
  }
  const urlArray: string[] = []

  const fetchDownloadUrl = (imageUrl: string): Promise<void> => {
    return new Promise((resolve) => {
      const storageRef = firebase.storage().ref()
      // let spaceRef = storageRef.child(uid+'.jpg');
      try {
        storageRef
          .child(imageUrl)
          .getDownloadURL()
          .then((url) => {
            urlArray.push(url)
            resolve(url)
          })
      } catch (error) {
        console.log('Error getting document:', error)
        setFlag(false)
      }
    })
  }

  useEffect(() => {
    if (imageURLArray && imageURLArray.length > 0) {
      const promises: Promise<void>[] = []
      imageURLArray.forEach((imageUrl: string) => {
        ;(function (imageUrl: string) {
          promises.push(fetchDownloadUrl(imageUrl))
        })(imageUrl)
      })
      Promise.all(promises).then(() => {
        setmediaImagePaths(urlArray)
        setFlag(true)
      })
    } else {
      setmediaImagePaths([noImage])
      setFlag(false)
    }
  }, [imageURLArray])

  return (
    <>
      {flag ? (
        <Slider {...settings}>
          {mediaImagePaths.map((mediaImageUrl: string) => (
            <img src={mediaImageUrl} style={{ height: '100%', width: '100%', objectFit: 'cover' }} key={mediaImageUrl} />
          ))}
        </Slider>
      ) : (
        <img src={noImage} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
      )}
    </>
  )
}

export { MediaCarousel }
