interface Point {
  latitude: number
  longitude: number
}

export const calculateCentroid = (points: Point[]): Point => {
  const numPoints = points.length
  let sumLat = 0
  let sumLng = 0
  for (const point of points) {
    sumLat += point.latitude
    sumLng += point.longitude
  }
  const centerLat = sumLat / numPoints
  const centerLng = sumLng / numPoints
  return { latitude: centerLat, longitude: centerLng }
}
