import React, { useState, useEffect } from 'react'
import { Icon, Menu, Label, Button } from 'semantic-ui-react'
import CartSearchTab from 'components/organisms/cart/CartSearchTab'
import CartContentTab from 'components/organisms/cart/CartContentTab'
import CartResumeTab from 'components/organisms/cart/CartResumeTab'
import CartRecommendTab from 'components/organisms/cart/CartRecommendTab'
import { useHistory } from 'react-router-dom'
import CartEditBreadcrump from 'components/organisms/breadcrumb/CartEditBreadcrump'

import './index.css'

const CartTabs = (props) => {
  const [activeItem, setActiveItem] = useState('')
  const [listCount, setListCount] = useState(0)
  const [recommendCount, setRecommendCount] = useState(0)
  const [cartCount, setCartCount] = useState(0)

  const history = useHistory()

  const handleItemClick = (e, { id }) => {
    setActiveItem(id)
    props.setMediaTarget('')
    props.setCurrentKey(-1)
  }

  useEffect(() => {
    let showResumeTab = document.getElementById('showResumeTab')
    let showSearchTab = document.getElementById('showSearchTab')
    let showRecommendTab = document.getElementById('showRecommendTab')
    let showCartTab = document.getElementById('showCartTab')
    switch (activeItem) {
      case 'resume':
        showResumeTab.style.setProperty('display', 'block')
        showSearchTab.style.setProperty('display', 'none')
        showRecommendTab.style.setProperty('display', 'none')
        showCartTab.style.setProperty('display', 'none')
        break
      case 'search':
        showResumeTab.style.setProperty('display', 'none')
        showSearchTab.style.setProperty('display', 'block')
        showRecommendTab.style.setProperty('display', 'none')
        showCartTab.style.setProperty('display', 'none')
        break
      case 'recommend':
        showResumeTab.style.setProperty('display', 'none')
        showSearchTab.style.setProperty('display', 'none')
        showRecommendTab.style.setProperty('display', 'block')
        showCartTab.style.setProperty('display', 'none')
        break
      case 'cart':
        showResumeTab.style.setProperty('display', 'none')
        showSearchTab.style.setProperty('display', 'none')
        showRecommendTab.style.setProperty('display', 'none')
        showCartTab.style.setProperty('display', 'block')
        break
      default:
        showResumeTab.style.setProperty('display', 'none')
        showSearchTab.style.setProperty('display', 'none')
        showRecommendTab.style.setProperty('display', 'none')
        showCartTab.style.setProperty('display', 'none')
        console.log('No name match')
    }
  })

  useEffect(() => {
    if (props.cart.status === 1) {
      setActiveItem('search')
    } else if (props.cart.status === 2) {
      setActiveItem('cart')
    }
  }, [props.cart.status])

  return (
    <>
      <div className="cartTabs__cartHeaderWrapper">
        <CartEditBreadcrump />
        <Menu widths={4} size="mini">
          <Menu.Item name="グループ概要" id="resume" color="orange" active={activeItem === 'resume'} onClick={handleItemClick} />
          {props.cart.status === 1 ? (
            <Menu.Item name="媒体一覧" color="orange" id="search" active={activeItem === 'search'} onClick={handleItemClick}>
              媒体一覧
              <Label style={{ marginLeft: '.5em' }} size="tiny">
                {listCount}
              </Label>
            </Menu.Item>
          ) : (
            <Menu.Item disabled name="媒体一覧" color="orange" id="search" active={activeItem === 'search'}>
              媒体一覧
              <Label style={{ marginLeft: '.5em' }} size="tiny">
                {listCount}
              </Label>
            </Menu.Item>
          )}
          {props.cart.status === 1 ? (
            <Menu.Item
              name="おすすめ"
              color="orange"
              id="recommend"
              active={activeItem === 'recommend'}
              onClick={handleItemClick}
              disabled={props.cart.mediaType === 'mediaTypeMansionSignage' || props.cart.mediaType === 'mediaTypeLeaflet'}
            >
              おすすめ
              <Label style={{ marginLeft: '.5em' }} size="tiny">
                {recommendCount}
              </Label>
            </Menu.Item>
          ) : (
            <Menu.Item disabled name="おすすめ" color="orange" id="recommend" active={activeItem === 'recommend'}>
              おすすめ
              <Label style={{ marginLeft: '.5em' }} size="tiny">
                {recommendCount}
              </Label>
            </Menu.Item>
          )}
          <Menu.Item name="カート" color="orange" id="cart" active={activeItem === 'cart'} onClick={handleItemClick}>
            <Icon name="cart" />
            {props.cart.status === 1 && 'カート'}
            {props.cart.status === 2 && '注文中'}
            <Label style={{ marginLeft: '.5em' }} size="tiny">
              {cartCount}
            </Label>
          </Menu.Item>
        </Menu>
      </div>
      <div id="showResumeTab">
        {props.cart.status === 1 && (
          <Button color="orange" onClick={() => history.push(`/group/edit/${props.cart.uid}`)} style={{ marginBottom: '1rem' }}>
            編集
          </Button>
        )}
        <CartResumeTab cart={props.cart} />
      </div>
      <div id="showSearchTab">
        <CartSearchTab
          setListedMedias={props.setListedMedias}
          mediaTarget={props.mediaTarget}
          setMediaTarget={props.setMediaTarget}
          setCurrentKey={props.setCurrentKey}
          setOldKey={props.setOldKey}
          currentKey={props.currentKey}
          oldKey={props.oldKey}
          currentKeyInfo={props.currentKeyInfo}
          setCurrentKeyInfo={props.setCurrentKeyInfo}
          oldKeyInfo={props.oldKeyInfo}
          setOldKeyInfo={props.setOldKeyInfo}
          setListCount={setListCount}
          period={props.period}
          cart={props.cart}
          activeItem={activeItem}
          firstTimeFlag={props.firstTimeFlag}
          referrer={props.referrer}
          setFirstTimeFlag={props.setFirstTimeFlag}
          setLoading={props.setLoading}
          sendedBudget={props.sendedBudget}
          mediaList={props.mediaList}
        />
      </div>
      <div id="showRecommendTab">
        {/* billboardのみおすすめタブを表示する */}
        {props.cart.mediaType === 'mediaTypeBillboard' && (
          <CartRecommendTab
            setListedMedias={props.setListedMedias}
            mediaTarget={props.mediaTarget}
            setMediaTarget={props.setMediaTarget}
            setCurrentKey={props.setCurrentKey}
            setOldKey={props.setOldKey}
            currentKey={props.currentKey}
            oldKey={props.oldKey}
            currentKeyInfo={props.currentKeyInfo}
            setCurrentKeyInfo={props.setCurrentKeyInfo}
            oldKeyInfo={props.oldKeyInfo}
            setOldKeyInfo={props.setOldKeyInfo}
            setRecommendCount={setRecommendCount}
            setActiveItem={setActiveItem}
            period={props.period}
            cart={props.cart}
            activeItem={activeItem}
            changeAllMediaInCart={props.changeAllMediaInCart}
            firstTimeFlag={props.firstTimeFlag}
            referrer={props.referrer}
            setFirstTimeFlag={props.setFirstTimeFlag}
            setLoading={props.setLoading}
            sendedBudget={props.sendedBudget}
            mediaList={props.mediaList}
          />
        )}
      </div>
      <div id="showCartTab">
        <CartContentTab
          setListedMedias={props.setListedMedias}
          mediaTarget={props.mediaTarget}
          setMediaTarget={props.setMediaTarget}
          setCurrentKey={props.setCurrentKey}
          setOldKey={props.setOldKey}
          currentKey={props.currentKey}
          oldKey={props.oldKey}
          currentKeyInfo={props.currentKeyInfo}
          setCurrentKeyInfo={props.setCurrentKeyInfo}
          oldKeyInfo={props.oldKeyInfo}
          setOldKeyInfo={props.setOldKeyInfo}
          setCartCount={setCartCount}
          cart={props.cart}
          activeItem={activeItem}
          period={props.period}
          bundleSummary={props.bundleSummary}
          setBundleSummary={props.setBundleSummary}
          mediaList={props.mediaList}
        />
      </div>
    </>
  )
}

export default CartTabs
