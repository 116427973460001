// Library
import React, { useEffect } from 'react'
import Spacer from 'components/atoms/Spacer'
import AppLayoutNotAuthRequired from 'components/organisms/layouts/AppLayoutNotAuthRequired'
// TODO: 同階層にassetsを入れ込む
import screenshot1 from 'assets/pictures/lp_screenshot1.png'
import screenshot2 from 'assets/pictures/lp_screenshot2.png'
import screenshot3 from 'assets/pictures/lp_screenshot3.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faChevronDown, faCheck, faShoppingCart, faChartBar } from '@fortawesome/free-solid-svg-icons'
import merit1 from 'assets/pictures/merit1.png'
import merit2 from 'assets/pictures/merit2.png'
import * as FadeIn from 'components/atoms/FadeIn'
import { mediaExample } from 'constants/MediaExample'
import { useHubspotChat } from 'helpers/useHubspotChat'
import './index.scss'

const Lp = () => {
  useHubspotChat()
  //TODO: METAタグの共通化
  useEffect(() => {
    const title = 'AdVice（アドバイス）'
    const description =
      'サービス認知を圧倒的に高めるOOHマーケティングを効果的に、カンタンに。屋外広告注文プラットフォーム「AdVice（アドバイス）」は、投資対効果ベースで屋外広告を注文できる新しいプラットフォームです。'
    document.title = title
    // title以外のmeta
    const headData = document.head.children

    for (let i = 0; i < headData.length; i++) {
      const nameVal = headData[i].getAttribute('name')
      if (nameVal !== null) {
        if (nameVal.indexOf('description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        // OGP(twitter)の設定
        if (nameVal.indexOf('twitter:title') !== -1) {
          headData[i].setAttribute('content', title)
        }
        if (nameVal.indexOf('twitter:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:title') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:image') !== -1) {
          headData[i].setAttribute('content', process.env.REACT_APP_OGP_URL + '/img_ogp.png')
        }
      }
    }
  })

  // TODO: layoutにやらせる

  return (
    <div className="landingPage__lpHidden">
      <script src="path/to/intersection-observer.js"></script>
      <AppLayoutNotAuthRequired>
        <div className="landingPage__wrapper">
          <section className="landingPage__section1">
            <div className="landingPage__first-view">
              <FadeIn.Up>
                <h2 className="landingPage__first-view__concept">
                  <span>サービス認知を</span>
                  <span>圧倒的に高める</span>
                  <span>ＯＯＨマーケティングを</span>
                  <span>効果的に、</span>
                  <span>カンタンに</span>
                </h2>
                <p className="landingPage__first-view__message">
                  <span>屋外広告を</span>
                  <span>カンタンに</span>
                  <span>注文でき、</span>
                  <span>あなたのブランドや</span>
                  <span>商品の認知を</span>
                  <span>広げることが</span>
                  <span>できる</span>
                  <span>ウェブサービス</span>
                </p>
                <img className="landingPage__first-view__image " src={screenshot1}></img>
                <a className="landingPage__first-view__button " href="/signup">
                  登録はこちら（無料）
                </a>
              </FadeIn.Up>
            </div>
            <div className="landingPage__issue-container">
              <FadeIn.Up>
                <h3 className="landingPage__issue-container__message">
                  <span>デジタルマーケティング</span>
                  <span>だけで</span>
                  <span>見込み顧客に</span>
                  <span>リーチできていますか？</span>
                </h3>
              </FadeIn.Up>
            </div>
          </section>
          <section className="landingPage__section2">
            <FadeIn.Up>
              <div className="landingPage__ooh-merit">
                {/* <img className='ooh-merit__image' src={issueImage}></img> */}
                <h2 className="landingPage__ooh-merit__title">
                  <span>屋外広告は、</span>
                  <span>デジタルマーケティング</span>
                  <span>では</span>
                  <span>リーチできない</span>
                  <span>見込み顧客に</span>
                  <span>リーチ</span>
                  <span>することが</span>可能です。
                </h2>
                <p className="landingPage__ooh-merit__body">
                  ウェブ広告でどれだけ費用をかけても、インターネットを利用しない層にはリーチできないという課題があります。また、近年はアドブロックや広告を排除できるブラウザの登場によって配信されたウェブ広告のうち実際にみられたものは４０％程度にとどまっています。
                </p>
                <br />
                <p className="landingPage__ooh-merit__body">
                  ビルボードやデジタルサイネージ・ポスターといった屋外広告は、ウェブ広告ではリーチできない層にリーチすることができる認知に優れた広告媒体です。また、フリクエンシーや視認時間が長く、無視もできないため、非常に認知効果が高い媒体です。
                </p>
              </div>
            </FadeIn.Up>
            <FadeIn.Up>
              <div className="landingPage__issue-container">
                <h3 className="landingPage__issue-container__message">
                  <span>しかし、</span>
                  <span>屋外広告を</span>
                  <span>注文するには、</span>
                  <span>多くの課題が</span>
                  <span>ありました。</span>
                </h3>
              </div>
            </FadeIn.Up>
          </section>
          <section className="landingPage__section3">
            <FadeIn.Up>
              <div className="landingPage__ooh-problem">
                <h2 className="landingPage__ooh-problem__title">これまでの屋外広告の課題</h2>
                <div className="landingPage__ooh-problem__body">
                  <div className="landingPage__problem-container">
                    <FontAwesomeIcon className="landingPage__problem-container__icon" icon={faCalendarAlt} />
                    <h3 className="landingPage__problem-container__title">計画的な運用が困難</h3>
                    <p className="landingPage__problem-container__detail">
                      WEB広告と異なり、広告予算に対してどれくらいのリーチが見込めるかシミュレーションすることができませんでした。
                    </p>
                  </div>
                  <div className="landingPage__problem-container">
                    <FontAwesomeIcon className="landingPage__problem-container__icon" icon={faShoppingCart} />
                    <h3 className="landingPage__problem-container__title">高い注文の障壁</h3>
                    <p className="landingPage__problem-container__detail">
                      複数の中小代理店を経由する方法が主流で媒体の価格が不透明ななかで媒体を探し、注文する必要がありました。
                    </p>
                  </div>
                  <div className="landingPage__problem-container">
                    <FontAwesomeIcon className="landingPage__problem-container__icon" icon={faChartBar} />
                    <h3 className="landingPage__problem-container__title">効果が測定できない</h3>
                    <p className="landingPage__problem-container__detail">
                      広告がどれだけ見られたか、どれだけの成果につながったかがわからず、広告効果の分析・評価ができませんでした。
                    </p>
                  </div>
                </div>
                <FontAwesomeIcon className="landingPage__ooh-problem__icon" icon={faChevronDown} />
                <div className="landingPage__solution-container">
                  <h3 className="landingPage__solution-container__title">
                    <span>すべて</span>
                    <span className="landingPage__solution-container__title--name1">AdVice</span>
                    <span className="landingPage__solution-container__title--name2">（アドバイス）</span>
                    <span>
                      で解決
                      <FontAwesomeIcon className="landingPage__solution-container__icon" icon={faCheck} />
                    </span>
                  </h3>
                  <p className="landingPage__solution-container__detail">
                    <span>Adviceは、</span>
                    <span>キャンペーン計画・</span>
                    <span>注文・</span>
                    <span>効果分析を</span>
                    <span>「一気通貫」で</span>
                    <span>行うことが</span>
                    <span>できる</span>
                    <span>新しい</span>
                    <span>WEBサービスです。</span>
                  </p>
                </div>
              </div>
            </FadeIn.Up>
            <Spacer />
            <Spacer />
            <Spacer />
            <Spacer />
            <Spacer />
            <Spacer />
            <Spacer />
          </section>
          <section>
            <FadeIn.Up>
              <div className="landingPage__usage1" style={{ backgroundColor: 'white' }}>
                <div className="landingPage__usage-title">
                  <span>AdVice</span>でのカンタン広告運用フロー
                </div>
                <div className="landingPage__usage1__main usage-description">
                  <h2 className="landingPage__usage-description__num">1</h2>
                  <h3 className="landingPage__usage-description__title">
                    <span>予算とターゲットを</span>
                    <span>指定して</span>
                    <span>キャンペーンを計画</span>
                  </h3>
                  <p className="landingPage__usage-description__detail">
                    弊社独自の技術で屋外広告媒体の性年齢別インプレッションを算出しており、WEB広告と同様に予算 ・
                    ターゲットを入力すると、予算内のターゲットへのリーチを最大化するように自動的に最適な広告プランが提案されます。
                  </p>
                </div>
                <div className="landingPage__usage1__image usage__right">
                  <img src={screenshot2}></img>
                </div>
              </div>
              <div className="landingPage__usage2" style={{ backgroundColor: 'white' }}>
                <div className="landingPage__usage2__main usage-description">
                  <h2 className="landingPage__usage-description__num">2</h2>
                  <h3 className="landingPage__usage-description__title">
                    <span>広告媒体を</span>
                    <span>カートに</span>
                    <span>入れて注文</span>
                  </h3>
                  <p className="landingPage__usage-description__detail">
                    媒体のインプレッションを確認しながら提案されたプランをカスタマイズ、最後にカートに入れてお支払いをするだけで広告の注文が行えます。
                  </p>
                </div>
                <div className="landingPage__usage2__image usage__right">
                  <img src={screenshot1}></img>
                </div>
              </div>
              <div className="landingPage__usage3" style={{ backgroundColor: 'white' }}>
                <div className="landingPage__usage3__main usage-description">
                  <h2 className="landingPage__usage-description__num">3</h2>
                  <h3 className="landingPage__usage-description__title--white">
                    <span>キャンペーンの効果を</span>
                    <span>確認・評価</span>
                  </h3>
                  <p className="landingPage__usage-description__detail--white">
                    実施した広告キャンペーンの成果をダッシュボードから確認することができます。GoogleAnalyticsなど他サービスと連携して細かい効果分析をする機能も現在開発中です。
                  </p>
                </div>
                <div className="landingPage__usage3__image">
                  <img src={screenshot3}></img>
                </div>
              </div>
            </FadeIn.Up>
            <Spacer />
            <Spacer />
            <Spacer />
          </section>
          <section>
            <FadeIn.Up>
              <div className="landingPage__service-appeal">
                <h2 className="landingPage__service-appeal__title">
                  <span>AdVice</span>は、屋外広告注文で圧倒的に便利なツールです
                </h2>
                <div className="landingPage__service-appeal__body">
                  <div className="landingPage__appeal-container">
                    <div className="landingPage__appeal-container__title">
                      <span>①</span>非常に高い費用対効果
                    </div>
                    <div className="landingPage__appeal-container__detail">
                      マージンを挟まないことに加え、最適な広告プランの提案システムによって従来の代理店の同じ予算で、ターゲットに対して2 ~
                      3倍リーチするキャンペーンを計画できます。
                    </div>
                    <div className="landingPage__appeal-container__image">
                      <img src={merit1}></img>
                    </div>
                  </div>
                  <div className="landingPage__appeal-container">
                    <div className="landingPage__appeal-container__title">
                      <span>②</span>複雑な注文を簡略化
                    </div>
                    <div className="landingPage__appeal-container__detail">
                      オンラインで完結するため、ヒアリングや媒体の選択を行う従来の代理店に比べ、広告注文までの時間を最大1/100 程度に短縮することが可能です。
                    </div>
                    <div className="landingPage__appeal-container__image">
                      <img src={merit2}></img>
                    </div>
                  </div>
                </div>
              </div>
            </FadeIn.Up>
          </section>
          <section>
            <div className="landingPage__media-list">
              <h2 className="landingPage__media-list__title">注文可能な媒体</h2>
              <div className="landingPage__media-list__body slider">
                <div className="landingPage__slide-track">
                  {mediaExample.map((medium) => {
                    return (
                      <div className="landingPage__medium-container slide" key={medium.name}>
                        <img className="landingPage__medium-container__image" src={medium.image} />
                        <h3 className="landingPage__medium-container__title">{medium.name}</h3>
                        <div className="landingPage__medium-container__type">ビルボード</div>
                        <p className="landingPage__medium-container__detail">
                          <span className="landingPage__medium-container__category">サイズ</span>
                          {medium.size}
                        </p>
                        <p className="landingPage__medium-container__detail">
                          <span className="landingPage__medium-container__category">CPM</span>
                          {medium.cpm}
                        </p>
                        <p className="landingPage__medium-container__detail">
                          <span className="landingPage__medium-container__category">コスト</span>
                          {medium.cost}
                        </p>
                      </div>
                    )
                  })}
                </div>
              </div>
              <p className="landingPage__media-list__message">23区を中心とした200以上のビルボード・デジタルサイネージ・ポスターから注文することができます。</p>
              <div className="landingPage__media-list__btn-wrapper">
                <a className="landingPage__media-list__button" href="/mediatop">
                  もっと見る
                </a>
              </div>
            </div>
          </section>
          <section>
            <FadeIn.Up>
              <div className="landingPage__question">
                <h2 className="landingPage__question__title">Q&A</h2>
                <div className="landingPage__question__body">
                  <div className="landingPage__question-container">
                    <p className="landingPage__question-container__question">いくらから広告を出稿できますか？利用料はかかりますか？</p>
                    <p className="landingPage__question-container__answer">
                      利用料はかかりません。注文した媒体の掲載費・施工費のみ費用がかかります。広告媒体は安くて月５万円から出稿できます。
                    </p>
                  </div>
                  <div className="landingPage__question-container">
                    <p className="landingPage__question-container__question">AdViceを利用した場合の費用対効果はどのくらいですか？</p>
                    <p className="landingPage__question-container__answer">
                      1000インプレッションあたりの費用であるCPMは、100～1000円程度で、エリアなどによって変わります。
                    </p>
                  </div>
                  <div className="landingPage__question-container">
                    <p className="landingPage__question-container__question">デザインの制作や掲載物を用意する必要はありますか？</p>
                    <p className="landingPage__question-container__answer">
                      基本的には広告主様に用意していただいていますが、依頼していただくことも可能です。問い合わせから相談してください。
                    </p>
                  </div>
                </div>
              </div>
            </FadeIn.Up>
          </section>
          <section>
            <div className="landingPage__cta-container">
              <h3 className="landingPage__cta-container__title">
                <span className="landingPage__cta-container__title--force">Advice</span>で、<span>屋外広告を</span>
                <span>カンタンに</span>
                <span>注文しませんか？</span>
              </h3>
              <a className="landingPage__cta-container__button" href="/signup">
                登録はこちら（無料）
              </a>
            </div>
          </section>
        </div>
      </AppLayoutNotAuthRequired>
    </div>
  )
}

export default Lp
