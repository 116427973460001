import React, { useCallback, useEffect, useState } from 'react'
import { Table, TableBody, TableHeader, TableRow, TableCell, TableHeaderCell, Input, InputOnChangeData, Button, DropdownProps } from 'semantic-ui-react'
import { createPackage, initialPackageValue, isPackage, MediaListElem, Package, PACKAGE_FIELDS } from './helper'
import { db } from 'FirebaseConfig'
import EditablePackageRowContent from 'components/organisms/admin/EditablePackageRowContent'
import MediaDropdown from 'components/organisms/admin/MediaDropdown'
import { useMedias } from 'providers/Medias'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'

const PackageTable = () => {
  const [packageInput, setPackageInput] = useState<Package>(initialPackageValue)
  const [packages, setPackages] = useState<Package[]>([])
  const medias = useMedias()

  useEffect(() => {
    db.collection('packages').onSnapshot((snapshot) => {
      const packageData = snapshot.docs.map((doc) => doc.data()).filter((packageData): packageData is Package => isPackage(packageData))
      setPackages(packageData)
    })
  }, [])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.SyntheticEvent<HTMLElement, Event>, { name, value }: InputOnChangeData | DropdownProps) => {
      setPackageInput((prevState) => {
        if (name === PACKAGE_FIELDS.MEDIA_LIST) {
          const mediaList: MediaListElem[] = (value as string[]).map((id) => {
            const media = medias.find((media) => media.uid === id)
            if (media) {
              const purchaceUnit = getColInfoFromTypeName(media.mediaType).purchasePeriodUnit
              const unitFieldKey = purchaceUnit === 'month' ? 'months' : 'weeks'
              return {
                id: id,
                mediaType: media.mediaType,
                [unitFieldKey]: 1,
              }
            } else {
              throw new Error('finding media failed...')
            }
          })
          return { ...prevState, [PACKAGE_FIELDS.MEDIA_LIST]: mediaList }
        }
        return { ...prevState, [name]: value }
      })
    },
    [medias]
  )

  const createPackageHandler = useCallback(async () => {
    await createPackage(packageInput.id, packageInput)
  }, [packageInput])

  return (
    <Table celled>
      <TableHeader>
        <TableRow>
          {Object.values(PACKAGE_FIELDS).map((key) => {
            return <TableHeaderCell key={key}>{key}</TableHeaderCell>
          })}
          <TableHeaderCell>追加ボタン</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          {Object.values(PACKAGE_FIELDS)
            .filter((key) => key !== PACKAGE_FIELDS.MEDIA_LIST)
            .map((key) => {
              return (
                <TableCell key={key} width={1}>
                  <Input name={key} onChange={handleChange} />
                </TableCell>
              )
            })}
          <TableCell width={4}>
            <MediaDropdown value={packageInput.mediaList.map((media) => media.id)} onChange={handleChange} />
          </TableCell>
          <TableCell width={1}>
            <Button onClick={createPackageHandler}>追加</Button>
          </TableCell>
        </TableRow>
      </TableBody>
      <TableHeader>
        <TableRow>
          {Object.values(PACKAGE_FIELDS).map((key) => {
            return <TableHeaderCell key={key}>{key}</TableHeaderCell>
          })}
          <TableHeaderCell>変更ボタン</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {packages.map((packageData) => {
          return <EditablePackageRowContent packageData={packageData} key={packageData.id} />
        })}
      </TableBody>
    </Table>
  )
}

export default PackageTable
