export const isAdmin = (users: { docId: string; isAdmin: boolean }[], userId: string) => {
  let admin = false
  if (users && userId) {
    const ref = users.find((u) => u.docId === userId)
    if (ref) {
      if (ref.isAdmin === true) {
        admin = true
      } else {
        admin = false
      }
    }
  }
  return admin
}
