import React, { useState, useEffect } from 'react'
import { Table, Input, Button } from 'semantic-ui-react'
import AppLayoutAdmin from 'components/organisms/layouts/AppLayoutAdmin'
import { db } from 'FirebaseConfig'
import { parseData, checkDataContent } from 'components/pages/Admin/AddMedia/AddMediaPage/helper'
import { columnDtypes, validationCallBacks } from './userDef'
import './index.css'

const editableCol = ['marginRate']

// TODO: firebase APIを切り出し
const AdminOwners = () => {
  const [owners, setOwners] = useState([])
  const [header, setHeader] = useState([])
  useEffect(() => {
    const unsubscribe = db
      .collection('user')
      .where('isOwner', '==', true)
      .onSnapshot((querySnapshot) => {
        const tmpDataArray = []
        const tmpKeys = {}
        for (const doc of querySnapshot.docs) {
          const tmpData = doc.data()
          tmpDataArray.push(tmpData)
          for (const key of Object.keys(tmpData)) {
            if (!tmpKeys[key]) {
              tmpKeys[key] = 1
            }
          }
        }
        setOwners(tmpDataArray)
        //初回以降はheaderを更新しない
        if (!header.length) {
          setHeader(Object.keys(tmpKeys))
        }
      })
    return unsubscribe
  }, [])

  const onChangeHandler = (idx, key, e) => {
    const parsedValue = parseData(e.target.value, columnDtypes[key])
    if ((typeof parsedValue === 'number' && isNaN(parsedValue)) || !checkDataContent(parsedValue, key, validationCallBacks)) {
      if (window) {
        window.alert('カラム: ' + key + '　に不正な値: ' + parsedValue + 'が入っています ')
      }
      e.preventDefault()
    } else {
      setOwners([...owners.slice(0, idx), { ...owners[idx], [key]: parsedValue }, ...owners.slice(idx + 1)])
    }
  }

  const onClickUpdate = async (idx) => {
    try {
      await db.collection('user').doc(owners[idx].uid).update(owners[idx])
      if (window) {
        alert('データが更新されました')
      }
    } catch (error) {
      if (window) {
        console.error(error)
        alert('データの書き換えに失敗')
      }
    }
  }

  const HeaderRow = (header) => {
    return (
      <Table.Row>
        {header.map((colnName) => (
          <Table.HeaderCell key={colnName}>{colnName}</Table.HeaderCell>
        ))}
        <Table.HeaderCell>変更ボタン</Table.HeaderCell>
      </Table.Row>
    )
  }

  const Row = (owner, rowIdx, header) => {
    const columns = Array(header.length)
    for (const [idx, key] of Object.entries(header)) {
      if (editableCol.includes(key)) {
        columns[idx] = (
          <Table.Cell key={key}>{<Input onChange={(e) => onChangeHandler(rowIdx, key, e)} value={String(owner[key])} type="number" step="0.1" />}</Table.Cell>
        )
      } else {
        columns[idx] = <Table.Cell key={key}>{owner[key] ? String(owner[key]) : null}</Table.Cell>
      }
    }
    return (
      <Table.Row>
        {columns}
        <Table.Cell>
          <Button
            onClick={() => {
              onClickUpdate(rowIdx)
            }}
          >
            変更
          </Button>
        </Table.Cell>
      </Table.Row>
    )
  }

  return (
    <AppLayoutAdmin>
      <div>
        <div className="owners__horizontal-scroll-container">
          <Table celled>
            <Table.Header>{HeaderRow(header)}</Table.Header>
            <Table.Body>{owners.map((owner, idx) => Row(owner, idx, header))}</Table.Body>
          </Table>
        </div>
      </div>
    </AppLayoutAdmin>
  )
}

export default AdminOwners
