import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'providers/Auth'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import { Container, Header, Grid, Button, Segment } from 'semantic-ui-react'
import Spacer from 'components/atoms/Spacer'
import AppLayoutOwner from 'components/organisms/layouts/AppLayoutOwner'
import { MediasOwnAllContext } from 'providers/MediasOwnAll'
import DeleteMediaForm from 'components/organisms/owner/DeleteMediaForm'
import firebase, { db } from 'FirebaseConfig'
import { shouldSignin } from 'helpers/shouldSignin'

const DeleteMedia = (props) => {
  const { currentUser } = useContext(AuthContext)
  const history = useHistory()
  const { mediasOwnAll } = useContext(MediasOwnAllContext)
  const [media, setMedia] = useState('')
  const [mediaDeleted, setMediaDeleted] = useState(false)

  // TODO: currentUserの有無のチェックをlayoutに移動
  useEffect(() => {
    if (shouldSignin(currentUser)) {
      history.push(`${paths.signinowner}`)
    }
  })

  useEffect(() => {
    if (props.match.params.uid) {
      let ref = mediasOwnAll.find((media) => media.uid === props.match.params.uid)
      if (ref) {
        setMedia(ref)
        if (ref.isDeleted) {
          setMediaDeleted(true)
        } else {
          setMediaDeleted(false)
        }
      } else {
        history.push(`${paths.nopagefound}`)
      }
    }
  }, [props, currentUser, history, mediasOwnAll])

  const clickDelete = () => {
    console.log('click delete')
    let today = new Date(Date.now())
    db.collection('media')
      .doc(media.uid)
      .update({
        isDeleted: true,
        deletedAt: firebase.firestore.Timestamp.fromDate(today),
      })
      .then(() => {
        history.push(`/owner/deletemedia/${media.uid}`)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <AppLayoutOwner>
        <Spacer />
        <Container>
          <Segment>
            <Header as="h2">媒体の削除</Header>
          </Segment>
          <br />
          <Segment>
            <Container text>
              <Spacer />
              {mediaDeleted ? (
                <>
                  <Spacer />
                  <Header as="h3" textAlign="center">
                    Thnak You!
                  </Header>
                  <Header as="h3" textAlign="center">
                    お客様の媒体は正常に削除されました。
                  </Header>
                  <Spacer />
                  <Grid centered columns={2}>
                    <div>
                      <Grid.Column>
                        <Button color="grey" onClick={() => history.push(`${paths.dashboardowner}`)}>
                          保有媒体一覧
                        </Button>
                      </Grid.Column>
                    </div>
                    <div>
                      <Grid.Column>
                        <Button color="orange" onClick={() => history.push(`${paths.menuowner}`)}>
                          ホーム画面に戻る
                        </Button>
                      </Grid.Column>
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                  {media && (
                    <>
                      <div className="deleteMedia__mediaDetailColumn">
                        <DeleteMediaForm media={media} />
                      </div>
                      <Spacer />
                      <Header as="h3" textAlign="center">
                        本当に削除しますか？
                      </Header>
                      <Spacer />
                      <Grid centered columns={2}>
                        <div>
                          <Grid.Column>
                            <Button color="grey" onClick={() => history.push(`${paths.dashboardowner}`)}>
                              戻る
                            </Button>
                          </Grid.Column>
                        </div>
                        <div>
                          <Grid.Column>
                            <Button color="orange" onClick={clickDelete}>
                              削除
                            </Button>
                          </Grid.Column>
                        </div>
                      </Grid>
                    </>
                  )}
                </>
              )}
              <Spacer />
            </Container>
          </Segment>
        </Container>
      </AppLayoutOwner>
    </>
  )
}

export default DeleteMedia
