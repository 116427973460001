import React, { useState } from 'react'
import 'semantic-ui-css/semantic.min.css'
import { Icon } from 'semantic-ui-react'
import noImage from 'assets/pictures/no_image.jpg'
import './index.css'

/**
 * 渡された画像を表示するスライダーのコンポーネント
 */
export const ImageSlider = (props) => {
  // eslint-disable-next-line
  const [currPage, setPage] = useState(0) // 現在表示されている画像のインデックス
  const images = props.images // これは画像の入った配列
  const numImage = images.length
  // 画像を右にスクロールする
  const onClickRight = () => {
    if (currPage + 1 < numImage) {
      setPage(currPage + 1)
    }
  }
  // 画像を左にスクロールする
  const onClickLeft = () => {
    if (currPage - 1 >= 0) {
      setPage(currPage - 1)
    }
  }
  // propsで画像が渡されなかった場合にはnoImageを表示する
  return (
    <div className="imageSlider__container">
      {numImage > 0 ? <img src={images[currPage]} className="imageSlider__slides" /> : <img src={noImage} className="imageSlider__slides" />}
      <div className="imageSlider__arrow-wrap">
        <Icon name="chevron circle left" onClick={onClickLeft} size="huge" /> <Icon name="chevron circle right" size="huge" onClick={onClickRight} />
      </div>
    </div>
  )
}
