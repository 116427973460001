import Loading from 'components/atoms/Loading'
import AppLayoutNotAuthRequired from 'components/organisms/layouts/AppLayoutNotAuthRequired'
import { usePackages } from 'providers/Packages'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Header, Segment } from 'semantic-ui-react'
import PackageBreadcrump from 'components/organisms/breadcrumb/PackageBreadcrump'
import PackageSummary from 'components/organisms/package/PackageSummary'
import PackageSubtotalList from 'components/organisms/package/PackageSubtotalList'
import './index.css'

const PackagePage = () => {
  const { packageId } = useParams<{ packageId: string }>()
  const packages = usePackages()
  const mediaPackage = useMemo(() => {
    return packages.find((mediaPackage) => mediaPackage.id === packageId) || null
  }, [packageId, packages])

  return (
    <AppLayoutNotAuthRequired>
      {mediaPackage ? (
        <div className="appLayoutGroup__packageLayoutContent">
          <PackageBreadcrump packageName={mediaPackage.name} />
          <Header as="h2">{mediaPackage.name}</Header>
          <Grid>
            <Grid.Column width={11}>
              <PackageSubtotalList mediaPackage={mediaPackage} />
            </Grid.Column>
            <Grid.Column width={5}>
              <Segment>
                <PackageSummary mediaPackage={mediaPackage} />
              </Segment>
            </Grid.Column>
          </Grid>
        </div>
      ) : (
        <Loading />
      )}
    </AppLayoutNotAuthRequired>
  )
}

export default PackagePage
