import React from 'react'
import { Header, Form, Button, Modal } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import TermsOfService from 'components/organisms/policy/TermsOfService'
import PrivacyPolicy from 'components/organisms/policy/PrivacyPolicy'
import './index.css'

// AddCampaign と EditCampaign の両方で使えるフォームにする
const SignUpForm = (props) => {
  const history = useHistory()
  const { handleSubmit, register, errors } = useForm()

  return (
    <>
      <Header as="h2">{props.header}</Header>
      <div className="signUpForm__loginFormNote">{props.note}</div>
      <br />
      <Form onSubmit={handleSubmit(props.clickSignup)}>
        <p className="signUpForm__signinFormLabel">
          <b>会社名・屋号</b>
        </p>
        &nbsp;&nbsp;
        <p className="signUpForm__requiredMark">必須</p>
        <input
          name="companyName"
          id="companyName"
          placeholder="例：株式会社パルダッド"
          onChange={props.handleChange}
          ref={register({
            required: 'Required',
          })}
        />
        <div>{errors.companyName ? errors.companyName.message : <>&nbsp;</>}</div>
        <p className="signUpForm__signinFormLabel">
          <b>住所</b>
        </p>
        &nbsp;&nbsp;
        <p className="signUpForm__requiredMark">必須</p>
        <input
          name="address"
          id="address"
          placeholder="例：東京都渋谷区111-11"
          onChange={props.handleChange}
          ref={register({
            required: 'Required',
          })}
        />
        <div>{errors.address ? errors.address.message : <>&nbsp;</>}</div>
        <p className="signUpForm__signinFormLabel">
          <b>担当者名</b>
        </p>
        &nbsp;&nbsp;
        <p className="signUpForm__requiredMark">必須</p>
        <input
          name="fullName"
          id="fullName"
          placeholder="例：看板　太郎"
          onChange={props.handleChange}
          ref={register({
            required: 'Required',
          })}
        />
        <div>{errors.fullName ? errors.fullName.message : <>&nbsp;</>}</div>
        <p className="signUpForm__signinFormLabel">
          <b>電話番号</b>
        </p>
        &nbsp;&nbsp;
        <p className="signUpForm__requiredMark">必須</p>
        <input
          name="tel"
          id="tel"
          placeholder="例：00-1234-1234"
          onChange={props.handleChange}
          ref={register({
            required: 'Required',
          })}
        />
        <div>{errors.tel ? errors.tel.message : <>&nbsp;</>}</div>
        <p className="signUpForm__signinFormLabel">
          <b>メールアドレス</b>
        </p>
        &nbsp;&nbsp;
        <p className="signUpForm__requiredMark">必須</p>
        <input
          name="email"
          id="email"
          placeholder="name@domain.com"
          onChange={props.handleChange}
          ref={register({
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'invalid email address',
            },
          })}
        />
        <div>{errors.email ? errors.email.message : <>&nbsp;</>}</div>
        <p className="signUpForm__signinFormLabel">
          <b>パスワード</b>
        </p>
        &nbsp;&nbsp;
        <p className="signUpForm__requiredMark">必須</p>
        <input
          type="password"
          name="password"
          id="password"
          placeholder="password"
          onChange={props.handleChange}
          ref={register({
            required: 'Required',
            minLength: {
              value: 6,
              message: 'password needs min 6 caractors',
            },
          })}
        />
        <div>{errors.password ? errors.password.message : <>&nbsp;</>}</div>
        <p className="signUpForm__signinFormLabel">
          <b>パスワード(確認)</b>
        </p>
        &nbsp;&nbsp;
        <p className="signUpForm__requiredMark">必須</p>
        <input
          type="password"
          name="passwordConfirm"
          id="passwordConfirm"
          placeholder="password"
          onChange={props.handleChange}
          ref={register({
            required: 'Required',
            minLength: {
              value: 6,
              message: 'password needs min 6 caractors',
            },
          })}
        />
        <div>{errors.passwordConfirm ? errors.passwordConfirm.message : <>&nbsp;</>}</div>
        <Form.Checkbox
          className="signUpForm__signinFormCheckbox"
          type="checkbox"
          name="policy"
          id="policy"
          onChange={props.handleChange}
          // control={Checkbox}
          ref={register({
            validate: (value) => value === 'true',
          })}
        />
        &nbsp;&nbsp;
        <Modal trigger={<Link className="signUpForm__signInFormLink">利用規約</Link>} dimmer={'inverted'}>
          <Modal.Content scrolling>
            <Modal.Description>
              <Header>利用規約</Header>
              <TermsOfService />
            </Modal.Description>
          </Modal.Content>
        </Modal>
        と
        <Modal trigger={<Link className="signUpForm__signInFormLink">プライバシーポリシー</Link>} dimmer={'inverted'}>
          <Modal.Content scrolling>
            <Modal.Description>
              <Header>プライバシーポリシー</Header>
              <PrivacyPolicy />
            </Modal.Description>
          </Modal.Content>
        </Modal>
        に同意して
        <div>
          <>&nbsp;</>
        </div>
        <Button color="orange" type="submit" fluid>
          新規会員登録
        </Button>
        <br />
        {props.owner ? (
          <>
            <Button fluid onClick={() => history.push(`${paths.signinowner}`)}>
              ログインはこちら
            </Button>
          </>
        ) : (
          <>
            <Button fluid onClick={() => history.push(`${paths.signin}`)}>
              ログインはこちら
            </Button>
          </>
        )}
        <br />
        <p>※ご入力いただいたメールアドレス宛に、新着情報などのメールをお送りする場合があります。</p>
      </Form>
    </>
  )
}

export default SignUpForm
