import React from 'react'
import { Table } from 'semantic-ui-react'
import ResidentPropertyOptions from 'constants/ResidentsPropertyOptions'

type TargetResidentsProperty =
  | {
      key: string
      text: string
      value: string
    }
  | undefined //find関数で見つからない場合

const TargetResidentsPropertyCell = (props) => {
  let jsxElms: string[] = []
  //valueからtextに変換する
  if (props.residentsProperty && props.residentsProperty.length && props.residentsProperty.length !== ResidentPropertyOptions.length) {
    jsxElms = props.residentsProperty.map((key) => {
      const targetResidentsProperty: TargetResidentsProperty = ResidentPropertyOptions.find((elem) => elem.value === key)
      if (targetResidentsProperty) {
        return targetResidentsProperty.text
      } else {
        return '全て'
      }
    })
  } else {
    jsxElms.push('全て')
  }
  const result: string = jsxElms.join('、')
  return <Table.Cell>{result}</Table.Cell>
}

export default TargetResidentsPropertyCell
