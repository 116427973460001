import { useMedias } from 'providers/Medias'
import React, { useMemo } from 'react'
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react'
import { PACKAGE_FIELDS } from '../PackageTable/helper'
import { getPeriodUnit } from 'helpers/mediaType/mediaTypeBillboard'
import { isJackChild } from '../../../../constants/mediaData'

type Props = {
  value?: string[]
  onChange?: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void
}

const MediaDropdown = ({ value, onChange }: Props) => {
  const mediaList = useMedias()
  //年額媒体を除外
  const filteredMediaList = useMemo(() => {
    return mediaList.filter((media) => {
      const periodUnit = media.mediaType === 'mediaTypeBillboard' && !isJackChild(media) && getPeriodUnit(media)
      if (periodUnit) {
        return periodUnit !== 'FixedOnlyYearly'
      }
      return true
    })
  }, [mediaList])
  const mediaOptions: DropdownItemProps[] = useMemo(() => {
    return filteredMediaList.map((medium) => {
      return {
        label: medium.uid,
        text: `${medium.uid}: ${medium.name}`,
        value: medium.uid,
      }
    })
  }, [filteredMediaList])

  return <Dropdown name={PACKAGE_FIELDS.MEDIA_LIST} value={value} selection multiple search fluid options={mediaOptions} onChange={onChange} />
}

export default MediaDropdown
