import Loading from 'components/atoms/Loading'
import AppLayoutAdmin from 'components/organisms/layouts/AppLayoutAdmin'

import React, { useEffect, useState } from 'react'

import 'firebase/firestore'
import { getList } from 'components/pages/Admin/MediaUnderConsideration/MediaUnderConsiderationPage/helper'
//import { layer } from "@fortawesome/fontawesome-svg-core";
//import { db } from 'FirebaseConfig'
//import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
//import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
/*TODO:downloaduserはサブコレクションで一括で呼び出すようにする
  useMemoやuseConstextを使って、呼び出し方を変える
*/

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '0',
    borderRadius: '0',
    boxShadow: 'none',
  },
}))

const AdminMediaUnderConsideration = () => {
  const classes = useStyles()
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let unmounted = false //getlistよぶ -> listを更新
    ;(async () => {
      const list = await getList()
      if (!unmounted) {
        setList(list)
        //isLoadingをfalseにする
        setIsLoading(false)
      }
    })()
    return () => {
      unmounted = true
    }
  }, [])

  return (
    <div>
      <AppLayoutAdmin>
        {isLoading && <Loading />}
        <TableContainer className={classes.root} component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '15%' }}>媒体名</TableCell>
                <TableCell style={{ width: '10%' }} align="right">
                  被検討数
                </TableCell>
                <TableCell style={{ width: '15%' }} align="right">
                  保有企業
                </TableCell>
                <TableCell style={{ width: '60%' }} align="center">
                  検討企業
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row) => (
                <TableRow key={row.mediaInfo.mediaName}>
                  <TableCell component="th" scope="row">
                    {row.mediaInfo.mediaName}
                  </TableCell>
                  <TableCell align="right">{row.popularity.all}</TableCell>
                  <TableCell align="right">{row.mediaInfo.mediaOwnerCopmanyName}</TableCell>
                  <TableCell align="right" className={classes.root}>
                    <div>
                      <Accordion className={classes.root}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                          <Typography className={classes.root} align="center">
                            詳細
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.root}>
                          <Typography className={classes.root}>
                            <TableContainer className={classes.root} component={Paper}>
                              <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="right">企業</TableCell>
                                    <TableCell align="right">氏名</TableCell>
                                    <TableCell align="right">検討方法</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {row.downloadUserInfo.map((elem) => (
                                    <TableRow key={elem.downloadUserCompanyName}>
                                      <TableCell align="right">{elem.downloadUserCompanyName}</TableCell>
                                      <TableCell align="right">{elem.downloadUserName}</TableCell>
                                      <TableCell align="right">PDF</TableCell>
                                    </TableRow>
                                  ))}
                                  {row.cartOwnerInfo.map((elem) => (
                                    <TableRow key={elem.cartOwnerName}>
                                      <TableCell align="right">{elem.cartOwnerCompanyName}</TableCell>
                                      <TableCell align="right">{elem.cartOwnerName}</TableCell>
                                      <TableCell align="right">カート</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AppLayoutAdmin>
    </div>
  )
}
export default AdminMediaUnderConsideration
