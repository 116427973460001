import React, { useContext, useEffect } from 'react'
import { AuthContext } from 'providers/Auth'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import { Container, Header, Segment } from 'semantic-ui-react'
import Spacer from 'components/atoms/Spacer'
import AppLayoutOwner from 'components/organisms/layouts/AppLayoutOwner'
import AddMediaForm from 'components/organisms/owner/AddMediaForm'
import Breadcrump from 'components/organisms/breadcrumb/AddMediaBreadcrump'
import { shouldSignin } from 'helpers/shouldSignin'

const AddMedia = () => {
  const { currentUser } = useContext(AuthContext)
  const history = useHistory()

  // TODO: currentUserの有無のチェックをlayoutに移動
  useEffect(() => {
    if (shouldSignin(currentUser)) {
      history.push(`${paths.signinowner}`)
    }
  })

  return (
    <>
      <AppLayoutOwner>
        <Container>
          <Spacer />
          <Breadcrump />
          <Segment>
            <Header as="h2">媒体情報の登録</Header>
          </Segment>
          {/* <Button
          color='grey'
          onClick={() => history.push(`${paths.addmedialist}`)}
        >
          登録媒体一覧(仮)
        </Button> */}
          <AddMediaForm />
        </Container>
      </AppLayoutOwner>
    </>
  )
}

export default AddMedia
