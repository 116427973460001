import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'

export const openMediaPageLink = (media: AdviceMediaDataType) => {
  let mediaUid
  if (isJackChild(media)) {
    mediaUid = media.parentUid
  } else {
    mediaUid = media.uid
  }
  const win = window.open(`/media/${mediaUid}`, '_blank')
  win && win.focus()
}
