import * as firebase from 'firebase/app'
import { calcRange } from 'helpers/admin/utils'
import { getVisibility, getJinryu, updateDBHandler, updateDBRelatedToTag, updateDBRelatedToLine, updateDBRelatedToHighway } from 'helpers/admin/apiHandlers'
import calcRatio from 'helpers/admin/calcRatio'
import { tagType } from 'helpers/admin/apiHandlers'

/**
 * ユーザ指定のrangeを使うかどうかをもとに、新たなrangeを計算する関数
 * @param {object} mediaInfo
 */
export const getNewRange = (useSuperRange: boolean, superRange: number, horizontalWidth: number, verticalWidth: number) => {
  if (useSuperRange) {
    return superRange
  } else {
    return calcRange(horizontalWidth, verticalWidth)
  }
}
type mediaInfoType = {
  name: string
  address: string
  hasIllumination: boolean
  note: string
  summary: string
  height: number
  verticalWidth: number
  horizontalWidth: number
  direction: number
  yearlyCost: number
  monthlyCost: number
  constructionCost: number
  recoveryCost: number
  mediaType: string
  mediaImageObjs: mediaImageType[]
  isPrivate: boolean
  tempCirculation: number
  tempVisibility: number
  useTempVisibility: boolean
  useTempCirculation: boolean
  tags: tagType[]
  numSurrBoards: number
  range: number
  weeklyCost: number
  numOfHouses: number
  hasAudio: boolean
  mansionType: string
  majorResidentsProperty: string
  lines: any
}

export const uploadLeaflet = async (mediaID, buildingPos, mediaInfo) => {
  const { maleAgeRatio, femaleAgeRatio, ageRatio } = await calcRatio({ latitude: buildingPos.lat, longitude: buildingPos.lng })
  await updateDBRelatedToTag(mediaID, mediaInfo.tags)
  await updateDBRelatedToLine(mediaID, mediaInfo.lines)
  await updateDBHandler(mediaID, {
    address: mediaInfo.address,
    note: mediaInfo.note,
    summary: mediaInfo.summary,
    latitude: buildingPos.lat,
    longitude: buildingPos.lng,
    name: mediaInfo.name,
    weeklyCost: mediaInfo.weeklyCost,
    mediaType: mediaInfo.mediaType,
    uid: mediaID,
    mediaImagePaths: mediaInfo.mediaImageObjs.map((objs) => objs.name),
    isPrivate: mediaInfo.isPrivate,
    distributionCost: mediaInfo.distributionCost,
    industry: mediaInfo.industry,
    storeName: mediaInfo.storeName,
    maleAgeRatio: maleAgeRatio,
    femaleAgeRatio: femaleAgeRatio,
    ageRatio: ageRatio,
  }) // firestoreのmediaを更新
}

export const uploadMansionSignage = async (mediaID, buildingPos, mediaInfo: mediaInfoType) => {
  const { maleAgeRatio, femaleAgeRatio, ageRatio } = await calcRatio({ latitude: buildingPos.lat, longitude: buildingPos.lng })
  await updateDBRelatedToTag(mediaID, mediaInfo.tags)
  await updateDBRelatedToLine(mediaID, mediaInfo.lines)
  await updateDBHandler(mediaID, {
    address: mediaInfo.address,
    note: mediaInfo.note,
    summary: mediaInfo.summary,
    latitude: buildingPos.lat,
    longitude: buildingPos.lng,
    verticalWidth: mediaInfo.verticalWidth,
    horizontalWidth: mediaInfo.horizontalWidth,
    name: mediaInfo.name,
    weeklyCost: mediaInfo.weeklyCost,
    mediaType: mediaInfo.mediaType,
    uid: mediaID,
    mediaImagePaths: mediaInfo.mediaImageObjs.map((objs) => objs.name),
    isPrivate: mediaInfo.isPrivate,
    numOfHouses: mediaInfo.numOfHouses,
    hasAudio: mediaInfo.hasAudio,
    mansionType: mediaInfo.mansionType,
    majorResidentsProperty: mediaInfo.majorResidentsProperty,
    maleAgeRatio: maleAgeRatio,
    femaleAgeRatio: femaleAgeRatio,
    ageRatio: ageRatio,
  }) // firestoreのmediaを更新
}

export const uploadVision = async (mediaID, buildingPos, mediaInfo, visiblePoints) => {
  const { maleAgeRatio, femaleAgeRatio, ageRatio } = await calcRatio({ latitude: buildingPos.lat, longitude: buildingPos.lng })
  const tempVisibility = mediaInfo.useTempVisibility ? mediaInfo.tempVisibility : null
  const tempCirculation = mediaInfo.useTempCirculation ? mediaInfo.tempCirculation : null
  await updateDBRelatedToTag(mediaID, mediaInfo.tags)
  await updateDBRelatedToLine(mediaID, mediaInfo.lines)
  //TODO: CSV入力
  await updateDBHandler(mediaID, {
    address: mediaInfo.address,
    direction: mediaInfo.direction,
    note: mediaInfo.note,
    summary: mediaInfo.summary,
    latitude: buildingPos.lat,
    longitude: buildingPos.lng,
    height: mediaInfo.height,
    verticalWidth: mediaInfo.verticalWidth,
    horizontalWidth: mediaInfo.horizontalWidth,
    name: mediaInfo.name,
    weeklyCost: mediaInfo.weeklyCost,
    constructionCost: mediaInfo.constructionCost,
    recoveryCost: mediaInfo.recoveryCost,
    mediaType: mediaInfo.mediaType,
    uid: mediaID,
    mediaImagePaths: mediaInfo.mediaImageObjs.map((objs) => objs.name),
    isPrivate: mediaInfo.isPrivate,
    hasAudio: mediaInfo.hasAudio,
    maleAgeRatio: maleAgeRatio,
    femaleAgeRatio: femaleAgeRatio,
    ageRatio: ageRatio,
    tempVisibility: tempVisibility,
    tempCirculation: tempCirculation,
  }) // firestoreのmediaを更新
  await getVisibility(
    mediaID,
    mediaInfo.numSurrBoards,
    buildingPos,
    visiblePoints,
    mediaInfo.range,
    mediaInfo.verticalWidth,
    mediaInfo.horizontalWidth,
    mediaInfo.height,
    mediaInfo.direction
  )
  await getJinryu(mediaID, visiblePoints)
}

export const uploadMedia = async (mediaID, buildingPos, mediaInfo, visiblePoints) => {
  const { maleAgeRatio, femaleAgeRatio, ageRatio } = await calcRatio({ latitude: buildingPos.lat, longitude: buildingPos.lng })
  const tempVisibility = mediaInfo.useTempVisibility ? mediaInfo.tempVisibility : null
  const tempCirculation = mediaInfo.useTempCirculation ? mediaInfo.tempCirculation : null
  await updateDBRelatedToTag(mediaID, mediaInfo.tags)
  await updateDBRelatedToLine(mediaID, mediaInfo.lines)
  await updateDBRelatedToHighway(mediaID, mediaInfo.highways)
  //TODO: CSV入力
  await updateDBHandler(mediaID, {
    address: mediaInfo.address,
    direction: mediaInfo.direction,
    note: mediaInfo.note,
    summary: mediaInfo.summary,
    latitude: buildingPos.lat,
    longitude: buildingPos.lng,
    height: mediaInfo.height,
    hasIllumination: mediaInfo.hasIllumination,
    verticalWidth: mediaInfo.verticalWidth,
    horizontalWidth: mediaInfo.horizontalWidth,
    name: mediaInfo.name,
    yearlyCost: mediaInfo.yearlyCost,
    monthlyCost: mediaInfo.monthlyCost,
    constructionCost: mediaInfo.constructionCost,
    recoveryCost: mediaInfo.recoveryCost,
    mediaType: mediaInfo.mediaType,
    uid: mediaID,
    mediaImagePaths: mediaInfo.mediaImageObjs.map((objs) => objs.name),
    isPrivate: mediaInfo.isPrivate,
    maleAgeRatio: maleAgeRatio,
    femaleAgeRatio: femaleAgeRatio,
    ageRatio: ageRatio,
    tempVisibility: tempVisibility,
    tempCirculation: tempCirculation,
  }) // firestoreのmediaを更新
  const visibilityPromise = getVisibility(
    mediaID,
    mediaInfo.numSurrBoards,
    buildingPos,
    visiblePoints,
    mediaInfo.range,
    mediaInfo.verticalWidth,
    mediaInfo.horizontalWidth,
    mediaInfo.height,
    mediaInfo.direction
  )
  const circulationPromise = getJinryu(mediaID, visiblePoints)
  await Promise.all([visibilityPromise, circulationPromise])
}

export type mediaImageType = {
  name: string
  file: Blob | Uint8Array | ArrayBuffer
  done: boolean
}
export const uploadMediaImage = async (mediaImageObjs: mediaImageType[]) => {
  const storageRef = firebase.storage().ref()
  mediaImageObjs.forEach((mediaImage) => {
    if (!mediaImage.done) {
      storageRef.child(mediaImage.name).put(mediaImage.file)
    }
  })
}
