// ### industory
//     | value | 名前 |
// | : ----: | : ----: |
// | convinience | コンビニエンスストア |
// | station | 駅 |
// | restaurant | 飲食店 |
// | bookStore | 書店 |
// | supermarket | スーパーマーケット |
// | mall | ショッピングセンター |
// | school | スクール |
// | education | 教育機関 |
// | pharmacy | ドラッグストア |

const IndustryOptions = [
  { key: 'convinience', text: 'コンビニエンスストア', value: 'convinience' },
  { key: 'station', text: '駅', value: 'station' },
  { key: 'restaurant', text: '飲食店', value: 'restaurant' },
  { key: 'bookStore', text: '書店', value: 'bookStore' },
  { key: 'supermarket', text: 'スーパーマーケット', value: 'supermarket' },
  { key: 'mall', text: 'ショッピングセンター', value: 'mall' },
  { key: 'school', text: 'スクール', value: 'school' },
  { key: 'education', text: '教育機関', value: 'education' },
  { key: 'price', text: 'ドラッグストア', value: 'pharmacy' },
]

export default IndustryOptions
