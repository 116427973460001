// enumっぽくデータ型を定義
export const types = {
  map: 0,
  string: 1,
  number: 2,
  bool: 3,
  array: 4,
  enum: 5,
  nullableNumber: 6,
  stringArrayElem: 7, //[string]となる文字列
}
