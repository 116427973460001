import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/analytics'
import { taxRate } from 'constants/constants'

let priceSetting, auth, db, storage, MAP_KEY, MAP_ID, HUBSPOT_CHAT_URL, ADRES_API_URL, ADRES_API_ENV
if (process.env.NODE_ENV === 'test') {
  priceSetting = {
    marginRate: 1.2,
    defaultMarginRate: 1.2,
    taxRate: taxRate,
  }
} else {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  }

  //TODO: taxRateの設定がconstantsにもあるのでまとめる
  priceSetting = {
    marginRate: Number(process.env.REACT_APP_DEFAULT_MARGIN_RATE),
    defaultMarginRate: Number(process.env.REACT_APP_DEFAULT_MARGIN_RATE),
    taxRate: taxRate,
  }

  MAP_KEY = process.env.REACT_APP_MAP_KEY
  MAP_ID = process.env.REACT_APP_MAP_ID

  ADRES_API_URL = process.env.REACT_APP_ADRES_API_URL
  ADRES_API_ENV = process.env.REACT_APP_ADRES_API_ENV
  HUBSPOT_CHAT_URL = process.env.REACT_APP_ADRES_HUBSPOT_CHAT_URL

  firebase.initializeApp(firebaseConfig)
  firebase.analytics()

  db = firebase.firestore()
  auth = firebase.auth()
  db.settings({ timestampsInSnapshots: true })
  storage = firebase.storage()
}

export default firebase
//TODO: firebaseのconfigとそのほかの環境変数が混ざっているのでファイルを分離する
export { priceSetting, auth, db, storage, MAP_KEY, MAP_ID, HUBSPOT_CHAT_URL, ADRES_API_URL, ADRES_API_ENV }

//----------------------------------------------
// ドメインとポート番号
//----------------------------------------------
let domain = document.domain
let port = domain === 'localhost' ? 3000 : 80 // eslint-disable-line
