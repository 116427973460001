import React, { useEffect, useState, useContext } from 'react'
import { Container, Header } from 'semantic-ui-react'
import Spacer from 'components/atoms/Spacer'
import { auth } from 'FirebaseConfig'
import NavigationBar from 'components/organisms/menubar/NavigationBar'
import NavigationBarOwner from 'components/organisms/menubar/NavigationBarOwner'
import NavigationBarPlane from 'components/organisms/menubar/NavigationBarPlane'
import { isOwner } from 'helpers/isOwner'
import { UsersContext } from 'providers/Users'

const NoPageFound = () => {
  const [owner, setOwner] = useState(false)
  const [customer, setCustomer] = useState(false)
  const [login, setLogin] = useState(false)
  const { users } = useContext(UsersContext)

  // TODO: layoutにやらせる
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setLogin(true)
        let ref = isOwner(users, user.uid)
        if (ref) {
          setOwner(true)
          setCustomer(false)
        } else {
          setOwner(false)
          setCustomer(true)
        }
      }
    })
  })

  return (
    <>
      {owner && <NavigationBarOwner />}
      {customer && <NavigationBar />}
      {login ? <></> : <NavigationBarPlane />}
      <Spacer />
      <Container>
        <Header as="h2">No Page Found</Header>
        <p>404 error</p>
      </Container>
    </>
  )
}

export default NoPageFound
