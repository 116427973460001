// Library
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { MediasAvailableContext, useMediaAvailable } from 'providers/MediasAvailable'
import styled from '@emotion/styled'
import MapPlaceSearchForm from 'components/organisms/mediasearch/MapPlaceSearchForm'
import MapPlaceSearchMarker from 'components/organisms/mediasearch/MapPlaceSearchMarker'
import GoogleMapReact from 'google-map-react'
import { Marker } from 'components/organisms/cart/Marker'
import { MAP_KEY, MAP_ID } from 'FirebaseConfig'
import { mediaList2Bounds } from 'helpers/calculates/CalculateAverageLatLng'

const DEFAULT_MAP_CENTER_LATITUDE = 35.6432027
const DEFAULT_MAP_CENTER_LONGITUDE = 139.6729435

// type Pin = AdviceMediaDataType & {
//   lat: number
//   lng: number
//   name: string
//   mediaId: string
// }

const CartMap = (props) => {
  const { mediasAvailable } = useContext(MediasAvailableContext)
  const [pins, setPins] = useState([]) //useState<Pin[]>([])
  const [lat, setLat] = useState() //useState<number>()
  const [lng, setLng] = useState() //useState<number>()
  const [zoom, setZoom] = useState(14) //useState<number>(14)//デフォルトのzoom
  const [placeService, setPlaceService] = useState() // useState<any>()
  const [searchPlaceMarkerLocation, setSearchPlaceMarkerLocation] = useState(null) //useState<any>(null)
  const [mapRef, setMapRef] = useState()

  // 媒体情報を取得し、stateにセット
  useEffect(() => {
    if (props.mediaTarget) {
      // 媒体が一つ選択されている場合
      const ref = mediasAvailable.find((media) => media.uid === props.mediaTarget)
      // mediaTargetを地図の中心にする
      if (ref.mediaType === 'mediaTypeJack') {
        const bounds = mediaList2Bounds(ref.childMedia)
        mapRef.fitBounds(bounds)
      } else {
        setLat(ref.latitude)
        setLng(ref.longitude)
        setZoom(15)
      }
    } else {
      setZoom(14)
      if (props.listedMedias) {
        // 媒体のリストが選択されている場合
        const refListedMedia = []
        props.listedMedias.forEach((media) => {
          //TODO: n^2となっているところを修正したい
          const ref = mediasAvailable.find((m) => m.uid === media)
          if (ref) {
            refListedMedia.push({
              ...ref,
              lat: ref.latitude,
              lng: ref.longitude,
              name: ref.name,
              mediaId: ref.uid,
            })
          }
        })
        setPins(refListedMedia)
        setLat(DEFAULT_MAP_CENTER_LATITUDE)
        setLng(DEFAULT_MAP_CENTER_LONGITUDE)
      }
    }
  }, [mediasAvailable, props.listedMedias, props.mediaTarget])

  ///////////////////////////////////////////////////////////////////////////////
  // GoogleMapのオプション
  const createMapOptions = () => {
    return {
      mapTypeControl: true,
      streetViewControl: true,
      styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
      mapId: MAP_ID,
    }
  }
  // 媒体カードをクリックした際に拡大表示されるピンを変更させる
  const changeBalloon = (key) => {
    const keyNumber = Number(key)
    if (props.currentKey === keyNumber) {
      props.setCurrentKey(-1)
    } else {
      props.setCurrentKey(keyNumber)
    }
  }

  const changeInfo = (key) => {
    const keyNumber = Number(key)
    if (props.currentKeyInfo === keyNumber) {
      props.setCurrentKeyInfo(-1)
    } else {
      props.setCurrentKeyInfo(keyNumber)
    }
  }

  const _onChildMouseLeave = () => {
    if (props.currentKeyInfo !== -1) {
      props.setCurrentKeyInfo(-1)
    }
  }

  useEffect(() => {
    if (props.currentKeyInfo !== -1) {
      const mediaCard = document.getElementById(`mediaCard_${props.currentKeyInfo}`)
      props.setOldKeyInfo(props.currentKeyInfo)
      if (mediaCard) {
        mediaCard.style.setProperty('background', '#eee')
      }
    }
    if (props.currentKey !== -1) {
      const mediaCard = document.getElementById(`mediaCard_${props.currentKey}`)
      props.setOldKey(props.currentKey)
      if (mediaCard) {
        mediaCard.style.setProperty('border', 'solid 2px #000')
      }
    }
  }, [props.currentKeyInfo, props, props.currentKey])

  useEffect(() => {
    if (props.currentKeyInfo !== props.oldKeyInfo) {
      const mediaCardOld = document.getElementById(`mediaCard_${props.oldKeyInfo}`)
      if (mediaCardOld) {
        mediaCardOld.style.setProperty('background', '#fff')
      }
    }
    if (props.currentKey !== props.oldKey) {
      const mediaCard = document.getElementById(`mediaCard_${props.oldKey}`)
      if (mediaCard) {
        mediaCard.style.setProperty('border', 'solid 1px #ddd')
      }
    }
  }, [props.currentKeyInfo, props.oldKeyInfo, props.currentKey, props.oldKey])

  const handleGoogleMapsAPILoaded = useCallback((maps, map) => {
    setPlaceService(new maps.places.PlacesService(map))
    setMapRef(map)
  }, [])

  const submitFormHandler = useCallback(
    (request) => {
      return new Promise((res) => {
        placeService?.findPlaceFromQuery(request, function (results, status) {
          if (status === 'OK') {
            const resultLocation = results && results[0].geometry?.location
            if (resultLocation) {
              setLat(resultLocation.lat())
              setLng(resultLocation.lng())
              setSearchPlaceMarkerLocation({
                lat: resultLocation.lat(),
                lng: resultLocation.lng(),
                text: results[0].name,
              })
            } else {
              setSearchPlaceMarkerLocation(null)
            }
          }
          res()
        })
      })
    },
    [placeService]
  )

  const { isSameJackGroup } = useMediaAvailable()

  return (
    <Wrapper>
      <GoogleMapWrapper style={{ height: 'calc(100vh - 56px)', position: 'relative' }}>
        <MapPlaceSearchForm submitFormHandler={submitFormHandler} />
        <GoogleMapReact
          // TODO: typeScript化したときに怒られるはず(https://github.com/google-map-react/google-map-react/issues/1039)
          bootstrapURLKeys={{
            key: MAP_KEY,
            mapIds: [MAP_ID],
            libraries: ['places'],
          }}
          defaultCenter={{ lat, lng }}
          center={{ lat, lng }}
          defaultZoom={14}
          zoom={zoom}
          onChange={(event) => {
            setZoom(event.zoom)
            setLat(() => {
              setLng(event.center.lng)
              return event.center.lat
            })
          }}
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals={true}
          onChildClick={(key) => changeBalloon(key)}
          onChildMouseEnter={(key) => changeInfo(key)}
          onChildMouseLeave={_onChildMouseLeave}
          onGoogleApiLoaded={({ maps, map }) => {
            handleGoogleMapsAPILoaded(maps, map)
          }}
        >
          {searchPlaceMarkerLocation && (
            <MapPlaceSearchMarker lat={searchPlaceMarkerLocation.lat} lng={searchPlaceMarkerLocation.lng} text={searchPlaceMarkerLocation.text} />
          )}
          {pins.map((pin, index) => (
            <Marker
              lat={pin.latitude}
              lng={pin.longitude}
              text={pin.name}
              mediaId={pin.mediaId}
              id={index}
              media={pin}
              isAtCart={props.cart.id && props.cart.id.indexOf(pin.uid) !== -1}
              setCurrentKey={props.setCurrentKey}
              showBalloon={props.mediaTarget === pin.mediaId}
              isSameJackGroup={isSameJackGroup(pin, props.mediaTarget)}
              showInfo={props.currentKeyInfo === index}
              setMediaTarget={props.setMediaTarget}
              period={props.period}
              key={index}
            />
          ))}
        </GoogleMapReact>
      </GoogleMapWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
`

const GoogleMapWrapper = styled.div`
  height: 85vh;
`

export default CartMap
