import React, { useCallback, useState } from 'react'
import { Input, Icon } from 'semantic-ui-react'
import './index.css'

type props = {
  submitFormHandler: (request: google.maps.places.FindPlaceFromQueryRequest) => Promise<void>
}

const MapPlaceSearchForm = ({ submitFormHandler }: props) => {
  const [queryInput, setQueryInput] = useState<string>('')

  const onChangeInputFieldHandler: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setQueryInput(event.target.value)
  }, [])

  const searchHandler = useCallback(async () => {
    const request = {
      query: queryInput,
      fields: ['name', 'geometry'],
    }
    await submitFormHandler(request)
  }, [queryInput])

  return (
    <div className="mapPlaceSearchForm__container">
      <Input
        icon={<Icon name="search" link onClick={searchHandler} />}
        placeholder="地点名/住所を入力"
        value={queryInput}
        onChange={onChangeInputFieldHandler}
        type="text"
      />
    </div>
  )
}

export default MapPlaceSearchForm
