import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'providers/Auth'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'
import AppLayout from 'components/organisms/layouts/AppLayout'
import GroupBreadcrump from 'components/organisms/breadcrumb/GroupBreadcrump'
// import GroupContent from 'components/organisms/group/GroupContent'
import EditGroupContent from 'components/organisms/group/EditGroupContent'
import AppLayoutGroupDashboard from 'components/organisms/layouts/AppLayoutGroup'
import Loading from 'components/atoms/Loading'
import { shouldSignin } from 'helpers/shouldSignin'

const EditGroup = () => {
  const [flagAngle, setFlagAngle] = useState(false)

  const { currentUser } = useContext(AuthContext)
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (shouldSignin(currentUser)) {
      history.push(`${paths.signin}`)
    }
  })

  return (
    <>
      <AppLayout>
        <AppLayoutGroupDashboard flagAngle={flagAngle} setFlagAngle={setFlagAngle}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Segment>
                <GroupBreadcrump edit />
              </Segment>
              <EditGroupContent setLoading={setLoading} loading={loading} />
            </>
          )}
        </AppLayoutGroupDashboard>
      </AppLayout>
    </>
  )
}

export default EditGroup
