import React, { createContext, useContext, useEffect, useState } from 'react'
import { auth } from 'FirebaseConfig'
import firebase from 'firebase'

type props = {
  children?: React.ReactNode
}

type CurrentUser = {
  currentUser?: firebase.User | null
}

const AuthContext = createContext<CurrentUser>({})

const AuthProvider = (props: props) => {
  // provides current login user from Firebase Auth
  const [currentUser, setCurrentUser] = useState<CurrentUser>({})

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser({ currentUser: user })
    })
  }, [])

  return <AuthContext.Provider value={currentUser}>{props.children}</AuthContext.Provider>
}

export const useAuthContext = () => {
  return useContext(AuthContext)
}
export { AuthContext, AuthProvider }
