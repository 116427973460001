import React from 'react'
import NavigationBar from 'components/organisms/menubar/NavigationBar'
//import FooterNav from 'components/organisms/menubar/FooterNav'
import './index.css'

const AppLayoutGroupDashboard = (props) => {
  return (
    <>
      <div style={{ display: 'grid' }} className="appLayoutGroup__appGridLayout">
        <div style={{ gridRow: '1', gridColumn: '1/3' }} className="appLayoutGroup__navBarGridRow">
          <NavigationBar />
        </div>
        <div style={{ gridRow: '2', gridColumn: '2/3' }} className={`appLayoutGroup__campaignLayoutContent ${'appLayoutGroup__campaignLayoutContentClose'}`}>
          {props.children}
        </div>
      </div>
    </>
  )
}

export default AppLayoutGroupDashboard
