import React, { useState } from 'react'
import { Grid, Form, Dropdown, Input, Button } from 'semantic-ui-react'
import StateOptions from 'constants/StateOptions'
import SortOptions from 'constants/SortOptions'
import MediaTypeOptions from 'constants/MediaTypeOptions'
import TagDropdown from 'components/organisms/tag/TagDropdown'
import { useHistory } from 'react-router-dom'

const MediaSearchForm = (props) => {
  const history = useHistory()
  const [searchWordInput, setSearchWordInput] = useState(props.searchWord)
  const [mediaTypeInput, setMediaTypeInput] = useState(props.mediaType)
  const [areaInput, setAreaInput] = useState(props.area)
  const [tagInput, setTagInput] = useState(props.tag)
  const [lineInput, setLineInput] = useState(props.line)
  const [highwayInput, setHighwayInput] = useState(props.highway)
  const [sortInput, setSortInput] = useState(props.sort)
  const filteredMediaTypeOptions = MediaTypeOptions.filter((x) => x.key != 'mediaTypeJack')

  const handleChange = (_e, { name, value }) => {
    switch (name) {
      case 'searchWord':
        setSearchWordInput(value)
        break
      case 'area':
        setAreaInput(value)
        break
      case 'tag':
        setTagInput((prev) => [...prev, value])
        break
      case 'deleteTag':
        setTagInput((prev) => prev.filter((tag) => tag !== value))
        break
      case 'line':
        setLineInput((prev) => [...prev, value])
        break
      case 'deleteLineTag':
        setLineInput((prev) => prev.filter((lineTag) => lineTag !== value))
        break
      case 'highway':
        setHighwayInput((prev) => [...prev, value])
        break
      case 'deleteHighwayTag':
        setHighwayInput((prev) => prev.filter((highwayTag) => highwayTag !== value))
        break
      case 'mediaType':
        setMediaTypeInput(value)
        break
      case 'sort':
        setSortInput(value)
        break
      default:
        break
    }
  }

  const pushSearchQuery = () => {
    const query = []
    if (mediaTypeInput && mediaTypeInput.length) {
      query.push('media_type=' + mediaTypeInput)
    }
    if (areaInput && areaInput.length) {
      query.push('area=' + areaInput)
    }
    if (searchWordInput) {
      query.push('searchword=' + searchWordInput)
    }
    if (tagInput && tagInput.length) {
      query.push('tag=' + tagInput)
    }
    if (lineInput && lineInput.length) {
      query.push('line=' + lineInput)
    }
    if (highwayInput && highwayInput.length) {
      query.push('highway=' + highwayInput)
    }
    if (sortInput) {
      query.push('sort=' + sortInput)
    }
    if (query.length) {
      const joinedQuery = query.join('&')
      history.push(`/mediasearch?${joinedQuery}`)
    } else {
      history.push('/mediasearch')
    }
  }

  return (
    <Form>
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column style={{ marginRight: '-1rem' }}>
            <div>
              <Dropdown
                compact
                fluid
                multiple
                placeholder="種類"
                search
                selection
                scrolling
                name="mediaType"
                value={mediaTypeInput}
                options={filteredMediaTypeOptions}
                onChange={handleChange}
              />
            </div>
          </Grid.Column>
          <Grid.Column style={{ paddingLeft: '0.2rem', marginRight: '-1rem' }}>
            <div>
              <Dropdown
                compact
                fluid
                multiple
                placeholder="エリア"
                search
                selection
                scrolling
                name="area"
                value={areaInput}
                options={StateOptions}
                onChange={handleChange}
              />
            </div>
          </Grid.Column>
          <Grid.Column style={{ paddingLeft: '0.2rem', marginRight: '-1rem' }}>
            <div>
              <TagDropdown tags={tagInput} lines={lineInput} highways={highwayInput} placeholder="タグ" changeTagCallback={handleChange} />
            </div>
          </Grid.Column>
          <Grid.Column style={{ paddingLeft: '0.2rem', marginRight: '-1rem' }}>
            <div>
              <Dropdown
                compact
                fluid
                placeholder="ソート"
                search
                selection
                scrolling
                name="sort"
                value={sortInput}
                options={SortOptions}
                onChange={handleChange}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={12}>
            <div>
              <Input fluid compact type="text" name="searchWord" placeholder="キーワード" value={searchWordInput} onChange={handleChange} />
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <Button style={{ width: '100%' }} color="orange" onClick={pushSearchQuery}>
              {props.buttonText}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  )
}

export default MediaSearchForm
