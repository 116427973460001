import React, { useContext, createContext, useState, useEffect } from 'react'
import { AuthContext } from 'providers/Auth'
import { db } from 'FirebaseConfig'

const UsersContext = createContext<{ users: userDataType[] }>({ users: [] })

export type userDataType = {
  address: string
  companyName: string
  docId: string
  email: string
  fullName: string
  isConfirmed: boolean
  isOwner: boolean
  tel: string
  uid: string
}

// provide medias
const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState<userDataType[]>([])
  const { currentUser } = useContext(AuthContext)
  useEffect(() => {
    if (currentUser) {
      db.collection('user')
        .where('uid', '==', currentUser.uid)
        .onSnapshot((query) => {
          const data: userDataType[] = []
          query.forEach((d) => data.push(d.data() as userDataType))
          setUsers(data)
        })
    }
  }, [currentUser])

  return <UsersContext.Provider value={{ users }}>{children}</UsersContext.Provider>
}

export { UsersContext, UsersProvider }

export const useUsersContext = () => {
  const { users } = useContext(UsersContext)
  return users
}
