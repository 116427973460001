import React, { useEffect, useState } from 'react'
import { Menu, Button } from 'semantic-ui-react'
import ManageMediaTab from 'components/pages/Owner/Dashboard/DashboardPage/ManageMediaTab'
import CartMediaTab from 'components/pages/Owner/Dashboard/DashboardPage/CartMediaTab'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import './index.css'

const DashboardTabs = () => {
  const [activeItem, setActiveItem] = useState('all')
  const [mediaTarget, setMediaTarget] = useState('')
  const handleItemClick = (e, { id }) => {
    setActiveItem(id)
    setMediaTarget('')
  }

  const history = useHistory()
  // useEffect(() => {
  //   let showMediaDetail = document.getElementById('showMediaDetail');
  //   if (mediaTarget) {
  //     showMediaDetail.style.setProperty('display', 'block');
  //   } else {
  //     showMediaDetail.style.setProperty('display', 'none');
  //   }
  // })

  useEffect(() => {
    let showMediaOwnAll = document.getElementById('showMediaOwnAll')
    let showMediaOwnCart = document.getElementById('showMediaOwnCart')
    switch (activeItem) {
      case 'all':
        showMediaOwnAll.style.setProperty('display', 'block')
        showMediaOwnCart.style.setProperty('display', 'none')
        break
      case 'cart':
        showMediaOwnAll.style.setProperty('display', 'none')
        showMediaOwnCart.style.setProperty('display', 'block')
        break
      default:
        showMediaOwnAll.style.setProperty('display', 'none')
        showMediaOwnCart.style.setProperty('display', 'none')
        console.log('No name match')
    }
  })

  return (
    <>
      <div className="dashboardTabs__flex">
        <Menu secondary>
          <Menu.Item name="媒体管理" id="all" color="orange" active={activeItem === 'all'} onClick={handleItemClick} />
          <Menu.Item name="注文" color="orange" id="cart" active={activeItem === 'cart'} onClick={handleItemClick} />
        </Menu>
        <div>
          <Button primary size="small" onClick={() => history.push(`${paths.addmedia}`)}>
            媒体追加
          </Button>
        </div>
      </div>
      <div style={{ display: 'grid' }}>
        <div
          style={{ gridColumn: '1' }}
          // className='mediaDetailColumn'
        >
          <div id="showMediaOwnAll">
            <ManageMediaTab mediaTarget={mediaTarget} setMediaTarget={setMediaTarget} />
          </div>
          <div id="showMediaOwnCart">
            <CartMediaTab mediaTarget={mediaTarget} setMediaTarget={setMediaTarget} />
          </div>
        </div>
      </div>
      {/* <div
            // style={{ gridColumn: '2' }}
            className='popup'
          >
            <div
              id='showMediaDetail'
              className='mediaDetailColumn'
            >
              <button >close me</button>
              <ManageMediaDetail
                mediaTarget={mediaTarget}
              />
            </div>
          </div> */}
    </>
  )
}

export default DashboardTabs
