import React from 'react'
import { Checkbox, Header } from 'semantic-ui-react'

const GroupFilters = (props) => {
  return (
    <>
      <Header>配信状況</Header>
      <Checkbox name="status1" label="注文前" onChange={props.handleChgangeFilter} value={props.status1} checked={props.status1} />
      &nbsp; &nbsp;
      <Checkbox name="status2" label="注文中" onChange={props.handleChgangeFilter} value={props.status2} checked={props.status2} />
      &nbsp; &nbsp;
      <Checkbox name="status3" label="掲載中" onChange={props.handleChgangeFilter} value={props.status3} checked={props.status3} />
      &nbsp; &nbsp;
      <Checkbox name="status4" label="掲載終了" onChange={props.handleChgangeFilter} value={props.status4} checked={props.status4} />
    </>
  )
}

export default GroupFilters
