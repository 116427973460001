import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'providers/Auth'
import MySettings from 'components/organisms/usersetting/MySettings'
import MyFields from 'components/organisms/usersetting/MyFields'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import { Container, Header } from 'semantic-ui-react'
import Spacer from 'components/atoms/Spacer'
import AppLayoutOwner from 'components/organisms/layouts/AppLayoutOwner'
import MyPassword from 'components/organisms/usersetting/MyPassword'
import { Menu } from 'semantic-ui-react'
import { shouldSignin } from 'helpers/shouldSignin'

const MyOwner = () => {
  const { currentUser } = useContext(AuthContext)
  const history = useHistory()
  const [activeItem, setActiveItem] = useState('profile')

  // TODO: currentUserのチェックをlayoutに移動
  useEffect(() => {
    if (shouldSignin(currentUser)) {
      history.push(`${paths.signinowner}`)
    }
  })

  const handleItemClick = (e, { id }) => {
    setActiveItem(id)
  }

  return (
    <>
      <AppLayoutOwner>
        <Spacer />
        <Container>
          <Header as="h2">媒体主マイページ</Header>
          <Menu secondary size="large" pointing>
            <Menu.Item name="プロフィール" id="profile" color="orange" active={activeItem === 'profile'} onClick={handleItemClick} />
            <Menu.Item name="パスワード" id="pw" color="orange" active={activeItem === 'pw'} onClick={handleItemClick} />
          </Menu>
          {activeItem === 'profile' && (
            <>
              <MySettings path={paths.myowner} />
              <MyFields path={paths.myowner} />
            </>
          )}
          {activeItem === 'pw' && (
            <>
              <MyPassword path={paths.myowner} />
            </>
          )}
        </Container>
      </AppLayoutOwner>
    </>
  )
}

export default MyOwner
