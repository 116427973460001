//TODO:owner画面でしか使われていないので消しても良い

export const MediaIsCartGenerator = (medias, carts) => {
  const mediaList = []
  medias.forEach((media) => {
    carts.forEach((cart) => {
      if (cart.id && cart.status === 2) {
        if (cart.id.indexOf(media.uid) !== -1) {
          mediaList.push({
            uid: media.uid,
            docId: media.uid,
            name: media.name,
            impression: media.impression,
            mediaImagePaths: media.mediaImagePaths,
            startDate: cart.startDate,
            endDate: cart.endDate,
            cartId: cart.uid,
            isVision: media.isVision,
            mediaType: media.mediaType,
            hasIllumination: media.hasIllumination,
          })
        }
      }
    })
  })

  return mediaList
}
