import { useMedias } from 'providers/Medias'
import React, { useCallback, useMemo, useState } from 'react'
import { Grid, Icon, Pagination, PaginationProps } from 'semantic-ui-react'
import MediaSearchMap from '../MediaSearchMap'
import { MapMediasSearchFilter } from 'helpers/filters/MapMediaSearchFilter'
import MapSearchForm from '../MapSearchForm'
import MediaCardMapSearch from 'components/organisms/card/MediaCardMapSearch'
import './index.css'
import MapSearchMediaDetail from '../MapSearchMediaDetail'

type props = {
  isLoggedIn: boolean
}

const PAGE_SIZE = 20
export const DISPLAY_DETAIL_WIDTH = 440

const MapSearchDisplay = (props: props) => {
  const [targetMediaId, setTargetMediaId] = useState<string>('')

  const [mediaTypeInput, setMediaTypeInput] = useState<string[]>([])
  const [areaInput, setAreaInput] = useState<string[]>([])
  const [tagInput, setTagInput] = useState<string[]>([])
  const [lineInput, setLineInput] = useState<string[]>([])
  const [highwayInput, setHighwayInput] = useState<string[]>([])
  const [searchWordInput, setSearchWordInput] = useState<string>('')

  const [shouldDisplayDetail, setShouldDisplayDetail] = useState(false)

  const mediaList = useMedias()

  const filteredMediaList = useMemo(() => {
    return MapMediasSearchFilter(mediaList, tagInput, lineInput, highwayInput, areaInput, mediaTypeInput, searchWordInput)
  }, [mediaList, searchWordInput, areaInput, tagInput, lineInput, highwayInput, mediaTypeInput])

  const [pageIndex, setPageIndex] = useState(0)
  const totalPages = useMemo(() => {
    return Math.ceil(filteredMediaList.length / PAGE_SIZE)
  }, [filteredMediaList])

  const handleChange = useCallback((_e, { name, value }) => {
    switch (name) {
      case 'searchWord':
        setSearchWordInput(value)
        break
      case 'area':
        setAreaInput(value)
        break
      case 'tag':
        setTagInput((prev) => [...prev, value])
        break
      case 'deleteTag':
        setTagInput((prev) => prev.filter((tag) => tag !== value))
        break
      case 'line':
        setLineInput((prev) => [...prev, value])
        break
      case 'deleteLineTag':
        setLineInput((prev) => prev.filter((lineTag) => lineTag !== value))
        break
      case 'highway':
        setHighwayInput((prev) => [...prev, value])
        break
      case 'deleteHighwayTag':
        setHighwayInput((prev) => prev.filter((highwayTag) => highwayTag !== value))
        break
      case 'mediaType':
        setMediaTypeInput(value)
        break
      default:
        break
    }
  }, [])

  const clickMediaCardHandler = useCallback((uid: string) => {
    return () => {
      setTargetMediaId(uid)
      setShouldDisplayDetail(true)
    }
  }, [])

  const pageChangeHandler = useCallback((_event, data: PaginationProps) => {
    data.activePage && setPageIndex(Number(data.activePage) - 1)
  }, [])

  return (
    <Grid.Row style={{ padding: 0 }} columns={2}>
      <Grid.Column style={{ background: '#fff', padding: 0 }} width={5} floated="left">
        <div style={{ padding: 20, overflowY: 'scroll', height: 'calc(100vh - 56px)' }}>
          <MapSearchForm
            mediaTypeInput={mediaTypeInput}
            areaInput={areaInput}
            tagInput={tagInput}
            lineInput={lineInput}
            highwayInput={highwayInput}
            searchWordInput={searchWordInput}
            handleChange={handleChange}
          />
          <div style={{ paddingTop: 40 }}>
            {filteredMediaList.length ? (
              filteredMediaList.slice(pageIndex * PAGE_SIZE, pageIndex * PAGE_SIZE + PAGE_SIZE).map((media) => {
                return (
                  <div className={media.uid === targetMediaId ? 'mapSearchDisplay__mediaCardSelected' : 'mapSearchDisplay__mediaCardDefault'} key={media.uid}>
                    <MediaCardMapSearch onClick={clickMediaCardHandler(media.uid)} media={media} months={1} weeks={1} />
                  </div>
                )
              })
            ) : (
              <p>指定した条件に当てはまる媒体が見つかりませんでした。</p>
            )}
          </div>
          <div className="mapSearchDisplay__pagenationWrapper">
            <Pagination defaultActivePage={1} totalPages={totalPages} onPageChange={pageChangeHandler} />
          </div>
        </div>
      </Grid.Column>
      <Grid.Column style={{ paddingLeft: 0, paddingRight: 0 }} width={11}>
        <div style={shouldDisplayDetail ? { width: `calc(100% - ${DISPLAY_DETAIL_WIDTH}px)`, float: 'left' } : {}}>
          <MediaSearchMap
            setTargetMediaId={setTargetMediaId}
            shouldDisplayDetail={shouldDisplayDetail}
            setShouldDisplayDetail={setShouldDisplayDetail}
            targetMediaId={targetMediaId}
            mediaList={filteredMediaList}
          />
        </div>
        {shouldDisplayDetail && (
          <div id="edit__showMediaDetail" style={{ float: 'right' }}>
            <div className="edit__closeDetailButton" onClick={() => setShouldDisplayDetail(false)}>
              <Icon color="grey" name="angle double right" size="large" inverted bordered />
            </div>
            <MapSearchMediaDetail mediaId={targetMediaId} isLogined={props.isLoggedIn} />
          </div>
        )}
      </Grid.Column>
    </Grid.Row>
  )
}

export default MapSearchDisplay
