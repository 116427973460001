const paths = {
  // sessions
  action: '/action',
  signup: '/signup',
  signupowner: '/owner/signup',
  signin: '/signin',
  signinowner: '/owner/signin',
  resetpassword: '/resetpassword',
  resetpasswordowner: '/resetpasswordowner',
  verifyEmail: '/verifyEmail',
  adminSignin: '/admin/signin',

  // group
  groupdashboard: '/group/dashboard',
  addgroup: '/group/add',
  editgroup: '/group/edit/:uid',

  // cart
  confirmcart: '/cart/confirm/:uid',
  editcart: '/cart/edit/:uid',

  // settings
  my: '/my',
  myowner: '/owner/my',

  // owner
  menuowner: '/owner/menu',
  dashboardowner: '/owner/dashboard',
  addmedia: '/owner/addmedia',
  addmedialist: '/owner/addmedialist',
  stopmedia: '/owner/stopmedia',
  restartmedia: '/owner/restartmedia',
  deletemedia: '/owner/deletemedia/:preuid',
  editmedia: '/owner/editmedia/:uid',

  // contact
  contact: '/contact',
  documentRequest: '/doc-req',

  // media, mediasearch, mediatop
  media: '/media/:uid',
  mediasearch: '/mediasearch',
  mediatop: '/mediatop',
  mapSearch: '/map',

  submit: '/submit/:uid',

  // package
  package: '/packages/:packageId',

  // 404
  nopagefound: '/404',

  //admin
  adminConsole: '/admin/console',
  adminAddCSVMedia: '/admin/addMedia',
  adminAddOneMedia: '/admin/editMedia/new',
  adminEditMedia: '/admin/editMedia', // 編集対象の媒体一覧が表示される
  adminEditMediaForm: '/admin/editMedia/:mediaID', // editMedia/new/と、editMedia/:mediaIDはadminEditMediaForm.jsxにルーティングされる
  adminMediaUnderConsideration: '/admin/mediaUnderConsideration', //ユーザーがカートに入れている媒体を表示する

  adminOwners: '/admin/owners',
  adminTags: '/admin/tags', //タグの編集画面

  adminPackages: '/admin/packages',

  // Lp
  lp: '/',

  blogs: '/blogs',
  blog: '/blog/:uid',
} as const

export default paths
