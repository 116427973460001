import React, { createContext, useState, useEffect, useContext } from 'react'
import { CartsAllContext } from 'providers/CartsAll'
import { cartDataType } from 'providers/CartsAll'

const CartsContext = createContext<{ carts: cartDataType[] }>({ carts: [] })

const CartsProvider = ({ children }) => {
  const [carts, setCarts] = useState<cartDataType[]>([])
  const { cartsAll } = useContext(CartsAllContext)

  //isDeletedでないもののみを残すフィルター
  useEffect(() => {
    if (cartsAll) {
      let tmpcarts = cartsAll
      tmpcarts = tmpcarts.filter((row) => {
        if (row.isDeleted === true) {
          return false
        } else {
          return row
        }
      })
      setCarts(tmpcarts)
    }
  }, [cartsAll])

  return <CartsContext.Provider value={{ carts }}>{children}</CartsContext.Provider>
}

export { CartsContext, CartsProvider }
