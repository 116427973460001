import { types } from 'constants/types'

export const columnDtypes = {
  docId: types.string,
  distance: types.number,
  name: types.string,
  queries: types.map,
}

export const lineColumnDTypes = {
  docId: types.string,
  distance: types.number,
  lineCd: types.number,
  name: types.string,
}

export const highwayColumnDTypes = {
  docId: types.string,
  distance: types.number,
  value: types.string,
  name: types.string,
}

export const validationCallBacks = {
  distance: (d) => 0 <= d,
  queries: (d) => {
    let flag = false
    for (const query of d) {
      if (query['key'] === undefined || query['value'] === undefined) {
        flag = false
        break
      } else {
        flag = true
      }
    }
    return flag
  },
}

export const serializeValue = (data, type) => {
  switch (type) {
    case types.enum:
    case types.string:
    case types.number:
    case types.bool:
      return data
    case types.map:
    case types.array:
      return JSON.stringify(data)
    default:
      return null
  }
}
