import { cartDataType } from 'providers/CartsAll'
import { AdviceBillboardDataType, AdviceVisionDataType } from 'constants/mediaData'

export const getTargetCirculation = (
  media: AdviceBillboardDataType | AdviceVisionDataType,
  targetAge: cartDataType['targetAge'],
  targetSex: cartDataType['targetSex']
): number => {
  let targetCirculation = 0
  let circulation15TargetAge = 0
  let circulation20TargetAge = 0
  let circulation30TargetAge = 0
  let circulation40TargetAge = 0
  let circulation50TargetAge = 0
  let circulation60TargetAge = 0
  let circulation70TargetAge = 0
  let circulation80TargetAge = 0
  let circulation15Unisex = 0
  let circulation20Unisex = 0
  let circulation30Unisex = 0
  let circulation40Unisex = 0
  let circulation50Unisex = 0
  let circulation60Unisex = 0
  let circulation70Unisex = 0
  let circulation80Unisex = 0

  if (targetAge) {
    const circulationPerDay: number = media.circulationPerDay || 0
    targetAge.forEach((age) => {
      switch (age) {
        case 15:
          circulation15Unisex = (circulationPerDay * media.ratio15) / 100
          switch (targetSex) {
            case 'unisex':
              circulation15TargetAge = circulation15Unisex
              break
            case 'male':
              circulation15TargetAge = (circulation15Unisex * media.maleRatio15) / 100
              break
            case 'female':
              circulation15TargetAge = (circulation15Unisex * media.femaleRatio15) / 100
              break
            // eslint-disable-next-line no-fallthrough
            default:
              console.log('No match')
          }
          break
        case 20:
          circulation20Unisex = (circulationPerDay * media.ratio20) / 100
          switch (targetSex) {
            case 'unisex':
              circulation20TargetAge = circulation20Unisex
              break
            case 'male':
              circulation20TargetAge = (circulation20Unisex * media.maleRatio20) / 100
              break
            case 'female':
              circulation20TargetAge = (circulation20Unisex * media.femaleRatio20) / 100
              break
            // eslint-disable-next-line no-fallthrough
            default:
              console.log('No match')
          }
          break
        case 30:
          circulation30Unisex = (circulationPerDay * media.ratio30) / 100
          switch (targetSex) {
            case 'unisex':
              circulation30TargetAge = circulation30Unisex
              break
            case 'male':
              circulation30TargetAge = (circulation30Unisex * media.maleRatio30) / 100
              break
            case 'female':
              circulation30TargetAge = (circulation30Unisex * media.femaleRatio30) / 100
              break
            // eslint-disable-next-line no-fallthrough
            default:
              console.log('No match')
          }
          break
        case 40:
          circulation40Unisex = (circulationPerDay * media.ratio40) / 100
          switch (targetSex) {
            case 'unisex':
              circulation40TargetAge = circulation40Unisex
              break
            case 'male':
              circulation40TargetAge = (circulation40Unisex * media.maleRatio40) / 100
              break
            case 'female':
              circulation40TargetAge = (circulation40Unisex * media.femaleRatio40) / 100
              break
            // eslint-disable-next-line no-fallthrough
            default:
              console.log('No match')
          }
          break
        case 50:
          circulation50Unisex = (circulationPerDay * media.ratio50) / 100
          switch (targetSex) {
            case 'unisex':
              circulation50TargetAge = circulation50Unisex
              break
            case 'male':
              circulation50TargetAge = (circulation50Unisex * media.maleRatio50) / 100
              break
            case 'female':
              circulation50TargetAge = (circulation50Unisex * media.femaleRatio50) / 100
              break
            // eslint-disable-next-line no-fallthrough
            default:
              console.log('No match')
          }
          break
        case 60:
          circulation60Unisex = (circulationPerDay * media.ratio60) / 100
          switch (targetSex) {
            case 'unisex':
              circulation60TargetAge = circulation60Unisex
              break
            case 'male':
              circulation60TargetAge = (circulation60Unisex * media.maleRatio60) / 100
              break
            case 'female':
              circulation60TargetAge = (circulation60Unisex * media.femaleRatio60) / 100
              break
            // eslint-disable-next-line no-fallthrough
            default:
              console.log('No match')
          }
          break
        case 70:
          circulation70Unisex = (circulationPerDay * media.ratio70) / 100
          switch (targetSex) {
            case 'unisex':
              circulation70TargetAge = circulation70Unisex
              break
            case 'male':
              circulation70TargetAge = (circulation70Unisex * media.maleRatio70) / 100
              break
            case 'female':
              circulation70TargetAge = (circulation70Unisex * media.femaleRatio70) / 100
              break
            // eslint-disable-next-line no-fallthrough
            default:
              console.log('No match')
          }
          break
        case 80:
          circulation80Unisex = (circulationPerDay * media.ratio80) / 100
          switch (targetSex) {
            case 'unisex':
              circulation80TargetAge = circulation80Unisex
              break
            case 'male':
              circulation80TargetAge = (circulation80Unisex * media.maleRatio80) / 100
              break
            case 'female':
              circulation80TargetAge = (circulation80Unisex * media.femaleRatio80) / 100
              break
            // eslint-disable-next-line no-fallthrough
            default:
              console.log('No match')
          }
          break
        // eslint-disable-next-line no-fallthrough
        default:
      }
    })
  }

  targetCirculation =
    circulation15TargetAge +
    circulation20TargetAge +
    circulation30TargetAge +
    circulation40TargetAge +
    circulation50TargetAge +
    circulation60TargetAge +
    circulation70TargetAge +
    circulation80TargetAge

  //targetAgeが存在しない場合、targetCirculationが0になるので、この場合はtargetSexごとにtargetCirculationを計算する
  if (targetCirculation === 0) {
    targetCirculation = media.circulationPerDay || 0
    switch (targetSex) {
      case 'unisex':
        break
      case 'male':
        targetCirculation = (targetCirculation * media.maleRatio) / 100
        break
      case 'female':
        targetCirculation = (targetCirculation * media.femaleRatio) / 100
        break
      // eslint-disable-next-line no-fallthrough
      default:
        console.log('No match')
    }
  }

  return targetCirculation
}
