import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import { useMedias } from 'providers/Medias'
import React, { useCallback, useState } from 'react'
import { Button, DropdownProps, Input, InputOnChangeData, Label, TableCell, TableRow, Grid } from 'semantic-ui-react'
import MediaDropdown from '../MediaDropdown'
import { deletePackage, MediaListElem, Package, PACKAGE_FIELDS, updatePackage } from '../PackageTable/helper'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type Props = {
  packageData: Package
}

const EditablePackageRowContent = ({ packageData }: Props) => {
  const [isEditabel, setIsEditable] = useState<boolean>(false)
  const [packageInput, setPackageInput] = useState<Package>(packageData)
  const medias = useMedias()

  const changeEditingModeHandler = useCallback(() => {
    setPackageInput(packageData)
    setIsEditable((prevState) => !prevState)
  }, [packageData])

  //TODO: createのところと共通化する
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.SyntheticEvent<HTMLElement, Event>, { name, value }: InputOnChangeData | DropdownProps) => {
      setPackageInput((prevState) => {
        if (name === PACKAGE_FIELDS.MEDIA_LIST) {
          const mediaList: MediaListElem[] = (value as string[]).map((id) => {
            const media = medias.find((media) => media.uid === id)
            if (media) {
              const purchaceUnit = getColInfoFromTypeName(media.mediaType).purchasePeriodUnit
              const unitFieldKey = purchaceUnit === 'month' ? 'months' : 'weeks'
              return {
                id: id,
                mediaType: media.mediaType,
                [unitFieldKey]: 1,
              }
            } else {
              throw new Error('finding media failed...')
            }
          })
          return { ...prevState, [PACKAGE_FIELDS.MEDIA_LIST]: mediaList }
        }
        return { ...prevState, [name]: value }
      })
    },
    [medias]
  )

  const handleChangePurchaceUnit = useCallback((targetIndex: number) => {
    return (_event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      setPackageInput((prev) => {
        return {
          ...prev,
          mediaList: prev.mediaList.map((media, index) => {
            return targetIndex === index ? { ...media, [data.name]: data.value } : media
          }),
        }
      })
    }
  }, [])

  const updatePackageHandler = useCallback(async () => {
    await updatePackage(packageData.id, packageInput)
    toast.success('パッケージ情報の更新に成功しました。')
  }, [packageData.id, packageInput])

  const deletePackageHandler = useCallback(async () => {
    if (window && window.confirm('パッケージを削除しますか？')) {
      await deletePackage(packageData.id)
    }
  }, [packageData.id])

  return (
    <>
      {isEditabel ? (
        <TableRow>
          <TableCell>{packageData.id}</TableCell>
          {Object.values(PACKAGE_FIELDS)
            .filter((key) => !([PACKAGE_FIELDS.MEDIA_LIST, PACKAGE_FIELDS.ID] as string[]).includes(key))
            .map((key) => (
              <TableCell key={key}>
                <Input name={key} value={packageInput[key]} onChange={handleChange} />
              </TableCell>
            ))}
          <TableCell>
            <MediaDropdown value={packageInput.mediaList.map((media) => media.id)} onChange={handleChange} />
          </TableCell>
          <TableCell>
            <Button onClick={updatePackageHandler}>変更</Button>
            <Button onClick={changeEditingModeHandler}>キャンセル</Button>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow>
          {Object.values(PACKAGE_FIELDS)
            .filter((key) => key !== PACKAGE_FIELDS.MEDIA_LIST)
            .map((key) => (
              <TableCell key={key}>{packageData[key]}</TableCell>
            ))}
          <TableCell>
            {packageData.mediaList.map((media) => (
              <Label key={media.id}>{media.id}</Label>
            ))}
            <hr />
            <Grid>
              <Grid.Column width={12}>
                {packageInput.mediaList.map((media, index) => {
                  const purchaceUnit = getColInfoFromTypeName(media.mediaType).purchasePeriodUnit
                  const unitFieldKey = purchaceUnit === 'month' ? 'months' : 'weeks'
                  return (
                    <div key={media.id}>
                      <Label>{media.id}</Label>
                      {'期間:'}
                      <Input name={unitFieldKey} onChange={handleChangePurchaceUnit(index)} type="number" value={media[unitFieldKey]} min={1} />
                      {unitFieldKey}
                    </div>
                  )
                })}
              </Grid.Column>
              <Grid.Column width={4}>
                <Button onClick={updatePackageHandler}>期間を更新</Button>
              </Grid.Column>
            </Grid>
          </TableCell>
          <TableCell>
            <Button onClick={changeEditingModeHandler}>編集</Button>
            <Button onClick={deletePackageHandler}>削除</Button>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default EditablePackageRowContent
