import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'providers/Auth'
import paths from 'constants/paths'
import { useHistory, useLocation } from 'react-router-dom'
import { Container, Header, Segment } from 'semantic-ui-react'
import AppLayoutOwner from 'components/organisms/layouts/AppLayoutOwner'
import { MediasOwnContext } from 'providers/MediasOwn'
import EditMediaForm from 'components/organisms/owner/EditMediaForm'
import Breadcrump from 'components/organisms/breadcrumb/EditMediaBreadcrump'
import { shouldSignin } from 'helpers/shouldSignin'

const EditMedia = () => {
  const { currentUser } = useContext(AuthContext)
  const history = useHistory()
  const { mediasOwn } = useContext(MediasOwnContext)
  const [media, setMedia] = useState('')
  const location = useLocation()

  // TODO: currentUserの有無のチェックをlayoutに移動
  useEffect(() => {
    if (shouldSignin(currentUser)) {
      history.push(`${paths.signinowner}`)
    }
  })

  useEffect(() => {
    console.log(location.pathname.split('/').pop())
    if (location.pathname) {
      let ref = mediasOwn.find((media) => media.uid === location.pathname.split('/').pop())
      if (ref) {
        setMedia(ref)
      } else {
        console.log('no uid')
      }
    }
  }, [history, location.pathname, mediasOwn])

  return (
    <>
      <AppLayoutOwner>
        <br />
        <Container>
          <Breadcrump mediaName={media.name} />
          <Segment>
            <Header as="h2">{media.name}の編集</Header>
          </Segment>
          <br />
          <Segment>
            <Container text>
              <EditMediaForm media={media} />
            </Container>
          </Segment>
        </Container>
      </AppLayoutOwner>
    </>
  )
}

export default EditMedia
