import React from 'react'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import Spacer from 'components/atoms/Spacer'
import { GridRow } from 'semantic-ui-react'
import { AdviceMediaDataType } from 'constants/mediaData'

type impressionFigureDefaultProps = {
  media: AdviceMediaDataType
  isMinimized: boolean
}

const ImpressionFigureDefault = ({ media, isMinimized }: impressionFigureDefaultProps) => {
  const figureInfo = getColInfoFromTypeName(media.mediaType).propertyFields
  const figureJsx: JSX.Element[] = []
  for (const info of figureInfo) {
    figureJsx.push(
      <GridRow key={info.displayedName}>
        <div style={{ marginLeft: '1rem', maxWidth: 500 }}>
          <p>{info.displayedName}</p>
          <Spacer />
          {/* stackedBarの場合のみisMinimizedというpropsを指定する必要あり */}
          {info.graphType === 'stackedBar' ? info.displayMethod(media, isMinimized) : info.displayMethod(media)}
        </div>
      </GridRow>
    )
  }
  return <>{figureJsx}</>
}

type impressionFigureProps = {
  media: AdviceMediaDataType
  isMinimized?: boolean
}
const ImpressionFigure = ({ media, isMinimized = false }: impressionFigureProps) => {
  switch (media) {
    default:
      return <ImpressionFigureDefault media={media} isMinimized={isMinimized} />
  }
}
export default ImpressionFigure
