import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'providers/Auth'
import paths from 'constants/paths'
import { useHistory, useLocation } from 'react-router-dom'
import { Header, Grid, Button } from 'semantic-ui-react'
import AppLayout from 'components/organisms/layouts/AppLayout'
import SubmitContent from 'components/pages/Submit/SubmitPage/SubmitContent'
import { CartFindFilter } from 'helpers/filters/CartFindFilter'
import AppLayoutGroupMenuBar from 'components/organisms/layouts/AppLayoutGroupMenuBar'
//import SubmitBreadcrump from 'components/organisms/breadcrumb/SubmitBreadcrump'
import { CartsContext } from 'providers/Carts'
import { mediaListCartGenerator } from 'helpers/filters/mediaListCartGenerator'
import { MediasAvailableContext } from 'providers/MediasAvailable'
import firebase, { db } from 'FirebaseConfig'
import { CountMonths } from 'helpers/calculates/CountMonths'
import Spacer from 'components/atoms/Spacer'
import { shouldSignin } from 'helpers/shouldSignin'

const ConfirmCart = () => {
  const [flagAngle, setFlagAngle] = useState(false)
  const { currentUser } = useContext(AuthContext)
  const history = useHistory()
  const location = useLocation()
  const { carts } = useContext(CartsContext)
  const [cart, setCart] = useState('')
  const { mediasAvailable } = useContext(MediasAvailableContext)
  const [mediaSortedList, setMediaSortedList] = useState()
  const [months, setMonths] = useState(0)
  const [loading, setLoading] = useState(false)
  const [userIdFlag, setUserIdFlag] = useState(false)

  useEffect(() => {
    if (cart.startDate && cart.endDate) {
      let monthsRef = CountMonths(cart.startDate, cart.endDate)
      setMonths(monthsRef)
    }
  }, [cart.endDate, cart.startDate])

  useEffect(() => {
    if (shouldSignin(currentUser)) {
      history.push(`${paths.signin}`)
    }

    let carttes = ''
    try {
      db.collection('cart')
        .doc(cart.uid)
        .get()
        .then((doc) => {
          carttes = doc.data()
          if (carttes.userId === currentUser.uid) {
            setUserIdFlag(true)
          }
        })
    } catch (e) {
      console.error(e)
    }
  })

  useEffect(() => {
    if (location.pathname) {
      let cartRef = CartFindFilter(carts, location.pathname.substr(-20))
      if (cartRef) {
        setCart(cartRef)
      }
    } else {
      console.log('no iud')
    }
  }, [carts, location.pathname])

  useEffect(() => {
    let mediaList = mediaListCartGenerator(mediasAvailable, cart)
    // インプ／コストの高い順番に並べて、mediaSortedListにセットする。
    // mediaList.sort(cpmSortCallback)
    setMediaSortedList(mediaList)
  }, [cart, mediasAvailable, months])

  const clickRemoveMediafromCart = (media) => {
    if (window.confirm('カートから削除しますか？')) {
      db.collection('cart')
        .doc(cart.uid)
        .update({
          id: firebase.firestore.FieldValue.arrayRemove(media.uid),
        })
        .then(() => {
          console.log('removed')
        })
    }
  }

  return (
    <>
      {userIdFlag && (
        <AppLayout>
          <AppLayoutGroupMenuBar flagAngle={flagAngle} setFlagAngle={setFlagAngle}>
            {/* { (loading || mediaSortedList === undefined) &&
            <Dimmer
              active
              inverted
            >
              <Loader size='massive'>Loading</Loader>
            </Dimmer>
          } */}
            {/* <SubmitBreadcrump /> */}
            <Header as="h2">クリエイティブ入稿</Header>
            <Grid>
              <Grid.Column>
                <SubmitContent
                  cart={cart}
                  mediaSortedList={mediaSortedList}
                  months={months}
                  clickRemoveMediafromCart={clickRemoveMediafromCart}
                  loading={loading}
                  setLoading={setLoading}
                />

                <Spacer />
                <Button color="grey" onClick={() => history.push(`/group/dashboard?campaignId=${cart.campaignId}`)}>
                  グループダッシュボードに戻る
                </Button>
              </Grid.Column>
            </Grid>
          </AppLayoutGroupMenuBar>
        </AppLayout>
      )}
    </>
  )
}

export default ConfirmCart
