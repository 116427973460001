import React from 'react'
import { MediaImage } from 'components/organisms/images/MediaImage'
import { Header } from 'semantic-ui-react'
import './index.css'

const MediaCardSubmit = (props) => {
  return (
    <>
      <div style={{ display: 'grid' }} className="mediaCardSubmit__mediaCardCard" key={props.media.uid}>
        <div style={{ gridRow: '1/5', gridColumn: '1/2', marginRight: '1rem' }}>
          <MediaImage floated="left" width="100px" media={props.media} />
        </div>
        <div style={{ gridRow: '1/2', gridColumn: '2/5' }}>
          <Header as="h5">{props.media.name}</Header>
        </div>

        <div style={{ gridRow: '2/3', gridColumn: '2/5' }}>
          <p className="mediaCardSubmit__cartSummaryText">{props.media.address}</p>
        </div>
        <div style={{ gridRow: '4/5', gridColumn: '2/3' }}></div>
      </div>
    </>
  )
}
export default MediaCardSubmit
