export const isOwner = (users: { docId: string; isOwner: boolean }[], userId: string) => {
  let owner = false
  if (users && userId) {
    const ref = users.find((u) => u.docId === userId)
    if (ref) {
      if (ref.isOwner === true) {
        owner = true
      } else {
        owner = false
      }
    }
  }
  return owner
}
