import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import { db } from 'FirebaseConfig'
import GoogleMapReact from 'google-map-react'
import { Grid, GridColumn, GridRow, Input, Label, Button, Dropdown } from 'semantic-ui-react'
import { getGeodata, getBuildingList, safeAlert } from 'helpers/admin/utils'
import { mediaTypes } from 'helpers/mediaType/mediaDef'
import { metre2degree } from 'helpers/admin/utils'
import { geoData22DArray, getVisibleRegion } from 'helpers/admin/getVisibleRegion'
import { SPACE } from 'helpers/admin/visibility_constants'
import { ImageSlider } from 'components/organisms/ImageSlider'
import { getNewRange, uploadMediaImage, uploadVision } from 'helpers/admin/helper'
const getTagNames = firebase.functions().httpsCallable('getTagNames')
const getLineTags = firebase.functions().httpsCallable('getLineTags')
const getHighwayTags = firebase.functions().httpsCallable('getHighwayTags')

const VisionMediaForm = (props) => {
  // 媒体のタイプ一覧
  const [mediaInfo, setMediaInfo] = useState({
    meshCD: null, // メッシュコード
    address: '', // 看板の住所
    name: '', // 媒体名
    direction: 0, // 看板の向いている方角
    hasIllumination: false,
    note: '', // 看板の説明文
    summary: '', // 看板のサマリ
    verticalWidth: 0, // 看板の縦、横幅
    horizontalWidth: 0,
    height: 0, // 看板の地上からの高さ
    heightSurrBuildings: 0, // 看板の周囲の建物の高さ
    numSurrBoards: 0, // 看板の周囲にある他の看板の数
    range: 0, // 可視領域の最大半径
    superRange: 0, // ユーザが設定した可視領域の最大半径
    useSuperRange: false, // ユーザが設定した可視領域の最大半径
    weeklyCost: 0,
    constructionCost: 0, // 施工費
    recoveryCost: 0, //回復費
    mediaType: mediaTypes[0], // 媒体のタイプ
    mediaImageObjs: [], // 媒体の写真に関する情報が入った配列,
    isPrivate: false,
    tempCirculation: 0,
    useTempCirculation: false,
    tempVisibility: 0,
    useTempVisibility: false,
    tags: [],
    lines: [],
    highways: [],
    hasAudio: false,
    ...props.mediaInfo,
  })
  useEffect(() => {
    if (props.mediaInfo.latitude && props.mediaInfo.longitude) {
      setBuildingPos({ lat: props.mediaInfo.latitude, lng: props.mediaInfo.longitude })
    }
    setMediaInfo((prevState) => {
      return { ...prevState, ...props.mediaInfo }
    })
  }, [props.mediaInfo])
  const [buildingPos, setBuildingPos] = useState({ lat: 35.6432027, lng: 139.6729435 }) // 看板の緯度経度
  const [marker, setMarker] = useState(null) // 建物の位置を表すマーカー
  const [rectangles, setRectangles] = useState([])
  const [range, setRange] = useState([])
  //const [pointlist,setPointlist] = useState([]);
  // const [buildingList, setBuildingList] = useState([]); // 近隣の建物のリスト
  const [visiblePoints, setVisiblePoints] = useState([]) // 視認可能な代表点のポリゴンデータ

  // google map apiに関連した状態
  const [maps, setMaps] = useState(null) // internal api of the google map
  const [map, setMap] = useState(null)
  // const [polygons, setPolygons] = useState([]); // google map api上に載せるポリゴンへの参照を入れた配列

  // 媒体をアップロードできる状態かを示すフラグ
  const [uploadReady, setUploadReady] = useState(false)

  // 媒体手動で付与することのできるタグのオプション
  const [tagOptions, setTagOptions] = useState([])
  const [tagLineOptions, setTagLineOptions] = useState([])
  const [tagHighwayOptions, setTagHighwayOptions] = useState([])

  useEffect(() => {
    const unsubscriber = db.collection('tags').onSnapshot((snapshot) => {
      const temp = []
      for (const doc of snapshot.docs) {
        const tag = doc.data()
        temp.push({
          key: doc.id,
          // ドロップダウンでvalueにObjectを渡すと動作が保証されないのでStringifyしている
          // valueからどのoptionであるのか特定するケースもあるので、nameとidの順序は必ず以下の順にする(name,id)
          value: JSON.stringify({
            name: tag.name,
            id: doc.id,
          }),
          text: tag.name,
        })
      }
      setTagOptions(temp)
    })
    return unsubscriber
  }, [])

  useEffect(() => {
    const unsubscriber = db.collection('lines').onSnapshot((snapshot) => {
      const temp = []
      for (const doc of snapshot.docs) {
        const line = doc.data()
        temp.push({
          key: doc.id,
          value: doc.id,
          text: line.name,
        })
      }
      setTagLineOptions(temp)
    })
    return unsubscriber
  }, [])

  useEffect(() => {
    const unsubscriber = db.collection('highways').onSnapshot((snapshot) => {
      const temp = []
      for (const doc of snapshot.docs) {
        const highway = doc.data()
        temp.push({
          key: doc.id,
          value: doc.id,
          text: highway.name,
        })
      }
      setTagHighwayOptions(temp)
    })
    return unsubscriber
  }, [])

  useEffect(() => {
    changeMarkerPosition({ lat: buildingPos.lat, lng: buildingPos.lng })
  }, [marker, maps, buildingPos])

  /**
   * {colName: newValue}の組を受け取って、mediaInfo[colName] = newValueと更新する関数
   * @param {object} newVals {colName: newValue}の組
   *
   */
  // eslint-disable-next-line
  const updateMediaInfo = (newVals) => {
    setMediaInfo((prevState) => {
      return { ...prevState, ...newVals }
    })
  }

  const handleChange = (_event, { name, value }) => {
    switch (name) {
      case 'width': {
        const newHorizontalWidth = parseFloat(value)
        const newRange = getNewRange(mediaInfo.useSuperRange, mediaInfo.superRange, newHorizontalWidth, mediaInfo.verticalWidth)
        updateMediaInfo({ range: newRange, horizontalWidth: newHorizontalWidth })
        break
      }
      case 'length': {
        const newVerticalWidth = parseFloat(value)
        const newRange = getNewRange(mediaInfo.useSuperRange, mediaInfo.superRange, mediaInfo.horizontalWidth, newVerticalWidth)
        updateMediaInfo({ range: newRange, verticalWidth: newVerticalWidth })
        break
      }
      case 'useSuperRange': {
        const newRange = getNewRange(mediaInfo.useSuperRange, mediaInfo.superRange, mediaInfo.horizontalWidth, mediaInfo.verticalWidth)
        const newUseSuperRange = value
        updateMediaInfo({ range: newRange, useSuperRange: newUseSuperRange })
        break
      }
      case 'superRange': {
        const newSuperRange = parseFloat(value)
        const newRange = getNewRange(mediaInfo.useSuperRange, newSuperRange, mediaInfo.horizontalWidth, mediaInfo.verticalWidth)
        updateMediaInfo({ superRange: newSuperRange, range: newRange })
        break
      }
      case 'height': {
        updateMediaInfo({ height: parseFloat(value) })
        break
      }
      case 'direction': {
        updateMediaInfo({ direction: parseInt(value) })
        break
      }
      case 'numSurrBoards': {
        updateMediaInfo({ numSurrBoards: parseInt(value) })
        break
      }
      case 'heightSurrBuildings': {
        updateMediaInfo({ heightSurrBuildings: parseFloat(value) })
        break
      }
      case 'weeklyCost': {
        updateMediaInfo({ weeklyCost: parseInt(value) })
        break
      }
      case 'constructionCost': {
        updateMediaInfo({ constructionCost: parseInt(value) })
        break
      }
      case 'recoveryCost': {
        updateMediaInfo({ recoveryCost: parseInt(value) })
        break
      }
      case 'tempVisibility': {
        updateMediaInfo({ tempVisibility: Number(value) })
        break
      }
      case 'tempCirculation': {
        updateMediaInfo({ tempCirculation: Number(value) })
        break
      }
      case 'useTempVisiblity': {
        updateMediaInfo({ useTempVisibility: value })
        break
      }
      case 'useTempCirculation': {
        updateMediaInfo({ useTempCirculation: value })
        break
      }
      case 'tag': {
        const parsedTags = value.map((tag) => JSON.parse(tag))
        updateMediaInfo({ tags: parsedTags })
        break
      }
      case 'line': {
        updateMediaInfo({ lines: value })
        break
      }
      case 'highway': {
        updateMediaInfo({ highways: value })
        break
      }
      case 'lat': {
        coordinateCallback(value, 'lat')
        break
      }
      case 'lon': {
        coordinateCallback(value, 'lng')
        break
      }
      case 'name': {
        updateMediaInfo({ name: value })
        break
      }
      case 'address': {
        updateMediaInfo({ address: value })
        break
      }
      case 'note': {
        updateMediaInfo({ note: value })
        break
      }
      case 'summary': {
        updateMediaInfo({ summary: value })
        break
      }
      case 'isPrivate': {
        updateMediaInfo({ isPrivate: value === 'true' })
        break
      }
      case 'hasAudio': {
        updateMediaInfo({ hasAudio: value === 'true' })
        break
      }
    }
  }

  /**>
   * google map上の看板位置を示すマーカを移動させる処理
   */
  const changeMarkerPosition = (buildingPos) => {
    if (marker && maps) {
      let latlng = new maps.LatLng(buildingPos.lat, buildingPos.lng)
      marker.setPosition(latlng)
      map.setCenter(latlng)
    }
  }

  const changeRectanglePosition = (pointlist) => {
    if (rectangles && maps) {
      for (let i = 0; i < rectangles.length; i++) {
        if (pointlist[i]) {
          rectangles[i].setMap(map)
          rectangles[i].setBounds(
            new maps.LatLngBounds(
              new maps.LatLng(pointlist[i][0] - range[0], pointlist[i][1] - range[1]),
              new maps.LatLng(pointlist[i][0] + range[0], pointlist[i][1] + range[1])
            )
          )
        } else {
          /*
          rectangles[i].setBounds( new maps.LatLngBounds(
  			       new maps.LatLng( 0, 0 ) ,
               new maps.LatLng( 0, 0 )
             ) ) ;
          */
          rectangles[i].setMap(null)
        }
      }
    }
  }

  const computeTags = async (buildingPos) => {
    const res = await getTagNames({
      lat: buildingPos.lat,
      lng: buildingPos.lng,
    })
    if (res.data.tags) {
      updateMediaInfo({ tags: res.data.tags })
    }
  }

  const computeLines = async (buildingPos) => {
    const res = await getLineTags({
      lat: buildingPos.lat,
      lng: buildingPos.lng,
    })
    if (res.data.lines) {
      updateMediaInfo({ lines: res.data.lines.map((line) => line.lineId) })
    }
  }

  const computeHighways = async (buildingPos) => {
    const res = await getHighwayTags({
      lat: buildingPos.lat,
      lng: buildingPos.lng,
    })
    if (res.data.highways) {
      updateMediaInfo({ highways: res.data.highways.map((highway) => highway.id) })
    }
  }

  const coordinateCallback = async (value, propName) => {
    const newBuildingPos = { ...buildingPos }
    newBuildingPos[propName] = parseFloat(value)
    setBuildingPos(newBuildingPos)
    const tagsPromise = computeTags(newBuildingPos)
    const lineTagsPromise = computeLines(newBuildingPos)
    const highwayTagsPromise = computeHighways(newBuildingPos)
    await Promise.all([tagsPromise, lineTagsPromise, highwayTagsPromise])
    changeMarkerPosition(newBuildingPos)
    changeRectanglePosition(visiblePoints)
  }

  const onClickUploadMedia = async () => {
    /*
    //TODO: 地図でユーザが指定した視認範囲から、代表点を抽出する処理を書く
    let polygonArray = polygons.getPath().getArray(); // get a polygon representing the visible area
    let visiblePoints = extractPoint(polygonArray, SPACE); // 視認範囲から代表点を抽出
    */

    // 画像のアップロード
    try {
      await uploadMediaImage(mediaInfo.mediaImageObjs)
      safeAlert(window, '媒体画像の追加に成功しました')
    } catch (err) {
      safeAlert(window, '媒体画像のアップロードに失敗しました' + err)
    }
    await uploadVision(props.mediaID, buildingPos, mediaInfo, visiblePoints, SPACE)
  }

  const onMarkerDragged = async (event) => {
    let lat = event.latLng.lat()
    let lng = event.latLng.lng()
    const tagsPromise = computeTags({ lat: lat, lng: lng })
    const lineTagsPromise = computeLines({ lat: lat, lng: lng })
    const highwayTagsPromise = computeHighways({ lat: lat, lng: lng })
    await Promise.all([tagsPromise, lineTagsPromise, highwayTagsPromise])
    setBuildingPos({ lat: lat, lng: lng })
  }

  const getVisiblePoints = async () => {
    if (map && maps) {
      let geoData = await getGeodata(buildingPos.lat, buildingPos.lng, mediaInfo.range)
      let buildings = getBuildingList(buildingPos.lat, buildingPos.lng, mediaInfo.horizontalWidth, mediaInfo.range, geoData)
      buildings = geoData22DArray(buildings)
      // setBuildingList(buildings);
      // eslint-disable-next-line
      let [newVisiblePoint, meshList] = await getVisibleRegion(
        buildingPos.lat,
        buildingPos.lng,
        mediaInfo.height,
        mediaInfo.horizontalWidth,
        mediaInfo.range,
        mediaInfo.direction,
        buildings
      )
      setVisiblePoints(newVisiblePoint)
      //await setPointlist(visiblePoints);

      changeRectanglePosition(newVisiblePoint)
      // ---------------------------------------------------------↓TODO: debug and uncomment below

      /*TODO: uncomment below
      let outline = makeOutline(meshList);
      if (outline.length < 2){
        if (window){
          window.alert("看板の可視範囲に人が通行できる通路がありませんでした");
        }
        setLoadBuildings(false);
        return;
      }
      removePolygons(polygons); // 現在のポリゴンを地図から消す
      addPolygons([outline], maps, map, setPolygons, false); //TODO: editable=trueにして地図上から編集できるようにする
      */
      setUploadReady(true) // 媒体アップロード可能な状態にする
    }
  }

  // ファイルの読み込み時に走る操作
  const handleChangeFile = (e) => {
    let files = e.target.files
    let counter = 0
    let newImageObjs = [...mediaInfo.mediaImageObjs]
    for (let i = 0; i < files.length; i++) {
      const fr = new FileReader()
      fr.readAsDataURL(files[i])
      fr.addEventListener('load', () => {
        const fileType = files[i].name.match(/\.(jpg|png|gif|jpeg)$/)[1]
        const fileName = files[i].name.substring(0, files[i].name.lastIndexOf('.'))
        newImageObjs.push({ imageUrl: fr.result, name: 'images/media/' + fileName + '_' + Number(new Date()) + '.' + fileType, file: files[i], done: false })
        counter += 1
        if (counter === files.length) {
          updateMediaInfo({ mediaImageObjs: newImageObjs })
        }
      })
    }
  }

  return (
    <>
      <Grid columns={2} devided>
        <GridColumn>
          <GridRow>
            <Label>公開/非公開</Label>
            <Input type="radio" id="public" name="isPrivate" value={false} checked={!mediaInfo.isPrivate} onChange={handleChange} />
            <label htmlFor="public">
              <Label>公開</Label>
            </label>
            <Input type="radio" id="private" name="isPrivate" value={true} checked={mediaInfo.isPrivate} onChange={handleChange} />
            <label htmlFor="private">
              <Label>非公開</Label>
            </label>
          </GridRow>

          <GridRow>
            <Label>看板の地上からの高さ</Label>
            <Input name="height" type="number" placeholder="看板の高さ" value={mediaInfo.height} onChange={handleChange} />
            <Label>看板の横幅</Label>
            <Input name="width" type="number" placeholder="看板の横幅" value={mediaInfo.horizontalWidth} onChange={handleChange} />
            <Label>看板の縦の幅</Label>
            <Input name="length" type="number" placeholder="看板の縦の幅" value={mediaInfo.verticalWidth} onChange={handleChange} />
          </GridRow>
          <GridRow>
            <Label>媒体の緯度</Label>
            <Input name="lat" type="number" placeholder="緯度" value={buildingPos.lat} step="0.01" onChange={handleChange} />
            <Label>媒体の経度</Label>
            <Input name="lon" type="number" placeholder="経度" value={buildingPos.lng} step="0.01" onChange={handleChange} />
            <GridRow>
              <Label>タグ</Label>
              <Dropdown
                placeholder="Select Tag"
                fluid
                multiple
                search
                selection
                name="tag"
                onChange={handleChange}
                value={mediaInfo.tags.map((tag) => JSON.stringify(tag))}
                options={tagOptions}
              />
              <Label>路線タグ</Label>
              <Dropdown
                placeholder="Select Line"
                fluid
                multiple
                search
                selection
                name="line"
                onChange={handleChange}
                value={mediaInfo.lines}
                options={tagLineOptions}
              />
              <Label>道路タグ</Label>
              <Dropdown
                placeholder="Select Highway"
                fluid
                multiple
                search
                selection
                name="highway"
                onChange={handleChange}
                value={mediaInfo.highways}
                options={tagHighwayOptions}
              />
            </GridRow>
            <GridRow>
              <Label>媒体の向き</Label>
              <Input name="direction" type="number" placeholder="媒体の向き(0~360度)" value={mediaInfo.direction} step="1" onChange={handleChange} />
            </GridRow>
          </GridRow>
          <GridRow>
            <Label>媒体名</Label>
            <Input type="text" placeholder="name" name="name" value={mediaInfo.name} onChange={handleChange} />
            <Label>媒体住所</Label>
            <Input type="text" placeholder="address" name="address" value={mediaInfo.address} onChange={handleChange} />
          </GridRow>
          <GridRow>
            <Label>媒体の説明文(note)</Label>
            <Input type="text" style={{ width: '600px' }} placeholder="note" name="note" value={mediaInfo.note} onChange={handleChange} />
          </GridRow>
          <GridRow>
            <Label>媒体のサマリ(summary)</Label>
            <Input type="text" style={{ width: '600px' }} placeholder="summary" name="summary" value={mediaInfo.summary} onChange={handleChange} />
          </GridRow>

          <GridRow>
            <Label>音声の有無</Label>
            <Input type="radio" id="audio" name="hasAudio" value={true} checked={mediaInfo.hasAudio} onChange={handleChange} />
            <label htmlFor="audio">
              <Label>あり</Label>
            </label>
            <Input type="radio" id="noAudio" name="hasAudio" value={false} checked={!mediaInfo.hasAudio} onChange={handleChange} />
            <label htmlFor="noAudio">
              <Label>なし</Label>
            </label>
          </GridRow>

          <GridRow>
            <Label>視認半径</Label>
            <Input name="superRange" type="Number" placeholder="視認半径" value={mediaInfo.superRange} onChange={handleChange} />
            <Label>入力した値を看板の視認半径として使う</Label>
            <Input type="checkbox" name="useSuperRange" id="useSuperRange" value="true" checked={mediaInfo.useSuperRange} onChange={handleChange} />
          </GridRow>
          <GridRow>
            <Label>周囲の他の看板の数</Label>
            <Input name="numSurrBoards" type="Number" placeholder="周囲の看板数" value={mediaInfo.numSurrBoards} onChange={handleChange} />
          </GridRow>

          <GridRow>
            <Label>看板周囲の建物の高さ</Label>
            <Input name="heightSurrBuildings" type="Number" placeholder="看板周囲の建物高さ" value={mediaInfo.heightSurrBuildings} onChange={handleChange} />
          </GridRow>
          <GridRow>
            <Label>媒体の週額(税抜き)</Label>
            <Input name="weeklyCost" type="Number" placeholder="媒体週額" value={mediaInfo.weeklyCost} onChange={handleChange} />
            <br />
            <Label>媒体の施工費</Label>
            <Input name="constructionCost" type="Number" placeholder="施工費" value={mediaInfo.constructionCost} onChange={handleChange} />
            <br />
            <Label>媒体の回復費</Label>
            <Input name="recoveryCost" type="Number" placeholder="回復費" value={mediaInfo.recoveryCost} onChange={handleChange} />
          </GridRow>
          <GridRow>
            <Label>媒体の仮視認率</Label>
            <Input name="tempVisibility" type="Number" placeholder="仮視認率" value={mediaInfo.tempVisibility} onChange={handleChange} />
            <Label>入力した値を仮視認率として使う</Label>
            <Input type="checkbox" name="useTempVisiblity" id="useTempVisiblity" checked={mediaInfo.useTempVisibility} onChange={handleChange} />
          </GridRow>
          <GridRow>
            <Label>媒体の仮サーキュレーション/日</Label>
            <Input name="tempCirculation" type="Number" placeholder="仮サーキュレーション/日" value={mediaInfo.tempCirculation} onChange={handleChange} />
            <Label>入力した値を仮サーキュレーション/日として使う</Label>
            <Input type="checkbox" name="useTempCirculation" id="useTempCirculation" checked={mediaInfo.useTempCirculation} onChange={handleChange} />
          </GridRow>
          <GridRow>
            <label>
              媒体の画像を追加
              <input type="file" multiple accept="image/*" name="mediaImages" onChange={handleChangeFile} />
            </label>
          </GridRow>
          <GridRow>
            <ImageSlider images={mediaInfo.mediaImageObjs.map((mediaImageObj) => mediaImageObj.imageUrl).reverse()} />
            {/*配列の中身の順番を逆にすることで、ユーザが直近で追加した画像が最初に表示されるようにする */}
            <GridRow>
              <Button onClick={getVisiblePoints}> 視認範囲を計算 </Button>
            </GridRow>
            <GridRow>
              {uploadReady && ( // 媒体を追加できる状態になり次第、媒体追加ボタンを出す
                <Button onClick={onClickUploadMedia}> 媒体を追加 </Button>
              )}
            </GridRow>
          </GridRow>
        </GridColumn>
        <GridColumn>
          <div style={{ height: '70vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyBz8o-2x_iarmPyLiv6dKe2g5DjZLmuw_M',
              }}
              defaultCenter={{ lat: 35.6432027, lng: 139.6729435 }}
              defaultZoom={12}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={({ map, maps }) => {
                let marker = addMarker(map, maps, buildingPos, onMarkerDragged)
                setRange(metre2degree(2.5))
                let rectangles = []
                //setPointlist(pointlist);
                for (let i = 0; i < 5000; i++) {
                  rectangles[i] = addRectangle(map, maps)
                }
                setMaps(maps)
                setMap(map)
                setMarker(marker)
                setRectangles(rectangles)
              }}
            ></GoogleMapReact>
          </div>
        </GridColumn>
      </Grid>
    </>
  )
}

export default VisionMediaForm

/*  *
 * google map api上へ建物のポリゴンを追加する
 * @param {Array} buildings
 * @param {*} maps
 * @param {*} map
 * @param {bool} editable
 * @returns Array
 */
/*
const addPolygons = (polygonArray, maps, map, setPolygons, editable) => {
  let gmapPolygon = [];
  for (let buildingIdx = 0; buildingIdx < polygonArray.length; buildingIdx++){
    let polygon = [];
    for (let pointIdx = 0; pointIdx < polygonArray[buildingIdx].length; pointIdx++){
      let [lat, lng] = polygonArray[buildingIdx][pointIdx];
      polygon.push(new maps.LatLng(lat, lng));
    }
    let p = new maps.Polygon({
      paths: polygon,
      editable: editable,
      "strokeColor": "#E92D63",
      "storkeWeight": 3,
      "storkeOpacity": 0.8,
      "fillColor": "#562DE9",
      "fillOpacity": 0.4
    });
    p.setMap(map);
    gmapPolygon.push(p);
  }
  setPolygons(gmapPolygon);
  return gmapPolygon;
}
*/
/**
 *
 * @param {Object} map an obj from google map api
 * @param {Object} maps an object from google map api
 * @param {Object} buildingPos lat, lngをキーにもつオブジェクト
 * @returns {Object} google map api上に追加されたマーカーのインスタンス
 */
const addMarker = (map, maps, buildingPos, onMarkerDragged) => {
  let marker = new maps.Marker({
    position: buildingPos,
    map,
    draggable: true,
  })
  maps.event.addListener(marker, 'dragend', onMarkerDragged)
  return marker
}

const addRectangle = (map, maps) => {
  let rectangle = new maps.Rectangle({
    map,
    bounds: new maps.LatLngBounds(new maps.LatLng(0, 0), new maps.LatLng(0, 0)),
    fillColor: 'green',
    strokeColor: 'green',
  })
  return rectangle
}
