import React, { useCallback, useState } from 'react'
import AppLayoutAdmin from 'components/organisms/layouts/AppLayoutAdmin'
import AdminEditForm from 'components/organisms/admin/AdminEditMediaForm'
import { genID } from 'helpers/admin/utils'
import { mediaTypes } from 'helpers/mediaType/mediaDef'
import MediaTypeSelect from 'components/molecules/radioGroup/MediaTypeSelect'

const NewMediaPage = () => {
  const mediaId = genID()
  const [selectedMediaType, setSelectedMediaType] = useState(mediaTypes[0])

  const changeMediaType = useCallback((_e, { value }) => {
    setSelectedMediaType(value)
  }, [])

  return (
    <AppLayoutAdmin>
      <h1>媒体追加</h1>
      <MediaTypeSelect selectedMediaType={selectedMediaType} changeCallback={changeMediaType} />
      <hr />
      <AdminEditForm mediaInfo={{ mediaType: selectedMediaType }} mediaID={mediaId} />
    </AppLayoutAdmin>
  )
}

export default NewMediaPage
