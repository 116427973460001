import React from 'react'
import { Table } from 'semantic-ui-react'

type Props = {
  targetAge: string[]
}

const TargetAgeCell = (props: Props) => {
  let jsxElms: JSX.Element[] = []
  const l = props.targetAge ? props.targetAge.length : 0
  if (l > 0 && l < 8) {
    jsxElms = props.targetAge.map((age, index) => {
      if (index !== l - 1) {
        if (index % 2 !== 1) {
          return <span>{age}代、</span>
        } else {
          return (
            <span>
              {age}代、
              <br />
            </span>
          )
        }
      } else {
        return <span>{age}代</span>
      }
    })
  } else {
    jsxElms.push(<span>全て</span>)
  }
  return <Table.Cell>{jsxElms}</Table.Cell>
}

export default TargetAgeCell
