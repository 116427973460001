import React from 'react'
import styled from '@emotion/styled'

export type Props = {
  height?: number
}

const Spacer = ({ height = 3 }: Props) => {
  const SpacerSegment = styled.div`
    clear: both;
    height: ${height}rem;
    width: auto;
  `

  return <SpacerSegment />
}

export default Spacer
