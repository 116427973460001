// Library
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { MediasAvailableContext } from 'providers/MediasAvailable'

import GoogleMapReact from 'google-map-react'

import { MAP_KEY, MAP_ID } from 'FirebaseConfig'
import MapSearchMarker from 'components/organisms/mediasearch/MapSearchMarker'
import MapPlaceSearchMarker from 'components/organisms/mediasearch/MapPlaceSearchMarker'
import MapPlaceSearchForm from 'components/organisms/mediasearch/MapPlaceSearchForm'
import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'
import { mediaList2Bounds } from 'helpers/calculates/CalculateAverageLatLng'

const DEFAULT_MAP_CENTER_LATITUDE = 35.6432027
const DEFAULT_MAP_CENTER_LONGITUDE = 139.6729435

type props = {
  targetMediaId: string
  //TODO: any型を排除する
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mediaList: AdviceMediaDataType[]
  setTargetMediaId: React.Dispatch<React.SetStateAction<string>>
  shouldDisplayDetail: boolean
  setShouldDisplayDetail: React.Dispatch<React.SetStateAction<boolean>>
}

//TODO: any型を排除する
// eslint-disable-next-line @typescript-eslint/no-explicit-any

type location = {
  lat: number
  lng: number
  text?: string
}

const MediaSearchMap = (props: props) => {
  const { mediasAvailable } = useContext(MediasAvailableContext)
  const [mapCenterLocation, setmapCenterLocation] = useState<location>({
    lat: DEFAULT_MAP_CENTER_LATITUDE,
    lng: DEFAULT_MAP_CENTER_LONGITUDE,
  })
  const [zoom, setZoom] = useState(15)
  const [mouseOveredMediaId, setMouseOveredMediaId] = useState<string>('')
  const [placeService, setPlaceService] = useState<google.maps.places.PlacesService>()
  const [searchPlaceMarkerLocation, setSearchPlaceMarkerLocation] = useState<location | null>(null)

  const [mapRef, setMapRef] = useState<any>()

  // 媒体情報を取得し、stateにセット
  useEffect(() => {
    if (props.targetMediaId) {
      // 媒体が一つ選択されている場合
      const ref = mediasAvailable.find((media) => media.uid === props.targetMediaId)
      if (!ref) {
        return
      }
      //ジャック媒体の親はジャック子の平均を計算
      // targetMediaを地図の中心にする
      if (props.shouldDisplayDetail) {
        if (ref.mediaType === 'mediaTypeJack') {
          const bounds = mediaList2Bounds(ref.childMedia)
          mapRef.fitBounds(bounds)
        } else {
          setZoom(15)
          setmapCenterLocation({
            lat: ref.latitude,
            lng: ref.longitude,
          })
        }
      }
    } else {
      setZoom(14)
    }
  }, [mediasAvailable, props.mediaList, props.targetMediaId, props.shouldDisplayDetail, mapRef])

  ///////////////////////////////////////////////////////////////////////////////
  // GoogleMapのオプション
  const createMapOptions = () => {
    return {
      mapTypeControl: true,
      streetViewControl: true,
      styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
      mapId: MAP_ID,
    }
  }

  const clickMarkerHandler = useCallback((mediaId: string) => {
    return () => {
      props.setShouldDisplayDetail(true)
      props.setTargetMediaId(mediaId)
    }
  }, [])

  const mouseOverMarkerHandler = useCallback((key: string) => {
    setMouseOveredMediaId(key)
  }, [])

  const mouseLeaveMarkerHandler = useCallback(() => {
    setMouseOveredMediaId('')
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleGoogleMapsAPILoaded = useCallback((maps: any, map: google.maps.Map) => {
    setPlaceService(new maps.places.PlacesService(map))
    setMapRef(map)
  }, [])

  const submitFormHandler = useCallback(
    (request: google.maps.places.FindPlaceFromQueryRequest) => {
      return new Promise<void>((res) => {
        placeService?.findPlaceFromQuery(request, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const resultLocation = results && results[0].geometry?.location
            if (resultLocation) {
              setmapCenterLocation({
                lat: resultLocation.lat(),
                lng: resultLocation.lng(),
              })
              setSearchPlaceMarkerLocation({
                lat: resultLocation.lat(),
                lng: resultLocation.lng(),
                text: results[0].name,
              })
            } else {
              setSearchPlaceMarkerLocation(null)
            }
          }
          res()
        })
      })
    },
    [placeService]
  )

  const isSameJackGroup = useCallback(
    (media: AdviceMediaDataType, targetMediaId: string) => {
      const parentUid = media.mediaType === 'mediaTypeJack' ? media.uid : isJackChild(media) ? media.parentUid : null
      if (!parentUid) {
        return false
      }
      const targetMedia = mediasAvailable.find((e) => e.uid === targetMediaId)
      if (!targetMedia) {
        return false
      }
      const targetParentUid = targetMedia.mediaType === 'mediaTypeJack' ? targetMedia.uid : isJackChild(targetMedia) ? targetMedia.parentUid : null
      return parentUid === targetParentUid
    },
    [mediasAvailable]
  )

  return (
    <div style={{ height: 'calc(100vh - 56px)', width: '100%' }}>
      <MapPlaceSearchForm submitFormHandler={submitFormHandler} />
      {MAP_KEY && (
        <GoogleMapReact
          // TODO: typeScript化したときに怒られるはず(https://github.com/google-map-react/google-map-react/issues/1039)
          bootstrapURLKeys={{
            key: MAP_KEY,
            libraries: ['places'],
          }}
          defaultCenter={{ lat: DEFAULT_MAP_CENTER_LATITUDE, lng: DEFAULT_MAP_CENTER_LONGITUDE }}
          center={{ lat: mapCenterLocation.lat, lng: mapCenterLocation.lng }}
          defaultZoom={14}
          zoom={zoom}
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals={true}
          onChildMouseEnter={mouseOverMarkerHandler}
          onChildMouseLeave={mouseLeaveMarkerHandler}
          onChange={(event) => {
            setZoom(event.zoom)
            setmapCenterLocation({
              lat: event.center.lat,
              lng: event.center.lng,
            })
          }}
          onGoogleApiLoaded={({ maps, map }) => {
            handleGoogleMapsAPILoaded(maps, map)
          }}
        >
          {searchPlaceMarkerLocation && (
            <MapPlaceSearchMarker lat={searchPlaceMarkerLocation.lat} lng={searchPlaceMarkerLocation.lng} text={searchPlaceMarkerLocation.text} />
          )}
          {props.mediaList
            .filter((media) => media.latitude !== undefined || media.longitude !== undefined)
            .map((media) => {
              return (
                <MapSearchMarker
                  onClick={clickMarkerHandler(media.uid)}
                  lat={media.latitude}
                  lng={media.longitude}
                  key={media.uid}
                  isTargetMedia={media.uid === props.targetMediaId}
                  isMouseOvered={mouseOveredMediaId === media.uid}
                  isSameJackGroup={isSameJackGroup(media, props.targetMediaId)}
                  isJackParent={media.mediaType === 'mediaTypeJack'}
                  media={media}
                />
              )
            })}
        </GoogleMapReact>
      )}
    </div>
  )
}

export default MediaSearchMap
