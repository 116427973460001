import React, { useContext, useEffect, useState } from 'react'
import { Button, Dropdown } from 'semantic-ui-react'
import MediaCardCart from 'components/organisms/card/MediaCardCart'
import { mediaListCartGenerator } from 'helpers/filters/mediaListCartGenerator'
import { MediasAvailableContext } from 'providers/MediasAvailable'
import { useHistory } from 'react-router-dom'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'
import './index.css'

const CartContentTab = (props) => {
  const [mediaSortedList, setMediaSortedList] = useState<(AdviceMediaDataType | undefined)[]>([])
  const { mediasAvailable } = useContext(MediasAvailableContext)
  const [mediaSort, setMediaSort] = useState('all')
  const [frag, setFrag] = useState(false)
  const history = useHistory()

  useEffect(() => {
    let mediaList: Array<AdviceMediaDataType> = mediasAvailable ? mediaListCartGenerator(mediasAvailable, props.cart) : []
    props.cart.id ? props.setCartCount(mediaList.length) : props.setCartCount(0) //カートの媒体数をセット
    if (mediaSort === 'jack') {
      mediaList = mediaList.filter((media) => isJackChild(media))
    }
    setMediaSortedList(mediaList)

    if (mediaList) {
      const colInfo = getColInfoFromTypeName(props.cart.mediaType)
      const newBundleSummary = colInfo.culcBundleSummary(mediaList, props.period, true)
      props.setBundleSummary(newBundleSummary)
    }
  }, [mediasAvailable, props.cart, history, props.activeItem, props.cart.uid, props.period, mediaSort])

  const onChangeDropdown = (e, data) => {
    setMediaSort(data.value)
  }

  const onClickMediaCard = (mediaId, index) => {
    if (props.mediaTarget !== mediaId) {
      props.setMediaTarget(mediaId)
      props.setCurrentKey(index)
    } else {
      props.setMediaTarget('')
      props.setCurrentKey(-1)
    }
  }

  const dropDownOptions = [
    {
      key: 'all',
      text: '全て',
      value: 'all',
    },
    {
      key: 'jack',
      text: 'ジャックのみ',
      value: 'jack',
    },
  ]

  useEffect(() => {
    if (mediaSortedList && mediaSortedList.length) {
      setFrag(true)
    } else {
      setFrag(false)
    }
  }, [mediaSortedList])

  return (
    <>
      <div className="cartContentTab__scrollCartTab">
        {props.cart.status === 1 && (
          <div>
            <Button color="orange" style={{ marginBottom: '2rem' }} onClick={() => history.push(`/cart/confirm/${props.cart.uid}`)} disabled={!frag}>
              確認画面に進む
            </Button>
            <Dropdown options={dropDownOptions} selection defaultValue={'all'} onChange={onChangeDropdown} />
          </div>
        )}
        {mediaSortedList &&
          mediaSortedList.map((media, index) =>
            media ? (
              <div
                onClick={() => onClickMediaCard(media.uid, index)}
                className={`cartContentTab__mediaCardDefault ${index === props.currentKeyInfo ? '' : ''} ${
                  index === props.currentKey ? 'cartContentTab__mediaCardSelected' : ''
                }`}
                key={media.uid}
              >
                <MediaCardCart media={media} period={props.period} />
              </div>
            ) : (
              ''
            )
          )}
      </div>
    </>
  )
}

export default CartContentTab
