import React from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'

const StopMediaBreadcrump = () => {
  const history = useHistory()
  return (
    <>
      <Breadcrumb size={'mini'}>
        <Breadcrumb.Section onClick={() => history.push(`${paths.menuowner}`)} link active>
          <i style={{ color: 'gray' }} className="home icon" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section>掲載一時停止</Breadcrumb.Section>
      </Breadcrumb>
    </>
  )
}

export default StopMediaBreadcrump
