import React, { useEffect, useState, useContext } from 'react'
import { AddMediasContext } from 'providers/AddMedias'
import { auth } from 'FirebaseConfig'
import { Table } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { Container, Header } from 'semantic-ui-react'
import Spacer from 'components/atoms/Spacer'
import AppLayoutOwner from 'components/organisms/layouts/AppLayoutOwner'
import { AuthContext } from 'providers/Auth'
import paths from 'constants/paths'
import { shouldSignin } from 'helpers/shouldSignin'

const AddMediasList = () => {
  const [addMedia, setAddMedia] = useState('')
  const { addMedias } = useContext(AddMediasContext)
  const [frag, setFrag] = useState(false)

  const { currentUser } = useContext(AuthContext)
  const history = useHistory()

  // TODO: currentUserの有無のチェックをlayoutに移動
  useEffect(() => {
    if (shouldSignin(currentUser)) {
      history.push(`${paths.signinowner}`)
    }
  })

  useEffect(() => {
    if (addMedias) {
      auth.onAuthStateChanged((user) => {
        let ref = [{}]
        ref = addMedias
        ref = ref.filter((row) => {
          if (row.userId !== user.uid) {
            return false
          }
          return row
        })
        setAddMedia(ref)
        if (ref === []) {
          setFrag(false)
        } else {
          setFrag(true)
        }
      })
    }
  }, [addMedias])

  return (
    <>
      <AppLayoutOwner>
        <Spacer />
        <Container>
          <Header as="h2">媒体情報の登録一覧</Header>
          <Spacer />
          {frag && (
            <>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>uid</Table.HeaderCell>
                    <Table.HeaderCell>name</Table.HeaderCell>
                    <Table.HeaderCell>address</Table.HeaderCell>
                    <Table.HeaderCell>heightSize</Table.HeaderCell>
                    <Table.HeaderCell>widthSize</Table.HeaderCell>
                    <Table.HeaderCell>heightPosition</Table.HeaderCell>
                    <Table.HeaderCell>direction</Table.HeaderCell>
                    <Table.HeaderCell>userId</Table.HeaderCell>
                    <Table.HeaderCell>createdAt</Table.HeaderCell>
                    <Table.HeaderCell>status</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {addMedia.map((item) => (
                    <Table.Row key={item.uid}>
                      <Table.Cell>{item.uid.slice(0, 3) + '…'}</Table.Cell>
                      <Table.Cell>{item.name}</Table.Cell>
                      <Table.Cell>{item.address}</Table.Cell>
                      <Table.Cell>{item.heightSize}</Table.Cell>
                      <Table.Cell>{item.widthSize}</Table.Cell>
                      <Table.Cell>{item.heightPosition}</Table.Cell>
                      <Table.Cell>{item.direction}</Table.Cell>
                      <Table.Cell>{item.userId.slice(0, 3) + '…'}</Table.Cell>
                      <Table.Cell>{item.createdAt.seconds}</Table.Cell>
                      <Table.Cell>{item.status}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          )}
        </Container>
      </AppLayoutOwner>
    </>
  )
}

export default AddMediasList
