import firebase from 'firebase'
import { mediaTypesEnum } from 'constants/mediaTypes'
import { tagDataType } from 'providers/Tags'

// mediaTypeごとのDBカラム(firestoreのmediaコレクション)の中身(field)を表した型(仕様はDocsに準拠)
export type FirestoreMediaDataType =
  | FirestorePosterDataType
  | FirestoreBillboardDataType
  | FirestoreVisionDataType
  | FirestoreLeafletDataType
  | FirestoreMansionSignageDataType

//各Typeは共通部分と独自部分の積集合
export type FirestoreBillboardDataType = FirestoreBasicMediaDataType & FirestoreBillboardDataTypeSub
export type FirestoreLeafletDataType = FirestoreBasicMediaDataType & FirestoreLeafletDataTypeSub
export type FirestoreVisionDataType = FirestoreBasicMediaDataType & FirestoreVisionDataTypeSub
export type FirestoreMansionSignageDataType = FirestoreBasicMediaDataType & FirestoreMansionSignageDataTypeSub
export type FirestorePosterDataType = FirestoreBasicMediaDataType & FirestorePosterDataTypeSub
export type FirestoreJackDataType = FirestoreBasicMediaDataType

// mediaTypeにかかわらず、すべての媒体が共通に持っているフィールド
export type FirestoreBasicMediaDataType = {
  address: string
  ageRatio: FirestoreAgeRatio
  maleAgeRatio: FirestoreMaleAgeRatio
  femaleAgeRatio: FirestoreFemaleAgeRatio
  name: string
  note: string
  isPrivate: boolean
  summary: string
  createdAt: firebase.firestore.Timestamp
  updatedAt?: firebase.firestore.Timestamp
  //owner~は媒体追加する場合のみ付加されるフィールド
  ownerEmail?: string
  ownerUserId?: string
  ownerFullName?: string
  ownerTel?: number
  ownerCompanyName?: string
  //↑ここまで
  latitude: number
  longitude: number
  mediaImagePaths: string[]
  mediaType: mediaType
  uid: string
  tags?: any
  lines?: any
  highways?: any
  deletedAt?: firebase.firestore.Timestamp | undefined // 消されていない場合はundefinedになる
  isDeleted?: boolean //消されていないundefined
  isLocalMedia?: boolean //undefinedである場合は、関東エリアの媒体
}
export type FirestoreBillboardDataTypeSub = {
  circulation?: FirestoreCirculation | null
  totalCirculation?: number
  circulationStatus: AdResApiStatus
  constructionCost: number
  direction: number
  hasIllumination: boolean
  height: number
  horizontalWidth: number
  impression?: FirestoreImpression
  totalImpression?: number
  impressionStatus?: AdResApiStatus
  monthlyCost: number
  recoveryCost: number
  verticalWidth: number
  visibility: number | null
  visibilityStatus: string
  yearlyCost: number
  tempVisibility: number | null
  tempCirculation: number | null
}

export type FirestoreLeafletDataTypeSub = {
  weeklyCost: number
  distributionCost: number
  industry: string
  storeName: string
}

export type FirestoreVisionDataTypeSub = {
  circulation?: FirestoreCirculation | null
  totalCirculation?: number
  circulationStatus: AdResApiStatus
  hasAudio: boolean
  height: number
  horizontalWidth: number
  weeklyCost: number
  verticalWidth: number
  visibility: number | null
  visibilityStatus: string
  tempVisibility: number | null
  tempCirculation: number | null
}

export type FirestorePosterDataTypeSub = {
  circulation?: FirestoreCirculation | null
  totalCirculation?: number
  circulationStatus: AdResApiStatus
  constructionCost: number
  hasIllumination: boolean
  height: number
  horizontalWidth: number
  impression?: number[]
  totalImpression?: number
  impressionStatus?: AdResApiStatus
  weeklyCost: number
  recoveryCost: number
  verticalWidth: number
  visibility: number | null
  visibilityStatus: string
  tempVisibility: number | null
  tempCirculation: number | null
}

export type FirestoreMansionSignageDataTypeSub = {
  horizontalWidth: number
  weeklyCost: number
  verticalWidth: number
  hasAudio: boolean
  majorResidentsProperty: ('family' | 'single' | 'dinks')[]
  mansionType: 'forRent' | 'forSale'
  numOfHouses: number
}

//impressionは長さ24、要素が全てnumberの配列またはnull
type FirestoreImpression = null | FixedSizeArray<24, number>
//circulationは長さ24、要素が全てnumberの配列またはnull
type FirestoreCirculation = null | FixedSizeArray<24, number>

//長さN、要素がTの配列のタイプ
type FixedSizeArray<N extends number, T> = N extends 0
  ? never[]
  : {
      0: T
      length: N
    } & ReadonlyArray<T>
//各年代の人数を表すオブジェクト
type FirestoreAgeRatio = {
  15: number
  20: number
  30: number
  40: number
  50: number
  60: number
  70: number
  80: number
}
//各年代の男性人数及びtotalの男性割合を表すオブジェクト
type FirestoreMaleAgeRatio = FirestoreAgeRatio & { total: number }
//各年代の女性人数及びtotalの女性割合を表すオブジェクト
type FirestoreFemaleAgeRatio = FirestoreAgeRatio & { total: number }

//mediaTypeの種類の配列
export const mediaTypes = Object.keys(mediaTypesEnum).map<mediaType>((mediaType) => mediaTypesEnum[mediaType])

// mediaTypeの"名前"を表す型
export type mediaType = 'mediaTypePoster' | 'mediaTypeBillboard' | 'mediaTypeVision' | 'mediaTypeLeaflet' | 'mediaTypeMansionSignage' | 'mediaTypeJack'

type AdResApiStatus = 'PENDING' | 'RUNNING' | 'SUCCEEDED' | 'FAILED'
// ------------------------以下型チェックの関数 ------------------------
//FirestoreMediaDataTypeかどうかの判定
export const isFirestoreMediaDataType = (value: unknown): value is FirestoreMediaDataType => {
  if (typeof value !== 'object' || value === null) {
    return false
  }
  let obj = value as Record<keyof FirestoreMediaDataType, unknown>
  switch (obj.mediaType) {
    case 'mediaTypeVision':
      obj = value as Record<keyof FirestoreVisionDataType, unknown>
      return isFirestoreVisionDataType(obj)
    case 'mediaTypeBillboard':
      obj = value as Record<keyof FirestoreBillboardDataType, unknown>
      return isFirestoreBillboardDataType(obj)
    case 'mediaTypeLeaflet':
      obj = value as Record<keyof FirestoreLeafletDataType, unknown>
      return isFirestoreLeafletDataType(obj)
    case 'mediaTypeMansionSignage':
      obj = value as Record<keyof FirestoreMansionSignageDataType, unknown>
      return isFirestoreMansionSignageDataType(obj)
    case 'mediaTypePoster':
      obj = value as Record<keyof FirestorePosterDataType, unknown>
      return isFirestorePosterDataType(obj)
    case 'mediaTypeJack':
      obj = value as Record<keyof FirestoreJackDataType, unknown>
      return isFirestoreJackDataType(obj)
    default:
      throw TypeError(`Received unknown mediaType: ${obj.mediaType}`)
  }
}
//FirestoreBillboardDataTypeかどうかの判定
export const isFirestoreBillboardDataType = (value: unknown): value is FirestoreBillboardDataType => {
  return isFirestoreBasicMediaDataType(value) && isFirestoreBillboardDataTypeSub(value)
}
//FirestoreVisionDataTypeかどうかの判定
export const isFirestoreVisionDataType = (value: unknown): value is FirestoreVisionDataType => {
  return isFirestoreBasicMediaDataType(value) && isFirestoreVisionDataTypeSub(value)
}
//FirestoreLeafletDataTypeかどうかの判定
export const isFirestoreLeafletDataType = (value: unknown): value is FirestoreLeafletDataType => {
  return isFirestoreBasicMediaDataType(value) && isFirestoreLeafletDataTypeSub(value)
}
//FirestoreMansionSignageDataTypeかどうかの判定
export const isFirestoreMansionSignageDataType = (value: unknown): value is FirestoreMansionSignageDataType => {
  return isFirestoreBasicMediaDataType(value) && isFirestoreMansionSignageDataTypeSub(value)
}
//FirestorePosterDataTypeかどうかの判定
export const isFirestorePosterDataType = (value: unknown): value is FirestorePosterDataType => {
  return isFirestoreBasicMediaDataType(value) && isFirestorePosterDataTypeSub(value)
}
//FirestoreJackDataTypeかどうかの判定 Jack固有のデータはサブコレクションのみなのでisFirestoreBasicMediaDataTypeのみ
export const isFirestoreJackDataType = (value: unknown): value is FirestoreJackDataType => {
  return isFirestoreBasicMediaDataType(value)
}

//FirestoreBasicMediaDataTypeに含まれないプロパティにはアクセスできないという意味で一定の型安全を保つ。(ただし、FirestoreBasicMediaDataTypeに含まれるプロパティについて以下ブロックで言及していない場合、そのプロパティに関しては型の確認が行えないことになる。)
export const isFirestoreBasicMediaDataType = (value: unknown): value is FirestoreBasicMediaDataType => {
  if (isObject(value)) {
    const obj = value as Record<keyof FirestoreBasicMediaDataType, unknown>
    const flag: boolean =
      validateType(obj.address, 'string') &&
      (Boolean(obj.isLocalMedia) ||
        (validateType(obj.ageRatio, isFirestoreAgeRatio) &&
          validateType(obj.maleAgeRatio, isFirestoreMaleAgeRatio) &&
          validateType(obj.femaleAgeRatio, isFirestoreFemaleAgeRatio))) &&
      validateType(obj.name, 'string') &&
      validateType(obj.note, 'string') &&
      validateType(obj.isPrivate, 'boolean') &&
      validateType(obj.summary, 'string') &&
      //TODO:firebaseの型についてどうするか聞く
      //if (!( typeof obj.createdAt === firebase.firestore.Timestamp )) throw new Error(`${obj.createdAt}はfirebase.firestore.Timestamp型ではありません。`)
      //if (!( typeof obj.updatedAt === firebase.firestore.Timestamp )) throw new Error(`${obj.updatedAt}はfirebase.firestore.Timestamp型ではありません。`)
      validateType(obj.ownerEmail, 'string', true) &&
      validateType(obj.ownerFullName, 'string', true) &&
      validateType(obj.ownerTel, 'number', true) &&
      validateType(obj.ownerCompanyName, 'string', true) &&
      validateType(obj.latitude, 'number') &&
      validateType(obj.longitude, 'number') &&
      Array.isArray(obj.mediaImagePaths) &&
      obj.mediaImagePaths.every((e) => validateType(e, 'string')) &&
      validateType(obj.mediaType, isMediaType) &&
      validateType(obj.uid, 'string') &&
      //TODO:firebaseの型についてどうするか聞く
      //if (!( typeof obj.deletedAt === firebase.firestore.Timestamp | undefined )) throw new Error(`${obj.aaaaa}はnumber型ではありません。`)// 消されていない場合はundefinedになる
      validateType(obj.isDeleted, 'boolean', true)
    return flag
  } else {
    return false
  }
}
//FirestoreBillboardDataTypeSubかどうかの判定
export const isFirestoreBillboardDataTypeSub = (value: unknown): value is FirestoreBillboardDataTypeSub => {
  if (isObject(value)) {
    const obj = value as Record<keyof FirestoreBillboardDataTypeSub | keyof FirestoreBasicMediaDataType, unknown>
    const flag =
      (Boolean(obj.isLocalMedia) ||
        ((typeof obj.visibility === 'undefined' || obj.visibility === null || typeof obj.visibility === 'number') && //throw new Error(`${obj.visibility}はnumber型ではありません。`)//TODO:エラーを吐けるように関数を作る
          (typeof obj.tempVisibility === 'number' || obj.tempVisibility === null) && //throw new Error(`${obj.tempVisibility}はnumber型ではないか値がありません。`)//TODO:エラーを吐けるように関数を作る
          (typeof obj.tempCirculation === 'number' || obj.tempCirculation === null) && //throw new Error(`${obj.tempCirculation}はnumber型ではないか値がありません。`)//TODO:エラーを吐けるように関数を作る
          validateType(obj.visibilityStatus, 'string', true) &&
          validateType(obj.circulation, isFirestoreCirculationArray, true) &&
          validateType(obj.totalCirculation, 'number', true) &&
          validateType(obj.circulationStatus, isAdResApiStatus, true) &&
          validateType(obj.impression, isFirestoreImpressionArray, true) &&
          validateType(obj.totalImpression, 'number', true) &&
          validateType(obj.impressionStatus, isAdResApiStatus, true))) &&
      validateType(obj.constructionCost, 'number', true) &&
      validateType(obj.direction, 'number') &&
      validateType(obj.hasIllumination, 'boolean') &&
      validateType(obj.height, 'number') &&
      validateType(obj.horizontalWidth, 'number') &&
      validateType(obj.monthlyCost, 'number', true) &&
      validateType(obj.recoveryCost, 'number', true) &&
      validateType(obj.verticalWidth, 'number') &&
      validateType(obj.yearlyCost, 'number', true)
    return flag
  } else {
    return false
  }
}
//FirestoreVisionDataTypeSubかどうかの判定
export const isFirestoreVisionDataTypeSub = (value: unknown): value is FirestoreVisionDataTypeSub => {
  if (isObject(value)) {
    const obj = value as Record<keyof FirestoreVisionDataTypeSub | keyof FirestoreBasicMediaDataType, unknown>
    const flag: boolean =
      (Boolean(obj.isLocalMedia) ||
        (validateType(obj.circulation, isFirestoreCirculationArray, true) &&
          validateType(obj.totalCirculation, 'number', true) &&
          validateType(obj.circulationStatus, isAdResApiStatus, true) &&
          (obj.visibility === null || typeof obj.visibility === 'number') && //throw new Error(`${obj.visibility}はnumber型ではありません。`) //TODO:エラーを吐けるように関数を作る
          validateType(obj.visibilityStatus, 'string') &&
          (typeof obj.tempVisibility === 'number' || obj.tempVisibility === null) && //throw new Error(`${obj.tempVisibility}はnumber型ではないか値がありません。`) //TODO:エラーを吐けるように関数を作る
          (typeof obj.tempCirculation === 'number' || obj.tempCirculation === null))) && //throw new Error(`${obj.tempCirculation}はnumber型ではないか値がありません。`) //TODO:エラーを吐けるように関数を作る
      validateType(obj.hasAudio, 'boolean') &&
      validateType(obj.height, 'number') &&
      validateType(obj.horizontalWidth, 'number') &&
      validateType(obj.weeklyCost, 'number', true) &&
      validateType(obj.verticalWidth, 'number')
    return flag
  } else {
    return false
  }
}
//FirestoreLeafletDataTypeSubかどうかの判定
export const isFirestoreLeafletDataTypeSub = (value: unknown): value is FirestoreLeafletDataTypeSub => {
  if (isObject(value)) {
    const obj = value as Record<keyof FirestoreLeafletDataTypeSub, unknown>
    const flag: boolean =
      validateType(obj.weeklyCost, 'number', true) &&
      validateType(obj.distributionCost, 'number', true) &&
      validateType(obj.industry, 'string') &&
      validateType(obj.storeName, 'string')
    return flag
  } else {
    return false
  }
}
//FirestoreMansionSignageDataTypeSubかどうかの判定
export const isFirestoreMansionSignageDataTypeSub = (value: unknown): value is FirestoreMansionSignageDataTypeSub => {
  if (isObject(value)) {
    const obj = value as Record<keyof FirestoreMansionSignageDataTypeSub, unknown>
    const flag: boolean =
      validateType(obj.horizontalWidth, 'number') &&
      validateType(obj.weeklyCost, 'number', true) &&
      validateType(obj.verticalWidth, 'number') &&
      validateType(obj.hasAudio, 'boolean') &&
      Array.isArray(obj.majorResidentsProperty) &&
      obj.majorResidentsProperty.every((e) => e === 'family' || e === 'single' || e === 'dinks') && //TODO:エラーを吐けるように関数を作る
      (obj.mansionType === 'forRent' || obj.mansionType === 'forSale') && //TODO:エラーを吐けるように関数を作る
      validateType(obj.numOfHouses, 'number')
    return flag
  } else {
    return false
  }
}
//FirestorePosterDataTypeSubかどうかの判定
export const isFirestorePosterDataTypeSub = (value: unknown): value is FirestorePosterDataTypeSub => {
  if (isObject(value)) {
    const obj = value as Record<keyof FirestorePosterDataTypeSub, unknown>
    const flag: boolean =
      validateType(obj.circulation, isFirestoreCirculationArray) &&
      validateType(obj.totalCirculation, 'number', true) &&
      validateType(obj.circulationStatus, isAdResApiStatus, true) &&
      validateType(obj.constructionCost, 'number') &&
      validateType(obj.hasIllumination, 'boolean') &&
      validateType(obj.height, 'number') &&
      validateType(obj.horizontalWidth, 'number') &&
      validateType(obj.impression, isFirestoreImpressionArray, true) &&
      validateType(obj.impression, isFirestoreImpressionArray, true) &&
      validateType(obj.totalImpression, 'number', true) &&
      validateType(obj.impressionStatus, isAdResApiStatus, true) &&
      validateType(obj.weeklyCost, 'number') &&
      validateType(obj.recoveryCost, 'number') &&
      validateType(obj.verticalWidth, 'number') &&
      (obj.visibility === null || typeof obj.visibility === 'number') && //throw new Error(`${obj.visibility}はnumber型ではありません。`) //TODO:エラーを吐けるように関数を作る
      validateType(obj.visibilityStatus, 'string') &&
      (typeof obj.tempVisibility === 'number' || obj.tempVisibility === null) && //throw new Error(`${obj.tempVisibility}はnumber型ではないか値がありません。`) //TODO:エラーを吐けるように関数を作る
      (typeof obj.tempCirculation === 'number' || obj.tempCirculation === null) //throw new Error(`${obj.tempCirculation}はnumber型ではないか値がありません。`) //TODO:エラーを吐けるように関数を作る
    return flag
  } else {
    return false
  }
}

const isAdResApiStatus = (value: unknown): value is AdResApiStatus => {
  if (!(value === 'PENDING' || value === 'RUNNING' || value === 'SUCCEEDED' || value === 'FAILED'))
    throw new Error(`${value}に'PENDING'||'RUNNING' || 'SUCCEEDED' || 'FAILED'以外のstringが入っています。`)
  return true
}

//TODO:isFirestoreAgeRatioとisFirestoreMaleAgeRatioとisFirestoreFemaleAgeRatioは中身がほぼ同じなので共通化したい
const isFirestoreAgeRatio = (value: unknown): value is FirestoreAgeRatio => {
  if (isObject(value)) {
    const obj = value as Record<keyof FirestoreAgeRatio, unknown>
    const ages: string[] = ['15', '20', '30', '40', '50', '60', '70', '80']
    const flag = ages.every((e) => validateType(obj[e], 'number'))
    return flag
  } else {
    return false
  }
}
const isFirestoreMaleAgeRatio = (value: unknown): value is FirestoreMaleAgeRatio => {
  if (isObject(value)) {
    const obj = value as Record<keyof FirestoreAgeRatio, unknown>
    const ages: string[] = ['15', '20', '30', '40', '50', '60', '70', '80', 'total']
    const flag = ages.every((e) => validateType(obj[e], 'number'))
    return flag
  } else {
    return false
  }
}
const isFirestoreFemaleAgeRatio = (value: unknown): value is FirestoreFemaleAgeRatio => {
  if (isObject(value)) {
    const obj = value as Record<keyof FirestoreAgeRatio, unknown>
    const ages: string[] = ['15', '20', '30', '40', '50', '60', '70', '80', 'total']
    const flag = ages.every((e) => validateType(obj[e], 'number'))
    return flag
  } else {
    return false
  }
}

//valueが指定のtypeに従っているかを判定(optionalなプロパティに対してはundefinedも許容)
const validateType = (value: any, type: string | ((value: any) => boolean), isOptional = false): boolean => {
  //そのプロパティがoptional(=undefinedでも許容される)な場合
  if (isOptional) {
    //typeが関数型の場合
    if (typeof type === 'function') {
      if (!(typeof value === 'undefined' || type(value))) throw new Error(`${value}は${type}型ではありません。`)
      return true
    } else {
      if (!(typeof value === 'undefined' || typeof value === type)) throw new Error(`${value}は${type}型ではありません。`)
      return true
    }
  } else {
    //typeが関数型の場合
    if (typeof type === 'function') {
      if (!type(value)) throw new Error(`${value}は${type}型ではありません。`)
      return true
    } else {
      if (!(typeof value === type)) throw new Error(`${value}は${type}型ではありません。`)
      return true
    }
  }
}

export const isTagDataType = (value: unknown): value is tagDataType => {
  if (isObject(value)) {
    const obj = value as Record<keyof tagDataType, unknown>
    //TODO:tagsにはdistanceプロパティがあるはずだが、firestoreにはないのでundefindを許容する
    if (!(typeof obj.distance === 'undefined' || typeof obj.distance === 'number')) throw new Error(`${obj.distance}はnumber型ではありません。`)
    //TODO:tagsにはdocIdプロパティがあるはずだが、firestoreにはないのでundefindを許容する
    if (!(typeof obj.docId === 'undefined' || typeof obj.docId === 'string')) throw new Error(`${obj.docId}はstring型ではありません。`)
    if (!(typeof obj.name === 'string')) throw new Error(`${obj.name}はstring型ではありません。`)
    if (!(typeof obj.id === 'string')) throw new Error(`${obj.id}はstring型ではありません。`)
    // TODO:配列のkeyとvalueの型チェック && obj.queries.every((e) => typeof e === "object" && e !== null && typeof keyof e === 'string')//{ key: string; value: string } []
    if (!Array.isArray(obj.queries)) throw new Error(`${obj.queries}は配列型ではありません。`)
    return true
  } else {
    return false
  }
}
//TODO:配列かつ要素の全てがnumber型かどうかを判別する関数。もう一段階一般化できそう(配列かつ要素が<T>か)。
const isNumberArray = (value: unknown): value is number[] => {
  if (!Array.isArray(value)) throw new Error(`${value}は配列型ではありません。`)
  if (!value.every((e) => typeof e === 'number')) throw new Error(`${value}の要素はnumber型ではありません。`)
  return true
}
//TODO:isFirestoreImpressionArrayとisFirestoreCirculationArrayは要素がほぼ同じなので共用できる。
const isFirestoreImpressionArray = (value: unknown): value is FirestoreImpression => {
  if (!isNumberArray(value)) return false
  if (!(value.length === 24)) throw new Error(`${value}は配列の長さは24ではありません。`)
  return true
}
const isFirestoreCirculationArray = (value: unknown): value is FirestoreCirculation => {
  if (value === null) return true
  if (!isNumberArray(value)) return false
  if (!(value.length === 24)) throw new Error(`${value}は配列の長さは24ではありません。`)
  return true
}

//mediaTypeが既存の媒体種に含まれるか判定
const isMediaType = (value: unknown): value is mediaType => {
  if (
    !(
      value === 'mediaTypePoster' ||
      value === 'mediaTypeBillboard' ||
      value === 'mediaTypeVision' ||
      value === 'mediaTypeLeaflet' ||
      value === 'mediaTypeMansionSignage' ||
      value === 'mediaTypeJack'
    )
  )
    throw new Error(
      `${value}は 'mediaTypePoster' | 'mediaTypeBillboard' | 'mediaTypeVision' | 'mediaTypeLeaflet' | 'mediaTypeMansionSignage' | 'mediaTypeJack'に含まれません。`
    )
  return true
}

//中身のあるobject(=nullでないオブジェクト)かを判定する関数
const isObject = (value: any): boolean => {
  if (typeof value !== 'object' || value === null) {
    throw new Error(`${value}はobject型でないまたはnullです。`)
  }
  return true
}
