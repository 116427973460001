import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'providers/Auth'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import AppLayout from 'components/organisms/layouts/AppLayout'
import CartTabs from 'components/organisms/cart/CartTabs'
import CartMap from 'components/organisms/cart/CartMap'
import AppLayoutGroupMenuBar from 'components/organisms/layouts/AppLayoutGroupMenuBar'
import CartMediaDetail from 'components/organisms/cart/CartMediaDetail'
import { CartsContext } from 'providers/Carts'
import { useLocation } from 'react-router-dom'
import { CartFindFilter } from 'helpers/filters/CartFindFilter'
import { shouldSignin } from 'helpers/shouldSignin'
import firebase, { db } from 'FirebaseConfig'
import CartTabFooter from 'components/organisms/cart/CartTabFooter'
import { Dimmer, Grid, Icon, Loader } from 'semantic-ui-react'
import { MediasAvailableContext } from 'providers/MediasAvailable'
import { getTargetCirculation } from 'helpers/calculates/getTargetCirculation'
import './index.css'
import { DISPLAY_DETAIL_WIDTH } from 'components/organisms/mediasearch/MapSearchDisplay'

const EditCart = (props) => {
  const [flagAngle, setFlagAngle] = useState(false) //サイドメニューがせり出すか否かの切り替え
  const { currentUser } = useContext(AuthContext)
  const [mediaTarget, setMediaTarget] = useState('') //選択されている媒体
  const [listedMedias, setListedMedias] = useState()
  const history = useHistory()
  const [currentKey, setCurrentKey] = useState(-1)
  const [oldKey, setOldKey] = useState(-1)
  const [currentKeyInfo, setCurrentKeyInfo] = useState(-1)
  const [oldKeyInfo, setOldKeyInfo] = useState(-1)
  const { carts } = useContext(CartsContext)
  const [cart, setCart] = useState({})
  const location = useLocation()
  const [bundleSummary, setBundleSummary] = useState({})
  const [firstTimeFlag, setFirstTimeFlag] = useState(false)
  const [period, setPeriod] = useState({
    months: 1,
    weeks: 4,
  })
  const [loading, setLoading] = useState(true)
  const [referrer, setReferrer] = useState()
  const [sendedBudget, setSendedBudget] = useState('')
  const [userIdFlag, setUserIdFlag] = useState(false)
  const { mediasAvailable } = useContext(MediasAvailableContext)

  useEffect(() => {
    if (Object.keys(cart).length) {
      mediasAvailable.map((medium) => {
        if (medium.mediaType === 'mediaTypeBillboard' || medium.mediaType === 'mediaTypeVision') {
          const Ref = getTargetCirculation(medium, cart.targetAge, cart.targetSex)
          medium.targetCirculationPerDay = Ref
        }
      })
    }

    setPeriod({
      months: cart.endDateMonth,
      weeks: cart.endDateWeek,
    })
  }, [cart])

  // TODO: 以下firebase APIの切り出し
  //この画面を表示すべきユーザーかを判定。
  useEffect(() => {
    if (shouldSignin(currentUser)) {
      history.push(`${paths.signin}`)
    }

    let carttes = ''
    try {
      db.collection('cart')
        .doc(cart.uid)
        .get()
        .then((doc) => {
          carttes = doc.data()
          //firebaseのカートデータのuserIdの中で、currentUserのIdが一致するものがあれば、userIdをセット
          if (carttes.userId === currentUser.uid) {
            setUserIdFlag(true)
          }
        })
    } catch (e) {
      console.error(e)
    }
  })
  //遷移前のページから、stateを取得
  useEffect(() => {
    try {
      setSendedBudget(props.location.state.sendedBudget) //カートの予算
      setFirstTimeFlag(props.location.state.firstTimeFlag) //初回かどうか
      setReferrer(props.location.state.referrer) //パス: `/group/edit/${docId}`
    } catch (e) {
      setFirstTimeFlag(false)
    }
  }, []) //初回レンダリング時

  //urlに含まれるIDデータを取得することで、このページで扱うカートをセットする
  useEffect(() => {
    if (location.pathname) {
      //カートIDに該当するurlの最後の20文字
      let cartRef = CartFindFilter(carts, location.pathname.substr(-20))
      cartRef ? setCart(cartRef) : setCart({})
    }
  }, [carts, location.pathname])

  const changeAllMediaInCart = (mediaList) => {
    db.collection('cart')
      .doc(String(cart.uid))
      .update({
        id: mediaList.map((medium) => medium.uid),
      })
  }

  const clickAddMediaToCart = (media) => {
    // TODO: 以下の予算制約の検証の関数を作成したい。mediaTypeによって計算項目が異なる
    // if (
    //   (bundleSummary.costSum * months + bundleSummary.constructionRecoveryCostSum) / months + (media.cost * months + media.constructionCost + media.recoveryCost) / months >
    //   cart.budget
    // ) {
    //   if (!window.confirm('予算を超えますがカートに追加しますか')) {
    //     return
    //   }
    // }
    const addMediaToCart = (mediaArray) => {
      mediaArray.forEach((media) =>
        firebase.analytics().logEvent('media_added_to_cart', {
          media_id: media.uid,
        })
      )
      const mediaUidArray = mediaArray.map((element) => element.uid)
      db.collection('cart')
        .doc(String(cart.uid))
        .update({
          id: firebase.firestore.FieldValue.arrayUnion(...mediaUidArray),
        })
    }

    if (media.mediaType === 'mediaTypeJack') {
      // ジャック親の場合
      const childMedia = mediasAvailable.filter((element) => element.parentUid === media.uid)
      addMediaToCart([media, ...childMedia])
    } else if (media.parentUid) {
      // ジャック子の場合
      const parentMedia = mediasAvailable.find((element) => element.uid === media.parentUid)
      const childMedia = mediasAvailable.filter((element) => element.parentUid === parentMedia.uid)
      addMediaToCart([parentMedia, ...childMedia])
    } else {
      addMediaToCart([media])
    }
  }
  // カートから媒体を削除
  const clickRemoveMediaFromCart = (media) => {
    const removeMediaFromCart = (mediaArray) => {
      const mediaUidArray = mediaArray.map((element) => element.uid)
      db.collection('cart')
        .doc(cart.uid)
        .update({
          id: firebase.firestore.FieldValue.arrayRemove(...mediaUidArray),
        })
    }
    if (media.mediaType === 'mediaTypeJack') {
      // ジャック親の場合
      const childMedia = mediasAvailable.filter((element) => element.parentUid === media.uid)
      removeMediaFromCart([media, ...childMedia])
    } else if (media.parentUid) {
      // ジャック子の場合
      const parentMedia = mediasAvailable.find((element) => element.uid === media.parentUid)
      const childMedia = mediasAvailable.filter((element) => element.parentUid === parentMedia.uid)
      removeMediaFromCart([parentMedia, ...childMedia])
    } else {
      removeMediaFromCart([media])
    }
  }

  return (
    <>
      {loading && (
        <Dimmer active inverted>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      {userIdFlag && (
        <AppLayout style={{ height: '100vh' }}>
          <AppLayoutGroupMenuBar fragAngle={flagAngle} setFragAngle={setFlagAngle}>
            <div className="edit__editCartGrid">
              <div
                style={{ gridRow: '1', gridColumn: '1/2' }}
                className={`edit__editCartTabs ${flagAngle ? 'edit__editCartTabsOpen' : 'edit__editCartTabsClose'} `}
              >
                <CartTabs
                  setLoading={setLoading}
                  mediaTarget={mediaTarget}
                  setMediaTarget={setMediaTarget}
                  setListedMedias={setListedMedias}
                  currentKey={currentKey}
                  oldKey={oldKey}
                  setCurrentKey={setCurrentKey}
                  setOldKey={setOldKey}
                  currentKeyInfo={currentKeyInfo}
                  setCurrentKeyInfo={setCurrentKeyInfo}
                  oldKeyInfo={oldKeyInfo}
                  setOldKeyInfo={setOldKeyInfo}
                  cart={cart}
                  changeAllMediaInCart={changeAllMediaInCart}
                  bundleSummary={bundleSummary}
                  setBundleSummary={setBundleSummary}
                  firstTimeFlag={firstTimeFlag}
                  setFirstTimeFlag={setFirstTimeFlag}
                  referrer={referrer}
                  period={period}
                  sendedBudget={sendedBudget}
                  mediaList={mediasAvailable}
                />
                <CartTabFooter bundleSummary={bundleSummary} mediaType={cart.mediaType} />
              </div>
              <Grid.Column>
                <div
                  // style={{ gridRow: '1', gridColumn: '2/3' }}
                  style={mediaTarget ? { width: `calc(100% - ${DISPLAY_DETAIL_WIDTH}px)`, float: 'left' } : {}}
                  className={`edit__editCartMap ${flagAngle ? 'edit__editCartMapOpen' : 'edit__editCartMapClose'} `}
                >
                  <CartMap
                    mediaTarget={mediaTarget}
                    setMediaTarget={setMediaTarget}
                    listedMedias={listedMedias}
                    setCurrentKey={setCurrentKey}
                    setOldKey={setOldKey}
                    currentKey={currentKey}
                    oldKey={oldKey}
                    cart={cart}
                    currentKeyInfo={currentKeyInfo}
                    setCurrentKeyInfo={setCurrentKeyInfo}
                    oldKeyInfo={oldKeyInfo}
                    setOldKeyInfo={setOldKeyInfo}
                    period={period}
                  />
                </div>
                {mediaTarget && (
                  <div id="edit__showMediaDetail">
                    <div className="edit__closeDetailButton" onClick={() => setMediaTarget('')}>
                      <Icon color="grey" name="angle double right" size="large" inverted bordered />
                    </div>
                    <CartMediaDetail
                      mediaTarget={mediaTarget}
                      setMediaTarget={setMediaTarget}
                      cart={cart}
                      period={period}
                      clickAddMediaToCart={clickAddMediaToCart}
                      clickRemoveMediaFromCart={clickRemoveMediaFromCart}
                    />
                  </div>
                )}
              </Grid.Column>
              {!mediaTarget && currentKey !== -1 && (
                <div className="edit__openDetailButton" onClick={() => setMediaTarget(listedMedias[currentKey])}>
                  <Icon color="grey" name="angle double left" size="large" inverted bordered />
                </div>
              )}
            </div>
          </AppLayoutGroupMenuBar>
        </AppLayout>
      )}
    </>
  )
}

export default EditCart
