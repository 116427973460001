import { AdviceBillboardDataType, isJackChild } from 'constants/mediaData'
import { AdviceMediaDataType } from 'constants/mediaData'
import { taxRate as defaultTaxRate } from 'constants/constants'
import {
  SexPieChartDispMethod,
  AgePieChartDispMethod,
  SexRatioByAgeChartDispMethod,
  bool2Has,
  getCol,
  parseArea,
  directionDisplayMethod,
  sizeDisplayMethod,
} from './helpers'
import { getPeriod } from 'helpers/mediaType/helpers'
import { daysInMonth } from 'constants/constants'
import { roundAndAddThousandsSeparator } from 'helpers/roundAndAddThousandsSeparator'

export const mediaTypeBillboard = {
  name: 'ビルボード',
  basicInfoFields: [
    {
      displayedPrefix: '',
      displayedName: '住所',
      displayedSuffix: '',
      valueType: 'string',
      displayMethod: getCol('address'),
    },
    {
      displayedName: '緯度',
      displayedPrefix: 'N',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: getCol('latitude'),
    },
    {
      displayedName: '経度',
      displayedPrefix: 'E',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: getCol('longitude'),
    },
    {
      displayedName: 'サイズ',
      displayedSuffix: '',
      displayedPrefix: '',
      valueType: 'number',
      displayMethod: sizeDisplayMethod,
    },
    {
      displayedName: '月額掲載費',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: (media) => {
        if (media.isPrivate) {
          return '価格非公開'
        }
        if (getPeriodUnit(media) === 'FixedOnlyYearly') {
          return `※年単位のみの扱いとなります。`
        } else if (isJackChild(media)) {
          return '-'
        } else {
          return `¥${roundAndAddThousandsSeparator(media.monthlyCost * media.marginRate * defaultTaxRate, 1)}`
        }
      },
    },
    {
      displayedName: '年額掲載費',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: (media) =>
        media.isPrivate
          ? '価格非公開'
          : isJackChild(media)
          ? '-'
          : `¥${roundAndAddThousandsSeparator(media.yearlyCost * media.marginRate * defaultTaxRate, 1)}`,
    },
    {
      displayedName: '施工費',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: (media) =>
        media.isPrivate
          ? '価格非公開'
          : isJackChild(media)
          ? '-'
          : `¥${roundAndAddThousandsSeparator(media.constructionCost * media.marginRate * defaultTaxRate, 1)}`,
    },
    {
      displayedName: '回復費',
      displayedSuffix: '',
      displayedPrefix: '',
      valueType: 'number',
      displayMethod: (media) =>
        media.isPrivate
          ? '価格非公開'
          : isJackChild(media)
          ? '-'
          : `¥${roundAndAddThousandsSeparator(media.recoveryCost * media.marginRate * defaultTaxRate, 1)}`,
    },
    {
      displayedName: '向き',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'string',
      displayMethod: directionDisplayMethod,
    },
    {
      displayedName: '照明',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'bool',
      displayMethod: (media) => bool2Has(media['hasIllumination']),
    },
    {
      displayedName: 'エリア',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'string',
      displayMethod: (media) => parseArea(media['address']),
    },
  ],
  //TODO:媒体一覧かカート編集かによって表示する項目自体も変わる
  effectIndicatorFields: [
    {
      fieldName: 'impression',
      displayedName: '総インプレッション数',
      displayedPrefix: '',
      displayedSuffix: '',
      summary: null,
      displayMethod: {
        mediaSearch: (media) => roundAndAddThousandsSeparator(media.impressionPerDay * daysInMonth, 1),
        cartEdit: null,
      },
    },
    {
      fieldName: 'cpm',
      displayedName: 'CPM ※１',
      displayedPrefix: '¥',
      displayedSuffix: '',
      summary: {
        mediaSearch:
          '※１：CPM（Cost Per Mille）は、1年間掲載した際の1000インプレッションあたりのコスト（税込みでの掲載価格＋施工回復費）。表示される価格は全て税込み。',
        cartEdit:
          '※１：CPM（Cost Per Mille）は、1000インプレッションあたりのコスト（税込みでの掲載価格＋施工回復費）。表示される価格は全て税込み。\n※２：tCPM（targeting Cost Per Mille）は、指定ターゲットへの1000インプレッションあたりのコスト。',
      },
      displayMethod: {
        mediaSearch: (media) =>
          media.isPrivate ? '非公開' : `¥${roundAndAddThousandsSeparator(calcBillboardCostEffectiveness(media, 12, false, true, true), 1)}`,
        cartEdit: null,
      },
    },
  ],
  //カート編集画面の効果指標テーブルのコンテンツ
  cartEditMediaDetailEffectTable: [
    {
      displayedName: 'インプレッション数',
      displayMethod: (args) => (!isNaN(args.media.impressionPerDay) ? roundAndAddThousandsSeparator(args.media.impressionPerDay * daysInMonth, 1) : 'N/A'),
    },
    {
      displayedName: 'サーキュレーション数',
      displayMethod: (args) => (!isNaN(args.media.circulationPerDay) ? roundAndAddThousandsSeparator(args.media.circulationPerDay * daysInMonth, 1) : 'N/A'),
    },
    {
      displayedName: 'ターゲットの割合',
      displayMethod: (args) =>
        !isNaN((args.media.targetCirculationPerDay * args.media.visibility) / args.media.impressionPerDay)
          ? `${Math.round(((args.media.targetCirculationPerDay * args.media.visibility) / args.media.impressionPerDay) * 100)}%` //TODO:日数の確認
          : 'N/A',
    },
    {
      displayedName: 'CPM ※１',
      displayMethod: (args) =>
        isJackChild(args.media)
          ? '-'
          : `¥${roundAndAddThousandsSeparator(calcBillboardCostEffectiveness(args.media, args.period.months, false, true, true), 1)}`,
    },
    {
      displayedName: 'tCPM ※２',
      displayMethod: (args) =>
        isJackChild(args.media)
          ? '-'
          : `¥${roundAndAddThousandsSeparator(calcBillboardCostEffectiveness(args.media, args.period.months, true, true, true), 1)}`,
    },
    {
      displayedName: '視認率',
      displayMethod: (args) => (!isNaN(args.media.visibility) ? `${Number(args.media.visibility * 100).toFixed(2)}%` : 'N/A'),
    },
  ],
  // 媒体がたくさん表示される画面で表示される小さなカードに載せる値
  cardContent: {
    mediaSearch: [
      {
        displayedName: '価格／月',
        displayMethod: (media) => {
          if (isJackChild(media)) {
            return '-'
          }
          if (getPeriodUnit(media) === 'FixedOnlyYearly') {
            return `¥${roundAndAddThousandsSeparator(calcBillboardCost(media, 12, true, false, false), 1)}※`
          } else {
            return `¥${roundAndAddThousandsSeparator(calcBillboardCost(media, 12, true, false, false), 1)}`
          }
        },
        displayedPrefix: '',
        displayedSuffix: '',
      },
      {
        displayedName: 'インプレッション/月',
        displayMethod: (media) => roundAndAddThousandsSeparator(media.impressionPerDay * daysInMonth, 1),
        displayedPrefix: '',
        displayedSuffix: '',
      },
      {
        displayedName: 'CPM',
        displayMethod: (media) =>
          isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(calcBillboardCostEffectiveness(media, 12, false, true, true), 1)}`,
        displayedPrefix: '',
        displayedSuffix: '',
      },
    ],
    cartEdit: [
      {
        displayedName: '価格/月',
        // eslint-disable-next-line
        displayMethod: (media, period) =>
          isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(calcBillboardCost(media, period.months, true, true, false), 1)}`,
        displayedPrefix: '',
        displayedSuffix: '',
      },
      {
        displayedName: 'インプレッション/月',
        // eslint-disable-next-line
        displayMethod: (media, period) => (!isNaN(media.impressionPerDay) ? roundAndAddThousandsSeparator(media.impressionPerDay * daysInMonth, 1) : 'N/A'),
        displayedPrefix: '',
        displayedSuffix: '',
      },
      {
        displayedName: 'ターゲットの割合',
        // eslint-disable-next-line
        displayMethod: (media, period) => {
          return !isNaN(media.targetCirculationPerDay / media.impressionPerDay)
            ? roundAndAddThousandsSeparator((media.targetCirculationPerDay / media.circulationPerDay) * 100, 1) + '%'
            : 'N/A'
        },
        displayedPrefix: '',
        displayedSuffix: '',
      },
      {
        displayedName: 'CPM',
        displayMethod: (media, period) =>
          isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(calcBillboardCostEffectiveness(media, period.months, false, true, true), 1)}`,
      },
    ],
  },
  cartFooterContent: [
    {
      displayedName: '合計インプレッション',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) => roundAndAddThousandsSeparator(bundleSummary.totalImpPerMonth, 1),
    },
    {
      displayedName: 'ターゲット割合',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) =>
        !isNaN(bundleSummary.totalTargetImpPerMonth / bundleSummary.totalImpPerMonth)
          ? Math.round((bundleSummary.totalTargetImpPerMonth / bundleSummary.totalImpPerMonth) * 100) + '%'
          : 'N/A',
    },
    {
      displayedName: '合計月額',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) => `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForMonth, 1)}`,
    },
    {
      displayedName: 'CPM',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) =>
        !isNaN(bundleSummary.cpm) ? `¥${roundAndAddThousandsSeparator(bundleSummary.cpm, 1)}` : 'N/A',
    },
  ],
  cartRecommendSummary: [
    {
      displayedName: '単月費用',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) => `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForMonth, 1)}`,
    },
    {
      displayedName: '単月インプレッション数',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) => roundAndAddThousandsSeparator(bundleSummary.totalImpPerMonth, 1),
    },
    {
      displayedName: 'CPM',
      displayMethod: (bundleSummary) => `¥${roundAndAddThousandsSeparator(bundleSummary.cpm, 1)}`,
    },
    {
      displayedName: 'tCPM',
      displayMethod: (bundleSummary) => `¥${roundAndAddThousandsSeparator(bundleSummary.tCpm, 1)}`,
    },
  ],
  propertyFields: [
    {
      fieldName: 'totalSexRatio',
      displayedName: '性別',
      graphType: 'pie',
      displayMethod: SexPieChartDispMethod,
    },
    {
      fieldName: 'ageRatio',
      displayedName: '年齢',
      graphType: 'pie',
      displayMethod: AgePieChartDispMethod,
    },
    {
      fieldName: 'sexRatioByAge',
      displayedName: '年代別性別比',
      graphType: 'stackedBar',
      displayMethod: SexRatioByAgeChartDispMethod,
    },
  ],
  cartConfirmCard: {
    header: ['媒体', '掲載月数', '単月掲載費', '施工費', '回復費', '小計'],
    contents: [
      {
        displayedName: '掲載月数',
        displayMethod: (media, period) => period.months + 'ヶ月',
      },
      {
        displayedName: '単月掲載費',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayMethod: (media, period) =>
          isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(media.monthlyCost * media.marginRate * defaultTaxRate, 1)}`,
      },
      {
        displayedName: '施工費',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayMethod: (media, period) =>
          isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(media.constructionCost * media.marginRate * defaultTaxRate, 1)}`,
      },
      {
        displayedName: '回復費',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayMethod: (media, period) =>
          isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(media.recoveryCost * media.marginRate * defaultTaxRate, 1)}`,
      },
      {
        displayedName: '小計',
        displayMethod: (media, period) =>
          isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(calcBillboardCost(media, period.months, true, true, true), 1)}`,
      },
    ],
  },
  cartConfirmSummary: [
    {
      displayedName: '媒体数',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeBillboard) => mediaSortedList.length,
    },
    {
      displayedName: '掲載期間',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeBillboard) => getPeriod(cart),
    },
    {
      displayedName: '掲載費合計(税抜)',
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeBillboard) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalPublicationCostForEntirePeriod, 1)}`,
    },
    {
      displayedName: '施工回復費合計(税抜)',
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeBillboard) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalConstructionCost + bundleSummary.totalRecoveryCost, 1)}`,
    },
    {
      displayedName: '消費税',
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeBillboard) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForEntirePeriod * (defaultTaxRate - 1), 1)}`,
    },
    {
      displayedName: '合計金額(税込)',
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeBillboard) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForEntirePeriod * defaultTaxRate, 1)}`,
    },
  ],
  cartConfirmFooter: [
    {
      displayedName: '合計インプレッション',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) => roundAndAddThousandsSeparator(bundleSummary.totalImpPerMonth, 1),
    },
    {
      displayedName: 'ターゲットへの合計インプレッション',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) =>
        !isNaN(bundleSummary.totalTargetImpPerMonth) ? roundAndAddThousandsSeparator(bundleSummary.totalTargetImpPerMonth, 1) : 'N/A',
    },
    {
      displayedName: '媒体掲載費合計(税込)',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalPublicationCostForEntirePeriod * defaultTaxRate, 1)}`,
    },
    {
      displayedName: '施工回復費合計(税込)',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) =>
        `¥${roundAndAddThousandsSeparator((bundleSummary.totalConstructionCost + bundleSummary.totalRecoveryCost) * defaultTaxRate, 1)}`,
    },
    {
      displayedName: 'CPM',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) =>
        !isNaN(bundleSummary.cpm) ? `¥${roundAndAddThousandsSeparator(bundleSummary.cpm, 1)}` : 'N/A',
    },
    {
      displayedName: 'tCPM',
      displayMethod: (bundleSummary: bundleSummaryDTypeBillboard) =>
        !isNaN(bundleSummary.tCpm) ? `¥${roundAndAddThousandsSeparator(bundleSummary.tCpm, 1)}` : 'N/A',
    },
  ],
  culcBundleSummary: (mediaList, period, isTaxIncluded): bundleSummaryDTypeBillboard => {
    const taxRate: number = isTaxIncluded ? defaultTaxRate : 1
    const bundleSummary: bundleSummaryDTypeBillboard = {
      totalOverallCostForEntirePeriod: 0,
      totalOverallCostForMonth: 0,
      totalPublicationCostForEntirePeriod: 0,
      totalPublicationCostForMonth: 0,
      totalConstructionCost: 0,
      totalRecoveryCost: 0,
      totalImpPerMonth: 0,
      totalTargetImpPerMonth: 0,
      cpm: 0,
      tCpm: 0,
      mediaType: 'mediaTypeBillboard',
    }
    mediaList
      .filter((medium) => !isJackChild(medium))
      .forEach((medium) => {
        bundleSummary.totalPublicationCostForMonth += calcBillboardCost(medium, period.months, isTaxIncluded, false, false)
        bundleSummary.totalConstructionCost += medium.constructionCost * medium.marginRate * taxRate
        bundleSummary.totalRecoveryCost += medium.recoveryCost * medium.marginRate * taxRate
        bundleSummary.totalImpPerMonth += medium.impressionPerDay * daysInMonth
        bundleSummary.totalTargetImpPerMonth += medium.targetCirculationPerDay * medium.visibility * daysInMonth
      })
    bundleSummary.totalPublicationCostForEntirePeriod = bundleSummary.totalPublicationCostForMonth * period.months
    bundleSummary.totalOverallCostForEntirePeriod =
      bundleSummary.totalPublicationCostForEntirePeriod + bundleSummary.totalRecoveryCost + bundleSummary.totalConstructionCost
    bundleSummary.totalOverallCostForMonth = bundleSummary.totalOverallCostForEntirePeriod / period.months
    bundleSummary.cpm =
      !isNaN(bundleSummary.totalOverallCostForMonth) && !isNaN(bundleSummary.totalImpPerMonth)
        ? calcCPM(bundleSummary.totalOverallCostForMonth, bundleSummary.totalImpPerMonth)
        : NaN
    bundleSummary.tCpm =
      !isNaN(bundleSummary.totalOverallCostForMonth) && !isNaN(bundleSummary.totalTargetImpPerMonth)
        ? calcCPM(bundleSummary.totalOverallCostForMonth, bundleSummary.totalTargetImpPerMonth)
        : NaN
    return bundleSummary
  },
  purchasePeriodUnit: 'month',
} as const

export const getPeriodUnit = (media: AdviceBillboardDataType) => {
  //monthlyCostとyearlyCostが共にnullの時は価格非公開を意味する
  if (media.monthlyCost === null && media.yearlyCost === null) {
    return '価格非公開'
  }
  //monthlyCostが0より大きい数字かつyearlyCostが0より大きい数字かつmonthlyCostを12倍した値がyearlyCostと等しくない場合はVaeiable型
  if (media.monthlyCost !== null && media.monthlyCost > 0 && media.yearlyCost !== null && media.yearlyCost > 0 && media.monthlyCost * 12 !== media.yearlyCost) {
    return 'Variable'
  }
  //monthlyCostが0またはnullかつyearlyCostが0より大きい数字の場合はFixedOnlyYearly型
  if ((media.monthlyCost === null || media.monthlyCost === 0) && media.yearlyCost !== null && media.yearlyCost > 0) {
    return 'FixedOnlyYearly'
  }
  //monthlyCostが0より大きい数値かつyearlyCostが0より大きい数値かつmonthlyCostを12倍した値がyearlyCostと等しい場合はFixedMonthly型
  if (
    media.monthlyCost !== null &&
    media.monthlyCost > 0 &&
    (media.monthlyCost * 12 === media.yearlyCost || media.yearlyCost === null || media.yearlyCost === 0)
  ) {
    return 'FixedMonthly'
  }
  return 'others'
}

export const calcBillboardCost = (
  media: AdviceBillboardDataType,
  months: number,
  isTaxIncluded: boolean,
  isFixedCostIncluded: boolean,
  isForEntirePeriod: boolean
) => {
  const periodUnit = getPeriodUnit(media)
  const years = Math.floor(months / 12)
  const taxRate: number = isTaxIncluded ? defaultTaxRate : 1
  const constructionCost: number = isFixedCostIncluded && media.constructionCost ? media.constructionCost : 0
  const recoveryCost: number = isFixedCostIncluded && media.recoveryCost ? media.recoveryCost : 0
  const monthlyCost: number = media.monthlyCost ? media.monthlyCost : 0
  const yearlyCost: number = media.yearlyCost ? media.yearlyCost : 0
  let costForEntirePeriod = 0
  switch (periodUnit) {
    case 'FixedMonthly':
      costForEntirePeriod = (monthlyCost * months + recoveryCost + constructionCost) * media.marginRate * taxRate
      break
    case 'FixedOnlyYearly':
      costForEntirePeriod = months % 12 === 0 ? (yearlyCost * years + recoveryCost + constructionCost) * media.marginRate * taxRate : 0
      break
    case 'Variable': {
      const remainderMonths = months - years * 12
      costForEntirePeriod = (yearlyCost * years + monthlyCost * remainderMonths + recoveryCost + constructionCost) * media.marginRate * taxRate
      break
    }
    default:
      costForEntirePeriod = 0
  }
  const result = isForEntirePeriod ? costForEntirePeriod : costForEntirePeriod / months
  return result
}

export const calcCPM = (cost: number, impression: number): number => {
  return (cost / impression) * 1000
}
//mediaTypeBillboardの費用対効果計算関数
export const calcBillboardCostEffectiveness = (
  media: AdviceBillboardDataType,
  months: number,
  hasTarget: boolean,
  isTaxIncluded: boolean,
  isOverall: boolean
) => {
  const impression =
    hasTarget && media.targetCirculationPerDay && media.visibility
      ? media.targetCirculationPerDay * media.visibility * daysInMonth
      : media.impressionPerDay
      ? media.impressionPerDay * daysInMonth
      : 0
  const price = calcBillboardCost(media, months, isTaxIncluded, isOverall, false)
  const costEffectiveness = calcCPM(price, impression)
  return costEffectiveness
}

// total: 全媒体について合計する
// overall: 掲載費用だけでなく、施工回復費用なので全ての種類の費用を計上する
// forEntirePeriod: 出稿期間全体について費用を算出する
export type bundleSummaryDTypeBillboard = {
  totalOverallCostForEntirePeriod: number //全ての種類の費用、全ての期間の合計費用
  totalOverallCostForMonth: number //全ての種類の費用、ひと月あたりの費用
  totalPublicationCostForEntirePeriod: number
  totalPublicationCostForMonth: number
  totalConstructionCost: number
  totalRecoveryCost: number
  totalImpPerMonth: number
  totalTargetImpPerMonth: number
  cpm: number
  tCpm: number
  mediaType: 'mediaTypeBillboard'
}
