import React, { useContext } from 'react'
import NavigationBar from 'components/organisms/menubar/NavigationBar'
import NavigationBarPlane from 'components/organisms/menubar/NavigationBarPlane'
import FooterNav from 'components/organisms/menubar/FooterNav'
import { Grid } from 'semantic-ui-react'
import './index.css'
import { AuthContext } from 'providers/Auth'

const AppLayoutNotAuthRequired = (props) => {
  const { currentUser } = useContext(AuthContext)

  return (
    <>
      <Grid.Row style={{ paddingBottom: '0px' }}>
        <Grid.Column>
          <div className="appLayoutNotAuthRequired__navBarGridRow">{currentUser ? <NavigationBar /> : <NavigationBarPlane />}</div>
        </Grid.Column>
      </Grid.Row>
      <>{props.children}</>
      <FooterNav />
    </>
  )
}

export default AppLayoutNotAuthRequired
