// Library
import React, { useContext } from 'react'
// import Sidebar from 'semantic-ui-react/dist/commonjs/modules/Sidebar';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import { Dropdown, Icon } from 'semantic-ui-react'
import paths from 'constants/paths'
import letterLogo from 'assets/pictures/advice-beta-logo-white.png'
import styled from '@emotion/styled'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import { auth } from 'FirebaseConfig'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'
import { AuthContext } from 'providers/Auth'
import './index.css'

const NavigationBar = () => {
  const NavBar = styled.div`
    background-color: #444;
    padding: 0 2rem;
    z-index: 999;
    position: sticky !important;
    left: 0px;
  `

  const RedDiv = styled.div`
    color: #de3c2d;
  `

  const ImageWrapper = styled(Image)`
    height: 24px !important;
    width: auto !important;
  `
  const history = useHistory()

  const { currentUser } = useContext(AuthContext)

  const clickLogout = () => {
    // すぐにサインアウトしないため/loginに遷移させることができない。
    if (window.confirm('ログアウトしますか？')) {
      auth.signOut()
    }
  }

  const handleItemClick = (event, { name }) => {
    switch (name) {
      case 'root':
        history.push(`${paths.mediatop}`)
        break
      case 'groupdashboard':
        history.push({
          pathname: `${paths.groupdashboard}`,
        })
        break
      case 'addgroup':
        history.push({
          pathname: `${paths.addgroup}`,
        })
        break
      case 'signin':
        history.push(`${paths.signin}`)
        break
      case 'signup':
        history.push(`${paths.signup}`)
        break
      case 'list':
        history.push(`${paths.mediasearch}`)
        break
      case 'media':
        history.push(`${paths.mapSearch}`)
        break
      case 'my':
        history.push(`${paths.my}`)
        break
      case 'contact':
        history.push(`${paths.contact}`)
        break
      default:
        console.log('No key match')
    }
  }

  return (
    <NavBar style={{ height: '56px' }}>
      <Menu secondary size="massive" inverted>
        <Menu.Item fitted name="root" onClick={handleItemClick}>
          &nbsp;&nbsp;
          <ImageWrapper src={letterLogo} />
          &nbsp;&nbsp;
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item name="groupdashboard" className="navigationBar__headerText" onClick={handleItemClick}>
            ダッシュボード
          </Menu.Item>

          <Menu.Item name="addgroup" className="navigationBar__headerText" onClick={handleItemClick}>
            グループを作成
          </Menu.Item>

          <Menu.Item name="list" className="navigationBar__headerText" onClick={handleItemClick}>
            リストから検索
          </Menu.Item>
          <Menu.Item name="media" className="navigationBar__headerText" onClick={handleItemClick}>
            マップで媒体検索
          </Menu.Item>
          {currentUser ? (
            <>
              <Dropdown
                item
                trigger={
                  <>
                    <Icon name="bars" size="large" />
                  </>
                }
                icon={null}
              >
                <Dropdown.Menu>
                  <Dropdown.Item name="my" onClick={handleItemClick}>
                    ユーザー設定
                  </Dropdown.Item>
                  <Dropdown.Item name="contact" onClick={handleItemClick}>
                    お問い合わせ
                  </Dropdown.Item>
                  <Dropdown.Item onClick={clickLogout}>
                    <RedDiv>ログアウト</RedDiv>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <>
              <Dropdown item>
                <Dropdown.Menu>
                  <Dropdown.Item name="signup" onClick={handleItemClick}>
                    新規登録
                  </Dropdown.Item>
                  <Dropdown.Item name="signin" onClick={handleItemClick}>
                    ログイン
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </Menu.Menu>
      </Menu>
    </NavBar>
  )
}

export default withRouter(NavigationBar)
