import React from 'react'
import Loading from 'components/atoms/Loading'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import './index.css'

const MediaImpressionTableDefault = ({ media }) => {
  const colInfoInTable = getColInfoFromTypeName(media.mediaType).effectIndicatorFields
  const fields = [] // 効果指標の表の中身のjsx
  const summaries = [] // 効果指標の表の下についている但し書きのjsx
  for (const colInfo of colInfoInTable) {
    fields.push(
      <td className="mediaImpressionTable__mediaDetailEffectItem" key={colInfo.fieldName}>
        <p className="mediaImpressionTable__mediaDetailEffectItemTitle">{colInfo.displayedName}</p>
        <p className="mediaImpressionTable__mediaDetailEffectItemValue">{colInfo.displayMethod.mediaSearch ? colInfo.displayMethod.mediaSearch(media) : ''}</p>
      </td>
    )
    if (colInfo.summary && colInfo.summary.mediaSearch) {
      summaries.push(
        <p style={{ marginBottom: '2rem', fontSize: '1rem', whiteSpace: 'normal' }} key={colInfo.fieldName + '_summary'}>
          {colInfo.summary.mediaSearch}
          <br />
        </p>
      )
    }
  }
  return (
    <>
      <table className="mediaImpressionTable__mediaDetailEffect">
        <tr>{fields}</tr>
      </table>
      <div basic>{summaries}</div>
    </>
  )
}

const MediaImpressionTable = ({ media }) => {
  if (!media) {
    return <Loading />
  }
  switch (media.mediaType) {
    default:
      return <MediaImpressionTableDefault media={media} />
  }
}
export default MediaImpressionTable
