import firebase from 'firebase'
import { mediaBookedDataType } from 'providers/MediaBookedAll'

export const SearchPageFilter = (
  medias: any, //TODO:any排除
  searchWord: string,
  areaList: string[],
  targetTags: string[],
  targetLines: string[],
  targetHighways: string[],
  minPrice: string,
  maxPrice: string,
  minCpm: string,
  startDate: firebase.firestore.Timestamp,
  endDate: firebase.firestore.Timestamp,
  mediaBooked: mediaBookedDataType[],
  mediaTypeList: string[]
) => {
  let tmpMedia = medias
  let flag = false
  const searchWordLower = searchWord.toLocaleLowerCase()
  if (startDate && endDate && mediaBooked) {
    tmpMedia = tmpMedia.filter((row) => {
      let dateFrag = true
      mediaBooked.forEach((item) => {
        if (item.mediaId === row.uid && item.isBooked) {
          if (startDate.seconds < item.date.seconds && endDate.seconds > item.date.seconds) {
            dateFrag = false
          }
        }
      })
      const today = Date.now() / 1000
      if (row.stopDate && row.stopDate.seconds < today) {
        if (row.restartDate) {
          if (row.restartDate.seconds > today) {
            dateFrag = false
          }
        } else {
          dateFrag = false
        }
      }

      if (dateFrag) {
        return row
      }
    })
  }

  return tmpMedia.filter((row) => {
    flag = false
    if (searchWordLower !== '' && row.name.indexOf(searchWordLower) === -1) {
      flag = true
      return false
    } else if (minPrice !== undefined && row.cost) {
      if (Number(minPrice) > row.cost) {
        flag = true
        return false
      }
    } else if (maxPrice !== undefined && Number(maxPrice) < row.cost) {
      flag = true
      return false
    } else if (
      minCpm !== undefined &&
      Number(minCpm) > Math.round(((row.cost * 1 * row.marginRate + row.constructionCost + row.recoveryCost) * 1.1 * 1000) / 1 / row.impression)
    ) {
      flag = true
      return false
    }

    if (areaList !== undefined && row.address) {
      const isIncludedInAreaList = areaList.some((area) => {
        return row.address.indexOf(area) !== -1
      })
      if (!isIncludedInAreaList) {
        return false
      }
    }

    if (mediaTypeList !== undefined && row.mediaType) {
      const isIncludedInMediaTypeList = mediaTypeList.some((mediaType) => {
        return mediaType === row.mediaType
      })
      if (!isIncludedInMediaTypeList) {
        return false
      }
    }

    if (targetTags.length > 0) {
      let res = false
      if (row.tags && row.tags.length > 0) {
        res = targetTags.every((targetTag) => {
          return row.tags.some((tag) => tag.id === targetTag)
        })
      }
      if (!res) {
        return false
      }
    }
    if (targetLines.length > 0) {
      let res = false
      if (row.lines && row.lines.length > 0) {
        res = targetLines.every((targetLine) => {
          return row.lines.some((line) => line.id === targetLine)
        })
      }
      if (!res) {
        return false
      }
    }
    if (targetHighways.length > 0) {
      let res = false
      if (row.highways && row.highways.length > 0) {
        res = targetHighways.every((targetHighway) => {
          return row.highways.some((highway) => highway.id === targetHighway)
        })
      }
      if (!res) {
        return false
      }
    }
    if (flag === false) {
      return row
    }
  })
}
