import React, { createContext, useState, useEffect, useContext } from 'react'
import { UsersContext } from 'providers/Users'
import { AuthContext } from 'providers/Auth'
import { userDataType } from 'providers/Users'

export const NOTLOADED = undefined // ユーザのデータがまだロードされていないことを示すjsの値
export const NOUSER = null // ユーザのデータが存在しないことを示すjsの値
export type nouserDataType = undefined | null // ユーザに値が入っていないときの値の型

const UserContext = createContext<{ user: userDataType | nouserDataType }>({ user: NOTLOADED })
// provide campaigns from user. user is provided by outside.
const UserProvider = ({ children }) => {
  const [user, setUser] = useState<userDataType>()
  const { users } = useContext(UsersContext)
  const { currentUser } = useContext(AuthContext)

  useEffect(() => {
    if (users && currentUser) {
      const userRef = users.find((u) => u.docId === currentUser.uid)
      if (userRef) {
        setUser(userRef)
      }
    }
  }, [currentUser, users])

  return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
}

export { UserContext, UserProvider }
