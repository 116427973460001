import React from 'react'
import { Table } from 'semantic-ui-react'
import MansionOptions from 'constants/MansionOptions'

type TargetMansionType =
  | {
      key: string
      text: string
      value: string
    }
  | undefined //find関数で見つからない場合

const TargetMansionTypeCell = (props) => {
  let jsxElms: string[] = []
  //valueからtextに変換する
  if (props.mansionType && props.mansionType.length && props.mansionType.length !== MansionOptions.length) {
    jsxElms = props.mansionType.map((key) => {
      const targetMansionType: TargetMansionType = MansionOptions.find((elem) => elem.value === key)
      if (targetMansionType) {
        return targetMansionType.text
      } else {
        return '全て'
      }
    })
  } else {
    jsxElms.push('全て')
  }
  const result: string = jsxElms.join('、')
  return <Table.Cell>{result}</Table.Cell>
}

export default TargetMansionTypeCell
