import React from 'react'
import { Checkbox, Form, Dropdown } from 'semantic-ui-react'
import Spacer from 'components/atoms/Spacer'
import 'react-calendar/dist/Calendar.css'
import StateOptions from 'constants/StateOptions'

// add group と edit group から使いたい
const GroupFormBillboard = (props) => {
  const handleChange = (e, { name, value }) => {
    switch (name) {
      case 'targetLocation':
        props.setTargetLocation(value)
        break
      case 'noTargetLocation':
        props.setNoTargetLocation(!props.noTargetLocation)
        break
      case 'targetAge10':
        props.setTargetAge10(!props.targetAge10)
        break
      case 'targetAge20':
        props.setTargetAge20(!props.targetAge20)
        break
      case 'targetAge30':
        props.setTargetAge30(!props.targetAge30)
        break
      case 'targetAge40':
        props.setTargetAge40(!props.targetAge40)
        break
      case 'targetAge50':
        props.setTargetAge50(!props.targetAge50)
        break
      case 'targetAge60':
        props.setTargetAge60(!props.targetAge60)
        break
      case 'targetAge70':
        props.setTargetAge70(!props.targetAge70)
        break
      case 'targetAge80':
        props.setTargetAge80(!props.targetAge80)
        break
      case 'targetAgeAll':
        props.setTargetAgeAll(!props.targetAgeAll)
        break
      case 'targetSexMale':
        props.setTargetSexMale(!props.targetSexMale)
        break
      case 'targetSexFemale':
        props.setTargetSexFemale(!props.targetSexFemale)
        break
      case 'targetSexAll':
        props.setTargetSexAll(!props.targetSexAll)
        break
      default:
        console.log('key not found')
    }
  }

  return (
    <>
      <p>ビルボード</p>
      <Form className="ui form">
        <Form.Field>
          <label>配信地域</label>
          <div>配信地域を選択してください</div>
          <Dropdown
            name="targetLocation"
            fluid
            multiple
            search
            selection
            options={StateOptions}
            onChange={handleChange}
            value={props.targetLocation}
            disabled={!props.noTargetLocation == false && true}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox value={props.noTargetLocation} checked={props.noTargetLocation} name="noTargetLocation" label="地域を指定しない" onChange={handleChange} />
        </Form.Field>
        <Spacer />
        <Form.Field>
          <label>ターゲット</label>
          <div>広告のターゲットとなるオーディエンスを設定して最適な媒体を見つけましょう</div>
        </Form.Field>
        <Spacer />
        <Form.Field>
          <label>年齢</label>
          <Checkbox checked={props.targetAge10} name="targetAge10" label="10代以下" onChange={handleChange} disabled={!props.targetAgeAll == false && true} />
          <span>&nbsp;&nbsp;</span>
          <Checkbox
            //value={props.targetAge20}
            checked={props.targetAge20}
            name="targetAge20"
            label="20代"
            onChange={handleChange}
            disabled={!props.targetAgeAll == false && true}
          />
          <span>&nbsp;&nbsp;</span>
          <Checkbox
            //value={props.targetAge30}
            checked={props.targetAge30}
            name="targetAge30"
            label="30代"
            onChange={handleChange}
            disabled={!props.targetAgeAll == false && true}
          />
          <span>&nbsp;&nbsp;</span>
          <Checkbox
            //value={props.targetAge40}
            checked={props.targetAge40}
            name="targetAge40"
            label="40代"
            onChange={handleChange}
            disabled={!props.targetAgeAll == false && true}
          />
          <span>&nbsp;&nbsp;</span>
          <Checkbox
            //value={props.targetAge50}
            checked={props.targetAge50}
            name="targetAge50"
            label="50代"
            onChange={handleChange}
            disabled={!props.targetAgeAll == false && true}
          />
          <span>&nbsp;&nbsp;</span>
          <Checkbox
            //value={props.targetAge60}
            checked={props.targetAge60}
            name="targetAge60"
            label="60代"
            onChange={handleChange}
            disabled={!props.targetAgeAll == false && true}
          />
          <span>&nbsp;&nbsp;</span>
          <Checkbox
            //value={props.targetAge70}
            checked={props.targetAge70}
            name="targetAge70"
            label="70代"
            onChange={handleChange}
            disabled={!props.targetAgeAll == false && true}
          />
          <Checkbox
            //value={props.targetAge80}
            checked={props.targetAge80}
            name="targetAge80"
            label="80代"
            onChange={handleChange}
            disabled={!props.targetAgeAll == false && true}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox checked={props.targetAgeAll} name="targetAgeAll" label="年齢を指定しない" onChange={handleChange} />
        </Form.Field>
        <Spacer />
        <Form.Field>
          <label>性別</label>
          <Checkbox
            value={props.targetSexMale}
            checked={props.targetSexMale}
            name="targetSexMale"
            label="男性"
            onChange={handleChange}
            disabled={!props.targetSexAll === false && true}
          />
          <span>&nbsp;&nbsp;</span>
          <Checkbox
            value={props.targetSexFemale}
            checked={props.targetSexFemale}
            name="targetSexFemale"
            label="女性"
            onChange={handleChange}
            disabled={!props.targetSexAll === false && true}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox value={props.targetSexAll} checked={props.targetSexAll} name="targetSexAll" label="性別を指定しない" onChange={handleChange} />
        </Form.Field>
        <Spacer />
      </Form>
      <Spacer />
    </>
  )
}

export default GroupFormBillboard
