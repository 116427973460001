import { RATIO } from './visibility_constants'
import osmtogeojson from 'osmtogeojson' // なぜかimportすると上手く動かない
import * as firebase from 'firebase/app'
import { FeatureCollection, GeometryObject } from 'geojson'

//getメソッドでhttpsリクエストを実行する関数。urlがリクエストを送信するURL。
export const sendGetRequest = async (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest()
    req.open('GET', url, true)
    req.responseType = 'text'
    req.onload = () => {
      if (req.readyState === req.DONE && req.status === 200) {
        resolve(req.responseText)
      } else {
        reject(req.responseText)
      }
    }
    req.send(null)
  })
}

//引数のmsecだけ処理を止める関数
export const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec))

//N文字のランダムな文字列を生成する関数
const getRandom = (N: number): string => {
  const S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  return Array.from(crypto.getRandomValues(new Uint8Array(N)))
    .map((n) => S[n % S.length])
    .join('')
}

/**
 * 媒体IDを生成する
 * @returns {string} result 生成された媒体ID
 */
export const genID = (): string => {
  return 'media_' + Date.now() + getRandom(10)
}

const fmod = (a: number, b: number): number => {
  return Number((a - Math.floor(a / b) * b).toPrecision(8))
}
export const pos2mesh = (lat: number, lon: number): string => {
  let result = ''
  const p = Math.floor((lat * 60) / 40)
  const a = fmod(lat * 60, 40)
  const q = Math.floor(a / 5)
  const b = fmod(a, 5)
  const r = Math.floor((b * 60) / 30)
  const c = fmod(b * 60, 30)
  const s = Math.floor(c / 15)

  const u = Math.floor(lon - 100)
  const f = lon - 100 - u
  const v = Math.floor((f * 60) / 7.5)
  const g = fmod(f * 60, 7.5)
  const w = Math.floor((g * 60) / 45)
  const h = fmod(g * 60, 45)
  const x = Math.floor(h / 22.5)

  const m = s * 2 + x + 1
  result = String(p) + String(u) + String(q) + String(v) + String(r) + String(w) + String(m)

  return result
}

//緯度経度、範囲を指定してOSMからデータを取得する関数
export const getGeodata = async (lat: number, lon: number, range: number) => {
  const range_degree = metre2degree(range * 2)
  const min_lat = Number(lat) - range_degree[0]
  const min_lon = Number(lon) - range_degree[1]
  const max_lat = Number(lat) + range_degree[0]
  const max_lon = Number(lon) + range_degree[1]
  const osm_data = await getOSM(min_lat, min_lon, max_lat, max_lon) //データダウンロード
  return osm2geojson(osm_data) //データ変換
}

//緯度経度、範囲を指定して道路の情報を取得する関数
export async function getWayPoint(lat: number, lon: number, range: number) {
  const geo_data = await getGeodata(lat, lon, range)
  const way_list = getWayList(geo_data)
  return way_list
}

export function getWayList(geo_data) {
  const way_list: number[][][] = []
  for (let i = 0; i < geo_data.features.length; i++) {
    const regex = /^way/
    if (regex.test(String(geo_data.features[i].id))) {
      // if (geo_data.features[i].properties.highway != undefined && geo_data.features[i].properties.highway != "motorway") {
      const elem: number[][] = []
      // tslint-disable-nextline
      for (let j = 0; j < geo_data.features[i].geometry.coordinates.length; j++) {
        elem.push([geo_data.features[i].geometry.coordinates[j][1], geo_data.features[i].geometry.coordinates[j][0]])
      }
      way_list.push(elem)
      // }
    }
  }
  return way_list
}

/**
メートルから度に変換する関数
**/
export const metre2degree = (length: number): [number, number] => {
  const lat = 110940
  const lon = 91287
  const degree_lat = length / lat
  const degree_lon = length / lon
  return [degree_lat, degree_lon]
}

/**
osmをapiから取得する関数
**/
export const getOSM = (min_lat: number, min_lon: number, max_lat: number, max_lon: number): Promise<string> => {
  return new Promise((resolve, reject) => {
    const url = 'https://api.openstreetmap.org/api/0.6/map?bbox=' + min_lon + ',' + min_lat + ',' + max_lon + ',' + max_lat // リクエスト先URL
    const request = new XMLHttpRequest()
    let result
    request.open('GET', url)
    request.onreadystatechange = function () {
      if (request.readyState !== 4) {
        // リクエスト中
      } else if (request.status !== 200) {
        reject('fail!')
        // 失敗
      } else {
        // 取得成功
        result = request.responseText
        resolve(result)
      }
    }
    request.send()
  })
}

/**
osmをgeojsonに変換する関数
**/
export const osm2geojson = (osm_data: string): FeatureCollection<GeometryObject> => {
  const parser = new DOMParser()
  const resultdom = parser.parseFromString(osm_data, 'text/xml')
  const jdata = osmtogeojson(resultdom)
  return jdata
}

/**
建物によって隠れる領域を排除する関数
**/
export function checkVisible(ideal_point: (number | boolean)[], buildings: number[][][], board_lat: number, board_lon: number, height: number) {
  // ideal_point = [lat, lng, isVisible]
  if (!ideal_point[2]) {
    return false
  }
  for (let i = 0; i < buildings.length; i++) {
    for (let j = 0; j < buildings[i].length - 1; j++) {
      const isCross = checkCross(
        {
          x: board_lat,
          y: board_lon,
        },
        {
          x: ideal_point[0] as number,
          y: ideal_point[1] as number,
        },
        {
          x: buildings[i][j][0],
          y: buildings[i][j][1],
        },
        {
          x: buildings[i][j + 1][0],
          y: buildings[i][j + 1][1],
        }
      )
      const crossHeight = checkCross(
        {
          x: 0,
          y: height,
        },
        {
          x: getDistance(board_lat, board_lon, ideal_point[0] as number, ideal_point[1] as number),
          y: 0,
        },
        {
          x: getDistance(board_lat, board_lon, buildings[i][j][0], buildings[i][j][1]),
          y: 0,
        },
        {
          x: getDistance(board_lat, board_lon, buildings[i][j][0], buildings[i][j][1]),
          y: buildings[i][j][2],
        }
      )
      if (isCross && crossHeight) {
        return false
      }
    }
  }
  return true
}

export const checkInPoly = (polygon: number[][], x: number, y: number) => {
  let degreeSum = 0
  const n = polygon.length
  for (let i = 1; i < n; i++) {
    if (polygon[i][0] === x && polygon[i][1] === y) {
      return true
    }
    const v1x = polygon[i - 1][0] - x
    const v1y = polygon[i - 1][1] - y
    const v2x = polygon[i][0] - x
    const v2y = polygon[i][1] - y
    degreeSum += calcDeg(v1x, v1y, v2x, v2y)
  }
  if (polygon[0][0] === x && polygon[0][1] === y) {
    return true
  }
  const v1x = polygon[n - 1][0] - x
  const v1y = polygon[n - 1][1] - y
  const v2x = polygon[0][0] - x
  const v2y = polygon[0][1] - y
  degreeSum += calcDeg(v1x, v1y, v2x, v2y)
  degreeSum = Math.abs(degreeSum)
  if (degreeSum >= 0.1) {
    return true
  }
  return false
}

export function calcDeg(x1, y1, x2, y2) {
  const abs1 = Math.sqrt(x1 * x1 + y1 * y1)
  const abs2 = Math.sqrt(x2 * x2 + y2 * y2)
  let degree = Math.acos((x1 * x2 + y1 * y2) / (abs1 * abs2)) // 内積を使って角度を計算
  const sign = Math.sign(x1 * y2 - y1 * x2) // 外積を使って符号を計算
  degree *= sign
  return degree
}
type Point = {
  x: number
  y: number
}
//二つの緯度経度の組みの線分同士が交差しているかを判定する関数
export function checkCross(line1_1: Point, line1_2: Point, line2_1: Point, line2_2: Point) {
  let s, t
  s = (line1_1.x - line1_2.x) * (line2_1.y - line1_1.y) - (line1_1.y - line1_2.y) * (line2_1.x - line1_1.x)
  t = (line1_1.x - line1_2.x) * (line2_2.y - line1_1.y) - (line1_1.y - line1_2.y) * (line2_2.x - line1_1.x)
  if (s * t > 0) return false

  s = (line2_1.x - line2_2.x) * (line1_1.y - line2_1.y) - (line2_1.y - line2_2.y) * (line1_1.x - line2_1.x)
  t = (line2_1.x - line2_2.x) * (line1_2.y - line2_1.y) - (line2_1.y - line2_2.y) * (line1_2.x - line2_1.x)
  if (s * t > 0) return false
  return true
}

//緯度経度間の距離を算出する関数
export const getDistance = (lat1: number, lon1: number, lat2: number, lon2: number, mode = true) => {
  // 緯度経度をラジアンに変換
  const radLat1 = deg2rad(lat1) // 緯度１
  const radLon1 = deg2rad(lon1) // 経度１
  const radLat2 = deg2rad(lat2) // 緯度２
  const radLon2 = deg2rad(lon2) // 経度２
  // 緯度差
  const radLatDiff = radLat1 - radLat2
  // 経度差算
  const radLonDiff = radLon1 - radLon2
  // 平均緯度
  const radLatAve = (radLat1 + radLat2) / 2.0
  // 測地系による値の違い
  const a = mode ? 6378137.0 : 6377397.155 // 赤道半径
  //e2 = (a*a - b*b) / (a*a);
  const e2 = mode ? 0.00669438002301188 : 0.00667436061028297 // 第一離心率^2
  //a1e2 = a * (1 - e2);
  const a1e2 = mode ? 6335439.32708317 : 6334832.10663254 // 赤道上の子午線曲率半径
  const sinLat = Math.sin(radLatAve)
  const W2 = 1.0 - e2 * (sinLat * sinLat)
  const M = a1e2 / (Math.sqrt(W2) * W2) // 子午線曲率半径M
  const N = a / Math.sqrt(W2) // 卯酉線曲率半径
  const t1 = M * radLatDiff
  const t2 = N * Math.cos(radLatAve) * radLonDiff
  const dist = Math.sqrt(t1 * t1 + t2 * t2)
  return dist
}

//度数をラジアンに変換する関数
export function deg2rad(deg) {
  const rad = deg * (Math.acos(-1.0) / 180)
  return rad
}

/**
 * 度数表記の方向をベクトルに変換する関数
 * @param {number} deg
 * @returns 三次元ベクトル
 */
export const deg2vector = (deg: number) => {
  const rad = deg2rad(deg)
  return [Number(Math.cos(rad).toFixed(2)), Number(Math.sin(rad).toFixed(2)), 0]
}

//角plot1-plot2-plot3の角度を計算する関数
export function calcAngle(plot1, plot2, plot3) {
  const ba = new Array(2)
  ba[0] = plot1.x - plot2.x
  ba[1] = plot1.y - plot2.y
  const bc = new Array(2)
  bc[0] = plot3.x - plot2.x
  bc[1] = plot3.y - plot2.y
  const babc = ba[0] * bc[0] + ba[1] * bc[1]
  const ban = ba[0] * ba[0] + ba[1] * ba[1]
  const bcn = bc[0] * bc[0] + bc[1] * bc[1]
  const radian = Math.acos(babc / Math.sqrt(ban * bcn))
  const angle = (radian * 180) / Math.PI
  return angle
}

//csvを二次元の配列に変換する関数
export const CSV2Array = (csv) => {
  return new Promise((resolve) => {
    const array = csv.split('\n')
    for (let i = 0; i < array.length; i++) {
      array[i] = array[i].replace(/\r/, '').split(',')
    }
    // 末尾が改行の場合最終行を削除
    if (array[array.length - 1].length === 1) {
      array.pop() //末尾の空の配列を削除
    }
    resolve(array)
  })
}

export function getBuildingList(lat: number, lon: number, width: number, range: number, geo_data: FeatureCollection<GeometryObject>) {
  // tslint:disable-next-line:no-explicit-any
  const building_list: any = []
  for (let i = 0; i < geo_data.features.length; i++) {
    const regex = /^way/
    if (regex.test(geo_data.features[i].id as string)) {
      if (geo_data.features[i].properties.building == 'yes') {
        //エリア内にある建物の情報
        // tslint:disable-next-line, eslint-disable-next-line
        const tmp = geo_data.features[i].geometry as { coordinates: number[][][] }
        const loopLen = tmp.coordinates[0].length
        for (let j = 0; j < loopLen; j++) {
          const distance = getDistance(lat, lon, tmp.coordinates[0][j][1], tmp.coordinates[0][j][0])
          if (distance < range) {
            building_list.push(geo_data.features[i])
            break
          }
        }
      }
    }
  }
  return building_list
}

export const calcRange = (width, height) => {
  return Math.sqrt((width * height) / RATIO)
}

/**
 * See https://www.algorithms-and-technologies.com/point_in_polygon/javascript
 * Performs the even-odd-rule Algorithm (a raycasting algorithm) to find out whether a point is in a given polygon.
 * This runs in O(n) where n is the number of edges of the polygon.
 *
 * @param {Array} polygon an array representation of the polygon where polygon[i][0] is the x Value of the i-th point and polygon[i][1] is the y Value.
 * @param {Array} point   an array representation of the point where point[0] is its x Value and point[1] is its y Value
 * @return {boolean} whether the point is in the polygon (not on the edge, just turn < into <= and > into >= for that)
 */
export const pointInPolygon = function (polygon: number[][], point: number[]) {
  //A point is in a polygon if a line from the point to infinity crosses the polygon an odd number of times
  let odd = false
  //For each edge (In this case for each point of the polygon and the previous one)
  for (let i = 0, j = polygon.length - 1; i < polygon.length; i++) {
    //If a line from the point into infinity crosses this edge
    if (
      polygon[i][1] > point[1] !== polygon[j][1] > point[1] && // One point needs to be above, one below our y coordinate
      // ...and the edge doesn't cross our Y corrdinate before our x coordinate (but between our x coordinate and infinity)
      point[0] < ((polygon[j][0] - polygon[i][0]) * (point[1] - polygon[i][1])) / (polygon[j][1] - polygon[i][1]) + polygon[i][0]
    ) {
      // Invert odd
      odd = !odd
    }
    j = i
  }
  //If the number of crossings was odd, the point is in the polygon
  return odd
}

export const extractPoint = (polygonArray: number[][], minP2PDist: number) => {
  // 緯度経度の最大最小を求める
  let [maxLat, maxLng] = [-Infinity, -Infinity]
  let [minLat, minLng] = [Infinity, Infinity]
  for (const [lat, lng] of polygonArray) {
    maxLat = Math.max(lat, maxLat)
    maxLng = Math.max(lng, maxLng)
    minLat = Math.min(lat, minLat)
    minLng = Math.min(lng, minLng)
  }

  // 多角形Polygon内の代表点を抽出
  const points: number[][] = []
  const maxLatIdx = (maxLat - minLat) / minP2PDist
  const maxLngIdx = (maxLng - minLng) / minP2PDist
  for (let latIdx = 0; latIdx <= maxLatIdx; latIdx++) {
    for (let lngIdx = 0; lngIdx <= maxLngIdx; lngIdx++) {
      const tmpPoint = [minLat + latIdx * minP2PDist, minLng + lngIdx * minP2PDist]
      if (pointInPolygon(polygonArray, tmpPoint)) {
        points.push(tmpPoint)
      }
    }
  }
  return points
}

/**
 * firestoreにおけるタイムスタンプを生成する
 * @returns TimeStamp
 */
export const genTimeStamp = () => {
  const today = new Date(Date.now())
  return firebase.firestore.Timestamp.fromDate(today)
}

/**
 * windowが使えるときだけalertを出す関数
 * @param {object} window
 * @param {string} msg
 */
export const safeAlert = (window: Window, msg: string) => {
  if (window) {
    window.alert(msg)
  }
}

export const readFileAsync = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsText(file)
  })
}
