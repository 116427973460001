import React from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'

const OwnerBreadcrump = (props) => {
  const history = useHistory()
  return (
    <>
      <Breadcrumb size={'mini'}>
        <Breadcrumb.Section onClick={() => history.push(`${paths.menuowner}`)} link active>
          <i style={{ color: 'gray' }} className="home icon"></i>
        </Breadcrumb.Section>
        {props.items.map((data, i, datas) => {
          if (i === datas.length - 1) {
            return (
              <>
                <Breadcrumb.Divider icon="right angle" />
                <Breadcrumb.Section>{data.text}</Breadcrumb.Section>
              </>
            )
          } else {
            return (
              <>
                <Breadcrumb.Divider icon="right angle" onClick={() => history.push(`${data.link}`)} link active />
                <Breadcrumb.Section>{data.text}</Breadcrumb.Section>
              </>
            )
          }
        })}
      </Breadcrumb>
    </>
  )
}

export default OwnerBreadcrump
