import React, { useEffect, useState } from 'react'
import firebase from 'FirebaseConfig'
import noImage from 'assets/pictures/no_image.jpg'

const MediaImage = ({ media }) => {
  const [mediaImageURL, setMediaImageURL] = useState<string>(noImage)

  useEffect(() => {
    try {
      if (media) {
        if (media.mediaImagePaths && media.mediaImagePaths.length > 0) {
          const storageRef = firebase.storage().ref()
          storageRef
            .child(media.mediaImagePaths[0])
            .getDownloadURL()
            .then(function (url) {
              setMediaImageURL(url)
            })
            .catch(function (error) {
              console.log('Error getting document:', error)
            })
        } else {
          setMediaImageURL(noImage)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [media])

  return (
    <>
      {mediaImageURL ? (
        <img src={mediaImageURL} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
      ) : (
        <img src={noImage} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
      )}
    </>
  )
}

export { MediaImage }
