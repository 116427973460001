// Library
import React, { useState, useEffect, useContext } from 'react'
// npm install @emotion/core and npm install @emotion/styled
import { Segment, Container, Dimmer, Loader, Message } from 'semantic-ui-react'
// import theme from 'assets/pictures/theme'
import Spacer from 'components/atoms/Spacer'
import paths from 'constants/paths'
import { auth } from 'FirebaseConfig'
import { AuthContext } from 'providers/Auth'
import NavigationBarPlane from 'components/organisms/menubar/NavigationBarPlane'
import { useHistory } from 'react-router-dom'
import SignInForm from 'components/organisms/sessions/SignInForm'
import FooterNav from 'components/organisms/menubar/FooterNav'
import './index.css'

const SigninOwner = () => {
  // currentUser is provided
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()
  const { currentUser } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({})
  const [visible, setVisible] = useState(false)

  // styledでcssかいたらhandleChangeのたびに再レンダーされるのでinputには向いてない。解決方法あるか。
  const handleChange = (event) => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value)
        break
      case 'password':
        setPassword(event.target.value)
        break
      default:
        console.log('key not found')
    }
  }

  const clickLoginAsOwner = () => {
    setLoading(true)
    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        history.push({
          pathname: `${paths.menuowner}`,
          state: {
            text: 'ログインしました',
            type: 'positive',
          },
        })
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        setVisible(true)
        setMessage({
          type: 'negative',
          title: 'Error',
          text: error.message,
        })
      })
  }

  useEffect(() => {
    if (currentUser) {
      history.push({
        pathname: `${paths.menuowner}`,
        state: {
          text: 'ログインしました。',
          type: 'positive',
        },
      })
    }
  }, [currentUser, history])

  const handleDismiss = () => {
    setVisible(false)
    history.push({
      state: { text: '', type: '' },
    })
  }

  return (
    <>
      <NavigationBarPlane />
      <Spacer />
      {loading && (
        <Dimmer active inverted>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <Container text>
        <Segment>
          <div className="singinOwner__loginFormContainer">
            <SignInForm
              header="AdVice for Ownerにログインする"
              note="AdVice for Ownerにログインして媒体の管理を行いましょう。"
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              clickLogin={clickLoginAsOwner}
              handleChange={handleChange}
              owner={true}
            />
          </div>
        </Segment>
      </Container>
      <Spacer />
      {visible && (
        <div className="singinOwner__appLayoutMessage">
          <Message negative onDismiss={handleDismiss} header={message.title} content={message.text} />
        </div>
      )}
      <FooterNav />
    </>
  )
}

export default SigninOwner
