import React from 'react'
import { Table } from 'semantic-ui-react'
import Loading from 'components/atoms/Loading'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'

const generateFieldText = (pre, succ, displayFunc, media) => {
  if (typeof displayFunc !== 'function') {
    return ''
  }
  return pre + displayFunc(media) + succ
}

// 特に支障のない場合、媒体情報の画面表示に使われる関数コンポーネント
const MediaDetailTableAuto = ({ media }) => {
  const colInfo = getColInfoFromTypeName(media.mediaType)
  const jsxElem = []
  for (const option of colInfo.basicInfoFields) {
    jsxElem.push(
      <Table.Row key={option.displayedName}>
        <Table.Cell key={option.displayedName}> {option.displayedName} </Table.Cell>
        <Table.Cell> {generateFieldText(option.displayedPrefix, option.displayedSuffix, option.displayMethod, media)} </Table.Cell>
      </Table.Row>
    )
  }
  return <Table>{colInfo ? jsxElem : ''}</Table>
}

// mediaTypeごとに適切な媒体情報表示用コンポーネントを選んで返すファクトリメソッド
const MediaDetailTable = ({ media }) => {
  if (!media) {
    return <Loading />
  }
  if (!media.mediaType) {
    throw new Error('This media is not assigned appropriate mediaType')
  }
  switch (media.mediaType) {
    default:
      return <MediaDetailTableAuto media={media} />
  }
}

export default MediaDetailTable
