//日付でソートする関数
export function dateSortCallback(a, b) {
  if (a.startDate && b.startDate) {
    if (a.startDate.seconds > b.startDate.seconds) return 1
    if (a.startDate.seconds < b.startDate.seconds) return -1
    if (a.startDate.seconds === b.startDate.seconds) {
      if (a.endDate.seconds >= b.endDate.seconds) {
        return 1
      } else return -1
    }
  } else if (a.startDate && !b.startDate) {
    return 1
  } else if (!a.startDate && b.startDate) {
    return -1
  }
  return 0
}
