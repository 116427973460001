import React from 'react'
import { Container } from 'semantic-ui-react'
import Spacer from 'components/atoms/Spacer'
import NavigationBar from 'components/organisms/menubar/NavigationBar'
import FooterNav from 'components/organisms/menubar/FooterNav'

const AppLayoutPlane = (props) => {
  return (
    <>
      <NavigationBar />
      <Spacer />
      <Container>{props.children}</Container>
      <FooterNav />
    </>
  )
}

export default AppLayoutPlane
