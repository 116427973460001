import React, { useContext, useState, useEffect } from 'react'
import { CartsContext } from 'providers/Carts'
import { useHistory, useLocation } from 'react-router-dom'
import { groupSearchFilter, statusFilter } from 'components/organisms/group/helper'
import './index.css'
import { AuthContext } from 'providers/Auth'

const VerticalBarCart = (props) => {
  const location = useLocation()
  const history = useHistory()
  const { carts } = useContext(CartsContext)
  const [activeCart, setActiveCart] = useState('')
  const [filteredCarts, setFilteredCarts] = useState([])
  const [cartId, setCartId] = useState('')
  const [locationPathname, setLocationPathname] = useState('')
  const { currentUser } = useContext(AuthContext)

  const handleCartClick = (cart) => {
    setActiveCart(cart.uid)
    if (location.pathname.substr(0, 11) === '/cart/edit/') {
      history.push(`/cart/edit/${cart.uid}`)
    } else if (location.pathname.substr(0, 14) === '/cart/confirm/') {
      history.push(`/cart/edit/${cart.uid}`)
    } else {
      history.push(`/group/edit/${cart.uid}`)
    }
  }

  useEffect(() => {
    if (locationPathname !== location.pathname) {
      // if /group/edit/:uid => :uid => campaignId
      if (location.pathname.substr(0, 12) === '/group/edit/') {
        setCartId(location.pathname.substr(-20))
      }
      // if /cart/edit/:uid => :uid => campaignId
      if (location.pathname.substr(0, 11) === '/cart/edit/') {
        setCartId(location.pathname.substr(-20))
      }
      // if /cart/confirm/:uid => :uid => campaignId
      if (location.pathname.substr(0, 14) === '/cart/confirm/') {
        setCartId(location.pathname.substr(-20))
      }
      // if /cart/confirm/:uid => :uid => campaignId
      if (location.pathname.substr(0, 14) === '/cart/confirm/') {
        setCartId(location.pathname.substr(-20))
      }
    }
    if (carts && cartId) {
      setActiveCart(cartId)
    }

    if (carts) {
      let tmpcarts = carts
      tmpcarts = groupSearchFilter(tmpcarts, currentUser.uid)
      tmpcarts = statusFilter(tmpcarts, true, true, true, true) //すべてのstatusのカートを表示(statusがないものを除く)
      setFilteredCarts(tmpcarts)
    }
    setLocationPathname(location.pathname)
  }, [cartId, carts, location.pathname, location.search, locationPathname])

  const clickGroupDashboard = () => {
    history.push(`/group/dashboard`)
  }
  return (
    <div>
      <div
        className={`
          verticalBarCart__verticalBarHeader
          ${props.flagAngle ? '' : 'verticalBarCart__verticalBarHeaderClose'}
        `}
        key={'all_campaigns'}
        onClick={clickGroupDashboard}
      >
        グループ一覧
      </div>
      <>
        {filteredCarts.map((cart) => (
          <div
            key={`${cart.uid}`}
            className={`
            verticalBarCart__verticalBarRow
            ${activeCart === cart.uid ? 'verticalBarCart__verticalBarRowSelected' : ''}
            ${props.flagAngle ? '' : 'verticalBarCart__verticalBarRowClose'}
          `}
            onClick={() => handleCartClick(cart)}
          >
            {cart.groupName}
          </div>
        ))}
      </>
    </div>
  )
}

export default VerticalBarCart
