import { db, ADRES_API_URL, ADRES_API_ENV } from 'FirebaseConfig'
import { validateMediaForm } from 'components/pages/Admin/AddMedia/AddMediaPage/helper'

//postメソッドでhttpsリクエストを実行する関数。urlがリクエストを送信するURL、body_objがbodyに乗っけるオブジェクト(json)
// eslint-disable-next-line
export const sendPostRequest = async (url: string, body_obj: any): Promise<string> => {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest()
    req.open('POST', url, true)
    // req.responseType = 'json'
    req.setRequestHeader('Content-Type', 'application/json')
    req.onload = function () {
      if (req.readyState === req.DONE) {
        if (req.status === 200) {
          resolve(req.responseText)
        } else {
          reject(req.responseText)
        }
      }
    }
    const body_str = JSON.stringify(body_obj)
    req.send(body_str)
  })
}

//putメソッドでhttpsリクエストを実行する関数。urlがリクエストを送信するURL、body_objがbodyに乗っけるオブジェクト(json)
// eslint-disable-next-line
export async function sendPutRequest(url: string, body_obj: any) {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest()
    req.open('PUT', url, true)
    // req.responseType = 'json'
    req.setRequestHeader('Content-Type', 'application/json')
    req.onload = () => {
      if (req.readyState === req.DONE) {
        if (req.status === 200) {
          resolve(req.responseText)
        } else {
          reject(req.responseText)
        }
      }
    }
    const body_str = JSON.stringify(body_obj)
    req.send(body_str)
  })
}

type APIArgType = {
  input: any
}
/**
 * APIにputリクエストを投げる関数
 * @param {*} putObj
 * @param {*} url
 * @param {*} sucessMessage
 * @param {*} sucessCode
 */
const APICaller = async (putObj: APIArgType, url: string, successMessage: string, sucessCodes = [200, 204]) => {
  const response = await sendPostRequest(url, putObj)
  const responseObj = JSON.parse(response)
  if (responseObj.statusCode && !sucessCodes.includes(responseObj.statusCode)) {
    throw responseObj
  }
}

/**
 * 緯度経度の配列をAPIに渡せるように、delimeterを区切り文字とした文字列へ変換する
 * @param {object} latlngArray
 * @param {char} delimiter
 * @returns {string}
 */
const stringifyPoints = (latlngArray: number[][], delimiter: string): string => {
  let pointString = ''
  for (let i = 0; i < latlngArray.length - 1; i++) {
    pointString += latlngArray[i][0] + ',' + latlngArray[i][1] + delimiter
  }
  return pointString.slice(0, -1) //末尾の区切り文字を追加
}

export const getJinryu = async (mediaID: string | number, visiblePoints: number[][]) => {
  const visiblePointString = stringifyPoints(visiblePoints, ':') // 人流量計算APIの区切り文字は ":"
  const putObj = {
    input: {
      id: mediaID,
      region: visiblePointString,
      env: ADRES_API_ENV,
    },
  }
  await APICaller(putObj, `${ADRES_API_URL}/start-aggregation`, '人流量計算を開始しました')
}

/**
 * 視認率計算のクエリを投げる関数
 */
export const getVisibility = async (
  uid: string,
  numSurrBoards: number,
  buildingPos: { lat: number; lng: number },
  visiblePoints: number[][],
  range: number,
  verticalWidth: number,
  horizontalWidth: number,
  altitude: number,
  direction: number
) => {
  const visiblePointString = stringifyPoints(visiblePoints, ',') // 視認率計算の区切り文字は","
  const putObj = {
    input: {
      board_lat: buildingPos.lat,
      board_lon: buildingPos.lng,
      board_height: altitude,
      board_direction_lat: Math.cos(direction),
      board_direction_lon: Math.sin(direction),
      board_size: verticalWidth * horizontalWidth,
      around_boards: numSurrBoards,
      range: range,
      region: visiblePointString,
      id: uid,
      env: ADRES_API_ENV,
    },
  }
  await APICaller(putObj, `${ADRES_API_URL}/start-calc-shinin`, '視認率計算を開始しました')
}

export const updateDBHandler = async (mediaID, payload) => {
  try {
    validateMediaForm(payload)
  } catch (error: any) {
    console.error(error.message)
    throw Error('データが不適切なためアップロードに失敗しました。')
  }
  const mediaRef = db.collection('media').doc(mediaID)
  await mediaRef.set(payload, { merge: true })
}

/**
 * firestoreのmediaの項目を更新する関数
 */
// export const updateDBHandler = async (mediaID, mediaInfo, buildingPos, imageURLs, maleAgeRatio, femaleAgeRatio, ageRatio, tempVisibility, tempCirculation) => {
//   const mediaRef = db.collection('media').doc(mediaID)
//   await mediaRef.set(
//     {
//       address: mediaInfo.address,
//       direction: mediaInfo.direction,
//       note: mediaInfo.note,
//       summary: mediaInfo.summary,
//       latitude: mediaInfo.buildingPos.lat,
//       longitude: buildingPos.lng,
//       height: mediaInfo.height,
//       hasIllumination: mediaInfo.hasIllumination,
//       verticalWidth: mediaInfo.verticalWidth,
//       horizontalWidth: mediaInfo.horizontalWidth,
//       name: mediaInfo.name,
//       yearlyCost: mediaInfo.yearlyCost,
//       monthlyCost: mediaInfo.monthlyCost,
//       constructionCost: mediaInfo.constructionCost,
//       recoveryCost: mediaInfo.recoveryCost,
//       mediaType: mediaInfo.mediaType,
//       uid: mediaID,
//       mediaImagePaths: imageURLs,
//       isPrivate: mediaInfo.isPrivate,
//       maleAgeRatio: maleAgeRatio,
//       femaleAgeRatio: femaleAgeRatio,
//       ageRatio: ageRatio,
//       tempVisibility: tempVisibility,
//       tempCirculation: tempCirculation,
//     },
//     { merge: true }
//   )
// }

export type tagType = {
  name: string
  id: string
}
/**
 * 以前の状態のタグと新しく生成されたタグから新規作成するタグと削除するタグを算出する
 * @param {*} prevTags 以前の状態のタグの配列
 * @param {*} newTags 新しく付与するタグの配列
 * @returns 新規作成するタグと削除するタグ
 */
const computeTagDiff = (prevTags: tagType[], newTags: tagType[]): { createdTags: tagType[]; deletedTags: tagType[] } => {
  const duplicatedTagIds = [...prevTags, ...newTags]
    .map((tag) => tag.id)
    .filter((id, idx, self) => {
      return self.indexOf(id) === idx && idx !== self.lastIndexOf(id)
    })
  const deletedTags = prevTags.filter((tag) => !duplicatedTagIds.includes(tag.id))
  const createdTags = newTags.filter((tag) => !duplicatedTagIds.includes(tag.id))
  return { createdTags, deletedTags }
}

const computeTagIdDiff = (prevTags: string[], newTags: string[]): { createdTagIds: string[]; deletedTagIds: string[] } => {
  const duplicatedTagIds = [...prevTags, ...newTags].filter((id, idx, self) => {
    return self.indexOf(id) === idx && idx !== self.lastIndexOf(id)
  })
  const deletedTagIds = prevTags.filter((id) => !duplicatedTagIds.includes(id))
  const createdTagIds = newTags.filter((id) => !duplicatedTagIds.includes(id))
  return { createdTagIds, deletedTagIds }
}

export const updateDBRelatedToTag = async (mediaId: string, tags: tagType[]) => {
  const mediaDoc = await db.collection('media').doc(mediaId).get()
  const tagsSnapshot = await db.collection('media').doc(mediaId).collection('tags').get()
  let prevTags: tagType[] = []
  if (mediaDoc.exists) {
    //新規に媒体を作成する場合もあるためこの条件分岐は必要
    prevTags = tagsSnapshot.docs.map((tagDoc) => {
      return tagDoc.data() as tagType
    })
  }
  const { createdTags, deletedTags } = computeTagDiff(prevTags, tags)
  const batch = db.batch()
  for (const tag of createdTags) {
    const tagsRef = db.collection('media').doc(mediaId).collection('tags').doc(tag.id)
    batch.set(tagsRef, {
      name: tag.name,
      id: tag.id,
      ref: db.collection('tags').doc(tag.id),
    })
    const mediaRef = db.collection('tags').doc(tag.id).collection('media').doc(mediaId)
    batch.set(mediaRef, {
      id: mediaId,
      ref: db.collection('media').doc(mediaId),
    })
  }
  for (const tag of deletedTags) {
    const tagsRef = db.collection('media').doc(mediaId).collection('tags').doc(tag.id)
    batch.delete(tagsRef)
    const mediaRef = db.collection('tags').doc(tag.id).collection('media').doc(mediaId)
    batch.delete(mediaRef)
  }
  await batch.commit()
}

export const updateDBRelatedToLine = async (mediaId, lineIds) => {
  const mediaDoc = await db.collection('media').doc(mediaId).get()
  const linesSnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await db.collection('lines').get()
  const mediaLinesSnapshot = await db.collection('media').doc(mediaId).collection('lines').get()
  let prevLineIds: string[] = []
  if (mediaDoc.exists) {
    //新規に媒体を作成する場合もあるためこの条件分岐は必要
    prevLineIds = mediaLinesSnapshot.docs.map((lineDoc) => {
      return lineDoc.id
    })
  }
  const { createdTagIds, deletedTagIds } = computeTagIdDiff(prevLineIds, lineIds)
  const batch = db.batch()
  for (const lineId of createdTagIds) {
    const linesRef = db.collection('media').doc(mediaId).collection('lines').doc(lineId)
    const flag = linesSnapshot.docs.find((doc) => doc.id === lineId)
    const lineData = flag ? flag.data() : undefined
    if (lineData) {
      batch.set(linesRef, {
        ...lineData,
        ref: db.collection('lines').doc(lineId),
      })
    }
  }
  for (const lineId of deletedTagIds) {
    const linesRef = db.collection('media').doc(mediaId).collection('lines').doc(lineId)
    batch.delete(linesRef)
  }
  await batch.commit()
}

export const updateDBRelatedToHighway = async (mediaId: string, highwayIds: string[]) => {
  const mediaDoc = await db.collection('media').doc(mediaId).get()
  const highwaysSnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await db.collection('highways').get()
  const mediaHighwaysSnapshot = await db.collection('media').doc(mediaId).collection('highways').get()
  let prevHighwayIds: string[] = []
  if (mediaDoc.exists) {
    //新規に媒体を作成する場合もあるためこの条件分岐は必要
    prevHighwayIds = mediaHighwaysSnapshot.docs.map((lineDoc) => {
      return lineDoc.id
    })
  }
  const { createdTagIds, deletedTagIds } = computeTagIdDiff(prevHighwayIds, highwayIds)
  const batch = db.batch()
  for (const highwayId of createdTagIds) {
    const highwaysRef = db.collection('media').doc(mediaId).collection('highways').doc(highwayId)
    const highwayData = highwaysSnapshot.docs.find((doc) => doc.id === highwayId)?.data()
    if (highwayData) {
      batch.set(highwaysRef, {
        ...highwayData,
        ref: db.collection('highways').doc(highwayId),
      })
    }
  }
  for (const highwayId of deletedTagIds) {
    const highwaysRef = db.collection('media').doc(mediaId).collection('highways').doc(highwayId)
    batch.delete(highwaysRef)
  }
  await batch.commit()
}
