import { AdviceMediaDataType } from 'constants/mediaData'
import React, { useEffect, useState } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, PieLabelRenderProps, ContentRenderer } from 'recharts'

const RADIAN = Math.PI / 180
const renderCustomizedLabel: ContentRenderer<PieLabelRenderProps> = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = Number(innerRadius) + (Number(outerRadius) - Number(innerRadius)) * 0.5
  const x = Number(cx) + radius * Math.cos(-Number(midAngle) * RADIAN)
  const y = Number(cy) + radius * Math.sin(-Number(midAngle) * RADIAN)

  return (
    <text x={x} y={y} fill="white" textAnchor={x > Number(cx) ? 'start' : 'end'} dominantBaseline="central">
      {`${(Number(percent) * 100).toFixed(0)}%`}
    </text>
  )
}

type sexRatioByAgeChartProps = {
  media: AdviceMediaDataType
  isMinimized?: boolean
}
export const SexRatioByAgeChart = ({ media, isMinimized = false }: sexRatioByAgeChartProps) => {
  const [data, setData] = useState([{}])
  useEffect(() => {
    setData([
      { name: '～10代', male: Math.round(media.maleRatio15 * 10) / 10, female: 100 - Math.round(media.maleRatio15 * 10) / 10 },
      { name: '20代', male: Math.round(media.maleRatio20 * 10) / 10, female: 100 - Math.round(media.maleRatio20 * 10) / 10 },
      { name: '30代', male: Math.round(media.maleRatio30 * 10) / 10, female: 100 - Math.round(media.maleRatio30 * 10) / 10 },
      { name: '40代', male: Math.round(media.maleRatio40 * 10) / 10, female: 100 - Math.round(media.maleRatio40 * 10) / 10 },
      { name: '50代', male: Math.round(media.maleRatio50 * 10) / 10, female: 100 - Math.round(media.maleRatio50 * 10) / 10 },
      { name: '60代', male: Math.round(media.maleRatio60 * 10) / 10, female: 100 - Math.round(media.maleRatio60 * 10) / 10 },
      { name: '70代', male: Math.round(media.maleRatio70 * 10) / 10, female: 100 - Math.round(media.maleRatio70 * 10) / 10 },
      { name: '80代～', male: Math.round(media.maleRatio80 * 10) / 10, female: 100 - Math.round(media.maleRatio80 * 10) / 10 },
    ])
  }, [media])

  return (
    <ResponsiveContainer height={300} width="100%">
      <BarChart data={data} layout="horizontal">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        {isMinimized || <YAxis label={{ value: '性別比[％]', angle: -90, position: 'insideLeft' }} />}
        <Bar label={renderCustomizedLabel} name="男性" dataKey="male" stackId="a" fill="#1854d2" />
        <Bar name="女性" dataKey="female" stackId="a" fill="#eb4e91" />
        <Tooltip />
        <Legend />
      </BarChart>
    </ResponsiveContainer>
  )
}
