import { Package } from 'components/organisms/admin/PackageTable/helper'
import { mediaType } from 'helpers/mediaType/mediaDef'
import { useMedias } from 'providers/Medias'
import React, { useMemo } from 'react'
import PackageSubtotalCard from '../PackageSubtotalCard'
import { splitMediaListByMediaType } from './helper'

type props = {
  mediaPackage: Package
}
const PackageSubtotalList = ({ mediaPackage }: props) => {
  const mediaList = useMedias()
  const splitMediaListObj = useMemo(() => {
    return splitMediaListByMediaType(mediaPackage.mediaList, mediaList)
  }, [mediaPackage, mediaList])
  const mediaTypeList = useMemo<mediaType[]>(() => {
    return (Object.keys(splitMediaListObj) as mediaType[]).sort()
  }, [splitMediaListObj])
  return (
    <div>
      {mediaTypeList.map((mediaType) => {
        const mediaList = splitMediaListObj[mediaType]
        return mediaList ? <PackageSubtotalCard mediaType={mediaType} mediaList={mediaList} /> : null
      })}
    </div>
  )
}

export default PackageSubtotalList
