import React from 'react'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import './index.css'

const MediaDetailEffectTable = (props) => {
  const jsxElems = []
  if (props.mediaType) {
    const colInfo = getColInfoFromTypeName(props.mediaType)
    const length = colInfo.cartEditMediaDetailEffectTable.length
    for (let i = 0; i < length; i = i + 2) {
      jsxElems.push(
        <tr>
          <td className="mediaDetailEffectTable__mediaDetailEffectItem">
            <p className="mediaDetailEffectTable__mediaDetailEffectItemTitle">{colInfo.cartEditMediaDetailEffectTable[i].displayedName}</p>
            <p className="mediaDetailEffectTable__mediaDetailEffectItemValue">{colInfo.cartEditMediaDetailEffectTable[i].displayMethod(props)}</p>
          </td>
          <td className="mediaDetailEffectTable__mediaDetailEffectItem">
            <p className="mediaDetailEffectTable__mediaDetailEffectItemTitle">{colInfo.cartEditMediaDetailEffectTable[i + 1].displayedName}</p>
            <p className="mediaDetailEffectTable__mediaDetailEffectItemValue">{colInfo.cartEditMediaDetailEffectTable[i + 1].displayMethod(props)}</p>
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      <table className="mediaDetailEffectTable__mediaDetailEffect">{jsxElems}</table>
      <div basic>
        {props.mediaType === 'mediaTypeBillBoard' || props.mediaType === 'mediaTypeBillboard' || props.mediaType === 'mediaTypePoster' ? (
          <p style={{ marginBottom: '2rem', fontSize: '1rem', whiteSpace: 'normal' }}>
            ※１：CPM（Cost Per Mille）は、1000インプレッションあたりのコスト（税込みでの掲載価格＋施工回復費）。表示される価格は全て税込み。
            <br />
            ※２：tCPM（targeting Cost Per Mille）は、指定ターゲットへの1000インプレッションあたりのコスト。
          </p>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default MediaDetailEffectTable
