import React, { useMemo } from 'react'
import './index.css'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'
import { useMediaAvailable } from 'providers/MediasAvailable'

type Props = {
  lat: number
  lng: number
  isMouseOvered: boolean
  isTargetMedia: boolean
  isSameJackGroup: boolean
  isJackParent: boolean
  media: AdviceMediaDataType
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const decideClassName = (isTargetMedia: boolean, isMouseOvered: boolean, isSameJackGroup: boolean, isJackParent: boolean) => {
  if (isTargetMedia || isMouseOvered) {
    return isJackParent ? 'mapSearchMarker__bubble10' : 'mapSearchMarker__bubble07'
  } else if (isSameJackGroup) {
    return isJackParent ? 'mapSearchMarker__bubble11' : 'mapSearchMarker__bubble08'
  } else {
    return isJackParent ? 'mapSearchMarker__bubble09' : 'mapSearchMarker__bubble06'
  }
}

const MapSearchMarker = (props: Props) => {
  const { mediasAvailable } = useMediaAvailable()
  const unitPrice = useMemo(() => {
    if (props.media.isPrivate) {
      return '-'
    }
    const media = props.media
    if (isJackChild(media)) {
      const m = mediasAvailable.find((m) => m.uid === media.parentUid)
      if (!m) {
        return '-'
      }
      const colInfo = getColInfoFromTypeName(m.mediaType)
      return colInfo.cardContent.mediaSearch[0].displayMethod(m)
    }
    const colInfo = getColInfoFromTypeName(media.mediaType)
    return colInfo.cardContent.mediaSearch[0].displayMethod(media)
  }, [props])

  return (
    <div onClick={props.onClick}>
      {props.isMouseOvered && (
        <div className="mapSearchMarker__mapInfo">
          <>{props.media.name}</>
        </div>
      )}
      <div
        className={decideClassName(props.isTargetMedia, props.isMouseOvered, props.isSameJackGroup, props.isJackParent)}
        style={{ cursor: 'pointer' }}
        title={props.media.name}
      >
        {unitPrice}
      </div>
    </div>
  )
}

export default MapSearchMarker
