import React, { useState } from 'react'
import AppLayoutAdmin from 'components/organisms/layouts/AppLayoutAdmin'
import { convertFile2CSV, addMedia, addJackChildMedia } from 'components/pages/Admin/AddMedia/AddMediaPage/helper'
import { safeAlert } from 'helpers/admin/utils'
import { Container, Button, Radio } from 'semantic-ui-react'
import { MediaPreview } from 'components/organisms/admin/MediaPreview'
import { mediaTypesEnum } from 'constants/mediaTypes'
import Spacer from 'components/atoms/Spacer'
import Loading from 'components/atoms/Loading'

const AdminAddMedia = () => {
  const [csvData, setCSV] = useState(null) // csvファイルから読み取ったデータの配列
  const [jackChildCsvData, setJackChildCsvData] = useState(null)
  const [selectedMediaType, setSelectedMediaType] = useState(mediaTypesEnum.BILLBOARD)
  const [selectedJackChildMediaType, setSelectedJackChildMediaType] = useState(mediaTypesEnum.BILLBOARD)
  const [loadingFlag, setLoadingFlag] = useState(false)

  return (
    <AppLayoutAdmin>
      <Spacer />
      <Container type="text">入力する媒体の種類を選択</Container>
      <Container>
        {Object.keys(mediaTypesEnum).map((mediaType) => {
          return (
            <Radio
              key={mediaType}
              label={mediaType}
              name="radioGroup"
              value={mediaTypesEnum[mediaType]}
              checked={selectedMediaType === mediaTypesEnum[mediaType]}
              onChange={(e, { value }) => {
                setSelectedMediaType(value)
                setCSV(null)
              }}
            />
          )
        })}
      </Container>
      <label>
        <Container type="text">csvファイルを選択</Container>
        <Container>
          <input
            type="file"
            onChange={async (e) => {
              setLoadingFlag(true)
              try {
                const result = await convertFile2CSV(e.target.files[0], selectedMediaType)
                setCSV(result)
              } catch (error) {
                safeAlert(window, error)
              }
              setLoadingFlag(false)
            }}
          />
        </Container>
      </label>
      <Spacer />
      <Container>{loadingFlag ? <Loading /> : <MediaPreview csvData={csvData} mediaType={selectedMediaType} />}</Container>
      {selectedMediaType === mediaTypesEnum.JACK && (
        <>
          <Container type="text">子媒体の媒体の種類を選択</Container>
          <Container>
            {Object.keys(mediaTypesEnum)
              .filter((mediaType) => mediaTypesEnum[mediaType] !== mediaTypesEnum.JACK)
              .map((mediaType) => {
                return (
                  <Radio
                    key={mediaType}
                    label={mediaType}
                    name="radioGroupJackChild"
                    value={mediaTypesEnum[mediaType]}
                    checked={selectedJackChildMediaType === mediaTypesEnum[mediaType]}
                    onChange={(e, { value }) => {
                      setSelectedJackChildMediaType(value)
                      setJackChildCsvData(null)
                    }}
                  />
                )
              })}
          </Container>
          <Container type="text">子媒体のcsvファイルを選択</Container>
          <Container>
            <input
              type="file"
              onChange={async (e) => {
                setLoadingFlag(true)
                try {
                  const result = await convertFile2CSV(e.target.files[0], selectedJackChildMediaType, true)
                  setJackChildCsvData(result)
                } catch (error) {
                  safeAlert(window, error)
                }
                setLoadingFlag(false)
              }}
            />
          </Container>
          <Spacer />
          <Container>
            {loadingFlag ? <Loading /> : <MediaPreview csvData={jackChildCsvData} mediaType={selectedJackChildMediaType} isJackChild={true} />}
          </Container>
        </>
      )}
      <Container>
        <Button
          onClick={async () => {
            if (csvData || jackChildCsvData) {
              try {
                csvData && (await addMedia(csvData, selectedMediaType))
                jackChildCsvData && (await addJackChildMedia(jackChildCsvData, selectedJackChildMediaType))
              } catch (err) {
                safeAlert(window, err)
                return
              }
            } else {
              safeAlert(window, 'ファイルが選択されていません')
            }
          }}
        >
          現在表示されている媒体を追加
        </Button>
      </Container>
    </AppLayoutAdmin>
  )
}

export default AdminAddMedia
