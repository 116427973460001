import React from 'react'
import { MediaImage } from 'components/organisms/images/MediaImage'
import { Header, Button, Label } from 'semantic-ui-react'
import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'
import './index.css'

type mediaCardConfirmProps = {
  media: AdviceMediaDataType
  clickRemoveMediafromCart: (media: AdviceMediaDataType) => void
}

const MediaCardConfirm = (props: mediaCardConfirmProps) => {
  return (
    <>
      <div style={{ display: 'grid' }} className="mediaCardConfirm__mediaCardCard" key={props.media.uid}>
        <div style={{ gridRow: '1/5', gridColumn: '1/2', marginRight: '1rem' }}>
          <MediaImage media={props.media} />
        </div>
        <div style={{ gridRow: '1/2', gridColumn: '2/5' }}>
          <Header as="h5">
            {props.media.name}
            {isJackChild(props.media) && <Label>エリアジャック専用</Label>}
          </Header>
        </div>

        <div style={{ gridRow: '2/3', gridColumn: '2/5' }}>
          <p className="mediaCardConfirm__cartSummaryText">{props.media.address}</p>
        </div>
        <div style={{ gridRow: '4/5', gridColumn: '2/3' }}>
          <Button inverted size="mini" compact color="red" onClick={() => props.clickRemoveMediafromCart(props.media)}>
            削除
          </Button>
        </div>
      </div>
    </>
  )
}
export default MediaCardConfirm
