import React, { useEffect, useState } from 'react'
import { Segment, Button } from 'semantic-ui-react'
import { GetYearMonthDate } from 'components/molecules/GetYearMonthDate'
import { convertValueToText } from 'helpers/mediaType/helpers'
import { roundAndAddThousandsSeparator } from 'helpers/roundAndAddThousandsSeparator'
import { cartDataType } from 'providers/CartsAll'
import './index.css'

type Props = {
  cart: cartDataType
}
//cartオブジェクトに含まれるプロパティは英数字なので、ディスプレイ用に変換するときの型
type cartStatusType = '注文前' | '注文中'
type sexType = ['男性', '女性'] | ['男性'] | ['女性']

const CartResumeTab = (props: Props) => {
  const [cartStatus, setCartStatus] = useState<cartStatusType>('注文前')
  const [sex, setSex] = useState<sexType>(['男性', '女性'])

  useEffect(() => {
    if (props.cart.status) {
      switch (props.cart.status) {
        case 1:
          setCartStatus('注文前')
          break
        case 2:
          setCartStatus('注文中')
          break
        default:
          console.log('no key match')
      }
    }
  }, [props.cart.status])

  useEffect(() => {
    if (props.cart.targetSex) {
      switch (props.cart.targetSex) {
        case 'unisex':
          setSex(['男性', '女性'])
          break
        case 'male':
          setSex(['男性'])
          break
        case 'female':
          setSex(['女性'])
          break
        default:
          console.log('no key match')
      }
    }
  }, [props.cart.targetSex])

  return (
    <>
      <div className="cartResumeTab__scrollColumn">
        <Segment>
          <table>
            <tbody>
              <tr>
                <th align="left">ステータス &nbsp;&nbsp; </th>
                <td align="left">{cartStatus}</td>
              </tr>
              <tr>
                <th align="left">予算 &nbsp;&nbsp; </th>
                <td align="left">{props.cart.budget == ('' || null) ? '指定なし' : `¥${roundAndAddThousandsSeparator(props.cart.budget, 1)}`}</td>
              </tr>
              <tr>
                <th align="left">配信期間 &nbsp;&nbsp; </th>
                <td>
                  {props.cart.startDate && <GetYearMonthDate timestamp={props.cart.startDate} />}〜
                  {props.cart.endDate && <GetYearMonthDate timestamp={props.cart.endDate} />}
                </td>
              </tr>
              <tr>
                <th align="left">媒体の種類 &nbsp;&nbsp; </th>
                <td>{convertValueToText(props.cart.mediaType)}</td>
              </tr>
            </tbody>
          </table>
        </Segment>
        <Segment style={{ whiteSpace: 'normal' }}>
          {props.cart.targetLocation && props.cart.targetLocation.length > 0 ? (
            <>
              <b>配信地域</b>
              <br />
              <br />
              {props.cart.targetLocation &&
                props.cart.targetLocation.map((item) => (
                  <Button style={{ marginBottom: '.5rem' }} color="teal" compact key={item}>
                    {item}
                  </Button>
                ))}
              <br />
              <br />
            </>
          ) : (
            ''
          )}
          <b>世代</b>
          <br />
          <br />
          {props.cart.targetAge &&
            props.cart.targetAge.map((item) => (
              <Button style={{ marginBottom: '.5rem' }} color="teal" active compact key={item}>
                {item}
              </Button>
            ))}
          <br />
          <br />
          <b>性別</b>
          <br />
          <br />
          {sex &&
            sex.map((item) => (
              <Button color="teal" active compact key={item}>
                {item}
              </Button>
            ))}
        </Segment>
      </div>
    </>
  )
}

export default CartResumeTab
