// Library
import React, { useState, useEffect } from 'react'
import * as firebase from 'firebase'
// npm install @emotion/core and npm install @emotion/styled
import { Segment, Container, Message, Dimmer, Loader } from 'semantic-ui-react'
// import theme from 'assets/pictures/theme'
import Spacer from 'components/atoms/Spacer'
import paths from 'constants/paths'
import { auth, db } from 'FirebaseConfig'
import { useHistory } from 'react-router-dom'
import NavigationBarPlane from 'components/organisms/menubar/NavigationBarPlane'
import SignUpForm from 'components/organisms/sessions/SignupForm'
import FooterNav from 'components/organisms/menubar/FooterNav'
import './index.css'

const Signup = () => {
  // currentUser is provided
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const history = useHistory()
  const [companyName, setCompanyName] = useState('')
  const [address, setAddress] = useState('')
  const [fullName, setFullName] = useState('')
  const [tel, setTel] = useState('')
  const [policy, setPolicy] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({})
  const [visible, setVisible] = useState(false)

  // styledでcssかいたらhandleChangeのたびに再レンダーされるのでinputには向いてない。解決方法あるか。
  const handleChange = (event) => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value)
        break
      case 'password':
        setPassword(event.target.value)
        break
      case 'passwordConfirm':
        setPasswordConfirm(event.target.value)
        break
      case 'companyName':
        setCompanyName(event.target.value)
        break
      case 'address':
        setAddress(event.target.value)
        break
      case 'fullName':
        setFullName(event.target.value)
        break
      case 'tel':
        setTel(event.target.value)
        break
      case 'policy':
        setPolicy(!policy)
        break
      // 広告主はdispllayNameを登録しない
      default:
        console.log('key not found')
    }
  }

  const clickSignupAsUser = () => {
    setLoading(true)
    if (policy) {
      if (password === passwordConfirm) {
        auth
          .createUserWithEmailAndPassword(email, password)
          .then((res) => {
            firebase.analytics().logEvent('sign_up')
            const user = res.user
            // 広告主はdisplayNameを登録しない。
            db.collection('user')
              .doc(user.uid)
              .set({
                uid: user.uid,
                docId: user.uid,
                companyName: companyName,
                address: address,
                fullName: fullName,
                tel: tel,
                email: email,
                isOwner: false,
                isConfirmed: true,
              })
              .then(() => {
                let domain = document.domain
                let port = domain === 'localhost' ? ':3000' : ''
                let prot = domain === 'localhost' ? 'http' : 'https'
                const actionCodeSettings = {
                  url: `${prot}://${domain}${port}${paths.signin}?email=${user.email}&uid=${user.uid}`,
                }
                console.log('メール送信先', actionCodeSettings.url)
                user
                  .sendEmailVerification(actionCodeSettings)
                  .then(() => {
                    history.push({
                      pathname: `${paths.verifyEmail}`,
                      state: {
                        text: `${user.email}宛に確認メールを送信しました。メールを確認して認証を完了させてください。`,
                        type: 'positive',
                      },
                    })
                  })
                  .catch((error) => {
                    console.error(error)
                    history.push({
                      pathname: `${paths.signup}`,
                      state: {
                        text: `${user.email}宛に確認メールを送信できませんでした[${error}]`,
                        type: 'negative',
                      },
                    })
                  })
                setLoading(false)
              })
              .catch(() => {
                setMessage({
                  type: 'negative',
                  title: '登録失敗',
                  text: 'ユーザーのデータ作成に失敗しました。',
                })
                setVisible(true)
                setLoading(false)
              })
          })
          .catch((error) => {
            switch (error.code) {
              case 'auth/email-already-in-use':
                setMessage({
                  type: 'negative',
                  text: '使用済みのメールアドレス',
                  title: '入力されたメールアドレスはすでに使用されています。別のメールアドレスをご入力ください。',
                })
                break
              case 'auth/invalid-email':
                setMessage({
                  type: 'negative',
                  title: '不適切なメールアドレス',
                  text: 'メールアドレスの形式が不適切です。正しいメールアドレスをご入力ください。',
                })
                break
              case 'auth/weak-password':
                setMessage({
                  type: 'negative',
                  title: '脆弱性の高いパスワード',
                  text: 'パスワードが弱すぎます。より複雑なパスワードをご入力ください。',
                })
                break
              default:
                setMessage({
                  type: 'negative',
                  title: '登録失敗',
                  text: 'ユーザー登録に失敗しました。',
                })
                break
            }
            setVisible(true)
            setLoading(false)
          })
        // メールリンク認証
        // リダイレクト先を指定するとコンティニューボタンが現れる。
        // .then(() => {
        //   history.push({
        //     pathname: `${paths.my}`,
        //     state: {
        //       text: 'ログインしました。',
        //       type: 'positive',
        //     }
        //   })
        // })
      } else {
        setVisible(true)
        setMessage({
          type: 'negative',
          text: 'パスワードが一致しません。',
          title: 'error',
        })
        setLoading(false)
      }
    } else {
      setVisible(true)
      setMessage({
        type: 'negative',
        text: '同意して下さい。',
        title: 'error',
      })
      setLoading(false)
    }
    // mail for e-mail address verification can be sent here by using sendSignInLinkToEmail()
  }

  // TODO: metaタグをコンポーネントにする
  useEffect(() => {
    const title = '新規会員登録｜AdVice（アドバイス）'
    const description =
      'AdViceを利用できるよう新規に会員登録することができます。サービス認知を圧倒的に高めるOOHマーケティングを効果的に、カンタンに。屋外広告注文プラットフォーム「AdVice（アドバイス）」は、投資対効果ベースで屋外広告を注文できる新しいプラットフォームです。'
    document.title = title
    // title以外のmeta
    const headData = document.head.children

    for (let i = 0; i < headData.length; i++) {
      const nameVal = headData[i].getAttribute('name')
      if (nameVal !== null) {
        if (nameVal.indexOf('description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        // OGP(twitter)の設定
        if (nameVal.indexOf('twitter:title') !== -1) {
          headData[i].setAttribute('content', title)
        }
        if (nameVal.indexOf('twitter:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:description') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:title') !== -1) {
          headData[i].setAttribute('content', description)
        }
        if (nameVal.indexOf('og:image') !== -1) {
          headData[i].setAttribute('content', process.env.REACT_APP_OGP_URL + '/img_ogp.png')
        }
      }
    }
  })
  useEffect(() => {
    //   if (currentUser) {
    //     history.push({
    //       pathname: `${paths.my}`,
    //       state: {
    //         text: 'ログインしました。',
    //         type: 'positive'
    //       }
    //     })
    //   }
    // }, [currentUser, history])
  })
  const handleDismiss = () => {
    setVisible(false)
    history.push({
      state: { text: '', type: '' },
    })
  }

  return (
    <>
      <NavigationBarPlane />
      <Spacer />
      {loading && (
        <Dimmer active inverted page>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <Container text>
        <Segment>
          <div className="signupOwner__loginFormContainer">
            <SignUpForm
              header="AdVice（アドバイス）に新しく登録する"
              note="AdVice（アドバイス）に登録して屋外広告の注文を行いましょう。"
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              companyName={companyName}
              setCompanyName={setCompanyName}
              address={address}
              setAddress={setAddress}
              fullName={fullName}
              setFullName={setFullName}
              tel={tel}
              setTel={setTel}
              clickSignup={clickSignupAsUser}
              handleChange={handleChange}
              owner={false}
            />
          </div>
        </Segment>
      </Container>
      <Spacer />
      {visible && (
        <div className="signupOwner__appLayoutMessage">
          <Message negative onDismiss={handleDismiss} header={message.title} content={message.text} />
        </div>
      )}
      <FooterNav />
    </>
  )
}

export default Signup
