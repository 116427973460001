import React, { createContext, useState, useEffect, useContext } from 'react'
import { db } from 'FirebaseConfig'
import { Package } from 'components/organisms/admin/PackageTable/helper'

const PackagesContext = createContext<Package[]>([])

// provide Tags
const PackagesProvider = ({ children }) => {
  const [packages, setPackages] = useState<Package[]>([])
  useEffect(() => {
    //TODO: tagsのwordingを変える(media_tagsとかに)
    const unsubscriber = db.collection('packages').onSnapshot(async (query) => {
      const tmpPackages: Package[] = []
      for (const doc of query.docs) {
        tmpPackages.push(doc.data() as Package)
      }
      setPackages(tmpPackages)
    })
    return unsubscriber
  }, [])

  return <PackagesContext.Provider value={packages}>{children}</PackagesContext.Provider>
}

export default PackagesProvider
export const usePackages = () => {
  return useContext(PackagesContext)
}
