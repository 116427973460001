import React, { useContext, useEffect } from 'react'
import { AuthContext } from 'providers/Auth'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import { Container, Header, Segment } from 'semantic-ui-react'
import AppLayoutOwner from 'components/organisms/layouts/AppLayoutOwner'
import RestartMediaForm from 'components/organisms/owner/RestartMediaForm'
import OwnerBreadcrump from 'components/organisms/breadcrumb/OwnerBreadcrump'
import { shouldSignin } from 'helpers/shouldSignin'

const RestartMedia = () => {
  const { currentUser } = useContext(AuthContext)
  const history = useHistory()

  // TODO: currentUserのチェックをlayoutに移動
  useEffect(() => {
    if (shouldSignin(currentUser)) {
      history.push(`${paths.signinowner}`)
    }
  })

  return (
    <>
      <AppLayoutOwner>
        <br />
        <Container>
          <OwnerBreadcrump items={[{ text: '掲載を再開' }]} />
          <Segment>
            <Header as="h2">掲載を再開</Header>
          </Segment>
          <RestartMediaForm />
        </Container>
      </AppLayoutOwner>
    </>
  )
}

export default RestartMedia
