import React, { useContext, useEffect } from 'react'
import { AuthContext } from 'providers/Auth'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import { Container, Header, Segment } from 'semantic-ui-react'
import Spacer from 'components/atoms/Spacer'
import AppLayoutOwner from 'components/organisms/layouts/AppLayoutOwner'
import DashboardTabs from 'components/pages/Owner/Dashboard/DashboardPage/DashboardTabs'
import Breadcrump from 'components/organisms/breadcrumb/ManageMediaBreadcrump'
import { shouldSignin } from 'helpers/shouldSignin'

const DashboardOwner = () => {
  const { currentUser } = useContext(AuthContext)
  const history = useHistory()

  // TODO: currentUserの有無のチェックをlayoutに移動
  useEffect(() => {
    if (shouldSignin(currentUser)) {
      history.push(`${paths.signinowner}`)
    }
  })

  return (
    <>
      <AppLayoutOwner>
        <Container>
          <br />
          <Breadcrump />
          <Segment>
            <Header as="H2">保有媒体一覧</Header>
          </Segment>
          <Spacer />
          <Container>
            <DashboardTabs />
          </Container>
        </Container>
      </AppLayoutOwner>
    </>
  )
}

export default DashboardOwner
