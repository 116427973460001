import React, { createContext, useState, useEffect, useContext } from 'react'
import { MediasContext } from 'providers/Medias'
import { AdviceMediaDataType } from 'constants/mediaData'

const MediaPublishedAllContext = createContext({
  mediaPublished: [] as AdviceMediaDataType[],
})

const MediaPublishedAllProvider = ({ children }) => {
  const [mediaPublished, setMediaPublished] = useState<AdviceMediaDataType[]>([])

  const { medias } = useContext(MediasContext)

  useEffect(() => {
    if (medias) {
      let tmpmedia = medias
      tmpmedia = tmpmedia.filter((row) => {
        if (row.isDeleted) {
          return false
        } else {
          return row
        }
      })
      setMediaPublished(tmpmedia)
    }
  }, [medias])

  return <MediaPublishedAllContext.Provider value={{ mediaPublished }}>{children}</MediaPublishedAllContext.Provider>
}

export { MediaPublishedAllContext, MediaPublishedAllProvider }
