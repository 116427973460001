// 使い方の例
// tmpmedias = MediasSearchFilter(mediasOwn, searchWord)

import { AdviceMediaDataType } from 'constants/mediaData'

//
export const MediasSearchFilter = (
  medias: AdviceMediaDataType[],
  searchWord: string,
  targetTags: string[],
  targetLines: string[],
  targetHighways: string[]
) => {
  let tmpMedia = medias
  const searchWordLower = searchWord.toLocaleLowerCase()

  tmpMedia = tmpMedia.filter((row) => {
    if (row.name.indexOf(searchWordLower) === -1) {
      return false
    }
    if (targetTags.length > 0) {
      let res = false
      if (row.tags && row.tags.length > 0) {
        res = targetTags.every((targetTag) => {
          return row.tags.some((tag) => tag.id === targetTag)
        })
      }
      if (!res) {
        return false
      }
    }
    if (targetLines.length > 0) {
      let res = false
      if (row.lines && row.lines.length > 0) {
        res = targetLines.every((targetLine) => {
          return row.lines.some((line) => line.id === targetLine)
        })
      }
      if (!res) {
        return false
      }
    }
    if (targetHighways.length > 0) {
      let res = false
      if (row.highways && row.highways.length > 0) {
        res = targetHighways.every((targetHighway) => {
          return row.highways.some((highway) => highway.id === targetHighway)
        })
      }
      if (!res) {
        return false
      }
    }
    return true
  })

  return tmpMedia
}
