import React from 'react'
import { Label, Input } from 'semantic-ui-react'
import { mediaTypes } from 'helpers/mediaType/mediaDef'

const MediaTypeSelect = ({ selectedMediaType, changeCallback }) => {
  return (
    <>
      <Label>媒体のタイプ</Label>
      <div>
        {mediaTypes
          .filter((mt) => mt !== 'mediaTypeJack')
          .map((type) => (
            <span key={type}>
              <Input type="checkbox" name="mediaType" id={type} checked={selectedMediaType === type} value={type} onChange={changeCallback} />
              <Label>{type}</Label>
            </span>
          ))}
      </div>
    </>
  )
}

export default MediaTypeSelect
