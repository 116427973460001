import React from 'react'
import { Table } from 'semantic-ui-react'
import IndustryOptions from 'constants/IndustryOptions'

type Props = {
  industry: string[]
}

type TargetIndustry =
  | {
      key: string
      text: string
      value: string
    }
  | undefined //find関数で見つからない場合

const TargetIndustryCell = (props: Props) => {
  let jsxElms: string[] = []
  //valueからtextに変換する
  if (props.industry && props.industry.length && props.industry.length !== IndustryOptions.length) {
    jsxElms = props.industry.map((key) => {
      const targetIndustry: TargetIndustry = IndustryOptions.find((elem) => elem.value === key)
      if (targetIndustry) {
        return targetIndustry.text
      } else {
        return '全て'
      }
    })
  } else {
    jsxElms.push('全て')
  }
  const result: string = jsxElms.join('、')
  return <Table.Cell>{result}</Table.Cell>
}

export default TargetIndustryCell
