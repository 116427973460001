import React, { createContext, useState, useEffect } from 'react'
// import { AuthContext } from 'components/Auth'
import { db } from 'FirebaseConfig'
// import { useCollectionData } from "react-firebase-hooks/firestore";

const AddMediasContext = createContext()
// const uid = auth.currentUser.uid

// provide carts.all
const AddMediasProvider = ({ children }) => {
  const [addMedias, setAddMedias] = useState([])

  useEffect(() => {
    db.collection('media').onSnapshot((query) => {
      const data = []
      query.forEach((d) => data.push({ ...d.data(), docId: d.uid }))
      setAddMedias(data)
    })
  }, [])

  return <AddMediasContext.Provider value={{ addMedias }}>{children}</AddMediasContext.Provider>
}

export { AddMediasContext, AddMediasProvider }
