import React from 'react'
import { Card } from 'semantic-ui-react'
import MediaCardSingle from 'components/organisms/card/MediaCardSingle'

const DeleteMediaForm = (props) => {
  return (
    <>
      {props.media && (
        <>
          <Card.Group itemsPerRow={1}>
            <MediaCardSingle media={props.media} />
          </Card.Group>
        </>
      )}
    </>
  )
}

export default DeleteMediaForm
