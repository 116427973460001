import React, { useCallback, useEffect, useState } from 'react'
import { Icon } from 'semantic-ui-react'
import './index.css'

type props = {
  lat?: number
  lng?: number
  text?: string
}

const MapPlaceSearchMarker = ({ text }: props) => {
  const [shouldDisplayInfo, setShouldDisplayInfo] = useState<boolean>(true)

  const clickMarkerHandler = useCallback(() => {
    setShouldDisplayInfo((prevState) => !prevState)
  }, [])

  useEffect(() => {
    setShouldDisplayInfo(true)
  }, [text])

  return (
    <div onClick={clickMarkerHandler}>
      {shouldDisplayInfo && <div className="mapPlaceSearchMarker__mapInfo">{text}</div>}
      <Icon className="mapPlaceSearchMarker__icon" color="red" size="huge" name="map marker alternate" />
    </div>
  )
}

export default MapPlaceSearchMarker
