import { Package } from 'components/organisms/admin/PackageTable/helper'
import paths from 'constants/paths'
import React from 'react'
import { useHistory } from 'react-router'
import { Breadcrumb } from 'semantic-ui-react'

type props = {
  packageName: Package['name']
}
const PackageBreadcrump = ({ packageName }: props) => {
  const history = useHistory()
  return (
    <Breadcrumb size={'mini'}>
      <Breadcrumb.Section onClick={() => history.push(`${paths.mediatop}`)} link active>
        <i style={{ color: 'gray' }} className="home icon" />
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section active>パッケージ詳細({packageName})</Breadcrumb.Section>
    </Breadcrumb>
  )
}

export default PackageBreadcrump
