import { mediaType } from 'helpers/mediaType/mediaDef'
import React from 'react'
import { Table } from 'semantic-ui-react'
import MediaCard from 'components/organisms/package/MediaCard'
import { mediaTypeDisplayNames } from 'constants/mediaTypes'
import { AdviceMediaDataTypeWithPeriod } from '../PackageSubtotalList/helper'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'

type props = {
  mediaType: mediaType
  mediaList: AdviceMediaDataTypeWithPeriod[]
}
const PackageSubtotalCard = (props: props) => {
  const { header, contents } = getColInfoFromTypeName(props.mediaType).cartConfirmCard
  return (
    <Table basic style={{ backgroundColor: 'white', marginBottom: '3rem', borderRadius: '10px', overflow: 'hidden' }}>
      <Table.Header style={{ backgroundColor: '#E0E1E2' }}>
        <Table.HeaderCell colSpan="10">{mediaTypeDisplayNames[props.mediaType]}</Table.HeaderCell>
      </Table.Header>
      <Table.Header>
        <Table.Row>
          {header.map((col) => {
            return <Table.HeaderCell key={col}>{col}</Table.HeaderCell>
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.mediaList.map((media) => {
          const period = {
            weeks: media.weeks,
            months: media.months,
          }
          return (
            <Table.Row key={media.uid}>
              <Table.Cell width={10}>
                <MediaCard media={media} />
              </Table.Cell>
              {contents.map((content) => {
                return <Table.Cell key={content.displayName}>{content.displayMethod(media, period)}</Table.Cell>
              })}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default PackageSubtotalCard
