import React, { useEffect, useState, useContext, useCallback } from 'react'
import { MediasSearchFilter } from 'helpers/filters/MediasSearchFilter'
import { Form, Button, Dropdown } from 'semantic-ui-react'
import MediaCardCart from 'components/organisms/card/MediaCardCart'
import { mediaListGenerator } from 'helpers/filters/mediaListGenerator'
import { MediaBookedAllContext } from 'providers/MediaBookedAll'
import { MediasContext } from 'providers/Medias'
import './index.css'
import TagDropdown from 'components/organisms/tag/TagDropdown'
import { useHistory } from 'react-router-dom'
import { isJackChild } from '../../../../constants/mediaData'
import { MediasAvailableContext } from 'providers/MediasAvailable'

const CartSearchTab = (props) => {
  const [searchWord, setSearchWord] = useState('')
  const [tags, setTags] = useState([])
  const [lines, setLines] = useState([])
  const [highways, setHighways] = useState([])
  const [mediaSortedList, setMediaSortedList] = useState([])
  const [mediaShown, setMediaShown] = useState([])
  const [pageIdx, setPageIdx] = useState(0) // 現在のページ(0-indexed)
  const [numPage, setNumPage] = useState(1) // ページの総数
  const [mediaSort, setMediaSort] = useState('all')
  const { mediaBookedAll } = useContext(MediaBookedAllContext)
  const { mediaLoading } = useContext(MediasContext)
  const mediaPerPage = 20 // 媒体一覧の1ページに載っている媒体の数
  const onSearchWordChange = (event) => {
    setSearchWord(event.target.value)
  }
  const history = useHistory()
  const { mediasAvailable } = useContext(MediasAvailableContext)

  const changeTagCallback = useCallback((_e, { name, value }) => {
    switch (name) {
      case 'tag':
        setTags((prev) => [...prev, value])
        break
      case 'deleteTag':
        setTags((prev) => prev.filter((tag) => tag !== value))
        break
      case 'line':
        setLines((prev) => [...prev, value])
        break
      case 'deleteLineTag':
        setLines((prev) => prev.filter((lineTag) => lineTag !== value))
        break
      case 'highway':
        setHighways((prev) => [...prev, value])
        break
      case 'deleteHighwayTag':
        setHighways((prev) => prev.filter((highwayTag) => highwayTag !== value))
        break
    }
  }, [])

  useEffect(() => {
    let tmpmedias
    if (searchWord || tags || lines) {
      tmpmedias = MediasSearchFilter(props.mediaList, searchWord, tags, lines, highways)
    } else {
      tmpmedias = props.mediaList
    }
    let mediaList = []
    if (tmpmedias && mediaBookedAll) {
      mediaList = mediaListGenerator(tmpmedias, props.cart, mediaBookedAll, mediasAvailable)
    }

    if (mediaSort === 'jack') {
      mediaList = mediaList.filter((media) => media.mediaType === 'mediaTypeJack' || isJackChild(media))
    }

    let listedMedias = [] //IDの配列
    mediaList.forEach((media) => {
      listedMedias.push(media.uid)
    })
    listedMedias ? props.setListCount(listedMedias.length) : props.setListCount(0)

    if (props.activeItem === 'search') {
      setMediaSortedList(mediaList)
      onNumPageChange(mediaList)
      props.setListedMedias(listedMedias)
      props.setLoading(false)
      props.setMediaTarget('') // 検索時にはmediaTargetを消して、検索結果の媒体が出てくるようにする
    } else {
      props.setLoading(false)
    }
  }, [props.mediaList, mediaLoading, searchWord, props.cart.budget, props.activeItem, props.cart, tags, lines, highways, history, mediaSort])

  const onClickMediaCard = (mediaId, index) => {
    props.setMediaTarget(mediaId)
    props.setCurrentKey(index)
  }

  /**
   * pageIdx番目のページに載せる媒体をmediaSortedListからスライスする関数
   * @param {*} pageIdx
   * @param {*} setPageIdx
   * @param {*} mediaSortedList
   * @param {*} mediaPerPage
   * @returns array
   */
  const extractShownMedia = (pageIdx, mediaSortedList, mediaPerPage) => {
    return mediaSortedList.slice(pageIdx * mediaPerPage, (pageIdx + 1) * mediaPerPage) // slice(start, end)のendが配列をはみ出るかのチェックは不要
  }

  /**
   * 選択されたページが切り替わったときに走る処理
   * @param {*} pageIdx
   * @param {*} mediaSortedList
   */
  const onPageIdxChange = (pageIdx, mediaSortedList) => {
    setPageIdx(pageIdx)
    const newShownMedia = extractShownMedia(pageIdx, mediaSortedList, mediaPerPage)
    setMediaShown(newShownMedia)
  }

  /**
   * ページの総数が変化したときに走る処理（検索ワードが変わったときなど）
   * @param {Array} mediaSortedList
   */
  const onNumPageChange = (mediaSortedList) => {
    setPageIdx(0)
    if (mediaSortedList) {
      if (mediaSortedList.length === 0) {
        setNumPage(1)
      } else {
        setNumPage(Math.ceil(mediaSortedList.length / mediaPerPage))
      }
      onPageIdxChange(0, mediaSortedList)
    }
  }

  // ページ選択用のボタンを作成
  const pageButtons = []
  for (let i = 0; i < numPage; i++) {
    let button = (
      <Button basic color={i === pageIdx ? 'orange' : 'black'} key={i} onClick={() => onPageIdxChange(i, mediaSortedList)}>
        {i}
      </Button>
    )
    pageButtons.push(button)
  }

  const dropDownOptions = [
    {
      key: 'all',
      text: '全て',
      value: 'all',
    },
    {
      key: 'jack',
      text: 'ジャックのみ',
      value: 'jack',
    },
  ]

  const onCgangeDropdown = (e, data) => {
    setMediaSort(data.value)
  }

  return (
    <>
      <Form>
        <TagDropdown
          style={{ marginBottom: '10px' }}
          tags={tags}
          lines={lines}
          highways={highways}
          changeTagCallback={changeTagCallback}
          placeholder="タグで候補を絞る"
        />
        <input
          style={{ marginBottom: '10px' }}
          type="text"
          name="searchWord"
          placeholder="キーワードで候補を絞る"
          value={searchWord}
          onChange={onSearchWordChange}
        />
        <br />
        <Dropdown options={dropDownOptions} selection defaultValue={'all'} onChange={onCgangeDropdown} />
      </Form>
      <div className="cartSearchTab__scrollSearchTab">
        {mediaSortedList && mediaSortedList.length < 1 && <p>入力された広告グループ情報に合う媒体が見つかりません。再度広告グループ情報を編集してください。</p>}
        {mediaShown &&
          mediaShown.map((media, index) => (
            <div
              onClick={() => onClickMediaCard(media.uid, index)}
              className={`cartSearchTab__mediaCardDefault ${index === props.currentKeyInfo ? '' : ''} ${
                index === props.currentKey ? 'cartSearchTab__mediaCardSelected' : ''
              }`}
              key={media.uid}
            >
              <MediaCardCart media={media} period={props.period} />
            </div>
          ))}
        {pageButtons}
      </div>
    </>
  )
}

export default CartSearchTab
