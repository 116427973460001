import React from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'

const GroupBreadcrump = ({ edit }) => {
  const history = useHistory()
  return (
    <>
      <Breadcrumb size={'mini'}>
        <Breadcrumb.Section onClick={() => history.push(`${paths.mediatop}`)} link active>
          <i style={{ color: 'gray' }} className="home icon" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section onClick={() => history.push('/group/dashboard')} link active style={{ color: 'gray' }}>
          ダッシュボード
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active>{edit ? <p>広告グループの編集</p> : <p>広告グループの作成</p>}</Breadcrumb.Section>
      </Breadcrumb>
    </>
  )
}

export default GroupBreadcrump
