import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import { db } from 'FirebaseConfig'
import GoogleMapReact from 'google-map-react'
import { Grid, GridColumn, GridRow, Input, Label, Button, Dropdown } from 'semantic-ui-react'
import { safeAlert } from 'helpers/admin/utils'
import { mediaTypes } from 'helpers/mediaType/mediaDef'
import { ImageSlider } from 'components/organisms/ImageSlider'
import { getNewRange, uploadLeaflet, uploadMediaImage } from 'helpers/admin/helper'
import { industryOptions } from 'helpers/mediaType/mediaTypeLeaflet'
const getTagNames = firebase.functions().httpsCallable('getTagNames')
const getLineTags = firebase.functions().httpsCallable('getLineTags')
const getHighwayTags = firebase.functions().httpsCallable('getHighwayTags')

const dropdownIndustoryOptions = industryOptions.map((option) => {
  return { key: option.value, value: option.value, text: option.name }
})

const LeafletEditMediaForm = (props) => {
  // 媒体のタイプ一覧
  const [mediaInfo, setMediaInfo] = useState({
    meshCD: null, // メッシュコード
    address: '', // 看板の住所
    name: '', // 媒体名
    note: '', // 看板の説明文
    summary: '', // 看板のサマリ
    weeklyCost: 0,
    mediaType: mediaTypes[0], // 媒体のタイプ
    mediaImageObjs: [], // 媒体の写真に関する情報が入った配列,
    isPrivate: false,
    tags: [],
    lines: [],
    highways: [],
    distributionCost: 0,
    industry: '',
    storeName: '',
    ...props.mediaInfo,
  })
  useEffect(() => {
    if (props.mediaInfo.latitude && props.mediaInfo.longitude) {
      setBuildingPos({ lat: props.mediaInfo.latitude, lng: props.mediaInfo.longitude })
    }
    setMediaInfo((prevState) => {
      return { ...prevState, ...props.mediaInfo }
    })
  }, [props.mediaInfo])
  const [buildingPos, setBuildingPos] = useState({ lat: 35.6432027, lng: 139.6729435 }) // 看板の緯度経度
  const [marker, setMarker] = useState(null) // 建物の位置を表すマーカー

  // google map apiに関連した状態
  const [maps, setMaps] = useState(null) // internal api of the google map
  const [map, setMap] = useState(null)

  // 媒体手動で付与することのできるタグのオプション
  const [tagOptions, setTagOptions] = useState([])
  const [tagLineOptions, setTagLineOptions] = useState([])
  const [tagHighwayOptions, setTagHighwayOptions] = useState([])

  useEffect(() => {
    const unsubscriber = db.collection('tags').onSnapshot((snapshot) => {
      const temp = []
      for (const doc of snapshot.docs) {
        const tag = doc.data()
        temp.push({
          key: doc.id,
          // ドロップダウンでvalueにObjectを渡すと動作が保証されないのでStringifyしている
          // valueからどのoptionであるのか特定するケースもあるので、nameとidの順序は必ず以下の順にする(name,id)
          value: JSON.stringify({
            name: tag.name,
            id: doc.id,
          }),
          text: tag.name,
        })
      }
      setTagOptions(temp)
    })
    return unsubscriber
  }, [])

  useEffect(() => {
    const unsubscriber = db.collection('lines').onSnapshot((snapshot) => {
      const temp = []
      for (const doc of snapshot.docs) {
        const line = doc.data()
        temp.push({
          key: doc.id,
          value: doc.id,
          text: line.name,
        })
      }
      setTagLineOptions(temp)
    })
    return unsubscriber
  }, [])

  useEffect(() => {
    const unsubscriber = db.collection('highways').onSnapshot((snapshot) => {
      const temp = []
      for (const doc of snapshot.docs) {
        const highway = doc.data()
        temp.push({
          key: doc.id,
          value: doc.id,
          text: highway.name,
        })
      }
      setTagHighwayOptions(temp)
    })
    return unsubscriber
  }, [])

  useEffect(() => {
    changeMarkerPosition({ lat: buildingPos.lat, lng: buildingPos.lng })
  }, [marker, maps, buildingPos])

  /**
   * {colName: newValue}の組を受け取って、mediaInfo[colName] = newValueと更新する関数
   * @param {object} newVals {colName: newValue}の組
   *
   */
  // eslint-disable-next-line
  const updateMediaInfo = (newVals) => {
    setMediaInfo((prevState) => {
      return { ...prevState, ...newVals }
    })
  }

  const handleChange = (_event, { name, value }) => {
    switch (name) {
      case 'width': {
        const newHorizontalWidth = parseFloat(value)
        const newRange = getNewRange(mediaInfo.useSuperRange, mediaInfo.superRange, newHorizontalWidth, mediaInfo.verticalWidth)
        updateMediaInfo({ range: newRange, horizontalWidth: newHorizontalWidth })
        break
      }
      case 'length': {
        const newVerticalWidth = parseFloat(value)
        const newRange = getNewRange(mediaInfo.useSuperRange, mediaInfo.superRange, mediaInfo.horizontalWidth, newVerticalWidth)
        updateMediaInfo({ range: newRange, verticalWidth: newVerticalWidth })
        break
      }
      case 'distributionCost': {
        updateMediaInfo({ distributionCost: parseInt(value) })
        break
      }
      case 'weeklyCost': {
        updateMediaInfo({ weeklyCost: parseInt(value) })
        break
      }
      case 'tag': {
        const parsedTags = value.map((tag) => JSON.parse(tag))
        updateMediaInfo({ tags: parsedTags })
        break
      }
      case 'line': {
        updateMediaInfo({ lines: value })
        break
      }
      case 'highway': {
        updateMediaInfo({ highways: value })
        break
      }
      case 'lat': {
        coordinateCallback(value, 'lat')
        break
      }
      case 'lon': {
        coordinateCallback(value, 'lng')
        break
      }
      case 'name': {
        updateMediaInfo({ name: value })
        break
      }
      case 'address': {
        updateMediaInfo({ address: value })
        break
      }
      case 'note': {
        updateMediaInfo({ note: value })
        break
      }
      case 'summary': {
        updateMediaInfo({ summary: value })
        break
      }
      case 'isPrivate': {
        updateMediaInfo({ isPrivate: value === 'true' })
        break
      }
      case 'storeName': {
        updateMediaInfo({ storeName: value })
        break
      }
      case 'industry': {
        updateMediaInfo({ industry: value })
        break
      }
    }
  }

  /**>
   * google map上の看板位置を示すマーカを移動させる処理
   */
  const changeMarkerPosition = (buildingPos) => {
    if (marker && maps) {
      let latlng = new maps.LatLng(buildingPos.lat, buildingPos.lng)
      marker.setPosition(latlng)
      map.setCenter(latlng)
    }
  }

  const computeTags = async (buildingPos) => {
    const res = await getTagNames({
      lat: buildingPos.lat,
      lng: buildingPos.lng,
    })
    if (res.data.tags) {
      updateMediaInfo({ tags: res.data.tags })
    }
  }

  const computeLines = async (buildingPos) => {
    const res = await getLineTags({
      lat: buildingPos.lat,
      lng: buildingPos.lng,
    })
    if (res.data.lines) {
      updateMediaInfo({ lines: res.data.lines.map((line) => line.lineId) })
    }
  }

  const computeHighways = async (buildingPos) => {
    const res = await getHighwayTags({
      lat: buildingPos.lat,
      lng: buildingPos.lng,
    })
    if (res.data.highways) {
      updateMediaInfo({ highways: res.data.highways.map((highway) => highway.id) })
    }
  }

  const coordinateCallback = async (value, propName) => {
    const newBuildingPos = { ...buildingPos }
    newBuildingPos[propName] = parseFloat(value)
    setBuildingPos(newBuildingPos)
    const tagsPromise = computeTags(newBuildingPos)
    const lineTagsPromise = computeLines(newBuildingPos)
    const highwayTagsPromise = computeHighways(newBuildingPos)
    await Promise.all([tagsPromise, lineTagsPromise, highwayTagsPromise])
    changeMarkerPosition(newBuildingPos)
  }

  const onClickUploadMedia = async () => {
    /*
    //TODO: 地図でユーザが指定した視認範囲から、代表点を抽出する処理を書く
    let polygonArray = polygons.getPath().getArray(); // get a polygon representing the visible area
    let visiblePoints = extractPoint(polygonArray, SPACE); // 視認範囲から代表点を抽出
    */

    // 画像のアップロード
    try {
      await uploadMediaImage(mediaInfo.mediaImageObjs)
      safeAlert(window, '媒体画像の追加に成功しました')
    } catch (err) {
      safeAlert(window, '媒体画像のアップロードに失敗しました' + err)
    }
    try {
      await uploadLeaflet(props.mediaID, buildingPos, mediaInfo)
      safeAlert(window, '媒体情報のアップロードに成功しました')
    } catch (err) {
      safeAlert(window, '媒体情報のアップロードに失敗しました' + err)
    }
  }

  const onMarkerDragged = async (event) => {
    let lat = event.latLng.lat()
    let lng = event.latLng.lng()
    const tagsPromise = computeTags({ lat: lat, lng: lng })
    const lineTagsPromise = computeLines({ lat: lat, lng: lng })
    const highwayTagsPromise = computeHighways({ lat: lat, lng: lng })
    await Promise.all([tagsPromise, lineTagsPromise, highwayTagsPromise])
    setBuildingPos({ lat: lat, lng: lng })
  }

  // ファイルの読み込み時に走る操作
  const handleChangeFile = (e) => {
    let files = e.target.files
    let counter = 0
    let newImageObjs = [...mediaInfo.mediaImageObjs]
    for (let i = 0; i < files.length; i++) {
      const fr = new FileReader()
      fr.readAsDataURL(files[i])
      fr.addEventListener('load', () => {
        const fileType = files[i].name.match(/\.(jpg|png|gif|jpeg)$/)[1]
        const fileName = files[i].name.substring(0, files[i].name.lastIndexOf('.'))
        newImageObjs.push({ imageUrl: fr.result, name: 'images/media/' + fileName + '_' + Number(new Date()) + '.' + fileType, file: files[i], done: false })
        counter += 1
        if (counter === files.length) {
          updateMediaInfo({ mediaImageObjs: newImageObjs })
        }
      })
    }
  }

  return (
    <>
      <Grid columns={2} devided>
        <GridColumn>
          <GridRow>
            <Label>公開/非公開</Label>
            <Input type="radio" id="public" name="isPrivate" value={false} checked={!mediaInfo.isPrivate} onChange={handleChange} />
            <label htmlFor="public">
              <Label>公開</Label>
            </label>
            <Input type="radio" id="private" name="isPrivate" value={true} checked={mediaInfo.isPrivate} onChange={handleChange} />
            <label htmlFor="private">
              <Label>非公開</Label>
            </label>
          </GridRow>

          <GridRow>
            <Label>媒体の緯度</Label>
            <Input name="lat" type="number" placeholder="緯度" value={buildingPos.lat} step="0.01" onChange={handleChange} />
            <Label>媒体の経度</Label>
            <Input name="lon" type="number" placeholder="経度" value={buildingPos.lng} step="0.01" onChange={handleChange} />
            <GridRow>
              <Label>タグ</Label>
              <Dropdown
                placeholder="Select Tag"
                fluid
                multiple
                search
                selection
                name="tag"
                onChange={handleChange}
                value={mediaInfo.tags.map((tag) => JSON.stringify(tag))}
                options={tagOptions}
              />
              <Label>路線タグ</Label>
              <Dropdown
                placeholder="Select Line"
                fluid
                multiple
                search
                selection
                name="line"
                onChange={handleChange}
                value={mediaInfo.lines}
                options={tagLineOptions}
              />
              <Label>道路タグ</Label>
              <Dropdown
                placeholder="Select Highway"
                fluid
                multiple
                search
                selection
                name="highway"
                onChange={handleChange}
                value={mediaInfo.highways}
                options={tagHighwayOptions}
              />
            </GridRow>
          </GridRow>
          <GridRow>
            <Label>媒体名</Label>
            <Input type="text" placeholder="name" name="name" value={mediaInfo.name} onChange={handleChange} />
            <Label>媒体住所</Label>
            <Input type="text" placeholder="address" name="address" value={mediaInfo.address} onChange={handleChange} />
          </GridRow>
          <GridRow>
            <Label>媒体の説明文(note)</Label>
            <Input type="text" style={{ width: '600px' }} placeholder="note" name="note" value={mediaInfo.note} onChange={handleChange} />
          </GridRow>
          <GridRow>
            <Label>媒体のサマリ(summary)</Label>
            <Input type="text" style={{ width: '600px' }} placeholder="summary" name="summary" value={mediaInfo.summary} onChange={handleChange} />
          </GridRow>

          <GridRow>
            <Label>設置施設名</Label>
            <Input type="text" placeholder="設置店舗名" name="storeName" value={mediaInfo.storeName} onChange={handleChange} />
          </GridRow>

          <GridRow>
            <Label>設置施設の業種</Label>
            <Dropdown
              placeholder="Select Industory"
              // multiple
              search
              selection
              name="industry"
              onChange={handleChange}
              value={mediaInfo.industry}
              options={dropdownIndustoryOptions}
            />
          </GridRow>

          <GridRow>
            <Label>媒体の週額(税抜き)</Label>
            <Input name="weeklyCost" type="Number" placeholder="媒体週額" value={mediaInfo.weeklyCost} onChange={handleChange} />
            <br />
          </GridRow>

          <GridRow>
            <Label>配布費用(税抜き)</Label>
            <Input name="distributionCost" type="Number" placeholder="配布費用" value={mediaInfo.distributionCost} onChange={handleChange} />
            <br />
          </GridRow>

          <GridRow>
            <label>
              媒体の画像を追加
              <input type="file" multiple accept="image/*" name="mediaImages" onChange={handleChangeFile} />
            </label>
          </GridRow>
          <GridRow>
            <ImageSlider images={mediaInfo.mediaImageObjs.map((mediaImageObj) => mediaImageObj.imageUrl).reverse()} />
            {/*配列の中身の順番を逆にすることで、ユーザが直近で追加した画像が最初に表示されるようにする */}
            <GridRow>
              <Button onClick={onClickUploadMedia}> 媒体を追加 </Button>
            </GridRow>
          </GridRow>
        </GridColumn>
        <GridColumn>
          <div style={{ height: '70vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyBz8o-2x_iarmPyLiv6dKe2g5DjZLmuw_M',
              }}
              defaultCenter={{ lat: 35.6432027, lng: 139.6729435 }}
              defaultZoom={12}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={({ map, maps }) => {
                let marker = addMarker(map, maps, buildingPos, onMarkerDragged)
                let rectangles = []
                //setPointlist(pointlist);
                for (let i = 0; i < 5000; i++) {
                  rectangles[i] = addRectangle(map, maps)
                }
                setMaps(maps)
                setMap(map)
                setMarker(marker)
              }}
            ></GoogleMapReact>
          </div>
        </GridColumn>
      </Grid>
    </>
  )
}

export default LeafletEditMediaForm

/**
 *
 * @param {Object} map an obj from google map api
 * @param {Object} maps an object from google map api
 * @param {Object} buildingPos lat, lngをキーにもつオブジェクト
 * @returns {Object} google map api上に追加されたマーカーのインスタンス
 */
const addMarker = (map, maps, buildingPos, onMarkerDragged) => {
  let marker = new maps.Marker({
    position: buildingPos,
    map,
    draggable: true,
  })
  maps.event.addListener(marker, 'dragend', onMarkerDragged)
  return marker
}

const addRectangle = (map, maps) => {
  let rectangle = new maps.Rectangle({
    map,
    bounds: new maps.LatLngBounds(new maps.LatLng(0, 0), new maps.LatLng(0, 0)),
    fillColor: 'green',
    strokeColor: 'green',
  })
  return rectangle
}
