import { calcBillboardCost, calcBillboardCostEffectiveness } from 'helpers/mediaType/mediaTypeBillboard'
import { calcVisionCost, calcVisionCostEffectiveness } from 'helpers/mediaType/mediaTypeVision'
import { calcMansionSignageCost, calcMansionSignageCostEffectiveness } from 'helpers/mediaType/mediaTypeMansionSignage'
import { calcLeafletCost } from 'helpers/mediaType/mediaTypeLeaflet'
import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'

const MaxCost = 99999999 //ソートで最端に持ってくるための定数

const calcCostEffectiveness = (media: AdviceMediaDataType): number => {
  if (media.isPrivate) {
    return Infinity
  }
  let costEffectiveness = 0
  if (!isJackChild(media)) {
    switch (media.mediaType) {
      case 'mediaTypeBillboard':
        costEffectiveness = calcBillboardCostEffectiveness(media, 12, false, true, true)
        break
      case 'mediaTypeVision': //TODO:digitalSignageに変更
        costEffectiveness = calcVisionCostEffectiveness(media, 4, false, true)
        break
      case 'mediaTypeMansionSignage':
        costEffectiveness = calcMansionSignageCostEffectiveness(media, 4, true)
        break
      case 'mediaTypeLeaflet':
        costEffectiveness = MaxCost
        break
      default:
        costEffectiveness = MaxCost
    }
  }
  return costEffectiveness
}

const calcPrice = (media: AdviceMediaDataType) => {
  if (media.isPrivate) {
    return Infinity
  }
  let cost = 0
  if (!isJackChild(media)) {
    switch (media.mediaType) {
      case 'mediaTypeBillboard':
        cost = calcBillboardCost(media, 12, true, true, false)
        break
      case 'mediaTypeVision':
        cost = calcVisionCost(media, 4, true, false)
        break
      case 'mediaTypeMansionSignage':
        cost = calcMansionSignageCost(media, 4, true, false)
        break
      case 'mediaTypeLeaflet':
        cost = calcLeafletCost(media, 4, true, false, false)
        break
      default:
        cost = MaxCost
    }
  }
  return cost
}

export function costEffectivenessSortCallback(mediaA, mediaB) {
  const costEffectivenessA = calcCostEffectiveness(mediaA)
  const costEffectivenessB = calcCostEffectiveness(mediaB)
  if (costEffectivenessA > costEffectivenessB) return 1
  if (costEffectivenessA < costEffectivenessB) return -1
  return 0
}

export function costSortCallback(mediaA: AdviceMediaDataType, mediaB: AdviceMediaDataType) {
  const costA: number = calcPrice(mediaA)
  const costB: number = calcPrice(mediaB)
  if (costA > costB) return 1
  if (costA < costB) return -1
  return 0
}

export function nameSortCallback(mediaA: AdviceMediaDataType, mediaB: AdviceMediaDataType) {
  if (mediaA.name > mediaB.name) return 1
  if (mediaA.name < mediaB.name) return -1
  return 0
}
