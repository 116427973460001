import React, { useEffect, useState, useContext } from 'react'
import firebase, { auth } from 'FirebaseConfig'
import { AuthContext } from 'providers/Auth'

const AccountImage = ({ height, width }) => {
  const { currentUser } = useContext(AuthContext)
  const [accountImageUrl, setAccountImageUrl] = useState('')
  const [frag, setFrag] = useState(false)

  const noUserImage = () => {
    let storageRef = firebase.storage().ref()
    storageRef
      .child('account.jpeg')
      .getDownloadURL()
      .then(function (url) {
        setAccountImageUrl(url)
        setFrag(true)
      })
      .catch(function (error) {
        console.log('Error getting document:', error)
        setFrag(false)
      })
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        if (user.photoURL) {
          let storageRef = firebase.storage().ref()
          // let spaceRef = storageRef.child(uid+'.jpg');

          storageRef
            .child(user.photoURL)
            .getDownloadURL()
            .then(function (url) {
              setAccountImageUrl(url)
              setFrag(true)
            })
            .catch(function (error) {
              console.log('Error getting document:', error)
              setFrag(false)
            })
        } else {
          noUserImage()
        }
      } else {
        noUserImage()
      }
    })
  }, [currentUser])

  return (
    <>
      {frag ? (
        <>
          <img src={accountImageUrl} height={height} width={width} />
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export { AccountImage }
