import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AuthContext } from 'providers/Auth'
import paths from 'constants/paths'
import { useHistory, useLocation } from 'react-router-dom'
import { Header, Segment, Grid, Button, Dimmer, Loader } from 'semantic-ui-react'
import AppLayout from 'components/organisms/layouts/AppLayout'
import ConfirmCartContent from 'components/organisms/cart/ConfirmCartContent'
import ConfirmCartSummary from 'components/organisms/cart/ConfirmCartSummary'
import { CartFindFilter } from 'helpers/filters/CartFindFilter'
import AppLayoutGroupMenuBar from 'components/organisms/layouts/AppLayoutGroupMenuBar'
import CartConfirmBreadcrump from 'components/organisms/breadcrumb/CartConfirmBreadcrump'
import { CartsContext } from 'providers/Carts'
import { mediaListCartGenerator } from 'helpers/filters/mediaListCartGenerator'
import { MediasAvailableContext } from 'providers/MediasAvailable'
import firebase, { db } from 'FirebaseConfig'
import Spacer from 'components/atoms/Spacer'
import { UserContext } from 'providers/User'
import { MediaFindFilter } from 'helpers/filters/MediaFindFilter'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import { shouldSignin } from 'helpers/shouldSignin'
import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'

const ConfirmCart = () => {
  const [flagAngle, setFlagAngle] = useState(false)
  const { currentUser } = useContext(AuthContext)
  const history = useHistory()
  const location = useLocation()
  const { carts } = useContext(CartsContext)
  const cart = useMemo(() => {
    return CartFindFilter(carts, location.pathname.substr(-20))
  }, [carts, location])
  const { mediasAvailable, getChildMedia } = useContext(MediasAvailableContext)
  const [mediaSortedList, setMediaSortedList] = useState<AdviceMediaDataType[]>([])
  const [period, setPeriod] = useState({})
  const [bundleSummary, setBundleSummary] = useState({})
  const [loading, setLoading] = useState(false)
  const [buttonDisable, setButtonDisable] = useState(false)
  const { user } = useContext(UserContext)
  const [userIdFlag, setUserIdFlag] = useState(false)
  const [downloadBtnText, setDownloadBtnText] = useState('カート情報をPDF形式でダウンロードする')
  const getMediaPDF = firebase.functions().httpsCallable('getCartPDF')

  //TODO: setDownloadBtnTextがなければ切り出せる
  //pdfのダウンロード処理
  const downloadPDF = useCallback(async () => {
    if (cart) {
      setDownloadBtnText('pdfを作成中です（30秒ほどお待ちください）')
      const res = await getMediaPDF({
        cartID: cart.uid,
      })
      if (res && res.data.status === 200 && window) {
        window.open(res.data.fileDownloadURL)
      } else {
        if (window) {
          window.alert('pdfが正常に作成されませんでした。')
        }
        console.error(res.data.message)
      }
      setDownloadBtnText('カート情報をPDF形式でダウンロードする')
    }
  }, [cart])

  useEffect(() => {
    if (cart && cart.startDate && cart.endDate) {
      setPeriod({
        months: cart.endDateMonth,
        weeks: cart.endDateWeek,
      })
    }
  }, [cart])

  // 取得したカートがユーザーのものであるかをチェックするuseEffect
  useEffect(() => {
    // TODO: layoutにやらせる
    if (shouldSignin(currentUser)) {
      history.push(`${paths.signin}`)
    }
    if (cart && currentUser) {
      try {
        db.collection('cart')
          .doc(cart.uid)
          .get()
          .then((doc) => {
            const carttes = doc.data()
            if (carttes.userId === currentUser.uid) {
              setUserIdFlag(true)
            }
          })
      } catch (e) {
        console.error(e)
      }
    }
  })

  useEffect(() => {
    try {
      if (cart && cart.id && cart.id.length === 0) {
        setButtonDisable(true)
      } else {
        setButtonDisable(false)
      }
    } catch (e) {
      console.log('error' + e)
    }
  }, [cart])

  // TODO: 以下firebase APIおよびロジックの切り出し
  const checkoutCart = () => {
    if (user && cart && currentUser && window.confirm('注文しますか？')) {
      console.log('pass window confirm')
      setLoading(true)
      const startDate = cart.startDate.toDate()
      const endDate = cart.endDate.toDate()

      for (let date = startDate; date <= endDate; date.setMonth(date.getMonth() + 1)) {
        cart.id &&
          cart.id.forEach((mediaId) => {
            const docId = db.collection('mediaBooked').doc().id
            db.collection('mediaBooked')
              .doc(docId)
              .set({
                uid: docId,
                docId: docId,
                userId: currentUser.uid,
                cartId: cart.uid,
                campaignId: cart.campaignId,
                date: date,
                isBooked: true,
                mediaId: mediaId,
              })
              .catch((error) => {
                console.log(error)
              })
          })
      }

      const medias = [] as string[]
      cart.id &&
        cart.id.forEach((mediaId) => {
          const refMedia = MediaFindFilter(mediasAvailable, mediaId)
          if (refMedia) {
            medias.push(refMedia.name)
          }
        })

      const today = new Date(Date.now())
      const startDateRef =
        cart.startDate.toDate().getFullYear() + '-' + (Number(cart.startDate.toDate().getMonth()) + 1) + '-' + cart.startDate.toDate().getDate()
      const endDateRef = cart.endDate.toDate().getFullYear() + '-' + (Number(cart.endDate.toDate().getMonth()) + 1) + '-' + cart.endDate.toDate().getDate()
      const sendCheckoutMail = firebase.functions().httpsCallable('sendCheckoutMail')
      const data = {
        fullName: user.fullName,
        startDate: startDateRef,
        endDate: endDateRef,
        id: medias,
      }
      sendCheckoutMail(data).catch((error) => {
        setLoading(false)
        console.log(error)
        history.push({
          state: {
            text: error,
            type: 'negative',
          },
        })
      })

      db.collection('cart')
        .doc(cart.uid)
        .update({
          status: 2,
          checkedOutAt: firebase.firestore.Timestamp.fromDate(today),
        })
        .then(() => {
          firebase.analytics().logEvent('cart_ordered')
          setLoading(false)
          history.push({
            pathname: `/cart/edit/${cart.uid}`,
            state: {
              text: '注文しました',
              type: 'positive',
            },
          })
        })
        .catch((error) => {
          setLoading(false)
          console.log(error)
          history.push({
            state: {
              text: error,
              type: 'negative',
            },
          })
        })
    }
  }

  useEffect(() => {
    if (cart) {
      const mediaList = mediaListCartGenerator(mediasAvailable, cart)
      // インプ／コストの高い順番に並べるて、mediaSortedListにセットする。
      // mediaList.sort(cpmSortCallback)
      setMediaSortedList(mediaList)
      if (cart.mediaType) {
        const colInfo = getColInfoFromTypeName(cart.mediaType)
        const newBundleSummary = colInfo.culcBundleSummary(mediaList, period, false)
        setBundleSummary(newBundleSummary)
      }
    }
  }, [cart, mediasAvailable, period])

  const clickRemoveMediafromCart = (media) => {
    const removeMediaFromCart = (mediaArray) => {
      const mediaUidArray = mediaArray.map((element) => element.uid)
      if (cart) {
        db.collection('cart')
          .doc(cart.uid)
          .update({
            id: firebase.firestore.FieldValue.arrayRemove(...mediaUidArray),
          })
          .then(() => {
            console.log('removed')
          })
      }
    }
    if (window.confirm('カートから削除しますか？')) {
      if (media.mediaType === 'mediaTypeJack') {
        // ジャック親の場合
        removeMediaFromCart([media, ...media.childMedia])
      } else if (isJackChild(media)) {
        // ジャック子の場合
        const parentMedia = mediasAvailable.find((element) => element.uid === media.parentUid)
        const childMedia = getChildMedia(media.uid)
        removeMediaFromCart([parentMedia, ...childMedia])
      } else {
        removeMediaFromCart([media])
      }
    }
  }
  const moveBackward = () => {
    history.goBack()
  }

  return (
    <>
      {userIdFlag && cart && (
        <AppLayout>
          <AppLayoutGroupMenuBar flagAngle={flagAngle} setFlagAngle={setFlagAngle}>
            {(loading || mediaSortedList === undefined) && (
              <Dimmer active inverted>
                <Loader size="massive">Loading</Loader>
              </Dimmer>
            )}
            <CartConfirmBreadcrump />
            <Header as="h2">カート内商品</Header>
            <Grid>
              <Grid.Column width={11}>
                <ConfirmCartContent
                  cart={cart}
                  mediaSortedList={mediaSortedList}
                  period={period}
                  clickRemoveMediafromCart={clickRemoveMediafromCart}
                  bundleSummary={bundleSummary}
                />

                <Spacer />
                <Button color="grey" onClick={moveBackward}>
                  媒体選択に戻る
                </Button>
                <Button
                  color="grey"
                  onClick={async () => {
                    await downloadPDF()
                  }}
                  disabled={downloadBtnText !== 'カート情報をPDF形式でダウンロードする'}
                >
                  {downloadBtnText}
                </Button>
              </Grid.Column>
              <Grid.Column width={5}>
                <Segment>
                  <ConfirmCartSummary cart={cart} mediaSortedList={mediaSortedList} period={period} bundleSummary={bundleSummary} />
                  <Spacer />
                  <Button color="orange" onClick={() => checkoutCart()} disabled={buttonDisable}>
                    注文する
                  </Button>
                </Segment>
              </Grid.Column>
            </Grid>
          </AppLayoutGroupMenuBar>
        </AppLayout>
      )}
    </>
  )
}

export default ConfirmCart
