import React from 'react'
import { MediaImage } from 'components/organisms/images/MediaImage'
import { Grid, Header, Label } from 'semantic-ui-react'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import './index.css'
import TagList from 'components/organisms/tag/TagList'
import { convertValueToText } from 'helpers/mediaType/helpers'
import { isJackChild } from 'constants/mediaData'

const MediaStats = (media) => {
  switch (media.mediaType) {
    default:
      return MediaStatsDefault(media)
  }
}

const MediaStatsDefault = ({ media }) => {
  const cardContent = getColInfoFromTypeName(media.mediaType).cardContent['mediaSearch']
  const jsxElems = []
  for (const option of cardContent) {
    const value = option.displayMethod ? option.displayMethod(media, null, null /* months and weeks are unused */) : ''
    jsxElems.push(
      <Grid.Column textAlign="left" style={{ width: '32%' }} key={option.displayName}>
        <div className="mediaCardSearch__mediaCardItemTitle">{option.displayedName}</div>
        <div className="mediaCardSearch__mediaCardColumn mediaCardSearch__mediaCardItemValueSearch">
          <b>{media.isPrivate ? '非公開' : value}</b>
        </div>
      </Grid.Column>
    )
  }
  return (
    <Grid.Row divided textAlign="center">
      {jsxElems}
    </Grid.Row>
  )
}
const MediaCardSearch = ({ media, login }) => {
  return (
    <>
      <div className="mediaCardSearch__mediaCardCard" key={media.uid}>
        <Grid>
          <Grid.Row stretched columns={2}>
            <Grid.Column width={5}>
              <MediaImage width="100px" style={{ borderRadius: '.5rem 0 0 .5rem' }} media={media} />
            </Grid.Column>
            <Grid.Column width={11} style={{ padding: '.75rem 0' }}>
              <div className="mediaCardSearch__mediaCardHeaderSearch">
                <Header as="h3">{media.name}</Header>
                {isJackChild(media) && <Label>エリアジャック専用</Label>}
                <Label active compact color="red">
                  {convertValueToText(media.mediaType)}
                </Label>
                <TagList tags={media.tags} color="#f39800" textColor="white" />
                <TagList tags={media.lines} color="#5cBBcf" textColor="white" />
                <TagList tags={media.highways} color="#5cBBcf" textColor="white" />
              </div>
              <div className="mediaCardSearch__mediaCardAddressSearch">{media.address}</div>
              <Grid>
                {login ? (
                  <MediaStats media={media} />
                ) : (
                  <>
                    <Grid.Row>
                      <Grid.Column>
                        <div style={{ margin: '15px 0px 15px 0px' }}>
                          <div style={{ display: 'block' }}>
                            <Header as="p" style={{ color: 'gray', fontSize: '1.125rem', fontWeight: '200' }}>
                              ※価格、効果をご覧頂くには会員登録が必要です。
                            </Header>
                          </div>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <style jsx>
        {`
          .mediaCardSearch__mediaCardDefault {
            margin-bottom: 2.5rem;
          }
          .mediaCardSearch__mediaCardItemTitle {
            color: black;
          }
          .mediaCardSearch__mediaCardDefault {
            transition: all 0.3s;
          }
          .mediaCardSearch__mediaCardDefault:hover {
            opacity: 0.7;
          }
        `}
      </style>
    </>
  )
}

export default MediaCardSearch
