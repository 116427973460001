import React from 'react'
import { Card } from 'semantic-ui-react'
import MediaCardSingle from 'components/organisms/card/MediaCardSingle'
import Spacer from 'components/atoms/Spacer'

const StopMediaConfirm = (props) => {
  return (
    <>
      {props.media && (
        <>
          <Card.Group itemsPerRow={1}>
            <MediaCardSingle media={props.media} />
          </Card.Group>
          <Spacer />
          <Card.Group itemsPerRow={1}>
            <Card>
              <Card.Content header="掲載を一時停止する期間" as="h6" textAlign="center" />
              <Card.Content description textAlign="center">
                {new Date(props.stopDateRef).getFullYear()}年{/*  */}
                {new Date(props.stopDateRef).getMonth() + 1}月{new Date(props.stopDateRef).getDate()}日〜
                {new Date(props.restartDateRef).getFullYear()}年{/*  */}
                {new Date(props.restartDateRef).getMonth() + 1}月{new Date(props.restartDateRef).getDate()}日
              </Card.Content>
            </Card>
          </Card.Group>
          <Spacer />
        </>
      )}
    </>
  )
}

export default StopMediaConfirm
