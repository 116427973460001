// Library
import React, { useState, useEffect } from 'react'
import { Container, Loader, Dimmer, Message, Button, Input } from 'semantic-ui-react'
import Spacer from 'components/atoms/Spacer'
import { useForm } from 'react-hook-form'
import paths from 'constants/paths'
import { auth } from 'FirebaseConfig'
import NavigationBarPlane from 'components/organisms/menubar/NavigationBarPlane' //TODO:フッターが必要か確認
import { useHistory } from 'react-router-dom'

import { getParameterByName } from 'components/pages/Action/ActionPage/helper'
import * as firebase from 'firebase/app'
import 'firebase/functions'

const MailAction = () => {
  const { register } = useForm()
  let domain = document.domain
  let port = domain === 'localhost' ? '3000:' : ''
  let prot = domain === 'localhost' ? 'http' : 'https'

  // currentUser is provided
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [isVision, setVision] = useState()
  const [message, setMessage] = useState({})
  const [isSuccess, setSuccess] = useState(null)
  const [isVerified, setVirified] = useState(false)
  const [mode, setMode] = useState('')
  const [actionCode, setActionCode] = useState('')

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value)
        break
      case 'password':
        setPassword(event.target.value)
        break
      default:
    }
  }

  const handleVerifyEmail = (auth, actionCode, uid) => {
    setLoading(true)
    const sendRegisterMail = firebase.functions().httpsCallable('sendRegisterMail')
    auth
      .applyActionCode(actionCode)
      .then(function () {
        sendRegisterMail({ uid })
          .then(() => {
            setSuccess(true)
            setLoading(false)
          })
          .catch(() => {
            setSuccess(true)
            setLoading(false)
          })
      })
      .catch(function (error) {
        console.log('failed at email authentification')
        setSuccess(false)
        setVision(true)
        setMessage({
          type: 'negative',
          title: 'メールの認証に失敗しました',
          text: error,
        })
        setLoading(false)
      })
  }

  const handleRecoverEmail = (auth, actionCode) => {
    var restoredEmail = null
    auth
      .checkActionCode(actionCode)
      .then(function (info) {
        restoredEmail = info['data']['email']
        // Revert to the old email.
        return auth.applyActionCode(actionCode)
      })
      .then(function () {
        setSuccess(true)
        auth
          .sendPasswordResetEmail(restoredEmail)
          .then(function () {
            // Password reset confirmation sent. Ask user to check their email.
          })
          .catch(function (error) {
            // Error encountered while sending password reset code.
            setVision(true)
            setMessage({
              type: 'negative',
              title: 'メールアドレスのリセットに失敗しました。',
              text: error,
            })
          })
      })
      .catch(function (error) {
        // Invalid code.
        setSuccess(false)
        setMessage({
          type: 'negative',
          title: 'メールアドレスのリセットに失敗しました。',
          text: error,
        })
      })
  }

  const handleResetPassword = (auth, actionCode) => {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Verify the password reset code is valid.
    auth
      .verifyPasswordResetCode(actionCode)
      .then(function (email) {
        setEmail(email)
        setSuccess(true)
        // TODO: Show the reset screen with the user's email and ask the user for
        // the new password.

        // Save the new password.
      })
      .catch(function (error) {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        setSuccess(false)
        switch (error.code) {
          case 'auth/user-token-expired':
            setMessage({
              type: 'negative',
              title: '期限切れのコード',
              text: 'パスワード再設定メールの期限が切れています。もう一度やり直してください。',
            })
            break
          case 'auth/invalid-api-key':
            setMessage({
              type: 'negative',
              title: '無効のAPIキー',
              text: 'APIキーが無効です。もう一度やり直してください。',
            })
            break
          default:
            setMessage({
              type: 'negative',
              title: 'エラー',
              text: '不明のエラーが発生しました。もう一度やり直してください。',
            })
        }
      })
  }

  const setNewPassword = () => {
    setLoading(true)
    auth
      .confirmPasswordReset(actionCode, password)
      .then(function () {
        // Password reset has been confirmed and new password updated.

        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        setLoading(false)
        // auth.signInWithEmailAndPassword(email, password);
        history.push({
          pathname: `${paths.groupdashboard}`,
          state: {
            text: 'ログインページに移動します',
            type: 'positive',
          },
        })

        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      })
      .catch(function (error) {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        setLoading(false)
        setVision(true)
        setMessage({
          type: 'negative',
          title: `エラー${error}`,
          text: 'パスワードのリセットに失敗しました',
        })
      })
  }

  const moveToSignIn = () => {
    history.push({
      pathname: `${paths.signin}`,
      state: {
        text: 'ログインページに移動します',
        type: 'positive',
      },
    })
  }

  const moveToPasswordReset = () => {
    history.push({
      pathname: `${paths.resetpassword}`,
      state: {
        text: 'パスワード再設定ページに移動します',
        type: 'positive',
      },
    })
  }

  const handleDismiss = () => {
    history.push({
      state: { text: '', type: '' },
    })
  }

  const sendValidEmail = () => {
    if (window.confirm('認証メールを送信しますか？')) {
      // try {
      setLoading(true)
      auth.onAuthStateChanged(function (user) {
        if (user) {
          const actionCodeSettings = {
            url: `${prot}://${domain}${port}${paths.signin}?email=${user.email}&uid=${user.uid}`,
          }
          user
            .sendEmailVerification(actionCodeSettings)
            .then(() => {
              setLoading(false)
              history.push({
                pathname: `${paths.signin}`,
                state: {
                  text: `${user.email}宛に確認メールを送信しました`,
                  type: 'positive',
                },
              })
              // history.go(0)
              // history.push({
              //   state: {
              //     text: `${user.email}宛に確認メールを送信しました`,
              //     type: 'positive'
              //   }
              // })
            })
            .catch(() => {
              setLoading(false)
              history.push({
                pathname: `${paths.signin}`,
                state: {
                  text: `${user.email}宛に確認メールを送信しました`,
                  type: 'positive',
                },
              })
              // history.go(0)
              // history.push({
              //   state: {
              //     text: `${user.email}宛に確認メールを送信できませんでした[${error}]`,
              //     type: 'negative'
              //   }
              // })
            })
        }
      })
    }
  }

  useEffect(() => {
    const url = window.location.search
    const mode = getParameterByName('mode', url)
    const actionCode = getParameterByName('oobCode', url)
    const continueUrl = getParameterByName('continueUrl', url)
    const lang = getParameterByName('lang', url) || 'en'
    const queryUrl = continueUrl.substring(continueUrl.indexOf('?'), continueUrl.length)
    const queryUid = getParameterByName('uid', queryUrl)
    setMode(mode)
    setActionCode(actionCode)
    // setActionCode(getParameterByName('oobCode', url))
    // setContinueUrl(getParameterByName('continueUrl', url));
    // setLang(getParameterByName('lang', url) || 'en')
    // // if(continueUrl.indexOf("?")>=0){
    // const queryUrl = continueUrl.substring(continueUrl.indexOf("?"), continueUrl.length);
    // const queryEmail = getParameterByName('email', queryUrl);
    // const queryUid = getParameterByName('uid', queryUrl)

    // setEmail(queryEmail);
    // setUid(queryUid);
    // }

    switch (mode) {
      case 'verifyEmail':
        if (auth.currentUser && auth.currentUser.emailVerified === true) {
          setVirified(true)
          console.log('this email has already been authentificated')
          break
        }

        console.log('this email has not been ahthentificated yet')
        handleVerifyEmail(auth, actionCode, queryUid)
        break
      case 'resetPassword':
        // Display reset password handler and UI.
        handleResetPassword(auth, actionCode, continueUrl, lang)
        break
      case 'recoverEmail':
        // Display email recovery handler and UI.
        // handleRecoverEmail(auth, actionCode, lang);
        break
      default:
    }
  }, [])
  //TODO:フッターがついていない。ヘッダーは1種のみ。
  //TODO: 不正なアクセスの場合、トップページなどにリダイレクトさせる
  return (
    <>
      <NavigationBarPlane />
      {loading && (
        <Dimmer active inverted>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <Spacer />
      {mode && mode === 'verifyEmail' && (
        <Container>
          {isVerified ? (
            <div>
              <h3>確認済みのメールアドレスです。</h3>
              <p>このメールアドレスはすでに確認済みです。</p>
              <Button primary onClick={moveToSignIn}>
                ログインページへ
              </Button>
            </div>
          ) : (
            isSuccess !== null && (
              <div>
                <h3>{isSuccess ? 'メールアドレスを確認しました' : 'メールアドレスの確認に失敗しました'}</h3>
                <p>{isSuccess ? '確認ありがとうございました。ログイン後サービスをご利用ください。' : 'もう一度認証メールを送ってください。'}</p>
                <Button primary onClick={isSuccess ? moveToSignIn : sendValidEmail}>
                  {isSuccess ? 'ログインページへ' : 'もう一度認証メールを送る'}
                </Button>
              </div>
            )
          )}
        </Container>
      )}
      {mode && mode === 'resetPassword' && (
        <Container>
          {isSuccess ? (
            <div>
              <h3>パスワードの再設定</h3>
              <h4>{email ? 'メールアドレス：' + email : ''}</h4>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="新しいパスワード"
                onChange={handleChange}
                ref={register({
                  required: 'Required',
                  minLength: {
                    value: 6,
                    message: 'password needs min 6 caractors',
                  },
                })}
                action
                size="big"
              >
                <input />
                <Button type="submit" primary onClick={setNewPassword}>
                  パスワードを更新する
                </Button>
              </Input>
            </div>
          ) : (
            <div>
              <h3>パスワードの再設定</h3>
              <p>{message.text}</p>
              <Button onClick={moveToPasswordReset}>パスワード再設定ページへ</Button>
              <Button primary onClick={moveToSignIn}>
                ログインページへ
              </Button>
            </div>
          )}
        </Container>
      )}
      {mode && mode === 'recoverEmail' && (
        <Container>
          {isSuccess ? (
            <Button primary onClick={handleRecoverEmail}>
              メールアドレスをリセットする
            </Button>
          ) : (
            <div>
              <h3>{message.title}</h3>
              <p>{message.text}</p>
              <Button primary onClick={moveToSignIn}>
                ログインページへ
              </Button>
            </div>
          )}
        </Container>
      )}
      <Spacer />
      {isVision && message && <Message negative onDismiss={handleDismiss} header={message.title} content={message.text} />}
    </>
  )
}

export default MailAction
