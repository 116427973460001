import React from 'react'
import AppLayoutOwner from 'components/organisms/layouts/AppLayoutOwner'
import { Header, Grid, Segment, Container } from 'semantic-ui-react'
import paths from 'constants/paths'
import Spacer from 'components/atoms/Spacer'
import { useHistory } from 'react-router-dom'
import './index.css'

const MenuOwner = () => {
  const history = useHistory()

  return (
    <AppLayoutOwner>
      <Container textAlign="center">
        <Spacer />
        <Spacer />
        <Segment>
          <Header as="h2">媒体管理メニュー</Header>
        </Segment>
        <Spacer />
        <Grid centered columns={2} stackable textAlign="center" relaxed="very">
          <Grid.Column>
            <div className="menuOwner__ownerMenuSegment" onClick={() => history.push(`${paths.dashboardowner}`)}>
              <div className="menuOwner__ownerMenuSegmentInner">掲載状況確認</div>
            </div>

            <Spacer />

            <div className="menuOwner__ownerMenuSegment" onClick={() => history.push(`${paths.addmedia}`)}>
              <div className="menuOwner__ownerMenuSegmentInner">新規媒体追加</div>
            </div>
          </Grid.Column>

          <Grid.Column>
            <div className="menuOwner__ownerMenuSegment" onClick={() => history.push(`${paths.stopmedia}`)}>
              <div className="menuOwner__ownerMenuSegmentInner">掲載一時停止</div>
            </div>

            <Spacer />

            <div className="menuOwner__ownerMenuSegment" onClick={() => history.push(`${paths.restartmedia}`)}>
              <div className="menuOwner__ownerMenuSegmentInner">掲載を再開</div>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    </AppLayoutOwner>
  )
}

export default MenuOwner
