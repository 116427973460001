import React, { useCallback } from 'react'
import { MediaImage } from 'components/organisms/images/MediaImage'
import TagList from 'components/organisms/tag/TagList'
import { Header, Icon, Label } from 'semantic-ui-react'
import { getColInfoFromTypeName } from 'helpers/mediaType/mediaTypes'
import './index.css'
import { convertValueToText } from 'helpers/mediaType/helpers'
import { openMediaPageLink } from 'helpers/openMediaPageLink'
import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'

type props = {
  media: AdviceMediaDataType
  months: number
  weeks: number
  onClick: React.MouseEventHandler<HTMLDivElement>
}

const MediaCardMapSearch = ({ media, months, weeks, onClick }: props) => {
  const cardContentOptions = getColInfoFromTypeName(media.mediaType).cardContent.mediaSearch

  const headers = cardContentOptions.map((option, idx) => {
    const gridRow = String(2 + idx) + '/' + String(3 + idx)
    return (
      <div className="mediaCardMapSearch__mediaCardItemTitle" style={{ gridRow: gridRow, gridColumn: '2/3' }} key={option.displayedName + '_header'}>
        {option.displayedName}
      </div>
    )
  })
  const contents = cardContentOptions.map((option, idx) => {
    const gridRow = String(2 + idx) + '/' + String(3 + idx)
    const key = option.displayedName + '_content'
    // null check
    const value = typeof option.displayMethod === 'function' ? option.displayMethod(media, months, weeks) : 'N/A'
    const prefix = option.displayedPrefix || ''
    const suffix = option.displayedSuffix || ''

    return (
      <div style={{ gridRow: gridRow, gridColumn: '3/5' }} className="mediaCardMapSearch__mediaCardColumn mediaCardMapSearch__mediaCardItemValue" key={key}>
        <b>{media.isPrivate ? '非公開' : prefix + value + suffix}</b>
      </div>
    )
  })

  const clickExternalLinkHandler = useCallback(() => {
    openMediaPageLink(media)
  }, [])

  return (
    <div onClick={onClick}>
      {media.tags && (
        <>
          <div style={{ padding: '4px' }}>
            {isJackChild(media) && <Label>エリアジャック専用</Label>}
            <Label active compact color="red">
              {convertValueToText(media.mediaType)}
            </Label>
            <TagList tags={media.tags} color="#f39800" textColor="white" />
            <TagList tags={media.lines} color="#5cBBcf" textColor="white" />
            <TagList tags={media.highways} color="#5cBBcf" textColor="white" />
          </div>
          <hr style={{ margin: 0, border: 'solid 1px #ddd' }} />
        </>
      )}
      <div style={{ display: 'grid' }} className="mediaCardMapSearch__mediaCardCard" key={media.uid}>
        <div style={{ gridRow: '1/6', gridColumn: '1/2' }}>
          <MediaImage media={media} />
        </div>

        <div style={{ gridRow: '1/2', gridColumn: '2/4', paddingTop: '.75rem' }} className="mediaCardMapSearch__mediaCardHeader">
          <Header as="h4">{media.name}</Header>
        </div>

        <div onClick={clickExternalLinkHandler} style={{ gridRow: '1/2', gridColumn: '4/5' }} className="mediaCardCart__mediaCardHeader">
          <Icon size="large" inverted color="grey" name="external square alternate" />
        </div>

        {/* 媒体情報を表示 */}
        {headers}
        {contents}
      </div>
    </div>
  )
}
export default MediaCardMapSearch
