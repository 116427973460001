import firebase from 'firebase'
import 'firebase/firestore'
//import { layer } from "@fortawesome/fontawesome-svg-core";
//import { db } from 'FirebaseConfig'
//import React from 'react';

//import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
/*TODO:downloaduserはサブコレクションで一括で呼び出すようにする
  useMemoやuseConstextを使って、呼び出し方を変える
*/

export const getList = async () => {
  const db = firebase.firestore()
  //mediaコレクションから媒体id、媒体名、登録者idを取得
  const mediaRef = await db.collection('media').get()
  const listMedia = mediaRef.docs.map((doc) => {
    return {
      mediaId: doc.data().uid || '',
      mediaName: doc.data().name || '',
      mediaOwnerId: doc.data().userId || '',
    }
  })
  //listMedia : [[媒体ID, 媒体名,媒体所有ユーザーID]]
  //userコレクションからユーザ名とユーザidを取得
  const userRef = await db.collection('user').get()
  const listUser = userRef.docs.map((doc) => {
    return {
      userId: doc.data().uid || '',
      userCompanyName: doc.data().companyName || '',
      userName: doc.data().fullName || '',
    }
  })
  //listUser : [[ユーザーID, ユーザー企業名, ユーザー氏名]]
  //mediaコレクションからPDFのdownloadUserを取得
  const listDownloadUser = []
  let count = 0
  for (let i = 0; i < listMedia.length; i++) {
    const downloadUserRef = await db.collection('media').doc(listMedia[i].mediaId).collection('downloadUser').get()
    if (downloadUserRef.docs.length > 0) {
      listDownloadUser.push({
        mediaId: listMedia[i].mediaId || '',
        downloadUserIds: [],
      })
      for (const doc of downloadUserRef.docs) {
        listDownloadUser[count].downloadUserIds.push(doc.data().uid || '')
      }
      count = count + 1
    }
  }
  //listDownloadUser = [[媒体ID, 広告主ユーザーID]]
  //cartコレクションからid, uid, userIdのデータを取得する
  const cartRef = await db.collection('cart').get()
  const listMediaInCart = []
  cartRef.docs.forEach((doc) => {
    if (doc.data().id && doc.data().id.length) {
      for (let mediaId of doc.data().id) {
        listMediaInCart.push({
          mediaId: mediaId,
          cartName: doc.data().groupName,
          userId: doc.data().userId,
          updateDate: doc.data().updateAt,
        })
      }
    }
  })
  let listMediaInCartSorted = []
  listMediaInCartSorted = [...new Set(listMediaInCart.map((row) => row.mediaId))]
  listMediaInCartSorted = listMediaInCartSorted.map((elem) => {
    return {
      mediaId: elem,
      cartInfo: [],
    }
  })
  for (let a = 0; a < listMediaInCartSorted.length; a++) {
    for (let j = 0; j < listMediaInCart.length; j++) {
      if (listMediaInCartSorted[a].mediaId === listMediaInCart[j].mediaId) {
        listMediaInCartSorted[a].cartInfo.push({
          cartName: listMediaInCart[j].cartName,
          cartOwnerId: listMediaInCart[j].userId,
        })
      }
    }
  }
  /*
        listMedia : [[媒体ID, 媒体名,媒体所有ユーザーID]]
        listUser : [[ユーザーID, ユーザー企業名, ユーザー氏名]]
        listDownloadUser = [[媒体ID, 広告主ユーザーID]]
        listMediaIncart = [[媒体ID, カート名, 広告主ユーザーID]]
        これらを媒体IDをキーに辿れるように整理する
        let allData1[n] = {
            mediaInfo:{
                mediaId:'',
                mediaName:'',
                mediaOwnerId:'',
                mediaOwnerCopmanyName:''
            },
            cartOwnerInfo:{[
                cartName:'',
                cartOwnerId:'',
                cartOwnerCompanyName:'',
                cartOwnerName:'',
                cartUpdateDate:''
            ]}
            downloadUserInfo:{[
                downloadUserId:'',
                downloaderCompanyName: ''
            ]}
        }
        */
  let allInfo = []
  const listUserId = listUser.map((row) => row.userId)
  //まずはmediaInfoを埋める
  for (let l = 0; l < listMedia.length; l++) {
    allInfo.push({
      mediaInfo: {
        mediaId: listMedia[l].mediaId,
        mediaName: listMedia[l].mediaName || '',
        mediaOwnerId: listMedia[l].mediaOwnerId || '',
        mediaOwnerCopmanyName: '',
      },
      cartOwnerInfo: [],
      downloadUserInfo: [],
    })
  }

  for (const media of allInfo) {
    const mediaOwnerIdIdx = listUserId.indexOf(media.mediaInfo.mediaOwnerId)
    media.mediaInfo.mediaOwnerCopmanyName = mediaOwnerIdIdx > -1 ? listUser[mediaOwnerIdIdx].userCompanyName : ''
    //含まれるdownloadUserの数だけ繰り返す
    for (let b = 0; b < listDownloadUser.length; b++) {
      if (media.mediaInfo.mediaId === listDownloadUser[b].mediaId) {
        for (let e = 0; e < listDownloadUser[b].downloadUserIds.length; e++) {
          const downloadUserIdIdx = listUserId.indexOf(listDownloadUser[b].downloadUserIds[e])
          media.downloadUserInfo.push({
            downloadUserId: listDownloadUser[b].downloadUserIds[e],
            downloadUserCompanyName: downloadUserIdIdx > -1 ? listUser[downloadUserIdIdx].userCompanyName : '',
            downloadUserName: downloadUserIdIdx > -1 ? listUser[downloadUserIdIdx].userName : '',
          })
        }
      }
    }
    for (let f = 0; f < listMediaInCartSorted.length; f++) {
      if (media.mediaInfo.mediaId === listMediaInCartSorted[f].mediaId) {
        for (let g = 0; g < listMediaInCartSorted[f].cartInfo.length; g++) {
          const cartOwnerIdIdx = listUserId.indexOf(listMediaInCartSorted[f].cartInfo[g].cartOwnerId)
          media.cartOwnerInfo.push({
            cartName: listMediaInCartSorted[f].cartInfo[g].cartName,
            cartOwnerId: listMediaInCartSorted[f].cartInfo[g].cartOwnerId,
            cartOwnerName: cartOwnerIdIdx > -1 ? listUser[cartOwnerIdIdx].userName : '',
            cartOwnerCompanyName: cartOwnerIdIdx > -1 ? listUser[cartOwnerIdIdx].userCompanyName : '',
            cartUpdateDate: listMediaInCartSorted[f].cartInfo[g].updateDate,
          })
        }
      }
    }
  }
  //検討者の数を追加
  allInfo.map((media) => {
    media.popularity = {}
    media.popularity.cart = media.cartOwnerInfo.length
    media.popularity.download = media.downloadUserInfo.length
    media.popularity.all = media.cartOwnerInfo.length + media.downloadUserInfo.length
  })
  //被検討数が0のものを排除
  allInfo = allInfo.filter((media) => media.popularity.all > 0)
  //媒体の表示順を被検討数で降順に
  allInfo.sort((a, b) => (a.popularity.all > b.popularity.all ? -1 : 1))
  // fetchData()
  return allInfo
}
