import React, { useEffect, useState, useContext } from 'react'
import { Grid } from 'semantic-ui-react'
import NavigationBarPlane from 'components/organisms/menubar/NavigationBarPlane'
import NavigationBar from 'components/organisms/menubar/NavigationBar'
import NavigationBarOwner from 'components/organisms/menubar/NavigationBarOwner'
import MapSearchDisplay from 'components/organisms/mediasearch/MapSearchDisplay'
import { AuthContext } from 'providers/Auth'
import { isOwner } from 'helpers/isOwner'
import { useUsersContext } from 'providers/Users'

type UserType = 'OWNER' | 'CUSTOMER' | 'OTHER'

const Map = () => {
  const [userType, setUserType] = useState<UserType>('OTHER')
  const { currentUser } = useContext(AuthContext)
  const users = useUsersContext()
  useEffect(() => {
    if (!currentUser) {
      setUserType('OTHER')
    } else if (isOwner(users, currentUser.uid)) {
      setUserType('OWNER')
    } else {
      setUserType('CUSTOMER')
    }
  }, [currentUser, users])

  return (
    <Grid as="Segment">
      <Grid.Row style={{ paddingBottom: 0 }}>
        <Grid.Column>
          {/* TODO: layoutコンポーネント呼び出す */}
          <div className="mediaSearch__navBarGridRow">
            {userType === 'OWNER' && <NavigationBarOwner />}
            {userType === 'CUSTOMER' && <NavigationBar />}
            {userType === 'OTHER' && <NavigationBarPlane />}
          </div>
        </Grid.Column>
      </Grid.Row>
      <MapSearchDisplay isLoggedIn={!(userType === 'OTHER')} />
    </Grid>
  )
}

export default Map
