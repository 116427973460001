import React from 'react'
import { Form, Dropdown, Grid, Input } from 'semantic-ui-react'
import TagDropdown from 'components/organisms/tag/TagDropdown'
import StateOptions from 'constants/StateOptions'
import MediaTypeOptions from 'constants/MediaTypeOptions'
import MapSearchBreadcrump from 'components/organisms/breadcrumb/MapSearchBreadcrump'

type props = {
  mediaTypeInput: string[]
  areaInput: string[]
  tagInput: string[]
  lineInput: string[]
  highwayInput: string[]
  searchWordInput: string
  //TODO: any型を排除する
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange: (_e: any, { name, value }: any) => void
}

const MapSearchForm = (props: props) => {
  return (
    <Form>
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column>
            <MapSearchBreadcrump />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column>
            <h3>検索条件で媒体を絞り込む</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column>
            <Dropdown
              compact
              fluid
              multiple
              placeholder="種類"
              search
              selection
              scrolling
              name="mediaType"
              value={props.mediaTypeInput}
              options={MediaTypeOptions}
              onChange={props.handleChange}
            />
          </Grid.Column>
          <Grid.Column>
            <Dropdown
              compact
              fluid
              multiple
              placeholder="エリア"
              search
              selection
              scrolling
              name="area"
              value={props.areaInput}
              options={StateOptions}
              onChange={props.handleChange}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column>
            <TagDropdown
              tags={props.tagInput}
              lines={props.lineInput}
              highways={props.highwayInput}
              placeholder="タグで候補を絞る"
              changeTagCallback={props.handleChange}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column>
            <Input fluid name="searchWord" type="text" placeholder="キーワードで候補を絞る" value={props.searchWordInput} onChange={props.handleChange} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  )
}

export default MapSearchForm
