import React, { useState, useEffect, useContext } from 'react'
import GroupFormBase from 'components/organisms/group/GroupFormBase'
import GroupFormBillboard from 'components/organisms/group/groupFormMediaType/GroupFormBillboard'
import GroupFormDigitalSignage from 'components/organisms/group/groupFormMediaType/GroupFormDigitalSignage'
import GroupFormPoster from 'components/organisms/group/groupFormMediaType/GroupFormPoster'
import GroupFormMansionSignage from 'components/organisms/group/groupFormMediaType/GroupFormMansionSignage'
import GroupFormLeaflet from 'components/organisms/group/groupFormMediaType/GroupFormLeaflet'
import GroupFormJack from 'components/organisms/group/groupFormMediaType/GroupFormJack'
import { Grid, Segment, Button, Header, Message } from 'semantic-ui-react'
import Spacer from 'components/atoms/Spacer'
import { useHistory } from 'react-router-dom'
import paths from 'constants/paths'
import { db } from 'FirebaseConfig'
import { AuthContext } from 'providers/Auth'
import * as firebase from 'firebase/app'
import 'firebase/functions'
import { allTargetAgeArray } from 'constants/constants'

function parseDate(date) {
  //本当はtypescriptにしてinterface指定したいところ...
  return date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2)
}

const AddGroupContent = (props) => {
  const today = new Date()

  const [groupName, setGroupName] = useState('')
  const defaultStartdate = parseDate(today)
  const [startDate, setStartDate] = useState(defaultStartdate)
  const [endDate, setEndDate] = useState(defaultStartdate)
  const [endDateMonth, setEndDateMonth] = useState(0)
  const [endDateWeek, setEndDateWeek] = useState(0)
  const [budget, setBudget] = useState('')
  const [noBudget, setNoBudget] = useState(false)
  const [targetLocation, setTargetLocation] = useState([])
  const [noTargetLocation, setNoTargetLocation] = useState(false)
  const [targetAge10, setTargetAge10] = useState(false)
  const [targetAge20, setTargetAge20] = useState(false)
  const [targetAge30, setTargetAge30] = useState(false)
  const [targetAge40, setTargetAge40] = useState(false)
  const [targetAge50, setTargetAge50] = useState(false)
  const [targetAge60, setTargetAge60] = useState(false)
  const [targetAge70, setTargetAge70] = useState(false)
  const [targetAge80, setTargetAge80] = useState(false)
  const [targetAgeAll, setTargetAgeAll] = useState(false)
  const [targetSexMale, setTargetSexMale] = useState(false)
  const [targetSexFemale, setTargetSexFemale] = useState(false)
  const [targetSexAll, setTargetSexAll] = useState(false)
  const [mediaType, setMediaType] = useState('')
  //leaflet独自の項目
  const [industry, setIndustry] = useState([])
  const [noIndustry, setNoIndustry] = useState(false)
  //mansionSignage独自の項目
  const [residentsProperty, setResidentsProperty] = useState([])
  const [noResidentsProperty, setNoResidentsProperty] = useState(false)
  const [mansionType, setMansionType] = useState([])
  const [noMansionType, setNoMansionType] = useState(false)

  //エラーのstate
  const [errorFlag, setErrorFlag] = useState(false)
  const [groupNameError, setGroupNameError] = useState('')
  const [startDateError, setStartDateError] = useState('')
  const [endDateError, setEndDateError] = useState('')
  const [endDateBillboardError, setEndDateBillboardError] = useState('')
  const [budgetError, setBudgetError] = useState('')
  const [targetLocationError, setTargetLocationError] = useState('')
  const [targetAgeError, setTargetAgeError] = useState('')
  const [targetSexError, setTargetSexError] = useState('')
  const [mediaTypeError, setMediaTypeError] = useState('')
  //leaflet独自の項目
  const [industryError, setIndustryError] = useState('')
  //mansionSignage独自の項目
  const [residentsPropertyError, setResidentsPropertyError] = useState('')
  const [mansionTypeError, setMansionTypeError] = useState('')

  const history = useHistory()
  const { currentUser } = useContext(AuthContext)

  const createGroup = () => {
    if (
      !mediaType ||
      groupName === '' ||
      (budget === '' && !noBudget) ||
      startDate === '' ||
      (endDateMonth == 0 && endDateWeek == 0) ||
      Number.isNaN(endDateMonth) ||
      Number.isNaN(endDateWeek) ||
      (endDateMonth == 0 && endDateWeek < 4 && mediaType === 'mediaTypeBillBoard') ||
      (targetLocation.length === 0 && noTargetLocation === false) ||
      (targetAge10 === false &&
        targetAge20 === false &&
        targetAge30 === false &&
        targetAge40 === false &&
        targetAge50 === false &&
        targetAge60 === false &&
        targetAge70 === false &&
        targetAge80 === false &&
        targetAgeAll === false) ||
      (targetSexMale === false && targetSexFemale === false && targetSexAll === false) ||
      (mediaType === 'mediaTypeLeaflet' && industry.length === 0 && !noIndustry) ||
      (mediaType === 'mediaTypeMansionSignage' && mansionType.length === 0 && !noMansionType) ||
      (mediaType === 'mediaTypeMansionSignage' && residentsProperty.length === 0 && !noResidentsProperty)
    ) {
      if (!mediaType) {
        setErrorFlag(true)
        setMediaTypeError('媒体の種類 ')
      }
      if (groupName === '') {
        setErrorFlag(true)
        setGroupNameError('グループ名 ')
      } else {
        setGroupNameError('')
      }

      if (startDate === '') {
        setErrorFlag(true)
        setStartDateError('開始日 ')
      } else {
        setStartDateError('')
      }

      if ((endDateMonth === 0 && endDateWeek === 0) || Number.isNaN(endDateMonth) || Number.isNaN(endDateWeek)) {
        setErrorFlag(true)
        setEndDateError('終了日 ')
      } else {
        setEndDateError('')
      }

      if (endDateMonth == 0 && endDateWeek < 4 && mediaType === 'mediaTypeBillBoard') {
        console.log(endDateMonth)
        setErrorFlag(true)
        setEndDateBillboardError('ビルボードは４週間以上の期間でなければ選択できません。')
      } else {
        setEndDateBillboardError('')
      }

      if (budget === '' && noBudget === false) {
        setErrorFlag(true)
        setBudgetError('予算 ')
      } else {
        setBudgetError('')
      }

      if (targetLocation.length === 0 && noTargetLocation === false) {
        setErrorFlag(true)
        setTargetLocationError('配信地域 ')
      } else {
        setTargetLocationError('')
      }

      //マンションサイネージで住民属性が埋まってない時のエラーメッセージ
      if (mediaType === 'mediaTypeMansionSignage' && residentsProperty.length === 0 && !noResidentsProperty) {
        setErrorFlag(true)
        setResidentsPropertyError('住民属性 ')
      } else {
        setTargetLocationError('')
      }
      //マンションサイネージで所有形態が埋まってない時のエラーメッセージ
      if (mediaType === 'mediaTypeMansionSignage' && mansionType.length === 0 && !noMansionType) {
        setErrorFlag(true)
        setMansionTypeError('所有形態 ')
      } else {
        setMansionTypeError('')
      }
      //リーフレットで設置施設の業種が埋まっていない時のエラーメッセージ
      if (mediaType === 'mediaTypeLeaflet' && industry.length === 0 && !noIndustry) {
        setErrorFlag(true)
        setIndustryError('設置施設の業種 ')
      } else {
        setIndustryError('')
      }

      if (
        targetAge10 === false &&
        targetAge20 === false &&
        targetAge30 === false &&
        targetAge40 === false &&
        targetAge50 === false &&
        targetAge60 === false &&
        targetAge70 === false &&
        targetAge80 === false &&
        targetAgeAll === false
      ) {
        setErrorFlag(true)
        setTargetAgeError('年齢 ')
      } else {
        setTargetAgeError('')
      }

      if (targetSexMale === false && targetSexFemale === false && targetSexAll === false) {
        setErrorFlag(true)
        setTargetSexError('性別 ')
      } else {
        setTargetSexError('')
      }
    } else {
      setErrorFlag(false)
      let docId = db.collection('cart').doc().id
      let today = new Date(Date.now())
      let data = {}
      data.uid = docId
      data.userId = currentUser.uid
      data.groupName = groupName
      data.createAt = today
      data.status = 1
      data.budget = noBudget ? 0 : Number(budget)
      data.targetLocation = noTargetLocation ? [] : targetLocation

      const startDateArray = startDate.match(/\d+/g)
      const endDateArray = endDate.match(/\d+/g)
      const tmpStartDate = new Date(startDateArray[0], startDateArray[1], startDateArray[2])
      const tmpEndDate = new Date(endDateArray[0], endDateArray[1], endDateArray[2])
      const fixedStartDate = tmpStartDate.setMonth(tmpStartDate.getMonth() - 1)
      const fixedEndDate = tmpEndDate.setMonth(tmpEndDate.getMonth() - 1)
      data.startDate = firebase.firestore.Timestamp.fromDate(new Date(fixedStartDate))
      data.endDate = firebase.firestore.Timestamp.fromDate(new Date(fixedEndDate))
      data.endDateMonth = endDateMonth
      data.endDateWeek = endDateWeek
      data.targetSex = targetSexAll || (targetSexMale && targetSexFemale) ? 'unisex' : targetSexMale ? 'male' : 'female'

      if (targetAgeAll) {
        data.targetAge = allTargetAgeArray
      } else {
        let tmpAgeArray = []
        const targetAgeArray = [targetAge10, targetAge20, targetAge30, targetAge40, targetAge50, targetAge60, targetAge70, targetAge80]
        for (let i = 0; i < targetAgeArray.length; i++) {
          if (targetAgeArray[i] === true) {
            tmpAgeArray.push(allTargetAgeArray[i])
          }
        }
        data.targetAge = tmpAgeArray
      }

      data.mediaType = mediaType
      data.mediaCount = 0

      switch (mediaType) {
        case 'mediaTypeLeaflet':
          data.industry = noIndustry ? [] : industry
          break
        case 'mediaTypeMansionSignage':
          data.residentsProperty = noResidentsProperty ? [] : residentsProperty
          data.mansionType = noMansionType ? [] : mansionType
          break
      }

      props.setLoading(true)
      firebase.analytics().logEvent('group_created')
      db.collection('cart')
        .doc(docId)
        .set(data)
        .then(
          history.push({
            pathname: `/cart/edit/${docId}`,
            state: {
              sendedBudget: budget,
              firstTimeFlag: true,
              referrer: `/group/edit/${docId}`,
            },
          })
        )
        .catch((error) => {
          console.log(`DB登録失敗[${error}]`)
        })
    }

    // users > group collectionを作成
    // no budget チェックでバリデーションする
    // /cart/edit/:uidに遷移
  }

  useEffect(() => {
    groupFormMediaType(mediaType)
  }, [mediaType])

  const groupFormMediaType = (mediaType) => {
    switch (mediaType) {
      case 'mediaTypeBillboard':
        return (
          <GroupFormBillboard
            setTargetAge10={setTargetAge10}
            setTargetAge20={setTargetAge20}
            setTargetAge30={setTargetAge30}
            setTargetAge40={setTargetAge40}
            setTargetAge50={setTargetAge50}
            setTargetAge60={setTargetAge60}
            setTargetAge70={setTargetAge70}
            setTargetAge80={setTargetAge80}
            setTargetAgeAll={setTargetAgeAll}
            targetAge10={targetAge10}
            targetAge20={targetAge20}
            targetAge30={targetAge30}
            targetAge40={targetAge40}
            targetAge50={targetAge50}
            targetAge60={targetAge60}
            targetAge70={targetAge70}
            targetAge80={targetAge80}
            targetAgeAll={targetAgeAll}
            setTargetSexMale={setTargetSexMale}
            setTargetSexFemale={setTargetSexFemale}
            setTargetSexAll={setTargetSexAll}
            targetSexMale={targetSexMale}
            targetSexFemale={targetSexFemale}
            targetSexAll={targetSexAll}
            setTargetLocation={setTargetLocation}
            setNoTargetLocation={setNoTargetLocation}
            noTargetLocation={noTargetLocation}
          />
        )
      case 'mediaTypeDigitalSignage':
        return (
          <GroupFormDigitalSignage
            setTargetAge10={setTargetAge10}
            setTargetAge20={setTargetAge20}
            setTargetAge30={setTargetAge30}
            setTargetAge40={setTargetAge40}
            setTargetAge50={setTargetAge50}
            setTargetAge60={setTargetAge60}
            setTargetAge70={setTargetAge70}
            setTargetAge80={setTargetAge80}
            setTargetAgeAll={setTargetAgeAll}
            targetAge10={targetAge10}
            targetAge20={targetAge20}
            targetAge30={targetAge30}
            targetAge40={targetAge40}
            targetAge50={targetAge50}
            targetAge60={targetAge60}
            targetAge70={targetAge70}
            targetAge80={targetAge80}
            targetAgeAll={targetAgeAll}
            setTargetSexMale={setTargetSexMale}
            setTargetSexFemale={setTargetSexFemale}
            setTargetSexAll={setTargetSexAll}
            targetSexMale={targetSexMale}
            targetSexFemale={targetSexFemale}
            targetSexAll={targetSexAll}
            setTargetLocation={setTargetLocation}
            setNoTargetLocation={setNoTargetLocation}
            noTargetLocation={noTargetLocation}
          />
        )
      case 'mediaTypeVision':
        return (
          <GroupFormDigitalSignage
            setTargetAge10={setTargetAge10}
            setTargetAge20={setTargetAge20}
            setTargetAge30={setTargetAge30}
            setTargetAge40={setTargetAge40}
            setTargetAge50={setTargetAge50}
            setTargetAge60={setTargetAge60}
            setTargetAge70={setTargetAge70}
            setTargetAge80={setTargetAge80}
            setTargetAgeAll={setTargetAgeAll}
            targetAge10={targetAge10}
            targetAge20={targetAge20}
            targetAge30={targetAge30}
            targetAge40={targetAge40}
            targetAge50={targetAge50}
            targetAge60={targetAge60}
            targetAge70={targetAge70}
            targetAge80={targetAge80}
            targetAgeAll={targetAgeAll}
            setTargetSexMale={setTargetSexMale}
            setTargetSexFemale={setTargetSexFemale}
            setTargetSexAll={setTargetSexAll}
            targetSexMale={targetSexMale}
            targetSexFemale={targetSexFemale}
            targetSexAll={targetSexAll}
            setTargetLocation={setTargetLocation}
            setNoTargetLocation={setNoTargetLocation}
            noTargetLocation={noTargetLocation}
          />
        )
      case 'mediaTypeMansionSignage':
        return (
          <GroupFormMansionSignage
            setTargetAge10={setTargetAge10}
            setTargetAge20={setTargetAge20}
            setTargetAge30={setTargetAge30}
            setTargetAge40={setTargetAge40}
            setTargetAge50={setTargetAge50}
            setTargetAge60={setTargetAge60}
            setTargetAge70={setTargetAge70}
            setTargetAge80={setTargetAge80}
            setTargetAgeAll={setTargetAgeAll}
            targetAge10={targetAge10}
            targetAge20={targetAge20}
            targetAge30={targetAge30}
            targetAge40={targetAge40}
            targetAge50={targetAge50}
            targetAge60={targetAge60}
            targetAge70={targetAge70}
            targetAge80={targetAge80}
            targetAgeAll={targetAgeAll}
            setTargetSexMale={setTargetSexMale}
            setTargetSexFemale={setTargetSexFemale}
            setTargetSexAll={setTargetSexAll}
            targetSexMale={targetSexMale}
            targetSexFemale={targetSexFemale}
            targetSexAll={targetSexAll}
            setTargetLocation={setTargetLocation}
            setNoTargetLocation={setNoTargetLocation}
            noTargetLocation={noTargetLocation}
            residentsProperty={residentsProperty}
            setResidentsProperty={setResidentsProperty}
            setNoResidentsProperty={setNoResidentsProperty}
            noResidentsProperty={noResidentsProperty}
            setMansionType={setMansionType}
            setNoMansionType={setNoMansionType}
            noMansionType={noMansionType}
            mansionType={mansionType}
          />
        )
      case 'mediaTypeLeaflet':
        return (
          <GroupFormLeaflet
            setTargetAge10={setTargetAge10}
            setTargetAge20={setTargetAge20}
            setTargetAge30={setTargetAge30}
            setTargetAge40={setTargetAge40}
            setTargetAge50={setTargetAge50}
            setTargetAge60={setTargetAge60}
            setTargetAge70={setTargetAge70}
            setTargetAge80={setTargetAge80}
            setTargetAgeAll={setTargetAgeAll}
            targetAge10={targetAge10}
            targetAge20={targetAge20}
            targetAge30={targetAge30}
            targetAge40={targetAge40}
            targetAge50={targetAge50}
            targetAge60={targetAge60}
            targetAge70={targetAge70}
            targetAge80={targetAge80}
            targetAgeAll={targetAgeAll}
            setTargetSexMale={setTargetSexMale}
            setTargetSexFemale={setTargetSexFemale}
            setTargetSexAll={setTargetSexAll}
            targetSexMale={targetSexMale}
            targetSexFemale={targetSexFemale}
            targetSexAll={targetSexAll}
            setTargetLocation={setTargetLocation}
            setNoTargetLocation={setNoTargetLocation}
            noTargetLocation={noTargetLocation}
            setIndustry={setIndustry}
            setNoIndustry={setNoIndustry}
            noIndustry={noIndustry}
            industry={industry}
          />
        )
      case 'mediaTypePoster':
        return (
          <GroupFormPoster
            setTargetAge10={setTargetAge10}
            setTargetAge20={setTargetAge20}
            setTargetAge30={setTargetAge30}
            setTargetAge40={setTargetAge40}
            setTargetAge50={setTargetAge50}
            setTargetAge60={setTargetAge60}
            setTargetAge70={setTargetAge70}
            setTargetAge80={setTargetAge80}
            setTargetAgeAll={setTargetAgeAll}
            targetAge10={targetAge10}
            targetAge20={targetAge20}
            targetAge30={targetAge30}
            targetAge40={targetAge40}
            targetAge50={targetAge50}
            targetAge60={targetAge60}
            targetAge70={targetAge70}
            targetAge80={targetAge80}
            targetAgeAll={targetAgeAll}
            setTargetSexMale={setTargetSexMale}
            setTargetSexFemale={setTargetSexFemale}
            setTargetSexAll={setTargetSexAll}
            targetSexMale={targetSexMale}
            targetSexFemale={targetSexFemale}
            targetSexAll={targetSexAll}
            setTargetLocation={setTargetLocation}
            setNoTargetLocation={setNoTargetLocation}
            noTargetLocation={noTargetLocation}
          />
        )
      case 'mediaTypeJack':
        return (
          <GroupFormJack
            setTargetAge10={setTargetAge10}
            setTargetAge20={setTargetAge20}
            setTargetAge30={setTargetAge30}
            setTargetAge40={setTargetAge40}
            setTargetAge50={setTargetAge50}
            setTargetAge60={setTargetAge60}
            setTargetAge70={setTargetAge70}
            setTargetAge80={setTargetAge80}
            setTargetAgeAll={setTargetAgeAll}
            targetAge10={targetAge10}
            targetAge20={targetAge20}
            targetAge30={targetAge30}
            targetAge40={targetAge40}
            targetAge50={targetAge50}
            targetAge60={targetAge60}
            targetAge70={targetAge70}
            targetAge80={targetAge80}
            targetAgeAll={targetAgeAll}
            setTargetSexMale={setTargetSexMale}
            setTargetSexFemale={setTargetSexFemale}
            setTargetSexAll={setTargetSexAll}
            targetSexMale={targetSexMale}
            targetSexFemale={targetSexFemale}
            targetSexAll={targetSexAll}
            setTargetLocation={setTargetLocation}
            setNoTargetLocation={setNoTargetLocation}
            noTargetLocation={noTargetLocation}
          />
        )
      default:
        return <p>ターゲットを指定するには媒体の種類を選択してください</p>
    }
  }

  return (
    <>
      <Header as="h2">広告グループを作成する</Header>
      <Grid stackable columns={1}>
        <Grid.Column>
          <Segment>
            <GroupFormBase
              mediaType={mediaType}
              setMediaType={setMediaType}
              groupName={groupName}
              setGroupName={setGroupName}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
              setEndDateMonth={setEndDateMonth}
              setEndDateWeek={setEndDateWeek}
              endDateMonth={endDateMonth}
              endDateWeek={endDateWeek}
              setBudget={setBudget}
              setNoBudget={setNoBudget}
              budget={budget}
              noBudget={noBudget}
            />
            {groupFormMediaType(mediaType)}
            <Spacer />
            {errorFlag ? (
              <>
                <Message
                  negative
                  header="エラー"
                  content={
                    mediaTypeError +
                    groupNameError +
                    startDateError +
                    endDateError +
                    budgetError +
                    targetLocationError +
                    targetAgeError +
                    targetSexError +
                    residentsPropertyError +
                    mansionTypeError +
                    industryError +
                    (groupNameError !== '' ||
                    startDateError !== '' ||
                    endDateError !== '' ||
                    budgetError !== '' ||
                    targetLocationError !== '' ||
                    targetAgeError !== '' ||
                    targetSexError !== ''
                      ? 'を入力してください。'
                      : '') +
                    endDateBillboardError
                  }
                />
                <Spacer />
              </>
            ) : (
              <></>
            )}
            <Grid centered columns={1}>
              <div>
                <Grid.Column>
                  <Button color="grey" onClick={() => history.push(`${paths.groupdashboard}`)}>
                    ダッシュボードに戻る
                  </Button>
                </Grid.Column>
              </div>
              <div>
                <Grid.Column>
                  <Button color="orange" onClick={() => createGroup()}>
                    編集を完了
                  </Button>
                </Grid.Column>
              </div>
            </Grid>
            <Spacer />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  )
}

export default AddGroupContent
