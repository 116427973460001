import React, { useEffect, useState, useContext } from 'react'
import { auth } from 'FirebaseConfig'
import NavigationBarAdmin from 'components/organisms/menubar/NavigationBarAdmin'
import { Container, Button } from 'semantic-ui-react'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import Spacer from 'components/atoms/Spacer'
import PageMessage from 'components/atoms/PageMessage'
import { UsersContext } from 'providers/Users'
import { isAdmin } from 'helpers/isAdmin'
import FooterNav from 'components/organisms/menubar/FooterNav'
import './index.css'

const AppLayoutAdmin = (props) => {
  const { users } = useContext(UsersContext)
  const [admin, setAdmin] = useState(true)
  const history = useHistory()

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user && users) {
        let ref = isAdmin(users, user.uid) // 管理者かどうかの判定
        setAdmin(ref)
      } else {
        history.push(`${paths.adminSignin}`) // 管理者でない場合は管理者ログイン画面へリダイレクト
      }
    })
  })

  return (
    <>
      <div className="appLayoutAdmin__appAdminLayout">
        <div className="appLayoutAdmin__navBarGridRow">
          <NavigationBarAdmin />
        </div>
        <div className="appLayoutAdmin__appGridContent" style={{ overflowX: 'auto' }}>
          <PageMessage />
          {admin ? (
            <>{props.children}</>
          ) : (
            <>
              <Spacer />
              <Container text>
                {' '}
                <h1>管理者権限のないユーザです </h1>
              </Container>
              <Container>
                <Button onClick={() => history.push(`${paths.lp}`)}>トップページへ移動</Button>
              </Container>
            </>
          )}
        </div>
        <div className="appLayoutAdmin__appGridFooter">
          <FooterNav />
        </div>
      </div>
    </>
  )
}

export default AppLayoutAdmin
