import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'providers/Auth'
import paths from 'constants/paths'
import { useHistory } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'
import AppLayout from 'components/organisms/layouts/AppLayout'
import GroupBreadcrumb from 'components/organisms/breadcrumb/GroupBreadcrump'
import AddGroupContent from 'components/organisms/group/AddGroupContent'
import Loading from 'components/atoms/Loading'
import AppLayoutGroupDashboard from 'components/organisms/layouts/AppLayoutGroup'
import { shouldSignin } from 'helpers/shouldSignin'

const AddGroup = () => {
  const [flagAngle, setFlagAngle] = useState(false)

  const { currentUser } = useContext(AuthContext)
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (shouldSignin(currentUser)) {
      history.push(`${paths.signin}`)
    }
  }, [currentUser])

  return (
    <>
      <AppLayout>
        <AppLayoutGroupDashboard flagAngle={flagAngle} setFlagAngle={setFlagAngle}>
          {loading ? (
            <Loading header={'Successful'} content={'グループ作成が成功しました。プランニングを表示します。'} />
          ) : (
            <>
              <Segment>
                <GroupBreadcrumb />
              </Segment>
              <AddGroupContent setLoading={setLoading} />
            </>
          )}
        </AppLayoutGroupDashboard>
      </AppLayout>
    </>
  )
}

export default AddGroup
