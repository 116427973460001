import React from 'react'
import { Card } from 'semantic-ui-react'
import MediaCardSingle from 'components/organisms/card/MediaCardSingle'
import Spacer from 'components/atoms/Spacer'

const RestarMediaConfirm = (props) => {
  return (
    <>
      <Spacer />
      {props.media && (
        <>
          <Card.Group itemsPerRow={1}>
            <MediaCardSingle media={props.media} />
          </Card.Group>
          <Spacer />
          <Card.Group itemsPerRow={1}>
            <Card>
              <Card.Content header="掲載を再開する日" as="h6" textAlign="center" />
              <Card.Content as="h3" textAlign="center">
                {new Date(props.restartDateRef).getFullYear()}年{/* Month +1 調整 */}
                {new Date(props.restartDateRef).getMonth() + 1}月{new Date(props.restartDateRef).getDate()}日
              </Card.Content>
            </Card>
          </Card.Group>
        </>
      )}
    </>
  )
}

export default RestarMediaConfirm
