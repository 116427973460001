import React, { useCallback, useEffect, useState } from 'react'
import AppLayoutAdmin from 'components/organisms/layouts/AppLayoutAdmin'
import AdminEditForm from 'components/organisms/admin/AdminEditMediaForm'
import { safeAlert } from 'helpers/admin/utils'
import { db, storage } from 'FirebaseConfig'
import { pos2mesh, calcRange } from 'helpers/admin/utils'
import MediaTypeSelect from 'components/molecules/radioGroup/MediaTypeSelect'

const EditMediaPage = () => {
  const [mediaInfo, setMediaInfo] = useState({})
  const [mediaId, setMediaId] = useState('')

  useEffect(() => {
    let unmounted = false
    ;(async () => {
      const lastPathString = location.pathname.split('/admin/editMedia/')[1]
      setMediaId(lastPathString)
      const mediaRef = db.collection('media').doc(lastPathString)
      const doc = await mediaRef.get()
      if (!doc.exists) {
        safeAlert(window, "Something went wrong! The media you requested doesn't exit")
        return
      }
      let loadedMedia = doc.data()
      const mediaImageObjPromises = loadedMedia.mediaImagePaths.map((path) => {
        return new Promise((resolve) => {
          storage
            .ref()
            .child(path)
            .getDownloadURL()
            .then((url) => resolve({ imageUrl: url, name: path, done: true }))
            .catch((error) => {
              console.error(error)
              alert(`誤った画像のpathが含まれています。path: ${path}`)
              resolve(null)
            })
        })
      })
      const mediaImageObjs = (await Promise.all(mediaImageObjPromises)).filter((url) => url !== null)
      const tagsSnapshot = await mediaRef.collection('tags').get()
      const tags = tagsSnapshot.docs.map((tagDoc) => {
        const tagData = tagDoc.data()
        return {
          name: tagData.name,
          id: tagDoc.id,
        }
      })
      const linesSnapshot = await mediaRef.collection('lines').get()
      const lines = linesSnapshot.docs.map((lineDoc) => {
        return lineDoc.id
      })

      const highwaysSnapshot = await mediaRef.collection('highways').get()
      const highways = highwaysSnapshot.docs.map((highwayDoc) => {
        return highwayDoc.id
      })

      if (!unmounted) {
        setMediaInfo({
          ...mediaInfo,
          ...loadedMedia,
          tags: tags,
          lines: lines,
          highways: highways,
          tempVisibility: loadedMedia.tempVisibility || 0,
          tempCirculation: loadedMedia.tempCirculation || 0,
          useTempVisibility: loadedMedia.tempVisibility !== null,
          useTempCirculation: loadedMedia.tempCirculation !== null,
          meshCD: pos2mesh(loadedMedia.latitude, loadedMedia.longitude),
          range: calcRange(loadedMedia.verticalWidth, loadedMedia.horizontalWidth),
          mediaImageObjs: mediaImageObjs,
        })
      }
    })()
    return () => {
      unmounted = true
    }
    // eslint-disable-next-line
  }, [location.pathname])

  const changeMediaType = useCallback((_e, { value }) => {
    setMediaInfo((prevState) => {
      return { ...prevState, mediaType: value }
    })
  }, [])

  return (
    <AppLayoutAdmin>
      <h1>媒体編集</h1>
      <MediaTypeSelect selectedMediaType={mediaInfo.mediaType} changeCallback={changeMediaType} />
      <hr />
      <AdminEditForm mediaInfo={mediaInfo} mediaID={mediaId} />
    </AppLayoutAdmin>
  )
}

export default EditMediaPage
