// Library
import React from 'react'
// import Sidebar from 'semantic-ui-react/dist/commonjs/modules/Sidebar';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import paths from 'constants/paths'
import logo from 'assets/pictures/advice-beta-logo-white.png'
import styled from '@emotion/styled'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'
import './index.css'

const NavigationBar = () => {
  const NavBar = styled.div`
     {
      background-color: #444;
      padding: 0 2rem;
      z-index: 999;
      height: 56px;
    }
  `

  const ImageWrapper = styled(Image)`
    height: 24px !important;
    width: auto !important;
  `
  const history = useHistory()

  const handleItemClick = (event, { name }) => {
    switch (name) {
      case 'root':
        history.push(`${paths.mediatop}`)
        break
      case 'signin':
        history.push(`${paths.signin}`)
        break
      case 'list':
        history.push(`${paths.mediasearch}`)
        break
      case 'media':
        history.push(`${paths.mapSearch}`)
        break
      case 'signinowner':
        history.push(`${paths.signinowner}`)
        break
      case 'contact':
        history.push(`${paths.contact}`)
        break
      case 'blogs':
        history.push(`${paths.blogs}`)
        break
      default:
        console.log('No key match')
    }
  }

  return (
    <NavBar>
      <Menu secondary size="massive" inverted>
        <Menu.Item fitted name="root" onClick={handleItemClick}>
          &nbsp;&nbsp;
          <ImageWrapper src={logo} size="mini" />
          &nbsp;&nbsp;
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item name="list" onClick={handleItemClick} className="navigationBar__headerText">
            リストから媒体検索
          </Menu.Item>
          <Menu.Item name="media" onClick={handleItemClick} className="navigationBar__headerText">
            マップで媒体検索
          </Menu.Item>
          <Menu.Item name="blogs" onClick={handleItemClick} className="navigationBar__headerText">
            フィード
          </Menu.Item>
          <Menu.Item name="contact" onClick={handleItemClick} className="navigationBar__headerText">
            お問い合わせ
          </Menu.Item>
          <Menu.Item name="signin" className="navigationBar__headerText" onClick={handleItemClick}>
            ログイン
          </Menu.Item>

          {/* <Menu.Item
                name='signinowner'
                onClick={handleItemClick}
                className="headerText"
              >
                媒体主としてログイン
              </Menu.Item> */}
        </Menu.Menu>
      </Menu>
    </NavBar>
  )
}

export default withRouter(NavigationBar)
