import Spacer from 'components/atoms/Spacer'
import { Package } from 'components/organisms/admin/PackageTable/helper'
import PackagePdfDownloader from 'components/organisms/buttons/PackagePdfDownloader'
import { allTargetAgeArray } from 'constants/constants'
import { mediaTypeDisplayNames } from 'constants/mediaTypes'
import { firestore } from 'firebase'
import { mediaType } from 'helpers/mediaType/mediaDef'
import { useAuthContext } from 'providers/Auth'
import { useMedias } from 'providers/Medias'
import React, { useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'
import { Button, Grid, Header } from 'semantic-ui-react'
import { AdviceMediaDataTypeWithPeriod, splitMediaListByMediaType } from '../PackageSubtotalList/helper'
import { createCart, displayMethods, calcTotalCost, splitMediaListByPeriod } from './helper'

type props = {
  mediaPackage: Package
}
const PackageSummary = (props: props) => {
  const mediaList = useMedias()
  const { currentUser } = useAuthContext()

  const totalCost = useMemo(() => {
    return calcTotalCost(props.mediaPackage, mediaList)
  }, [mediaList, props.mediaPackage])

  const handleAddPackageToCart = useCallback(async () => {
    const splitMediaListObj = splitMediaListByMediaType(props.mediaPackage.mediaList, mediaList)
    const promises = (Object.keys(splitMediaListObj) as mediaType[]).map(async (mediaType) => {
      const packageMediaList = splitMediaListObj[mediaType] as AdviceMediaDataTypeWithPeriod[]
      const splitPackageMediaList = splitMediaListByPeriod(packageMediaList)
      const promises = splitPackageMediaList.map(async (mediaList) => {
        const weeks = mediaList[0].weeks
        const months = mediaList[0].months
        const periodDisplay = weeks ? `${weeks}週間` : `${months}ヶ月間`
        const groupName = `${props.mediaPackage.name}(${mediaTypeDisplayNames[mediaType]},${periodDisplay})`
        const createAt = new Date(Date.now())
        const startDate = firestore.Timestamp.fromDate(createAt)
        const purchaceDays = mediaList[0].weeks ? mediaList[0].weeks * 7 : mediaList[0].months && mediaList[0].months * 31
        if (!purchaceDays || !currentUser || typeof totalCost === 'string') {
          throw new Error('パッケージまたは、ユーザー情報に不備があります。')
        }
        const endDate = firestore.Timestamp.fromDate(new Date(createAt.getTime() + purchaceDays * 24 * 60 * 60 * 1000))
        await createCart({
          budget: totalCost,
          createAt,
          groupName,
          id: mediaList.map((media) => media.uid),
          mediaCount: mediaList.length,
          mediaType: mediaType,
          startDate,
          endDate,
          endDateMonth: Math.ceil(purchaceDays / 31),
          endDateWeek: Math.ceil(purchaceDays / 7),
          status: 1,
          targetAge: allTargetAgeArray,
          targetLocation: [],
          targetSex: 'unisex',
          userId: currentUser.uid,
        })
        return groupName
      })
      const groupNames = await Promise.all(promises)
      return groupNames
    })
    try {
      const groupNames = (await Promise.all(promises)).flat()
      const message = (
        <>
          <div>パッケージのカート追加に成功しました。</div>
          {groupNames.map((name, index) => (
            <div key={name}>
              No.{index + 1}:{name}
            </div>
          ))}
        </>
      )
      toast.success(message)
      toast.info(
        <div>
          <div>作成されたグループは</div>
          <div>ダッシュボード画面から確認できます。</div>
        </div>,
        {
          autoClose: 20000,
        }
      )
    } catch (error) {
      console.error(error)
      toast.error('パッケージの追加に失敗しました。')
    }
  }, [mediaList])

  return (
    <div>
      <Header as="h4">パッケージ内容</Header>
      <Grid>
        {displayMethods.map((method) => {
          return (
            <Grid.Row key={method.name}>
              <Grid.Column width={6}>
                <b>{method.name}</b>
              </Grid.Column>
              <Grid.Column width={10}>{method.method(props.mediaPackage, mediaList)}</Grid.Column>
            </Grid.Row>
          )
        })}
      </Grid>
      <Spacer />
      <Button color="orange" onClick={handleAddPackageToCart}>
        カートに入れる
      </Button>
      <PackagePdfDownloader packageId={props.mediaPackage.id} />
    </div>
  )
}

export default PackageSummary
