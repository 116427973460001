// Library
import React from 'react'
import { Container, Header, Button } from 'semantic-ui-react'
import Spacer from 'components/atoms/Spacer'
import paths from 'constants/paths'
import NavigationBarPlane from 'components/organisms/menubar/NavigationBarPlane'
import { useHistory } from 'react-router-dom'
import FooterNav from 'components/organisms/menubar/FooterNav'

const VerifyEmail = () => {
  const history = useHistory()

  const moveToSignIn = () => {
    history.push({
      pathname: `${paths.signin}`,
      state: {
        text: 'ログインページに移動します',
        type: 'positive',
      },
    })
  }

  return (
    <>
      <NavigationBarPlane />
      <Container>
        <Spacer />
        <Header as="h2">アカウントの登録を受け付けました</Header>
        <p>ご登録頂いたメールアドレス宛に確認用のメールを送付いたしました。</p>
        <p>メールの内容を確認して、AdViceアカウントの登録確認を完了してください。</p>
        <p>上記の確認作業が完了しないと、AdViceのサービスが利用できません。</p>
        <Button onClick={moveToSignIn}>ログイン画面へ</Button>
        <Spacer />
      </Container>
      <FooterNav />
    </>
  )
}

export default VerifyEmail
