import { SexPieChartDispMethod, AgePieChartDispMethod, SexRatioByAgeChartDispMethod, getCol, parseArea } from './helpers'
import IndustryOptions from 'constants/IndustryOptions'
import { taxRate as defaultTaxRate } from 'constants/constants'
import { getPeriod } from 'helpers/mediaType/helpers'
import { types } from 'constants/types'
import { AdviceMediaDataType, isJackChild } from 'constants/mediaData'
import { roundAndAddThousandsSeparator } from 'helpers/roundAndAddThousandsSeparator'

export const mediaTypeLeaflet = {
  name: 'リーフレット',
  basicInfoFields: [
    {
      displayedName: '住所',
      displayedSuffix: '',
      displayedPrefix: '',
      valueType: 'string',
      displayMethod: getCol('address'),
    },
    {
      displayedName: '緯度',
      displayedPrefix: 'N',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: getCol('latitude'),
    },
    {
      displayedName: '経度',
      displayedPrefix: 'E',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: getCol('longitude'),
    },
    {
      displayedName: '週額掲載費',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: (media) =>
        media.isPrivate
          ? '価格非公開'
          : isJackChild(media)
          ? '-'
          : `¥${roundAndAddThousandsSeparator(media.weeklyCost * media.marginRate * defaultTaxRate, 1)}`,
    },
    {
      displayedName: '配布費',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'number',
      displayMethod: (media) =>
        getCol('distributionCost', { isTaxIncluded: true, isMarginIncluded: true, isCurrency: true, shouldFilterJackChild: true })(media),
    },
    {
      displayedName: 'エリア',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'string',
      displayMethod: (media) => parseArea(media['address']),
    },
    {
      displayedName: '設置場所業種',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'string',
      displayMethod: (media) => {
        const result = IndustryOptions.find((v) => v.key === media.industry)
        return result ? result.text : '-'
      },
    },
    {
      displayedName: '設置施設名',
      displayedPrefix: '',
      displayedSuffix: '',
      valueType: 'string',
      displayMethod: getCol('storeName') || '未登録',
    },
  ],
  //TODO:媒体一覧かカート編集かによって表示する項目自体も変わる
  effectIndicatorFields: [
    {
      fieldName: 'null',
      displayedName: '効果指標はただいま作成中です',
      displayedPrefix: '',
      displayedSuffix: '',
      summary: null,
      displayMethod: {
        mediaSearch: null,
        cartEdit: null,
      },
    },
    {
      fieldName: 'null',
      displayedName: '費用対効果指標はただいま作成中です',
      displayedPrefix: '',
      displayedSuffix: '',
      summary: null,
      displayMethod: {
        mediaSearch: null,
        cartEdit: null,
      },
    },
  ],
  cardContent: {
    mediaSearch: [
      {
        displayedName: '価格/週',
        prefix: '¥',
        suffix: '',
        displayMethod: (media) => (isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(media.weeklyCost * media.marginRate * defaultTaxRate, 1)}`),
      },
      {
        displayedName: '設置施設業種',
        displayMethod: (media) => {
          const result = IndustryOptions.find((v) => v.key === media.industry)
          return result ? result.text : '-'
        },
      },
    ],
    cartEdit: [
      {
        displayedName: '価格/週',
        displayMethod: (media, period) =>
          isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(calcLeafletCost(media, period.weeks, true, true, false), 1)}`,
      },
      {
        displayedName: '設置施設業種',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayMethod: (media, period) => {
          const result = IndustryOptions.find((v) => v.key === media.industry)
          return result ? result.text : '-'
        },
      },
    ],
  },
  cartEditMediaDetailEffectTable: [
    {
      displayedName: '効果指標はただいま作成中です',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (args) => null,
    },
    {
      displayedName: '費用対効果指標はただいま作成中です',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (args) => null,
    },
  ],
  cartFooterContent: [
    {
      displayedName: '総週費用',
      displayMethod: (bundleSummary: bundleSummaryDTypeLeaflet) => `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForWeek, 1)}`,
    },
  ],
  cartRecommendSummary: [
    {
      displayedName: '合計週額費用',
      displayMethod: (bundleSummary: bundleSummaryDTypeLeaflet) => `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForWeek, 1)}`,
    },
  ],
  propertyFields: [
    {
      fieldName: 'totalSexRatio',
      displayedName: '性別',
      graphType: 'pie',
      displayMethod: SexPieChartDispMethod,
    },
    {
      fieldName: 'ageRatio',
      displayedName: '年齢',
      graphType: 'pie',
      displayMethod: AgePieChartDispMethod,
    },
    {
      fieldName: 'sexRatioByAge',
      displayedName: '年代別性別比',
      graphType: 'stackedBar',
      displayMethod: SexRatioByAgeChartDispMethod,
    },
  ],
  cartConfirmCard: {
    header: ['媒体', '掲載週数', '単週掲載費', '配布費', '小計'],
    contents: [
      {
        displayedName: '掲載週数',
        displayMethod: (media, period) => period.weeks + '週間',
      },
      {
        displayedName: '単週掲載費',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayMethod: (media, period) =>
          isJackChild(media) ? '-' : `${roundAndAddThousandsSeparator(media.weeklyCost * media.marginRate * defaultTaxRate, 1)}`,
      },
      {
        displayedName: '配布費',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayMethod: (media, period) =>
          isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(media.distributionCost * media.marginRate * defaultTaxRate, 1)}`,
      },
      {
        displayedName: '小計',
        displayMethod: (media, period) => {
          return isJackChild(media) ? '-' : `¥${roundAndAddThousandsSeparator(calcLeafletCost(media, period.weeks, true, true, true), 1)}`
        },
      },
    ],
  },
  cartConfirmSummary: [
    {
      displayedName: '媒体数',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeLeaflet) => mediaSortedList.length,
    },
    {
      displayedName: '掲載期間',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeLeaflet) => getPeriod(cart),
    },
    {
      displayedName: '掲載費合計(税抜)',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeLeaflet) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalPublicationCostForEntirePeriod, 1)}`,
    },
    {
      displayedName: '配布費合計(税抜)',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeLeaflet) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalDistributionCost, 1)}`,
    },
    {
      displayedName: '消費税',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeLeaflet) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForEntirePeriod * (defaultTaxRate - 1), 1)}`,
    },
    {
      displayedName: '合計金額(税込)',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayMethod: (mediaSortedList: AdviceMediaDataType[], cart, bundleSummary: bundleSummaryDTypeLeaflet) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalOverallCostForEntirePeriod * defaultTaxRate, 1)}`,
    },
  ],
  cartConfirmFooter: [
    {
      displayedName: '媒体掲載費合計(税込)',
      displayMethod: (bundleSummary: bundleSummaryDTypeLeaflet) =>
        `¥${roundAndAddThousandsSeparator(bundleSummary.totalPublicationCostForEntirePeriod * defaultTaxRate, 1)}`,
    },
    {
      displayedName: '配布費合計(税込)',
      displayMethod: (bundleSummary: bundleSummaryDTypeLeaflet) => `¥${roundAndAddThousandsSeparator(bundleSummary.totalDistributionCost * defaultTaxRate, 1)}`,
    },
  ],
  culcBundleSummary: (mediaList, period, isTaxIncluded) => {
    const taxRate: number = isTaxIncluded ? defaultTaxRate : 1
    const bundleSummary: bundleSummaryDTypeLeaflet = {
      totalOverallCostForEntirePeriod: 0,
      totalOverallCostForWeek: 0,
      totalPublicationCostForEntirePeriod: 0,
      totalPublicationCostForWeek: 0,
      totalDistributionCost: 0,
      mediaType: 'mediaTypeLeaflet',
    }
    mediaList
      .filter((medium) => !isJackChild(medium))
      .forEach((medium) => {
        bundleSummary.totalPublicationCostForWeek += medium.weeklyCost * medium.marginRate * taxRate
        bundleSummary.totalDistributionCost += medium.distributionCost * medium.marginRate * taxRate
      })
    bundleSummary.totalPublicationCostForEntirePeriod += bundleSummary.totalPublicationCostForWeek * period.weeks
    bundleSummary.totalOverallCostForEntirePeriod = bundleSummary.totalPublicationCostForEntirePeriod + bundleSummary.totalDistributionCost
    bundleSummary.totalOverallCostForWeek = bundleSummary.totalOverallCostForEntirePeriod / period.weeks
    return bundleSummary
  },
  purchasePeriodUnit: 'week',
} as const

export const leafletColumnDtypes = {
  address: types.string,
  latitude: types.number,
  longitude: types.number,
  weeklyCost: types.number,
  distributionCost: types.number,
  industry: types.string,
  storeName: types.string,
  mediaType: types.enum,
  //TODO: arrayにして、複数の画像のパスを入力できるようにする
  mediaImagePaths: types.stringArrayElem,
  name: types.string,
  note: types.string,
  summary: types.string,
  uid: types.string,
  isPrivate: types.bool,
  ageRatio: types.string,
  femaleAgeRatio: types.map,
  maleAgeRatio: types.map,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { weeklyCost, distributionCost, ...others } = leafletColumnDtypes
export const jackChildLeafletColumnDtypes = { ...others, parentUid: types.string }

export const industryOptions = [
  {
    name: 'コンビニ',
    value: 'convenience',
  },
  {
    name: '駅',
    value: 'station',
  },
  {
    name: '飲食店',
    value: 'restaurant',
  },
  {
    name: '書店',
    value: 'bookStore',
  },
  {
    name: 'スーパー',
    value: 'supermarket',
  },
  {
    name: 'ショッピングセンター',
    value: 'mall',
  },
  {
    name: 'スクール',
    value: 'school',
  },
  {
    name: '教育機関',
    value: 'education',
  },
  {
    name: 'ドラッグストア',
    value: 'pharmacy',
  },
]

//mediaTypeLeafletの価格計算関数
export const calcLeafletCost = (media /*:adviceMediaDataType*/, weeks: number, isTaxIncluded: boolean, isOverall: boolean, isForEntirePeriod: boolean) => {
  const taxRate: number = isTaxIncluded ? defaultTaxRate : 1
  const distributionCost: number = isOverall ? media.distributionCost * media.marginRate : 0
  const costForEntirePeriod: number = (media.weeklyCost * weeks + distributionCost) * media.marginRate * taxRate
  const result = isForEntirePeriod ? costForEntirePeriod : costForEntirePeriod / weeks
  return result
}

// total: 全媒体について合計する
// overall: 掲載費用だけでなく、施工回復費用など全ての種類の費用を計上する
// forEntirePeriod: 出稿期間全体について費用を算出する
export type bundleSummaryDTypeLeaflet = {
  totalOverallCostForEntirePeriod: number //全ての種類の費用、全ての期間の合計費用
  totalOverallCostForWeek: number //全ての種類の費用、1週間あたりの費用
  totalPublicationCostForEntirePeriod: number
  totalPublicationCostForWeek: number
  totalDistributionCost: number
  mediaType: 'mediaTypeLeaflet'
}
