import React from 'react'
import AppLayoutAdmin from 'components/organisms/layouts/AppLayoutAdmin'
import PackageTable from 'components/organisms/admin/PackageTable'

const AdminPackages = () => {
  return (
    <AppLayoutAdmin>
      <h2>パッケージ一覧</h2>
      <hr />
      <PackageTable />
    </AppLayoutAdmin>
  )
}

export default AdminPackages
